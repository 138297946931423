import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import IssueComponent from 'src/components/support/Issue';

const Issue = () => {
  return (
    <DashboardLayout>
      <IssueComponent />
    </DashboardLayout>
  );
};

export default Issue;
