import React from 'react';
import { formatDate, formatTime } from 'src/utils/helpers';
import Icon from 'src/components/common/Icon';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import YawoUserVerification from 'src/components/yawo/YawoUserVerification';
import { Link } from 'react-router-dom';
import {formatAmount} from 'src/utils/helpers';

export const agentsTableHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Name</p>;
    },
    render: function DateTaken(rowData) {
      return (
        <p>
          {rowData['firstName']} {rowData['lastName']} {rowData['otherNames']}
        </p>
      );
    },
  },
  {
    title: function title() {
      return <p>Email Address</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['email']?.address}</p>;
    },
  },
  {
    title: function title() {
      return <p>Is Verified</p>;
    },
    render: function DateTaken(rowData) {
      return <YawoUserVerification user={rowData} />;
    },
  },
  {
    title: function title() {
      return <p>MMO ShortCode</p>;
    },
    render: function DateTaken(rowData) {
      return (
        <Link className="linked-shortcode" to={`/users/mmo/${rowData['vendor']?.shortCode}`}>
          {rowData['vendor']?.shortCode}
        </Link>
      );
    },
  },
  {
    title: function title() {
      return <p>Yawo Wallet</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{rowData['yawoWallet'] || ''}</p>;
    },
  },
  {
    title: function title() {
      return <p>Loan Limit</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{rowData['loanLimit'] ? formatAmount(rowData['loanLimit']) : '-'}</p>;
    },
  },
  {
    title: function title() {
      return <p>Date</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{formatDate(rowData['createdAt'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Time</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{formatTime(rowData['createdAt'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>View</p>;
    },
    render: function SerialNumber(rowData) {
      return <Icon Icon={OpenInNewIcon} type="link" to={`agents/${rowData['_id']}`} />;
    },
  },
];
