import React from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/common/table/Table';
import { mmoTableHeader } from './mmoTableHeader';
import { sortTableData } from 'src/utils/helpers';
import MmoSearch from './MmoSearch';

const MmoTable = ({ tableData, onPageChange, onLimitChange }) => {
  const leftHeader = <MmoSearch />;
  const rightHeader = null;

  return (
    <div>
      <Table
        headers={mmoTableHeader}
        rightHeader={rightHeader}
        leftHeader={leftHeader}
        data={tableData && tableData.mmos ? sortTableData(tableData.mmos, {}) : []}
        limit={tableData && tableData.limit}
        page={tableData && tableData.page}
        count={tableData && tableData.count}
        showNav={true}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </div>
  );
};

MmoTable.defaultProps = {
  tableData: { data: [], limit: 0, page: 1, count: 0 },
};

MmoTable.propTypes = {
  tableData: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
};

export default MmoTable;
