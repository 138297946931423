import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import LoansComponent from 'src/components/yawo/loans/Loans';

const Loans = () => {
  return (
    <DashboardLayout>
      <LoansComponent />
    </DashboardLayout>
  );
};

export default Loans;
