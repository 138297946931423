import React from 'react';
import { formatAmount, formatDate } from 'src/utils/helpers';

export const chargebackTableHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>RRN</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['rrn']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Vendor</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['vendor']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Terminal ID</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['terminalId']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Amount</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{formatAmount(rowData['amount'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['status']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Date Lodged</p>;
    },
    render: function SerialNumber(rowData) {
      return (
        <p>{rowData['timeCreated'] && rowData['timeCreated'] !== 'None' ? formatDate(rowData['timeCreated']) : ''}</p>
      );
    },
  },
];
