import React, { useState, useEffect, useCallback } from 'react';
import { Card } from '@material-ui/core';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { formatDate, formatAmount, sortPlatforms } from 'src/utils/helpers';

import { DateRangeInput, SelectInput } from 'src/components/common/inputs';
import TransactionsTable from './TransactionsTable';
import DisplayCard from 'src/components/common/DisplayCard';
import TypesSideBar from 'src/components/layout/SideNav';
import Icon from 'src/components/common/Icon';
import {
  getAllTransactions,
  getTransactionsByType,
  setTransactionPage,
  setTransactionLimit,
  setTransactionDate,
  setTransactionStatus,
  resetTransactionDate,
  setTransactionSearchString,
  getPosActivationTransactions,
} from 'src/store/actions/transactionActions';

// Table Icons
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';

// SideNav Icons
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import TransformIcon from '@material-ui/icons/Transform';
import TimelineIcon from '@material-ui/icons/Timeline';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import SettingsCellIcon from '@material-ui/icons/SettingsCell';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  .transaction_body {
    padding: 1.5rem;
    display: grid;
    grid-gap: 1rem;
    height: max-content;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .platform_select {
    display: flex;
    justify-content: space-between;
  }
  .platform {
    display: grid;
    width: max-content;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 0.5rem;
  }
  .date_range {
    padding: 0rem 0.5rem;
    display: grid;
    border-radius: 5px;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const sideBarIcons = [
  { title: 'Overview', value: 'overview', icon: TimelineIcon },
  { title: 'Withdrawals', value: 'withdrawals', icon: LocalAtmIcon },
  { title: 'Transfers', value: 'transfers', icon: TransformIcon },
  { title: 'Airtime', value: 'airtime', icon: LocalPhoneIcon },
  { title: 'Data', value: 'data', icon: DataUsageIcon },
  { title: 'Tv Cable', value: 'tv-cable', icon: LiveTvIcon },
  { title: 'PHCN', value: 'electricity', icon: EmojiObjectsIcon },
  { title: 'Wallet Topup', value: 'wallet-topup', icon: FormatColorFillIcon },
  { title: 'Collections', value: 'collections', icon: TransformIcon },
  { title: 'POS Activation', value: 'pos-activation', icon: SettingsCellIcon },
];

const Transactions = ({
  getAllTransactions,
  getPosActivationTransactions,
  getTransactionsByType,
  transactions,
  setTransactionPage,
  setTransactionLimit,
  setTransactionDate,
  setTransactionStatus,
  resetTransactionDate,
  setTransactionSearchString,
  platforms,
  appIsActive,
}) => {
  const [platform, setPlatform] = useState('ikonik');
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState('overview');
  const [state, setState] = useState('overview');
  const [subType, setSubType] = useState(' ');

  const getTransactions = useCallback(
    (type, platform, refresh = false) => {
      type === 'overview'
        ? getAllTransactions(platform, refresh)
        : type === 'pos-activation'
        ? getPosActivationTransactions(platform, refresh)
        : getTransactionsByType(platform, type, refresh);
    },
    [getAllTransactions, getTransactionsByType]
  );

  useEffect(() => {
    getTransactions(state, platform);
  }, [state, platform, getTransactions]);

  appIsActive;

  /* 	useEffect(() => {
		let timeout;
		if (appIsActive) {
			timeout = setInterval(() => {
				getTransactions(state, platform, true);
			}, 30000);
		} else {
			clearInterval(timeout);
		}
		return ()=> {
			clearInterval(timeout);
		};
	}, [appIsActive, state, platform]); */

  const toggle = () => setIsOpen(!isOpen);

  const onTypeChange = (type) => {
    setTransactionSearchString('');
    setType(type);
    setSubType(' ');
    setTransactionPage(1);
    setTransactionStatus('all');
    setState(type);
  };

  const onPlatformChange = (platform) => {
    setTransactionSearchString('');
    setPlatform(platform);
    setTransactionPage(1);
    if (type !== 'pos-activation') {
      setType('overview');
      setSubType(' ');
      setTransactionStatus('all');
      setState('overview');
    }
  };

  const onPageChange = (page) => {
    setTransactionPage(page);
    getTransactions(state, platform);
  };

  const onLimitChange = (limit) => {
    setTransactionLimit(limit);
    getTransactions(state, platform);
  };

  const onResetDateClick = () => {
    resetTransactionDate();
    getTransactions(state, platform);
  };

  const onStatusChange = (status) => {
    setTransactionStatus(status);
    getTransactions(state, platform);
  };

  const onDateChange = (date) => {
    setTransactionDate(date.startDate, date.endDate);
    getTransactions(state, platform);
  };

  const onSubTypeChange = (type) => {
    setTransactionSearchString('');
    setSubType(type);
    setState(type);
  };

  const onRefreshClick = () => {
    getTransactions(state, platform);
  };

  return (
    <Wrapper>
      <TypesSideBar title="Transactions" type={type} setType={onTypeChange} options={sideBarIcons} />
      <div className="transaction_body">
        <div className="cards">
          <DisplayCard
            icon={WorkOutlineIcon}
            title="Total Volume"
            value={transactions !== null && transactions.count}
          />
          <DisplayCard
            icon={AccountBalanceIcon}
            title="Total Value"
            value={transactions !== null && formatAmount(transactions.value)}
          />
          <DisplayCard
            icon={AccountBalanceWalletIcon}
            title="Total Revenue"
            value={transactions !== null && formatAmount(transactions.revenue)}
          />
          <DisplayCard
            icon={TrendingUpIcon}
            title="Total Income"
            value={transactions !== null && formatAmount(transactions.income)}
          />
          <DisplayCard
            icon={TrendingUpIcon}
            title="Ikonik Income"
            value={transactions !== null && formatAmount(transactions.ikonik_income)}
          />
        </div>
        <div className="platform_select">
          <div className="platform">
            <h4>Select platform:</h4>
            <SelectInput options={sortPlatforms(platforms)} value={platform} onChange={onPlatformChange} />
          </div>
          <Card className="date_range">
            <h6>{`${transactions === null ? '' : formatDate(transactions.start_date)} - ${
              transactions === null ? '' : formatDate(transactions.end_date)
            }`}</h6>
            <Icon Icon={DateRangeIcon} alt="Set Date" onClick={toggle} />
            <Icon Icon={RotateLeftIcon} alt="Reset Date" onClick={onResetDateClick} />
          </Card>
        </div>
        <TransactionsTable
          subType={subType}
          setSubType={onSubTypeChange}
          type={type}
          state={state}
          platform={platform}
          tableData={transactions}
          onPageChange={onPageChange}
          onLimitChange={onLimitChange}
          onStatusChange={onStatusChange}
          onRefreshClick={onRefreshClick}
          getTransactions={() => getTransactions(state, platform)}
        />
      </div>
      <div className="range_input">
        <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
      </div>
    </Wrapper>
  );
};

Transactions.propTypes = {
  getAllTransactions: PropTypes.func.isRequired,
  getPosActivationTransactions: PropTypes.func.isRequired,
  getTransactionsByType: PropTypes.func.isRequired,
  transactions: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(null)]),
  setTransactionLimit: PropTypes.func.isRequired,
  setTransactionPage: PropTypes.func.isRequired,
  setTransactionDate: PropTypes.func.isRequired,
  setTransactionStatus: PropTypes.func.isRequired,
  resetTransactionDate: PropTypes.func.isRequired,
  setTransactionSearchString: PropTypes.func.isRequired,
  platforms: PropTypes.object.isRequired,
  appIsActive: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { transactions } = state.transaction;
  const { platforms } = state.platform;
  const { isActive } = state.app;
  return {
    transactions,
    platforms,
    appIsActive: isActive,
  };
};

export default connect(mapStateToProps, {
  getAllTransactions,
  getPosActivationTransactions,
  getTransactionsByType,
  setTransactionStatus,
  setTransactionDate,
  setTransactionLimit,
  setTransactionPage,
  resetTransactionDate,
  setTransactionSearchString,
})(Transactions);
