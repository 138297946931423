import querystring from 'querystring';
import client from 'src/client';
import {
  ADD_YAWO_AGENTS,
  ADD_YAWO_AGENT,
  YAWO_AGENT_IS_LOADING,
  YAWO_AGENT_NOT_LOADING,
  SET_YAWO_AGENT_DATE,
  RESET_YAWO_AGENT_DATE,
  SET_YAWO_AGENT_LIMIT,
  SET_YAWO_AGENT_STATUS,
  SET_YAWO_AGENT_PAGE,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from 'src/store/actions/alertActions';

const { adminService } = client;

const yawoAgentIsLoading =
  (msg = 'Loading Yawo Agents') =>
  (dispatch) => {
    dispatch({ type: YAWO_AGENT_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const yawoAgentNotLoading = () => (dispatch) => {
  dispatch({ type: YAWO_AGENT_NOT_LOADING });
};

const addYawoAgents = (yawoAgents) => {
  return { type: ADD_YAWO_AGENTS, payload: yawoAgents };
};

const addYawoAgent = (yawoAgent) => {
  return { type: ADD_YAWO_AGENT, payload: yawoAgent };
};

export const setYawoAgentDate = (start_date, end_date) => {
  return { type: SET_YAWO_AGENT_DATE, payload: { start_date, end_date } };
};

export const resetYawoAgentDate = () => {
  return { type: RESET_YAWO_AGENT_DATE };
};

export const setYawoAgentPage = (page) => {
  return { type: SET_YAWO_AGENT_PAGE, payload: page };
};

export const setYawoAgentLimit = (limit) => {
  return { type: SET_YAWO_AGENT_LIMIT, payload: limit };
};

export const setYawoAgentStatus = (status) => {
  return { type: SET_YAWO_AGENT_STATUS, payload: status };
};

const generatePath = (endPoint, yawoAgent, query) => {
  const { limit, page } = yawoAgent || {};
  query = {
    page,
    limit,
    ...query,
  };
  if (query.page === undefined) delete query.page;
  if (query.limit === undefined) delete query.limit;
  let url = endPoint;
  if (query && Object.keys(query).length > 0) {
    url += `?${querystring.encode(query)}`;
  }
  return url;
};

export const getAllYawoAgents =
  (query = {}) =>
  (dispatch, getState) => {
    dispatch(yawoAgentIsLoading(query?.search ? 'Searching...' : 'Loading Yawo Agents'));
    const { yawoAgents } = getState().yawoAgent;
    const path = generatePath('/yawo/users/agents', yawoAgents, query);
    adminService
      .get(path)
      .then((res) => {
        dispatch(addYawoAgents(res?.data?.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Agents' })))
      .finally(() => dispatch(yawoAgentNotLoading()));
  };

export const getSingleYawoAgent = (userId, extraParams) => (dispatch) => {
  const { silently = false } = extraParams || {};
  if (!silently) {
    dispatch(yawoAgentIsLoading('Loading Yawo Agent'));
  }
  adminService
    .get(`/yawo/users/${userId}`)
    .then((res) => {
      dispatch(addYawoAgent(res?.data?.data));
      dispatch(clearAlert());
    })
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Agent' })))
    .finally(() => dispatch(yawoAgentNotLoading()));
};

export const updateLoanLimit =
  ({ agentId, loanLimit }, callback) =>
  (dispatch) => {
    dispatch(yawoAgentIsLoading('Updating Loan Limit ...'));
    adminService
      .post('/yawo/users/agents/loan-limit', { agentId, loanLimit })
      .then((res) => {
        callback(res?.data?.data);
        dispatch(successAlert('Loan Limit Updated'));
      })
      .catch((err) => {
        dispatch(errorAlert({ msg: err?.response?.data?.message || 'Error updating loan limit' }));
      })
      .finally(() => dispatch(yawoAgentNotLoading()));
  };

export const activateAgent = (userId) => (dispatch) => {
  dispatch(yawoAgentIsLoading('Activating Agent ...'));
  adminService
    .get(`/yawo/users/${userId}/activate`)
    .then((res) => {
      dispatch(addYawoAgent(res?.data?.data));
      dispatch(successAlert('Agent Activated'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data?.message || 'Error activating agent' }));
    })
    .finally(() => dispatch(yawoAgentNotLoading()));
};

export const suspendAgent = (userId) => (dispatch) => {
  dispatch(yawoAgentIsLoading('Suspending Agent ...'));
  adminService
    .get(`/yawo/users/${userId}/suspend`)
    .then((res) => {
      dispatch(addYawoAgent(res?.data?.data));
      dispatch(successAlert('Agent Suspended'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data?.message || 'Error suspending agent' }));
    })
    .finally(() => dispatch(yawoAgentNotLoading()));
};

export const verifyAgent = (agentId) => (dispatch) => {
  dispatch(yawoAgentIsLoading('Verifying Agent ...'));
  adminService
    .post('/yawo/users/agents/verify', { agentId })
    .then((res) => {
      dispatch(addYawoAgent(res?.data?.data));
      dispatch(successAlert('Agent Verified'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data?.message || 'Error verifying agent' }));
    })
    .finally(() => dispatch(yawoAgentNotLoading()));
};

export const loadAgentOptions = async (searchString) => {
  const options = await adminService
    .get(`/yawo/users/agents?search=${searchString}&limit=50`)
    .then((res) => res?.data?.data?.data || [])
    .catch(() => []);

  return options
    .map((x) => ({
      ...x,
      label: `${x.firstName} ${x.lastName} // ${x?.vendor?.shortCode} // ${x?.email?.address}`,
      value: x._id,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
};
