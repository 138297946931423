import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  getAllReconciliations,
  setReconciliationLimit,
  setReconciliationPage,
  setReconciliationStatus,
} from 'src/store/actions/reconciliationActions';
import ExtractTransactions from './ExtractTransactions';
import ResolveReconciliations from './ResolveReconciliations';
import ReconciliationTable from './ReconciliationTable';
import FinanceLayout from 'src/components/finance/FinanceLayout';
import { SelectInput } from 'src/components/common/inputs';
import { sortPlatforms } from 'src/utils/helpers';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  height: max-content;
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .platform {
    display: grid;
    width: max-content;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 0.5rem;
  }
`;

const Reconciliations = ({
  platforms,
  reconciliations,
  getAllReconciliations,
  setReconciliationLimit,
  setReconciliationPage,
  setReconciliationStatus,
}) => {
  const [platform, setPlatform] = useState('ikonik');

  const getReconciliations = useCallback(
    (platform) => {
      platform === 'ikonik' ? getAllReconciliations('all') : getAllReconciliations(platform);
    },
    [getAllReconciliations]
  );

  useEffect(() => {
    setReconciliationPage(1);
    setReconciliationLimit(20);
    getReconciliations(platform);
  }, []);

  useEffect(() => {
    getReconciliations(platform);
  }, [platform]);

  const onPageChange = (page) => {
    setReconciliationPage(page);
    getReconciliations(platform);
  };

  const onLimitChange = (limit) => {
    setReconciliationLimit(limit);
    getReconciliations(platform);
  };

  const onStatusChange = (status) => {
    setReconciliationStatus(status);
    getReconciliations(platform);
  };

  const onPlatformChange = (platform) => {
    setReconciliationPage(1);
    setReconciliationLimit(20);
    setPlatform(platform);
  };

  return (
    <FinanceLayout>
      <Wrapper>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, max-content)',
            gap: '0.5rem',
            justifyContent: 'end',
          }}
        >
          <ExtractTransactions />
          <ResolveReconciliations />
        </div>
        <div className="platform">
          <h4>Select platform:</h4>
          <SelectInput options={sortPlatforms(platforms)} value={platform} onChange={onPlatformChange} />
        </div>
        <ReconciliationTable
          tableData={reconciliations}
          onPageChange={onPageChange}
          onLimitChange={onLimitChange}
          onStatusChange={onStatusChange}
        />
      </Wrapper>
    </FinanceLayout>
  );
};

Reconciliations.propTypes = {
  platforms: PropTypes.array.isRequired,
  reconciliations: PropTypes.object.isRequired,
  getAllReconciliations: PropTypes.func.isRequired,
  setReconciliationLimit: PropTypes.func.isRequired,
  setReconciliationPage: PropTypes.func.isRequired,
  setReconciliationStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { platforms } = state.platform;
  const { reconciliations } = state.reconciliation;
  return { platforms, reconciliations };
};

export default connect(mapStateToProps, {
  getAllReconciliations,
  setReconciliationPage,
  setReconciliationLimit,
  setReconciliationStatus,
})(Reconciliations);
