import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  getAllRoles,
  setRoleLimit,
  setRolePage,
  setRoleDate,
  resetRoleDate,
  fetchRolePermissions,
} from 'src/store/actions/roleActions';
import CreateRole from './CreateRole';
import RoleTable from './RoleTable';
import StaffLayout from 'src/components/staffs/StaffLayout';
import DisplayCard from 'src/components/common/DisplayCard';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  height: max-content;
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .mmo-filters__wrapper {
    display: flex;
    justify-content: space-between;
  }
  .mmo-filters {
    display: flex;

    & > :not(:last-child) {
      margin-right: 1rem;
    }
  }
  .mmo-filter {
    display: grid;
    width: max-content;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 0.5rem;
  }
  .date_range {
    padding: 0rem 0.5rem;
    display: grid;
    border-radius: 5px;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const Roles = ({ roles, getAllRoles, setRoleLimit, setRolePage, fetchRolePermissions }) => {
  fetchRolePermissions();

  const [appIsUploaded] = useState('all');
  const [appIsLive] = useState('all');

  useEffect(() => {
    setRolePage(1);
    setRoleLimit(20);
    const query = {};
    if (appIsUploaded && appIsUploaded !== 'all') {
      query.isUploaded = appIsUploaded;
    }
    if (appIsUploaded && appIsLive !== 'all') {
      query.appIsLive = appIsLive;
    }
    getAllRoles(query);
  }, [appIsUploaded, appIsLive]);

  const getRoles = () => {
    const query = {};
    if (appIsUploaded && appIsUploaded !== 'all') {
      query.isUploaded = appIsUploaded;
    }
    if (appIsUploaded && appIsLive !== 'all') {
      query.appIsLive = appIsLive;
    }
    getAllRoles(query);
  };

  const onPageChange = (page) => {
    setRolePage(page);
    getRoles();
  };

  const onLimitChange = (limit) => {
    setRoleLimit(limit);
    getRoles();
  };

  return (
    <StaffLayout>
      <Wrapper>
        <CreateRole />
        <div className="cards">
          <DisplayCard icon={ChangeHistoryIcon} title="Total Roles" value={roles !== null && roles.count} />
        </div>
        <RoleTable tableData={roles} onPageChange={onPageChange} onLimitChange={onLimitChange} />
      </Wrapper>
    </StaffLayout>
  );
};

Roles.propTypes = {
  roles: PropTypes.object.isRequired,
  getAllRoles: PropTypes.func.isRequired,
  setRoleLimit: PropTypes.func.isRequired,
  setRolePage: PropTypes.func.isRequired,
  setRoleDate: PropTypes.func.isRequired,
  resetRoleDate: PropTypes.func.isRequired,
  fetchRolePermissions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { roles } = state.role;
  return { roles };
};

export default connect(mapStateToProps, {
  getAllRoles,
  setRolePage,
  setRoleLimit,
  setRoleDate,
  resetRoleDate,
  fetchRolePermissions,
})(Roles);
