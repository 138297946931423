import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'src/components/common/Icon';

const Wrapper = styled.div`
  max-width: 15rem;
  min-height: 100vh;
  background-color: ${(props) => props.theme.color.lightGrey};
  -webkit-box-shadow: 1px 1px 1px 1px #ccc;
  -moz-box-shadow: 1px 1px 1px 1px #ccc;
  box-shadow: 1px 1px 1px 1px #ccc;
  .header {
    padding: 1.7rem;
    min-width: 13rem;
  }
  .type {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    grid-gap: 0.3rem;
    height: 2.5rem;
    padding: 0rem 1.7rem;
    color: ${(props) => props.theme.color.deepGrey};
    cursor: pointer;
  }
  .isActive {
    background-color: ${(props) => props.theme.color.blue};
    color: ${(props) => props.theme.color.white};
  }
`;

const TypesSideBar = ({ type, setType, options, title }) => {
  const displayType = (option) => (
    <div className={`type ${type === option.value && 'isActive'}`} onClick={() => setType(option.value)}>
      <Icon Icon={option.icon} alt="" className={type === option.value ? 'isActive' : ''} />
      <p>{option.title}</p>
    </div>
  );

  return (
    <Wrapper>
      <h1 className="header">{title}</h1>
      <div>
        {options.map((option) => (
          <React.Fragment key={option.value}>{displayType(option)}</React.Fragment>
        ))}
      </div>
    </Wrapper>
  );
};

TypesSideBar.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default TypesSideBar;
