import axios from 'axios';
import keys from 'src/utils/keys';

const { authService, walletService, userService, adminService, mediaService, appService } = keys;

const clients = {};

clients.authService = axios.create({
  baseURL: authService.server,
  auth: authService.auth,
});

clients.walletService = axios.create({
  baseURL: walletService.server,
  auth: walletService.auth,
});

clients.userService = axios.create({
  baseURL: userService.server,
  auth: userService.auth,
});

clients.adminService = axios.create({
  baseURL: adminService.server,
  auth: adminService.auth,
});

clients.mediaService = axios.create({
  baseURL: mediaService.server,
  auth: mediaService.auth,
});

clients.appService = axios.create({
  baseURL: appService.server,
  auth: appService.auth,
});

export const setAuthToHeader = (token) => {
  clients.adminService.defaults.headers['gat'] = token;
  return;
};

export const removeAuthFromHeader = () => {
  delete clients.adminService.defaults.headers['gat'];
  return;
};

export default Object.freeze(clients);
