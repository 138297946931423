import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { NavLink, Link } from 'react-router-dom';

const Wrapper = styled.div`
  .cursor {
    cursor: pointer;
  }
  :hover {
    background-color: ${(props) => props.theme.color.grey};
  }
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    padding: 0.2rem 0rem;
    height: 1.8rem;
  }
  .blue {
    color: ${(props) => props.theme.color.blue};
  }
  .grey {
    color: ${(props) => props.theme.color.grey};
    :hover {
      color: ${(props) => props.theme.color.blue};
    }
  }
  .greyIsActive {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.color.grey};
    color: ${(props) => props.theme.color.blue};
  }
`;

const Icon = ({ Icon, alt, onClick, theme, type, to, className, disabled, localImage }) => {
  const displayIconNavLink = () => (
    <NavLink to={`${to}`} className={`${theme} ${className}`} activeClassName={`${theme}IsActive`}>
      <Tooltip title={alt || ''}>
        {localImage ? (
          <img className="icon" src={Icon} alt={alt} style={{ width: '1.3rem' }} />
        ) : (
          <Icon className="icon" />
        )}
      </Tooltip>
    </NavLink>
  );

  const displayIconLink = () => (
    <Link to={`${to}`} className={`${theme} ${className}`}>
      <Tooltip title={alt || ''}>
        <Icon className="icon" />
      </Tooltip>
    </Link>
  );

  const displayIcon = () => (
    <div onClick={() => onClick()} className={`${theme} ${className}`}>
      <Tooltip title={alt || ''}>
        <Icon className={`icon ${!disabled ? 'cursor' : ''}`} />
      </Tooltip>
    </div>
  );

  return (
    <Wrapper>
      {type === 'icon' && displayIcon()}
      {type === 'navLink' && displayIconNavLink()}
      {type === 'link' && displayIconLink()}
    </Wrapper>
  );
};

Icon.defaultProps = {
  onClick: () => {},
  theme: 'blue',
  type: 'icon',
  to: '/',
  disabled: false,
  localImage: false,
};

Icon.propTypes = {
  Icon: PropTypes.any.isRequired,
  alt: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  localImage: PropTypes.bool,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(['blue', 'grey']).isRequired,
  type: PropTypes.oneOf(['icon', 'navLink', 'link']).isRequired,
};

export default Icon;
