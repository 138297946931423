import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { PasswordInput } from 'src/components/common/inputs';
import Button from 'src/components/common/Button';
import { resetPassword, verifyResetToken } from 'src/store/actions/userActions';
import { connect } from 'react-redux';

const Wrapper = styled(Paper)`
  padding: 2rem;
  max-width: 30rem;
  min-width: 25rem;
  display: grid;
  grid-gap: 0.6rem;
  .forgot_section {
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem 0;
  }
  .forgot_link {
    font-size: 0.8rem;
    color: ${(props) => props.theme.color.blue};
    text-decoration: none;
  }
`;

const ResetPasswordForm = ({ resetPassword, verifyResetToken }) => {
  const history = useHistory();
  const useQuery = () => new URLSearchParams(history.location.search);
  const token = useQuery().get('token') || '';
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const onSubmit = (event) => {
    event.preventDefault();

    const data = {
      token,
      password,
      confirmPassword,
    };

    resetPassword(data, {
      success: () => {
        setPassword('');
        setConfirmPassword('');
        history.push('/');
      },
    });
  };

  useEffect(() => {
    if (token) {
      verifyResetToken(token);
    } else {
      history.push('/');
    }
  }, [token]);

  if (token) {
    return (
      <Wrapper>
        <form onSubmit={onSubmit}>
          <PasswordInput label="Password" value={password} onChange={setPassword} required />
          <br />
          <PasswordInput label="Confirm Password" value={confirmPassword} onChange={setConfirmPassword} required />
          <div className="forgot_section">
            <Link to="/" className="forgot_link">
              I remember my password
            </Link>
          </div>
          <br />
          <Button type="submit" text="Reset Password" fullWidth={true} />
        </form>
      </Wrapper>
    );
  }

  return null;
};

ResetPasswordForm.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  verifyResetToken: PropTypes.func.isRequired,
};

export default connect(null, { resetPassword, verifyResetToken })(ResetPasswordForm);
