import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  getAllFwCardPayments,
  setFwCardPaymentsLimit,
  setFwCardPaymentsPage,
  setFwCardPaymentsDate,
  resetFwCardPaymentsDate,
} from 'src/store/actions/fwCardPaymentActions';
import FwCardPaymentsTable from './FwCardPaymentsTable';
import NotificationsLayout from 'src/components/notifications/NotificationsLayout';
import { DateRangeInput, SelectInput } from 'src/components/common/inputs';
import { formatDate } from 'src/utils/helpers';
import { Card } from '@material-ui/core';
import Icon from 'src/components/common/Icon';

// Table Icons
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import DateRangeIcon from '@material-ui/icons/DateRange';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  height: max-content;
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .notification-filters__wrapper {
    display: flex;
    justify-content: space-between;
  }
  .notification-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .notification-filter {
    display: grid;
    width: max-content;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 0.5rem;
  }
  .date_range {
    padding: 0rem 0.5rem;
    display: grid;
    border-radius: 5px;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const FwCardPayments = ({
  fwCardPayments,
  getAllFwCardPayments,
  setFwCardPaymentsLimit,
  setFwCardPaymentsPage,
  setFwCardPaymentsDate,
  resetFwCardPaymentsDate,
}) => {
  const [fwCardPaymentsStatus, setFwCardPaymentsStatus] = useState('all');

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const getQuery = () => ({
    status: fwCardPaymentsStatus !== 'all' ? fwCardPaymentsStatus : undefined,
  });

  const getFwCardPayments = (params) => {
    const { resetSearchString = false } = params || {};
    const query = getQuery();
    if (resetSearchString) {
      query.search = undefined;
    }
    getAllFwCardPayments(query);
  };

  useEffect(() => {
    setFwCardPaymentsPage(1);
    setFwCardPaymentsLimit(20);
    getFwCardPayments();
  }, [fwCardPaymentsStatus]);

  const onPageChange = (page) => {
    setFwCardPaymentsPage(page);
    getFwCardPayments();
  };

  const onLimitChange = (limit) => {
    setFwCardPaymentsLimit(limit);
    getFwCardPayments();
  };

  const onDateChange = (date) => {
    setFwCardPaymentsDate(date.startDate, date.endDate);
    getFwCardPayments();
  };

  const onResetDateClick = () => {
    resetFwCardPaymentsDate();
    getFwCardPayments();
  };

  return (
    <NotificationsLayout>
      <Wrapper>
        <div className="notification-filters__wrapper">
          <div className="notification-filters">
            <div className="notification-filter">
              <h4>Status:</h4>
              <SelectInput
                options={cardPaymentsStatusOptions}
                value={fwCardPaymentsStatus}
                onChange={setFwCardPaymentsStatus}
              />
            </div>

            <div className="notification-filter">
              <Card className="date_range">
                <h6>{`${fwCardPayments === null ? '' : formatDate(fwCardPayments.start_date)} - ${
                  fwCardPayments === null ? '' : formatDate(fwCardPayments.end_date)
                }`}</h6>
                <Icon Icon={DateRangeIcon} alt="Set Date" onClick={toggle} />
                <Icon Icon={RotateLeftIcon} alt="Reset Date" onClick={onResetDateClick} />
              </Card>
            </div>
          </div>
        </div>
        <FwCardPaymentsTable tableData={fwCardPayments} onPageChange={onPageChange} onLimitChange={onLimitChange} />
        <div className="range_input">
          <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
        </div>
      </Wrapper>
    </NotificationsLayout>
  );
};

const cardPaymentsStatusOptions = [
  {
    text: 'All',
    value: 'all',
  },
  {
    text: 'SUCCESS',
    value: 'SUCCESS',
  },
  {
    text: 'PENDING',
    value: 'PENDING',
  },
];

FwCardPayments.propTypes = {
  fwCardPayments: PropTypes.object.isRequired,
  getAllFwCardPayments: PropTypes.func.isRequired,
  setFwCardPaymentsLimit: PropTypes.func.isRequired,
  setFwCardPaymentsPage: PropTypes.func.isRequired,
  setFwCardPaymentsDate: PropTypes.func.isRequired,
  resetFwCardPaymentsDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { fwCardPayments } = state.fwCardPayment;
  return { fwCardPayments };
};

export default connect(mapStateToProps, {
  getAllFwCardPayments,
  setFwCardPaymentsPage,
  setFwCardPaymentsLimit,
  setFwCardPaymentsDate,
  resetFwCardPaymentsDate,
})(FwCardPayments);
