import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import ModalTwo from 'src/components/common/ModalTwo';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { updateMmoServices } from 'src/store/actions/mmoActions';

const Wrapper = styled.div`
  h2 {
    font-size: x-large;
  }

  .form-section-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    align-items: end;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .long {
      grid-column-end: span 1;
    }

    .longer {
      grid-column-end: span 2;

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .longest {
      grid-column-end: span 3;

      @media (max-width: 768px) {
        grid-column-end: span 2;
      }

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .align-start {
      align-self: start;
    }

    .align-center {
      align-self: center;
    }

    .align-end {
      align-self: end;
    }

    .justify-end {
      justify-self: end;
    }
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;

    & > :first-child {
      margin-right: 0.5rem;
    }
  }
`;

const EditMmoServices = ({ mmo, mmoServices, updateMmoServices, getSingleMmo }) => {
  if (!mmo) return null;
  if (!mmoServices) return null;

  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();

    const services = [];
    const checkboxes = Array.from(document.querySelectorAll('[name="services[]"]'));
    for (let i = 0; i < checkboxes.length; i++) {
      if (!checkboxes[i].checked) continue;
      services.push(checkboxes[i].value);
    }

    setIsSubmitting(true);
    updateMmoServices(
      mmo.shortCode,
      { services },
      {
        always: () => {
          setIsSubmitting(false);
        },
        success: () => {
          setShow(false);
          if (getSingleMmo) getSingleMmo(mmo.shortCode, { silently: true });
        },
      }
    );
  };

  return (
    <Wrapper>
      <Button text="Edit Services" onClick={toggleShow} />

      <ModalTwo active={show} onModalCloseCallback={toggleShow}>
        <div>
          <center>
            <h2>Edit Services</h2>
          </center>

          <br />

          <form id="updateMmoIsUploadedForm" onSubmit={submitForm} className="form-section-grid">
            {mmoServices.map((service, index) => {
              return (
                <div key={index}>
                  <label htmlFor={service.code}>
                    <input
                      id={service.code}
                      type="checkbox"
                      value={service.code}
                      name="services[]"
                      defaultChecked={mmo.services && mmo.services.split(';').some((x) => x === service.code)}
                    />
                    <span>{service.title}</span>
                  </label>
                </div>
              );
            })}

            <div className="longest justify-end">
              <Button
                type="submit"
                text={isSubmitting ? 'Updating Services ...' : 'Update Services'}
                disabled={isSubmitting}
              />
            </div>
          </form>
        </div>
      </ModalTwo>
    </Wrapper>
  );
};

EditMmoServices.propTypes = {
  mmo: PropTypes.object,
  mmoServices: PropTypes.array,
  updateMmoServices: PropTypes.func.isRequired,
  getSingleMmo: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { mmo, mmoServices } = state.mmo;
  return { mmo, mmoServices };
};

export default connect(mapStateToProps, { updateMmoServices })(EditMmoServices);
