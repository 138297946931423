import React from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/common/table/Table';
import { customersTableHeader } from './customersTableHeader';
import { sortTableData } from 'src/utils/helpers';
import CustomersSearch from './CustomersSearch';
// import { SelectInput } from 'src/components/common/inputs';

// const statusOptions = [
//   { text: 'All', value: 'all' },
//   { text: 'Active', value: 'active' },
//   { text: 'Suspended', value: 'suspended' },
// ];

const CustomersTable = ({ tableData, onPageChange, onLimitChange }) => {
  const leftHeader = (
    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
      <div>
        <CustomersSearch />
      </div>
      {/* <div>
        <SelectInput options={statusOptions} value={status} onChange={setStatus} />
      </div> */}
    </div>
  );
  return (
    <div>
      <Table
        showTopNav={true}
        rightHeader={null}
        leftHeader={leftHeader}
        headers={customersTableHeader}
        data={tableData && tableData.data ? sortTableData(tableData.data, {}) : []}
        limit={tableData && tableData.limit}
        page={tableData && tableData.page}
        count={tableData && tableData.count}
        showNav={true}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </div>
  );
};

CustomersTable.propTypes = {
  tableData: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
};

export default CustomersTable;
