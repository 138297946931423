import { combineReducers } from 'redux';
import { CLEAR_SESSION } from 'src/store/types';

import appReducer from './appReducer';
import alertReducer from './alertReducer';
import userReducer from './userReducer';
import transactionReducer from './transactionReducer';
import platformReducer from './platformReducer';
import accountReducer from './accountReducer';
import oldposReducer from './oldposReducer';
import posReducer from './posReducer';
import terminalReducer from './terminalReducer';
import issuesReducer from './issuesReducer';
import virtualWalletRequestsReducer from './virtualWalletRequestsReducer';
import mmoReducer from './mmoReducer';
import staffReducer from './staffReducer';
import roleReducer from './roleReducer';
import notificationReducer from './notificationReducer';
import reconciliationReducer from './reconciliationReducer';
import chargebackReducer from './chargebackReducer';
import cardPaymentReducer from './cardPaymentReducer';
import ptspReducer from './ptspReducer';
import fwCardPaymentReducer from './fwCardPaymentReducer';
import yawoVendorReducer from './yawo/vendorReducer';
import yawoAgentReducer from './yawo/agentReducer';
import yawoCustomerReducer from './yawo/customerReducer';
import yawoGuarantorReducer from './yawo/guarantorReducer';
import yawoLoanReducer from './yawo/loanReducer';
import yawoWalletReducer from './yawo/walletReducer';
import yawoTransactionReducer from './yawo/transactionReducer';
import yawoCollectionReducer from './yawo/collectionReducer';

const reducers = combineReducers({
  app: appReducer,
  alert: alertReducer,
  user: userReducer,
  oldpos: oldposReducer,
  pos: posReducer,
  terminal: terminalReducer,
  account: accountReducer,
  transaction: transactionReducer,
  platform: platformReducer,
  issue: issuesReducer,
  virtualWalletRequest: virtualWalletRequestsReducer,
  mmo: mmoReducer,
  staff: staffReducer,
  role: roleReducer,
  notification: notificationReducer,
  reconciliation: reconciliationReducer,
  chargeback: chargebackReducer,
  cardPayment: cardPaymentReducer,
  ptsp: ptspReducer,
  fwCardPayment: fwCardPaymentReducer,
  yawoVendor: yawoVendorReducer,
  yawoAgent: yawoAgentReducer,
  yawoCustomer: yawoCustomerReducer,
  yawoGuarantor: yawoGuarantorReducer,
  yawoLoan: yawoLoanReducer,
  yawoWallet: yawoWalletReducer,
  yawoTransaction: yawoTransactionReducer,
  yawoCollection: yawoCollectionReducer,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === CLEAR_SESSION) state = undefined;
  return reducers(state, action);
};

export default rootReducer;
