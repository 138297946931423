import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { TextInput } from 'src/components/common/inputs';
import Button from 'src/components/common/Button';
import { forgotPassword } from 'src/store/actions/userActions';
import { connect } from 'react-redux';

const Wrapper = styled(Paper)`
  padding: 2rem;
  max-width: 30rem;
  min-width: 25rem;
  display: grid;
  grid-gap: 0.6rem;
  .forgot_section {
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem 0;
  }
  .forgot_link {
    font-size: 0.8rem;
    color: ${(props) => props.theme.color.blue};
    text-decoration: none;
  }
`;

const ForgotPasswordForm = ({ forgotPassword }) => {
  const history = useHistory();
  const [email, setEmail] = useState('');

  const onSubmit = (event) => {
    event.preventDefault();
    forgotPassword(email, {
      success: () => {
        setEmail('');
        history.push('/');
      },
    });
  };

  return (
    <Wrapper>
      <form onSubmit={onSubmit}>
        <TextInput label="Enter Email address" value={email} onChange={setEmail} type="email" required />
        <div className="forgot_section">
          <Link to="/" className="forgot_link">
            I remember my password
          </Link>
        </div>
        <br />
        <Button type="submit" text="Submit Email" fullWidth={true} />
      </form>
    </Wrapper>
  );
};

ForgotPasswordForm.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
};

export default connect(null, { forgotPassword })(ForgotPasswordForm);
