import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import ModalTwo from 'src/components/common/ModalTwo';
import { TextInput, SelectInput } from 'src/components/common/inputs';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { createTerminal } from 'src/store/actions/terminalActions';
import { csvToArray } from 'src/utils/helpers';

const Wrapper = styled.div`
  h2 {
    font-size: x-large;
  }

  .form-section-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    margin-bottom: 1.5rem;
    align-items: end;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .long {
      grid-column-end: span 1;
    }

    .longer {
      grid-column-end: span 2;

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .longest {
      grid-column-end: span 3;

      @media (max-width: 768px) {
        grid-column-end: span 2;
      }

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .align-start {
      align-self: start;
    }

    .align-center {
      align-self: center;
    }

    .align-end {
      align-self: end;
    }

    .justify-end {
      justify-self: end;
    }
  }

  .label {
    color: ${(props) => props.theme.color.black};
    min-width: max-content;
  }
`;

const UploadTerminals = ({ terminalTypes, createTerminal, getAllTerminals }) => {
  if (!terminalTypes) return null;

  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ptsp, setPtsp] = useState('');
  const [terminalIds, setTerminalIds] = useState([]);

  const resetFormState = () => {
    document.getElementById('uploadTerminalsForm').reset();
    setPtsp('');
    setTerminalIds('');
  };

  const submitForm = (e) => {
    e.preventDefault();

    const data = {
      ptsp,
      terminalIds,
    };

    setIsSubmitting(true);
    createTerminal(data, {
      always: () => {
        setIsSubmitting(false);
      },
      success: () => {
        setShow(false);
        resetFormState();
        if (getAllTerminals) getAllTerminals();
      },
    });
  };

  const onFileChange = (files) => {
    const file = files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const csvText = e.target.result;
        const csvArray = csvToArray(csvText, ',', true);
        setTerminalIds(
          csvArray.reduce((acc, x) => {
            if (x[0].trim()) {
              return [...acc, x[0].trim()];
            } else {
              return acc;
            }
          }, [])
        );
      };

      return reader.readAsText(file);
    }
    setTerminalIds([]);
  };

  const ptspOptions = Array.isArray(terminalTypes) ? terminalTypes.map((type) => ({ text: type, value: type })) : [];

  return (
    <Wrapper>
      <div className="form-section-grid">
        <div className="longest justify-end">
          <Button text="Upload Terminal IDs" onClick={toggleShow} />
        </div>
      </div>

      <ModalTwo size="large" active={show} onModalCloseCallback={toggleShow}>
        <div>
          <center>
            <h2>Upload Terminal IDs</h2>
          </center>

          <br />

          <form id="uploadTerminalsForm" onSubmit={submitForm} className="form-section-grid">
            <div className="align-start">
              <SelectInput label="Select PTSP" options={ptspOptions} value={ptsp} onChange={setPtsp} required />
            </div>

            <div className="longer">
              <TextInput type="file" label="Select Terminals CSV File" onChange={onFileChange} required />
              {terminalIds.length > 0 && (
                <div className="color-grey">
                  <b>{terminalIds.length}</b> terminal ids detected
                </div>
              )}
            </div>

            <div className="longest justify-end">
              <Button type="submit" text={isSubmitting ? 'uploading ...' : 'Upload'} disabled={isSubmitting} />
            </div>
          </form>
        </div>
      </ModalTwo>
    </Wrapper>
  );
};

UploadTerminals.propTypes = {
  terminalTypes: PropTypes.array.isRequired,
  createTerminal: PropTypes.func.isRequired,
  getAllTerminals: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { terminalTypes } = state.terminal;
  return { terminalTypes };
};

export default connect(mapStateToProps, { createTerminal })(UploadTerminals);
