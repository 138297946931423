import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import ModalTwo from 'src/components/common/ModalTwo';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { updateUserServices, fetchUserServices } from 'src/store/actions/accountActions';
import { useParams } from 'react-router-dom';

const Wrapper = styled.div`
  h2 {
    font-size: x-large;
  }

  .form-section-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    align-items: end;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .long {
      grid-column-end: span 1;
    }

    .longer {
      grid-column-end: span 2;

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .longest {
      grid-column-end: span 3;

      @media (max-width: 768px) {
        grid-column-end: span 2;
      }

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .align-start {
      align-self: start;
    }

    .align-center {
      align-self: center;
    }

    .align-end {
      align-self: end;
    }

    .justify-end {
      justify-self: end;
    }
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;

    & > :first-child {
      margin-right: 0.5rem;
    }
  }
`;

const EditUserServices = ({ userServicesList, userServices, updateUserServices, fetchUserServices }) => {
  if (!userServices) return null;
  if (!userServicesList) return null;
  const { userId } = useParams();

  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();

    const checkedServices = [];
    const checkboxes = Array.from(document.querySelectorAll('[name="services[]"]'));
    for (let i = 0; i < checkboxes.length; i++) {
      if (!checkboxes[i].checked) continue;
      checkedServices.push(checkboxes[i].value);
    }

    const services = {};
    userServicesList.map((service) => {
      services[service] = checkedServices.includes(service);
    });

    setIsSubmitting(true);
    updateUserServices(userId, services, {
      always: () => {
        setIsSubmitting(false);
      },
      success: () => {
        setShow(false);
        if (fetchUserServices) fetchUserServices({ userId });
      },
    });
  };

  return (
    <Wrapper>
      <Button text="Edit Services" onClick={toggleShow} />

      <ModalTwo active={show} onModalCloseCallback={toggleShow}>
        <div>
          <center>
            <h2>Edit Services</h2>
          </center>

          <br />

          <form id="updateMmoIsUploadedForm" onSubmit={submitForm} className="form-section-grid">
            {userServicesList.map((service, index) => {
              return (
                <div key={index}>
                  <label htmlFor={service}>
                    <input
                      id={service}
                      type="checkbox"
                      value={service}
                      name="services[]"
                      defaultChecked={Boolean(userServices?.[service])}
                    />
                    <span>{service}</span>
                  </label>
                </div>
              );
            })}

            <div className="longest justify-end">
              <Button
                type="submit"
                text={isSubmitting ? 'Updating Services ...' : 'Update Services'}
                disabled={isSubmitting}
              />
            </div>
          </form>
        </div>
      </ModalTwo>
    </Wrapper>
  );
};

EditUserServices.propTypes = {
  match: PropTypes.object,
  userServicesList: PropTypes.array,
  userServices: PropTypes.object,
  updateUserServices: PropTypes.func.isRequired,
  fetchUserServices: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { userServicesList, userServices } = state.account;
  return { userServicesList, userServices };
};

export default connect(mapStateToProps, { updateUserServices, fetchUserServices })(EditUserServices);
