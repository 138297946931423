import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import ModalTwo from 'src/components/common/ModalTwo';
import { TextInput, SelectInput } from 'src/components/common/inputs';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { updatePtsp, getAllPtsps } from 'src/store/actions/ptspActions';
import Icon from 'src/components/common/Icon';
import EditIcon from '@material-ui/icons/Edit';

const Wrapper = styled.div`
  h2 {
    font-size: x-large;
  }

  .form-section-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    margin-bottom: 1.5rem;
    align-items: end;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .long {
      grid-column-end: span 1;
    }

    .longer {
      grid-column-end: span 2;

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .longest {
      grid-column-end: span 3;

      @media (max-width: 768px) {
        grid-column-end: span 2;
      }

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .align-start {
      align-self: start;
    }

    .align-center {
      align-self: center;
    }

    .align-end {
      align-self: end;
    }

    .justify-end {
      justify-self: end;
    }
  }

  .label {
    color: ${(props) => props.theme.color.black};
    min-width: max-content;
  }
`;

const EditPtsp = ({
  cardTypes,
  ptspOptions,
  ptsp,
  updatePtsp,
  getAllPtsps,
  useButton,
  externalControlForVisibility,
  setShowComponent,
  showComponent,
}) => {
  if (!cardTypes) return null;
  if (!ptspOptions) return null;

  const [stateShow, setStateShow] = useState(false);
  const show = showComponent || stateShow;
  const setShow = setShowComponent || setStateShow;
  const toggleShow = () => setShow(!show);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardType, setCardType] = useState('');
  const [ptspOption, setPtspOption] = useState('');
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');

  const setFormState = (ptsp) => {
    setCardType(ptsp.cardType || '');
    setPtspOption(ptsp.ptsp || '');
    setMin(ptsp.min || '');
    setMax(ptsp.max || '');
  };

  useEffect(() => {
    setFormState(ptsp);
  }, [ptsp]);

  const submitForm = (e) => {
    e.preventDefault();

    const data = {
      settingId: ptsp._id,
      ptsp: ptspOption,
      min,
      max,
    };

    setIsSubmitting(true);
    updatePtsp(data, {
      always: () => {
        setIsSubmitting(false);
      },
      success: () => {
        setShow(false);
        if (getAllPtsps) getAllPtsps();
      },
    });
  };

  const cardTypeOptions = Array.isArray(cardTypes) ? cardTypes.map((type) => ({ text: type, value: type })) : [];
  ptspOptions = Array.isArray(ptspOptions) ? ptspOptions.map((ptsp) => ({ text: ptsp, value: ptsp })) : [];

  return (
    <Wrapper>
      {!externalControlForVisibility && (
        <>
          {useButton ? (
            <Button text="Edit PTSP" onClick={toggleShow} />
          ) : (
            <Icon Icon={EditIcon} type="icon" onClick={toggleShow} />
          )}
        </>
      )}

      <ModalTwo size="large" active={show} onModalCloseCallback={toggleShow}>
        <div>
          <center>
            <h2>Edit PTSP</h2>
          </center>

          <br />

          <form id="updatePtspForm" onSubmit={submitForm} className="form-section-grid">
            <div>
              <SelectInput
                label="Select Card Type"
                options={cardTypeOptions}
                value={cardType}
                onChange={setCardType}
                required
                disabled
              />
            </div>

            <div>
              <SelectInput label="Select PTSP" options={ptspOptions} value={ptspOption} onChange={setPtspOption} required />
            </div>

            <div>
              <TextInput label="Min amount" value={min} onChange={setMin} required />
            </div>

            <div>
              <TextInput label="Max amount" value={max} onChange={setMax} required />
            </div>

            <div className="longest justify-end">
              <Button type="submit" text={isSubmitting ? 'Updating PTSP ...' : 'Update PTSP'} disabled={isSubmitting} />
            </div>
          </form>
        </div>
      </ModalTwo>
    </Wrapper>
  );
};

EditPtsp.propTypes = {
  ptsp: PropTypes.object,
  cardTypes: PropTypes.array.isRequired,
  ptspOptions: PropTypes.array,
  updatePtsp: PropTypes.func.isRequired,
  getAllPtsps: PropTypes.func,
  useButton: PropTypes.bool,
  externalControlForVisibility: PropTypes.bool,
  setShowComponent: PropTypes.func,
  showComponent: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { cardTypes, ptspOptions } = state.ptsp;
  return { cardTypes, ptspOptions };
};

export default connect(mapStateToProps, { updatePtsp, getAllPtsps })(EditPtsp);
