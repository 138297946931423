import React from 'react';
import Icon from 'src/components/common/Icon';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export const staffTableHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Name</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{`${rowData['firstName']} ${rowData['lastName']}`}</p>;
    },
  },
  {
    title: function title() {
      return <p>Staff Id</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['staffId']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Email</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['email']['address']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Is Verified</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['email']['isVerified'] ? '✅' : '❌'}</p>;
    },
  },
  {
    title: function title() {
      return <p>View</p>;
    },
    render: function SerialNumber(rowData) {
      return <Icon Icon={OpenInNewIcon} type="link" to={`staffs/${rowData['staffId']}`} />;
    },
  },
];
