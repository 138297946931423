import querystring from 'querystring';
import client from 'src/client';
import {
  ADD_TERMINALS,
  ADD_TERMINAL,
  CLEAR_TERMINAL,
  TERMINAL_IS_LOADING,
  TERMINAL_NOT_LOADING,
  SET_TERMINAL_DATE,
  RESET_TERMINAL_DATE,
  SET_TERMINAL_LIMIT,
  SET_TERMINAL_STATUS,
  SET_TERMINAL_PAGE,
  SET_TERMINAL_TYPES,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from './alertActions';

const { adminService } = client;

const terminalIsLoading =
  (msg = 'Terminal Loading') =>
  (dispatch) => {
    dispatch({ type: TERMINAL_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const terminalNotLoading = () => (dispatch) => {
  dispatch({ type: TERMINAL_NOT_LOADING });
};

const addTerminals = (terminals) => {
  return { type: ADD_TERMINALS, payload: terminals };
};

const addTerminal = (terminal) => {
  return { type: ADD_TERMINAL, payload: terminal };
};

const clearTerminal = () => {
  return { type: CLEAR_TERMINAL };
};

export const setTerminalDate = (start_date, end_date) => {
  return { type: SET_TERMINAL_DATE, payload: { start_date, end_date } };
};

export const resetTerminalDate = () => {
  return { type: RESET_TERMINAL_DATE };
};

export const setTerminalPage = (page) => {
  return { type: SET_TERMINAL_PAGE, payload: page };
};

export const setTerminalLimit = (limit) => {
  return { type: SET_TERMINAL_LIMIT, payload: limit };
};

export const setTerminalStatus = (status) => {
  return { type: SET_TERMINAL_STATUS, payload: status };
};

const generatePath = (endPoint, terminal, query) => {
  const { limit, page } = terminal || {};
  let url = `${endPoint}?limit=${limit}&page=${page}`;
  if (query && Object.keys(query).length > 0) {
    url += `&${querystring.encode(query)}`;
  }
  return url;
};

export const getAllTerminals =
  (query = {}) =>
  (dispatch, getState) => {
    dispatch(terminalIsLoading('Loading Terminals'));
    const { terminals } = getState().terminal;
    const path = generatePath('/terminals', terminals, query);
    adminService
      .get(path)
      .then((res) => {
        dispatch(addTerminals(res.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading TERMINALs' })))
      .finally(() => dispatch(terminalNotLoading()));
  };

export const getSingleTerminal = (id, extraParams) => (dispatch) => {
  const { silently = false } = extraParams || {};
  if (!silently) {
    dispatch(terminalIsLoading('Loading Terminal'));
  }
  adminService
    .get(`/terminals/${id}`)
    .then((res) => {
      dispatch(addTerminal(res.data));
      dispatch(clearAlert());
    })
    .catch((err) => {
      dispatch(clearTerminal());
      dispatch(errorAlert({ msg: err.response?.data?.message || err.message }));
    })
    .finally(() => dispatch(terminalNotLoading()));
};

export const searchTerminal = (searchString) => (dispatch) => {
  dispatch(terminalIsLoading('Searching...'));
  adminService
    .get(`/terminals?search=${searchString}`)
    .then((res) => {
      dispatch(addTerminals(res.data));
      dispatch(clearAlert());
    })
    .catch(() => {
      dispatch(errorAlert({ msg: 'Error Loading Terminals' }));
    })
    .finally(() => dispatch(terminalNotLoading()));
};

export const loadTerminalIdOptions = async (searchString) => {
  let options = [];
  await adminService
    .get(`/terminals?search=${searchString}`)
    .then((res) => {
      options = res?.data?.data || [];
    })
    .catch(() => {});

  return options
    .map((x) => ({ ...x, label: x.terminalId, value: x.terminalId }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
};

// Create & Update TERMINAL

const createTerminalIsLoading =
  (msg = 'Creating Terminal ID(s)') =>
  (dispatch) =>
    dispatch(infoAlert(msg));
const createTerminalNotLoading = () => (dispatch) => dispatch(clearAlert());

export const createTerminal = (formData, callback) => (dispatch) => {
  dispatch(createTerminalIsLoading());
  adminService
    .post('/terminals', formData)
    .then(() => {
      dispatch(successAlert('Terminal ID(s) Created'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => {
      setTimeout(() => {
        dispatch(createTerminalNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

const updateTerminalIsLoading =
  (msg = 'Updating Terminal') =>
  (dispatch) =>
    dispatch(infoAlert(msg));
const updateTerminalNotLoading = () => (dispatch) => dispatch(clearAlert());

export const updateTerminal = (id, formData, callback) => (dispatch) => {
  dispatch(updateTerminalIsLoading());
  adminService
    .put(`/terminals/${id}`, formData)
    .then(() => {
      dispatch(successAlert('Terminal Updated'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => {
      setTimeout(() => {
        dispatch(updateTerminalNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

// Terminal Types

const setTerminalTypes = (rolePermissions) => {
  return { type: SET_TERMINAL_TYPES, payload: rolePermissions };
};

export const fetchTerminalTypes = () => (dispatch) => {
  adminService
    .get('/terminals/types')
    .then((res) => dispatch(setTerminalTypes(res.data)))
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading Terminal Types' })))
    .finally(() => dispatch(terminalNotLoading()));
};
