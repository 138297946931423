import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import ViewAgentComponent from 'src/components/yawo/agents/ViewAgent';

const ViewAgent = () => {
  return (
    <DashboardLayout>
      <ViewAgentComponent />
    </DashboardLayout>
  );
};

export default ViewAgent;
