/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { DateRangeInput, SelectInput } from 'src/components/common/inputs';
import { formatDate, dateFormatYMD, sortPlatforms } from 'src/utils/helpers';

import { Card } from '@material-ui/core';
import SideBar from '../SideBar';
import { useSelector } from 'react-redux';
import VirtualWalletRequestsTable from './VirtualWalletRequestsTable';
import Icon from 'src/components/common/Icon';
import {
  getAllVirtualWalletRequests,
  setVirtualWalletRequestLimit,
  setVirtualWalletRequestPage,
  setVirtualWalletRequestDate,
  resetVirtualWalletRequestDate,
} from 'src/store/actions/virtualWalletRequestsActions';
import DateRangeIcon from '@material-ui/icons/DateRange';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;

  .issues_body {
    padding: 1.5rem;
    display: grid;
    grid-gap: 1rem;
    height: max-content;
  }
  .platform {
    display: grid;
    width: max-content;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 0.5rem;
  }
  .filters {
    display: flex;
    justify-content: space-between;
  }
  .date_range {
    padding: 0rem 0.5rem;
    display: grid;
    border-radius: 5px;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const VirtualWalletRequests = ({
  virtualWalletRequests,
  getAllVirtualWalletRequests,
  setVirtualWalletRequestLimit,
  setVirtualWalletRequestPage,
  resetVirtualWalletRequestDate,
  setVirtualWalletRequestDate,
}) => {
  const platforms = useSelector((state) => state.platform.platforms);
  const [status, setStatus] = useState('all');
  const [platform, setPlatform] = useState('ikonik');

  //initial date range from backend
  const virtualWalletRequestsEndDate = virtualWalletRequests?.end_date;
  const virtualWalletRequestsStartDate = virtualWalletRequests?.start_date;

  const [endDate, setEndDate] = useState(virtualWalletRequestsEndDate);
  const [startDate, setStartDate] = useState(virtualWalletRequestsStartDate);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const getVirtualWalletRequests = () => {
    const query = {};

    if (status && status !== 'all') {
      query.status = status;
    }
    if (platform && platform !== 'ikonik') {
      query.platform = platform === 'ikonik' ? 'all' : platform;
    }
    if (startDate && startDate !== dateFormatYMD(virtualWalletRequestsStartDate)) {
      query.start_date = dateFormatYMD(startDate);
    }
    if (endDate && endDate !== dateFormatYMD(virtualWalletRequestsEndDate)) {
      query.end_date = dateFormatYMD(endDate);
    }
    getAllVirtualWalletRequests(query);
  };

  useEffect(() => {
    setVirtualWalletRequestPage(1);
    setVirtualWalletRequestLimit(20);
    getVirtualWalletRequests();
  }, [status, platform, startDate, endDate]);

  const onPageChange = (page) => {
    setVirtualWalletRequestPage(page);
    getVirtualWalletRequests();
  };

  const onLimitChange = (limit) => {
    setVirtualWalletRequestLimit(limit);
    getVirtualWalletRequests();
  };

  const onResetDateClick = () => {
    resetVirtualWalletRequestDate();
    setStartDate(dateFormatYMD(virtualWalletRequestsStartDate));
    setEndDate(dateFormatYMD(virtualWalletRequestsEndDate));
  };
  const onDateChange = (date) => {
    setVirtualWalletRequestDate(date.startDate, date.endDate);
    setStartDate(date.startDate);
    setEndDate(date.endDate);
  };

  return (
    <SideBar>
      <Wrapper>
        <div className="issues_body">
          <div className="filters">
            <div className="platform">
              <h4>Select platform:</h4>
              <SelectInput options={sortPlatforms(platforms)} value={platform} onChange={setPlatform} />
            </div>
            <Card className="date_range">
              <h6>{`${virtualWalletRequests === null ? '' : formatDate(virtualWalletRequests.start_date)} - ${
                virtualWalletRequests === null ? '' : formatDate(virtualWalletRequests.end_date)
              }`}</h6>
              <Icon Icon={DateRangeIcon} alt="Set Date" onClick={toggle} />
              <Icon Icon={RotateLeftIcon} alt="Reset Date" onClick={onResetDateClick} />
            </Card>
          </div>
          <VirtualWalletRequestsTable
            tableData={virtualWalletRequests}
            onPageChange={onPageChange}
            onLimitChange={onLimitChange}
            status={status}
            setStatus={setStatus}
          />
          <div className="range_input">
            <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
          </div>
        </div>
      </Wrapper>
    </SideBar>
  );
};

VirtualWalletRequests.propTypes = {
  virtualWalletRequests: PropTypes.object,
  getAllVirtualWalletRequests: PropTypes.func.isRequired,
  setVirtualWalletRequestLimit: PropTypes.func.isRequired,
  setVirtualWalletRequestPage: PropTypes.func.isRequired,
  setVirtualWalletRequestDate: PropTypes.func.isRequired,
  resetVirtualWalletRequestDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { virtualWalletRequests } = state.virtualWalletRequest;
  return { virtualWalletRequests };
};

export default connect(mapStateToProps, {
  getAllVirtualWalletRequests,
  setVirtualWalletRequestLimit,
  setVirtualWalletRequestPage,
  resetVirtualWalletRequestDate,
  setVirtualWalletRequestDate,
})(VirtualWalletRequests);
