import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import YawoLayout from 'src/components/yawo/YawoLayout';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from 'styled-components';
import Icon from 'src/components/common/Icon';
import { withRouter } from 'react-router-dom';
import { getSingleYawoTransaction } from 'src/store/actions/yawo/transactionActions';
import { connect } from 'react-redux';
import { formatAmount, formatDate, formatTime } from 'src/utils/helpers';
import TransactionStatus from './TransactionStatus';

const Wrapper = styled.div`
  .back {
    display: flex;
    width: max-content;
    grid-gap: 1rem;
    align-items: center;
  }
  .linked-btn {
    text-decoration: none;
  }
  .section {
    border-top: 1px solid grey;
    min-height: 10rem;
    margin-top: 1rem;
    padding: 1rem 0rem;
  }
  .avatar {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
  }
  .img {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    margin: 0;
    padding: 0;
  }
  .profile {
    display: flex;
    grid-gap: 1rem;
    align-items: center;
  }
  .details_body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .main_body {
    padding: 1rem;
    background-color: ${(props) => props.theme.color.white};
    border-radius: 0.5rem;
    min-height: calc(100vh - 6rem);
    & > :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .header_text {
    text-align: center;
  }
  .mmo_body {
    padding: 0.5rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
  .left {
    border-right: 1px solid ${(props) => props.theme.color.grey};
  }
  .section_title {
    color: ${(props) => props.theme.color.deepGrey};
    margin-top: 1rem;
  }
  .item {
    display: flex;
    padding-top: 0.5rem;
  }
  .item_title {
    width: 10rem;
  }
  .item_value {
    color: ${(props) => props.theme.color.deepGrey};
  }
`;

const ViewYawoTransaction = ({ match, yawoTransaction, getSingleYawoTransaction, isLoading }) => {
  const { transactionRef } = match.params;

  useEffect(() => {
    getSingleYawoTransaction(transactionRef);
  }, [getSingleYawoTransaction, transactionRef]);

  const displayInfo = (title, value) => (
    <div className="item">
      <h4 className="item_title">{title || ''}</h4>
      <h4 className="item_value">
        {value || ''}
        {value && value[0] === '#' && (
          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              background: `${value}`,
              margin: 'auto 5px',
            }}
          />
        )}
      </h4>
    </div>
  );
  return (
    <YawoLayout>
      <Wrapper>
        <div className="main_body">
          <h2 className="header_text">Yawo Transaction Details</h2>

          <div className="back">
            <Icon
              Icon={ArrowBackIcon}
              type="link"
              to="/yawo/transactions"
              alt="Back to Yawo Transactions"
              text="Back"
            />
            <span>Back</span>
          </div>

          <div className="mmo_body">
            {isLoading ? (
              <React.Fragment />
            ) : yawoTransaction ? (
              <React.Fragment>
                <div className="left">
                  <div>
                    <h5 className="section_title">GENERAL INFO</h5>
                    {displayInfo('Yawo Ref:', yawoTransaction.yawoRef)}
                    {displayInfo('Amount:', formatAmount(yawoTransaction.amount) || '')}
                    {displayInfo('Transaction Type:', yawoTransaction.transactionType)}
                    {displayInfo('Status:', <TransactionStatus status={yawoTransaction.status} />)}
                    {displayInfo('Message:', yawoTransaction.message)}
                    {displayInfo('Created Date:', formatDate(yawoTransaction?.createdAt || ''))}
                    {displayInfo('Created Time:', formatTime(yawoTransaction?.createdAt || ''))}
                  </div>
                </div>
                <div>
                  <div>
                    <h5 className="section_title">SENDER DETAILS</h5>
                    {displayInfo('Sender IUWI:', yawoTransaction.senderIuwi || '')}
                    {displayInfo('Sender Short Code:', yawoTransaction.senderShortCode || '')}
                    {displayInfo('Sender Ref:', yawoTransaction.senderHistoryRef || '')}
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">RECIEVER DETAILS</h5>
                    {displayInfo('Receiver IUWI:', yawoTransaction.receiverIuwi || '')}
                    {displayInfo('Receiver Short Code:', yawoTransaction.receiverShortCode || '')}
                    {displayInfo('Receiver Ref:', yawoTransaction.receiverHistoryRef || '')}
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <div>No Yawo Transaction Data</div>
            )}
          </div>
        </div>
      </Wrapper>
    </YawoLayout>
  );
};

ViewYawoTransaction.propTypes = {
  match: PropTypes.object.isRequired,
  yawoTransaction: PropTypes.object,
  isLoading: PropTypes.bool,
  getSingleYawoTransaction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading, yawoTransaction } = state.yawoTransaction;
  return { isLoading, yawoTransaction };
};

export default connect(mapStateToProps, { getSingleYawoTransaction })(withRouter(ViewYawoTransaction));
