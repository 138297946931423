import {
  ADD_PTSPS,
  ADD_PTSP,
  PTSP_IS_LOADING,
  PTSP_NOT_LOADING,
  SET_PTSPS_PAGE,
  SET_PTSPS_LIMIT,
  SET_CARD_TYPES,
  SET_PTSP_OPTIONS,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  ptsps: null,
  ptsp: null,
  cardTypes: [],
  ptspOptions: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PTSP_IS_LOADING:
      return { ...state, isLoading: true };
    case PTSP_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_PTSPS:
      return { ...state, ptsps: action.payload };
    case ADD_PTSP:
      return { ...state, ptsp: action.payload };
    case SET_PTSPS_LIMIT:
      return {
        ...state,
        ptsps: { ...state.ptsps, limit: action.payload },
      };
    case SET_PTSPS_PAGE:
      return {
        ...state,
        ptsps: { ...state.ptsps, page: action.payload },
      };
    case SET_CARD_TYPES:
      return { ...state, cardTypes: action.payload };
    case SET_PTSP_OPTIONS:
      return { ...state, ptspOptions: action.payload };
    default:
      return state;
  }
}
