import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import YawoLayout from 'src/components/yawo/YawoLayout';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from 'styled-components';
import Icon from 'src/components/common/Icon';
import { Link, withRouter } from 'react-router-dom';
import { getSingleYawoAgent } from 'src/store/actions/yawo/agentActions';
import { connect } from 'react-redux';
import { formatDate, formatAmount } from 'src/utils/helpers';
import Button from 'src/components/common/Button';
import UpdateLoanLimit from './UpdateLoanLimit';
import ActivateAgent from './ActivateAgent';
import SuspendAgent from './SuspendAgent';
import VerifyAgent from './VerifyAgent';
import ImageViewer from 'src/components/common/ImageViewer';

const Wrapper = styled.div`
  .back {
    display: flex;
    width: max-content;
    grid-gap: 1rem;
    align-items: center;
  }
  .linked-btn {
    text-decoration: none;
  }
  .section {
    border-top: 1px solid grey;
    min-height: 10rem;
    margin-top: 1rem;
    padding: 1rem 0rem;
  }
  .avatar {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
  }
  .img {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    margin: 0;
    padding: 0;
  }
  .profile {
    display: flex;
    grid-gap: 1rem;
    align-items: center;
  }
  .details_body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }

  .main_body {
    padding: 1rem;
    background-color: ${(props) => props.theme.color.white};
    border-radius: 0.5rem;
    min-height: calc(100vh - 6rem);

    & > :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .header_text {
    text-align: center;
  }
  .mmo_body {
    padding: 0.5rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
  .left {
    border-right: 1px solid ${(props) => props.theme.color.grey};
  }
  .section_title {
    color: ${(props) => props.theme.color.deepGrey};
    margin-top: 1rem;
  }
  .item {
    display: flex;
    padding-top: 0.5rem;
  }
  .item_title {
    width: 10rem;
  }
  .item_value {
    color: ${(props) => props.theme.color.deepGrey};
  }
`;

const ViewAgent = ({ match, getSingleYawoAgent, yawoAgent, isLoading }) => {
  const { agentId } = match.params;
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const toggleViewer = () => setViewerIsOpen(!viewerIsOpen);

  useEffect(() => {
    getSingleYawoAgent(agentId);
  }, [agentId]);

  const displayInfo = (title, value) => (
    <div className="item">
      <h4 className="item_title">{title || ''}</h4>
      <h4 className="item_value">
        {value || ''}
        {value && value[0] === '#' && (
          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              background: `${value}`,
              margin: 'auto 5px',
            }}
          />
        )}
      </h4>
    </div>
  );

  return (
    <YawoLayout>
      <Wrapper>
        <div className="main_body">
          <h2 className="header_text">Yawo Agent Details</h2>
          <div className="back">
            <Icon Icon={ArrowBackIcon} type="link" to="/yawo/agents" alt="Back to Yawo Agents" text="Back" />
            <span>Back</span>
          </div>
          <div className="mmo_body">
            {isLoading ? (
              <React.Fragment />
            ) : yawoAgent === null ? (
              <div>Yawo Agent Not Found</div>
            ) : (
              <React.Fragment>
                <div className="left">
                  <div style={{ display: 'flex' }}>
                    <div>
                      {displayInfo('First Name:', yawoAgent.firstName || '')}
                      {displayInfo('Last Name:', yawoAgent.lastName || '')}
                      {displayInfo('Other Names:', yawoAgent.otherNames || '')}
                      {displayInfo('Vendor:', yawoAgent?.vendor?.shortCode || '')}
                      <br />
                      <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                        {yawoAgent?.adminVerification?.isVerified ? (
                          <>
                            <UpdateLoanLimit
                              agentId={yawoAgent._id}
                              callback={() => getSingleYawoAgent(yawoAgent._id)}
                            />
                            {yawoAgent.isActive ? (
                              <SuspendAgent agentId={yawoAgent._id} />
                            ) : (
                              <ActivateAgent agentId={yawoAgent._id} />
                            )}
                            {yawoAgent.yawoWallet && (
                              <Link to={`/yawo/wallets?yawoWalletIuwi=${yawoAgent.yawoWallet}`} className="linked-btn">
                                <Button text="View Wallet" />
                              </Link>
                            )}
                          </>
                        ) : (
                          <VerifyAgent agentId={yawoAgent._id} />
                        )}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">GENERAL INFO</h5>
                    {displayInfo('Ikonik Wallet:', yawoAgent.ikonikWallet || '')}
                    {displayInfo('Yawo Wallet:', yawoAgent.yawoWallet || '')}
                    {displayInfo('Loan Limit:', formatAmount(yawoAgent.loanLimit))}
                    {displayInfo('Is Vendor Verified:', yawoAgent?.vendor?.isVerified ? '✅' : '❌')}
                    {displayInfo('Is Admin Verified:', yawoAgent?.adminVerification?.isVerified ? '✅' : '❌')}
                    {displayInfo('Is Active:', yawoAgent.isActive ? '✅' : '❌')}
                    {displayInfo('Email:', yawoAgent?.email?.address || '')}
                    {displayInfo('Phone:', yawoAgent?.phoneNumber?.number || '')}
                    {displayInfo(
                      'Created At:',
                      yawoAgent.createdAt && yawoAgent.createdAt !== 'None' ? formatDate(yawoAgent.createdAt) : ''
                    )}
                    {displayInfo(
                      'Updated At:',
                      yawoAgent.updatedAt && yawoAgent.updatedAt !== 'None' ? formatDate(yawoAgent.updatedAt) : ''
                    )}
                  </div>
                </div>
                <div>
                  <h5 className="section_title">PHOTO(S)</h5>
                  <div className="item">
                    <h4 className="item_title">Agent Photo:</h4>
                    <div className="item_value">
                      <img src={yawoAgent?.photo} style={{ width: '100%', maxWidth: '340px' }} onClick={toggleViewer} />
                    </div>
                  </div>
                  <ImageViewer isOpen={viewerIsOpen} onClose={toggleViewer} images={[yawoAgent?.photo]} />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </Wrapper>
    </YawoLayout>
  );
};

ViewAgent.propTypes = {
  match: PropTypes.object.isRequired,
  yawoAgent: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSingleYawoAgent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { yawoAgent, isLoading } = state.yawoAgent;
  return { yawoAgent, isLoading };
};

export default connect(mapStateToProps, { getSingleYawoAgent })(withRouter(ViewAgent));
