import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import GuarantorsComponent from 'src/components/yawo/guarantors/Guarantors';

const Guarantors = () => {
  return (
    <DashboardLayout>
      <GuarantorsComponent />
    </DashboardLayout>
  );
};

export default Guarantors;
