import {
  ACCOUNT_IS_LOADING,
  ACCOUNT_NOT_LOADING,
  ADD_ACCOUNTS,
  ADD_ACCOUNT,
  CLEAR_ACCOUNT,
  SET_ACCOUNT_LIMIT,
  SET_ACCOUNT_PAGE,
  SET_ACCOUNT_STATUS,
  SET_USER_SERVICES_LIST,
  SET_USER_SERVICES,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  accounts: null,
  account: null,
  userServicesList: [],
  userServices: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_IS_LOADING:
      return { ...state, isLoading: true };
    case ACCOUNT_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_ACCOUNTS:
      return { ...state, accounts: action.payload };
    case ADD_ACCOUNT:
      return { ...state, account: action.payload };
    case CLEAR_ACCOUNT:
      return { ...state, account: initialState.account };
    case SET_ACCOUNT_LIMIT:
      return {
        ...state,
        accounts: { ...state.accounts, limit: action.payload },
      };
    case SET_ACCOUNT_PAGE:
      return {
        ...state,
        accounts: { ...state.accounts, page: action.payload },
      };
    case SET_ACCOUNT_STATUS:
      return {
        ...state,
        accounts: { ...state.accounts, status: action.payload },
      };
    case SET_USER_SERVICES_LIST:
      return { ...state, userServicesList: action.payload };
    case SET_USER_SERVICES:
      return { ...state, userServices: action.payload };
    default:
      return state;
  }
}
