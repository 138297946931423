import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getAllMmos, setMmoLimit, setMmoPage, setMmoDate, resetMmoDate } from 'src/store/actions/mmoActions';
// import { formatDate } from 'src/utils/helpers';
// import { DateRangeInput, SelectInput } from 'src/components/common/inputs';
import CreateMmo from './CreateMmo';
import MmoTable from './MmoTable';
import UserLayout from 'src/components/users/UserLayout';
import DisplayCard from 'src/components/common/DisplayCard';
import BusinessIcon from '@material-ui/icons/Business';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SupervisedUserCircleIcon from '@material-ui/icons/LocalActivityRounded';
// import { Card } from '@material-ui/core';
// import Icon from 'src/components/common/Icon';

// // Table Icons
// import RotateLeftIcon from '@material-ui/icons/RotateLeft';
// import DateRangeIcon from '@material-ui/icons/DateRange';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  height: max-content;
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .mmo-filters__wrapper {
    display: flex;
    justify-content: space-between;
  }
  .mmo-filters {
    display: flex;

    & > :not(:last-child) {
      margin-right: 1rem;
    }
  }
  .mmo-filter {
    display: grid;
    width: max-content;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 0.5rem;
  }
  .date_range {
    padding: 0rem 0.5rem;
    display: grid;
    border-radius: 5px;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const Mmo = ({ mmos, getAllMmos, setMmoLimit, setMmoPage }) => {
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);
  const [appIsUploaded] = useState('all');
  const [appIsLive] = useState('all');

  useEffect(() => {
    setMmoPage(1);
    setMmoLimit(20);
    const query = {};
    if (appIsUploaded && appIsUploaded !== 'all') {
      query.isUploaded = appIsUploaded;
    }
    if (appIsUploaded && appIsLive !== 'all') {
      query.appIsLive = appIsLive;
    }
    getAllMmos(query);
  }, [appIsUploaded, appIsLive]);

  // const onAppIsUploadedChange = (value) => setAppIsUploaded(value);
  // const onAppIsLiveChange = (value) => setAppIsLive(value);

  const getMmos = () => {
    const query = {};
    if (appIsUploaded && appIsUploaded !== 'all') {
      query.isUploaded = appIsUploaded;
    }
    if (appIsUploaded && appIsLive !== 'all') {
      query.appIsLive = appIsLive;
    }
    getAllMmos(query);
  };

  const onPageChange = (page) => {
    setMmoPage(page);
    getMmos();
  };

  const onLimitChange = (limit) => {
    setMmoLimit(limit);
    getMmos();
  };

  // const onDateChange = (date) => {
  // 	setMmoDate(date.startDate, date.endDate);
  // 	getMmos();
  // };

  // const onResetDateClick = () => {
  // 	resetMmoDate();
  // 	getAllMmos();
  // };

  return (
    <UserLayout>
      <Wrapper>
        <CreateMmo getAllMmos={getAllMmos} />
        <div className="cards">
          <DisplayCard icon={BusinessIcon} title="Total MMO" value={mmos !== null && mmos.count} />
          <DisplayCard icon={CloudUploadIcon} title="Total Uploaded" value={mmos !== null && mmos.active_users} />
          <DisplayCard
            icon={SupervisedUserCircleIcon}
            title="Total Live"
            value={mmos !== null && mmos.suspended_users}
          />
        </div>
        {/* <div className="mmo-filters__wrapper">
					<div className="mmo-filters">
						<div className="mmo-filter">
							<h4>App Uploaded:</h4>
							<SelectInput options={appIsUploadedOrLiveOptions} value={appIsUploaded} onChange={onAppIsUploadedChange} />
						</div>
						<div className="mmo-filter">
							<h4>App Live:</h4>
							<SelectInput options={appIsUploadedOrLiveOptions} value={appIsLive} onChange={onAppIsLiveChange} />
						</div>
					</div>
					<Card className="date_range">
						<h6>{`${mmos === null ? '' : formatDate(mmos.start_date)} - ${mmos === null ? '' : formatDate(mmos.end_date)}`}</h6>
						<Icon Icon={DateRangeIcon} alt='Set Date' onClick={toggle} />
						<Icon Icon={RotateLeftIcon } alt='Reset Date' onClick={onResetDateClick}/>
					</Card>
				</div> */}
        <MmoTable tableData={mmos} onPageChange={onPageChange} onLimitChange={onLimitChange} />
        {/* <div className="range_input">
					<DateRangeInput
						isOpen={isOpen}
						onChange={onDateChange}
						toggleOpen={toggle}
					/>
				</div> */}
      </Wrapper>
    </UserLayout>
  );
};

// const appIsUploadedOrLiveOptions = [
// 	{
// 		text: 'All',
// 		value: 'all',
// 	},
// 	{
// 		text: 'Yes',
// 		value: 'true',
// 	},
// 	{
// 		text: 'No',
// 		value: 'false',
// 	},
// ];

Mmo.propTypes = {
  mmos: PropTypes.object.isRequired,
  getAllMmos: PropTypes.func.isRequired,
  setMmoLimit: PropTypes.func.isRequired,
  setMmoPage: PropTypes.func.isRequired,
  setMmoDate: PropTypes.func.isRequired,
  resetMmoDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { mmos } = state.mmo;
  return { mmos };
};

export default connect(mapStateToProps, { getAllMmos, setMmoPage, setMmoLimit, setMmoDate, resetMmoDate })(Mmo);
