import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import CollectionsComponent from 'src/components/yawo/collections/Collections';

const Collections = () => {
  return (
    <DashboardLayout>
      <CollectionsComponent />
    </DashboardLayout>
  );
};

export default Collections;
