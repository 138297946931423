import React from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/common/table/Table';
import { roleTableHeader } from './roleTableHeader';
import { sortTableData } from 'src/utils/helpers';
import RoleSearch from './RoleSearch';

const RoleTable = ({ tableData }) => {
  const leftHeader = <RoleSearch />;
  const rightHeader = null;

  return (
    <div>
      <Table
        headers={roleTableHeader}
        rightHeader={rightHeader}
        leftHeader={leftHeader}
        data={tableData && tableData.data ? sortTableData(tableData.data, {}) : []}
        showNav={false}
      />
    </div>
  );
};

RoleTable.defaultProps = {
  tableData: { data: [], limit: 0, page: 1, count: 0 },
};

RoleTable.propTypes = {
  tableData: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
};

export default RoleTable;
