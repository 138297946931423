import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import ModalTwo from 'src/components/common/ModalTwo';
import { TextInput } from 'src/components/common/inputs';
import SelectRolePermissions from './SelectRolePermissions';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { createRole, getAllRoles } from 'src/store/actions/roleActions';

const Wrapper = styled.div`
  h2 {
    font-size: x-large;
  }

  .form-section-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    align-items: end;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .long {
      grid-column-end: span 1;
    }

    .longer {
      grid-column-end: span 2;

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .longest {
      grid-column-end: span 3;

      @media (max-width: 768px) {
        grid-column-end: span 2;
      }

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .align-start {
      align-self: start;
    }

    .align-center {
      align-self: center;
    }

    .align-end {
      align-self: end;
    }

    .justify-end {
      justify-self: end;
    }
  }

  .label {
    color: ${(props) => props.theme.color.black};
    min-width: max-content;
  }
`;

const CreateRole = ({ rolePermissions, createRole, getAllRoles }) => {
  if (!rolePermissions) return null;

  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState('');

  const resetFormState = () => {
    document.getElementById('createRoleForm').reset();
    setName('');
  };

  const submitForm = (e) => {
    e.preventDefault();

    const permissions = [];
    const checkboxes = Array.from(document.querySelectorAll('[name="permissions[]"]'));
    for (let i = 0; i < checkboxes.length; i++) {
      if (!checkboxes[i].checked) continue;
      permissions.push(checkboxes[i].value);
    }

    const data = {
      name,
      permissions,
    };

    setIsSubmitting(true);
    createRole(data, {
      always: () => {
        setIsSubmitting(false);
      },
      success: () => {
        setShow(false);
        resetFormState();
        if (getAllRoles) getAllRoles();
      },
    });
  };

  return (
    <Wrapper>
      <div className="form-section-grid">
        <div className="longest justify-end">
          <Button text="Create Role" onClick={toggleShow} />
        </div>
      </div>

      <ModalTwo size="large" active={show} onModalCloseCallback={toggleShow}>
        <div>
          <center>
            <h2>Create Role</h2>
          </center>

          <br />

          <form id="createRoleForm" onSubmit={submitForm} className="form-section-grid">
            <div className="longest">
              <TextInput label="Name" value={name} onChange={setName} required />
            </div>

            <SelectRolePermissions rolePermissions={rolePermissions} />

            <div className="longest justify-end">
              <Button
                type="submit"
                text={isSubmitting ? 'Creating Role ...' : '+ Create Role'}
                disabled={isSubmitting}
              />
            </div>
          </form>
        </div>
      </ModalTwo>
    </Wrapper>
  );
};

CreateRole.propTypes = {
  rolePermissions: PropTypes.object.isRequired,
  createRole: PropTypes.func.isRequired,
  getAllRoles: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { rolePermissions } = state.role;
  return { rolePermissions };
};

export default connect(mapStateToProps, { createRole, getAllRoles })(CreateRole);
