import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Theme from './components/layout/Theme';
import Router from './routes/Router';
import { connect } from 'react-redux';
import { setCurrentUser } from './store/actions/userActions';
import { getPlatforms } from './store/actions/platformActions';
import { setAppIsActive } from './store/actions/appActions';

function App({ setCurrentUser, isLoading, getPlatforms, setAppIsActive }) {
  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      const status = document.hidden;
      setAppIsActive(!status);
    });
  }, [status]);

  useEffect(() => {
    setCurrentUser();
    getPlatforms();
  }, [setCurrentUser, getPlatforms]);

  return <Theme>{!isLoading && <Router />}</Theme>;
}

App.propTypes = {
  setCurrentUser: PropTypes.func.isRequired,
  getPlatforms: PropTypes.func.isRequired,
  setAppIsActive: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading } = state.app;
  return { isLoading };
};

export default connect(mapStateToProps, { setCurrentUser, getPlatforms, setAppIsActive })(App);
