import React from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import Notify from 'src/components/notification/Notify';
import PoppinsExtraBold from 'src/assets/font/Poppins-ExtraBold.ttf';
import PoppinsBold from 'src/assets/font/Poppins-Bold.ttf';
import PoppinsSemiBold from 'src/assets/font/Poppins-SemiBold.ttf';
import PoppinsMedium from 'src/assets/font/Poppins-Medium.ttf';
import PoppinsLight from 'src/assets/font/Poppins-Light.ttf';

const theme = {
  color: {
    white: '#FFFFFF',
    blue: '#0173BC',
    purple: '#373B53',
    grey: '#E8E8E8',
    deepGrey: '#6F6C99',
    lightGrey: '#E5E5E5',
    black: '#000000',
    red: '#FF6760',
    green: '#21C38D',
    lightBlue: '#4CB8FF',
  },
};

const Wrapper = styled.div`
  background-color: ${theme.color.grey};
  min-height: 100vh;
  min-width: 100%;
`;

const GlobalStyle = createGlobalStyle`
	@font-face {
    font-family: poppins-ExtraBold;
    src: url(${PoppinsExtraBold}) ;
  }
	@font-face {
    font-family: poppins-Bold;
    src: url(${PoppinsBold}) ;
  }
	@font-face {
    font-family: poppins-SemiBold;
    src: url(${PoppinsSemiBold}) ;
  }
	@font-face {
    font-family: poppins-Medium;
    src: url(${PoppinsMedium}) ;
  }
	@font-face {
    font-family: Poppins-Light;
    src: url(${PoppinsLight}) ;
  }
	html {
		box-sizing: border-box;
	}
	*, *:before, *:after {
		box-sizing: inherit;
	}
	body {
		padding: 0;
		margin: 0;
		font-size: 0.8rem;
		color: ${theme.color.black};
		font-family: poppins-medium;
	}
	div, span {
    margin: 0;
    padding: 0;
  }
	h1, h2, h3, h4, h5, h6, p {
		margin: 0;
		padding: 0;
	}
	h1 {
    font-size: 1.4rem;
		font-family: poppins-Bold;
	}
	h2 {
    font-size: 1rem;
		font-family: poppins-Bold;
	}
	h3 {
    font-size: 1rem;
		font-family: poppins-SemiBold;
	}
	h6 {
    font-size: 0.9rem;
		font-family: poppins-SemiBold;
	}
	p {
		font-size: 0.8rem;
		font-family: poppins-medium;
	}
	.link {
		text-decoration: none;
	}
`;

const Theme = (props) => (
  <ThemeProvider theme={theme}>
    <div>
      <GlobalStyle />
      <Wrapper>
        <Notify />
        {props.children}
      </Wrapper>
    </div>
  </ThemeProvider>
);
Theme.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Theme;
