import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import UsersComponent from 'src/components/users/Users';

const Users = () => {
  return (
    <DashboardLayout>
      <UsersComponent />
    </DashboardLayout>
  );
};

export default Users;
