import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  .mapped_text,
  .active_text {
    color: ${(props) => props.theme.color.green};
  }
  .inactive_text {
    color: ${(props) => props.theme.color.red};
  }
  .unmapped_text{
    color: ${(props) => props.theme.color.blue};
  }
`;

function PosStatus({ status }) {
  const displayNotActived = () => <p className="inactive_text">not activated</p>;

  const displayActived = () => <p className="active_text">activated</p>;

  const displayNotMapped = () => <p className="unmapped_text">not mapped</p>;

  const displayMapped = () => <p className="mapped_text">mapped</p>;

  return (
    <Wrapper>
      {status === 'not activated' && displayNotActived()}
      {status === 'activated' && displayActived()}
      {status === 'not mapped' && displayNotMapped()}
      {status === 'mapped' && displayMapped()}
    </Wrapper>
  );
}

PosStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default PosStatus;
