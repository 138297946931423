import React from 'react';
import PropTypes from 'prop-types';
import ImageViewer from 'react-simple-image-viewer';

const Viewer = ({ isOpen, onClose, images }) => {
  return (
    <div>
      {isOpen && (
        <ImageViewer
          src={images}
          currentIndex={0}
          onClose={onClose}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
        />
      )}
    </div>
  );
};

Viewer.defaultProps = {
  isOpen: false,
  onClose: () => {},
  images: [],
};

Viewer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
};

export default Viewer;
