import QS from 'qs';
import client from 'src/client';
import {
  ADD_ROLES,
  ADD_ROLE,
  CLEAR_ROLE,
  ROLE_IS_LOADING,
  ROLE_NOT_LOADING,
  SET_ROLE_DATE,
  RESET_ROLE_DATE,
  SET_ROLE_LIMIT,
  SET_ROLE_STATUS,
  SET_ROLE_PAGE,
  SET_ROLE_PERMISSIONS,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from './alertActions';

const { adminService } = client;

const roleIsLoading =
  (msg = 'Role Loading') =>
  (dispatch) => {
    dispatch({ type: ROLE_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const roleNotLoading = () => (dispatch) => {
  dispatch({ type: ROLE_NOT_LOADING });
};

const addRoles = (roles) => {
  return { type: ADD_ROLES, payload: roles };
};

const addRole = (role) => {
  return { type: ADD_ROLE, payload: role };
};

const clearRole = () => {
  return { type: CLEAR_ROLE };
};

export const setRoleDate = (start_date, end_date) => {
  return { type: SET_ROLE_DATE, payload: { start_date, end_date } };
};

export const resetRoleDate = () => {
  return { type: RESET_ROLE_DATE };
};

export const setRolePage = (page) => {
  return { type: SET_ROLE_PAGE, payload: page };
};

export const setRoleLimit = (limit) => {
  return { type: SET_ROLE_LIMIT, payload: limit };
};

export const setRoleStatus = (status) => {
  return { type: SET_ROLE_STATUS, payload: status };
};

const generatePath = (endPoint, query) => {
  let url = `${endPoint}?`;
  if (query && Object.keys(query).length > 0) {
    url += QS.stringify(query);
  }
  return url;
};

export const getAllRoles =
  (query = {}, loadSilently = false) =>
  (dispatch) => {
    if (!loadSilently) dispatch(roleIsLoading('Loading Roles'));
    const path = generatePath('/roles', query);
    adminService
      .get(path)
      .then((res) => {
        dispatch(addRoles(res.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading Roles' })))
      .finally(() => dispatch(roleNotLoading()));
  };

export const getSingleRole = (roleId, extraParams) => (dispatch) => {
  const { silently = false } = extraParams || {};
  if (!silently) {
    dispatch(roleIsLoading('Loading Role'));
  }
  adminService
    .get(`/roles/${roleId}`)
    .then((res) => {
      dispatch(addRole(res.data));
      dispatch(clearAlert());
    })
    .catch((err) => {
      dispatch(clearRole());
      dispatch(errorAlert({ msg: err.response?.data?.message || err.message }));
    })
    .finally(() => dispatch(roleNotLoading()));
};

export const searchRole = (searchString) => (dispatch) => {
  dispatch(roleIsLoading('Searching...'));
  adminService
    .get(`/roles?search=${searchString}`)
    .then((res) => {
      dispatch(addRoles(res.data));
      dispatch(clearAlert());
    })
    .catch(() => {
      dispatch(errorAlert({ msg: 'Error Loading Roles' }));
    })
    .finally(() => dispatch(roleNotLoading()));
};

// Create & Update Role

const createRoleIsLoading =
  (msg = 'Creating Role') =>
  (dispatch) =>
    dispatch(infoAlert(msg));
const createRoleNotLoading = () => (dispatch) => dispatch(clearAlert());

export const createRole = (formData, callback) => (dispatch) => {
  dispatch(createRoleIsLoading());
  adminService
    .post('/roles', formData)
    .then(() => {
      dispatch(successAlert('Role Created'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => {
      setTimeout(() => {
        dispatch(createRoleNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

const updateRoleIsLoading =
  (msg = 'Updating Role') =>
  (dispatch) =>
    dispatch(infoAlert(msg));
const updateRoleNotLoading = () => (dispatch) => dispatch(clearAlert());

export const updateRole = (roleId, formData, callback) => (dispatch) => {
  dispatch(updateRoleIsLoading());
  adminService
    .put(`/roles/${roleId}`, formData)
    .then(() => {
      dispatch(successAlert('Role Updated'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => {
      setTimeout(() => {
        dispatch(updateRoleNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

export const activateRole = (roleId, callback) => (dispatch) => {
  dispatch(updateRoleIsLoading());
  adminService
    .get(`/roles/${roleId}/activate`)
    .then((res) => {
      const { data: role } = res.data || {};
      if (role) dispatch(addRole(role));
      dispatch(successAlert('Role Activated'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => dispatch(updateRoleNotLoading()));
};

export const archiveRole = (roleId) => (dispatch) => {
  dispatch(updateRoleIsLoading());
  adminService
    .get(`/roles/${roleId}/archive`)
    .then((res) => {
      const { data: role } = res.data || {};
      if (role) dispatch(addRole(role));
      dispatch(successAlert('Role Archived'));
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => dispatch(updateRoleNotLoading()));
};

// Role Permissions

const setRolePermissions = (rolePermissions) => {
  return { type: SET_ROLE_PERMISSIONS, payload: rolePermissions };
};

export const fetchRolePermissions = () => (dispatch) => {
  adminService
    .get('/roles/permissions')
    .then((res) => dispatch(setRolePermissions(res.data)))
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading Role Permissions' })))
    .finally(() => dispatch(roleNotLoading()));
};
