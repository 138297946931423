import QS from 'qs';
import client from 'src/client';
import {
  ADD_MMOS,
  ADD_MMO,
  CLEAR_MMO,
  MMO_IS_LOADING,
  MMO_NOT_LOADING,
  SET_MMO_DATE,
  RESET_MMO_DATE,
  SET_MMO_LIMIT,
  SET_MMO_STATUS,
  SET_MMO_PAGE,
  SET_MMO_SERVICES,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from './alertActions';

const { adminService, mediaService } = client;

const mmoIsLoading =
  (msg = 'MMO Loading') =>
  (dispatch) => {
    dispatch({ type: MMO_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const mmoNotLoading = () => (dispatch) => {
  dispatch({ type: MMO_NOT_LOADING });
};

const addMmos = (mmos) => {
  return { type: ADD_MMOS, payload: mmos };
};

const addMmo = (mmo) => {
  return { type: ADD_MMO, payload: mmo };
};

const clearMmo = () => {
  return { type: CLEAR_MMO };
};

export const setMmoDate = (start_date, end_date) => {
  return { type: SET_MMO_DATE, payload: { start_date, end_date } };
};

export const resetMmoDate = () => {
  return { type: RESET_MMO_DATE };
};

export const setMmoPage = (page) => {
  return { type: SET_MMO_PAGE, payload: page };
};

export const setMmoLimit = (limit) => {
  return { type: SET_MMO_LIMIT, payload: limit };
};

export const setMmoStatus = (status) => {
  return { type: SET_MMO_STATUS, payload: status };
};

const generatePath = (endPoint, mmo, query) => {
  const { limit = 20, page = 1 } = mmo;
  let url = `${endPoint}?limit=${limit}&page=${page}`;
  if (query && Object.keys(query).length > 0) {
    url += `&${QS.stringify(query)}`;
  }
  return url;
};

export const getAllMmos =
  (query = {}) =>
  (dispatch, getState) => {
    dispatch(mmoIsLoading('Loading Mmos'));
    const { mmos } = getState().mmo;
    const path = generatePath('/mmos', mmos, query);
    adminService
      .get(path)
      .then((res) => {
        dispatch(addMmos(res.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading MMOs' })))
      .finally(() => dispatch(mmoNotLoading()));
  };

export const getSingleMmo = (mmoId, extraParams) => (dispatch) => {
  const { silently = false } = extraParams || {};
  if (!silently) {
    dispatch(mmoIsLoading('Loading MMO'));
  }
  adminService
    .get(`/mmos/${mmoId}`)
    .then((res) => {
      dispatch(addMmo(res.data));
      dispatch(clearAlert());
    })
    .catch((err) => {
      dispatch(clearMmo());
      dispatch(errorAlert({ msg: err.response?.data?.message || err.message }));
    })
    .finally(() => dispatch(mmoNotLoading()));
};

export const searchMmo = (searchString) => (dispatch) => {
  dispatch(mmoIsLoading('Searching...'));
  adminService
    .get(`/mmos?search=${searchString}`)
    .then((res) => {
      dispatch(addMmos(res.data));
      dispatch(clearAlert());
    })
    .catch(() => {
      dispatch(errorAlert({ msg: 'Error Loading Mmos' }));
    })
    .finally(() => dispatch(mmoNotLoading()));
};

export const loadMmoOptions = async (searchString) => {
  let options = [];
  await adminService
    .get(`/mmos?search=${searchString}`)
    .then((res) => {
      options = res?.data?.mmos || [];
    })
    .catch(() => {});

  return options.map((x) => ({ ...x, label: x.name, value: x.shortCode })).sort((a, b) => (a.label > b.label ? 1 : -1));
};

// Create & Update MMO

const createMmoIsLoading =
  (msg = 'Creating MMO') =>
  (dispatch) =>
    dispatch(infoAlert(msg));
const createMmoNotLoading = () => (dispatch) => dispatch(clearAlert());

export const createMmo = (formData, callback) => (dispatch) => {
  dispatch(createMmoIsLoading());
  adminService
    .post('/mmos', formData)
    .then(() => {
      dispatch(successAlert('MMO Created'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => {
      setTimeout(() => {
        dispatch(createMmoNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

const updateMmoIsLoading =
  (msg = 'Updating MMO') =>
  (dispatch) =>
    dispatch(infoAlert(msg));
const updateMmoNotLoading = () => (dispatch) => dispatch(clearAlert());

export const updateMmo = (mmoShortCode, formData, callback) => (dispatch) => {
  dispatch(updateMmoIsLoading());
  adminService
    .put(`/mmos/${mmoShortCode}`, formData)
    .then(() => {
      dispatch(successAlert('MMO Updated'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => {
      setTimeout(() => {
        dispatch(updateMmoNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

export const uploadMmoLogo = (formData, callback) => (dispatch) => {
  dispatch(updateMmoIsLoading('Uploading MMO Logo'));
  mediaService
    .post('/mmos/logo', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(() => {
      dispatch(successAlert('MMO Logo Is Uploaded'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => {
      setTimeout(() => {
        dispatch(updateMmoNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

export const updateMmoIsUploaded = (mmoShortCode) => (dispatch) => {
  dispatch(updateMmoIsLoading('Uploading Mmo App'));
  adminService
    .get(`/mmos/${mmoShortCode}/uploaded`)
    .then(({ data }) => {
      dispatch(successAlert('MMO App Is Uploaded'));
      dispatch(addMmo(data.data));
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => {
      setTimeout(() => dispatch(updateMmoNotLoading()), 3000);
    });
};

export const resetMmoPassword = (mmoShortCode) => (dispatch) => {
  dispatch(updateMmoIsLoading('Resetting Mmo Password'));
  adminService
    .get(`/mmos/${mmoShortCode}/reset-password`)
    .then(() => {
      dispatch(successAlert('Password has been reset'));
    })
    .catch((err) => dispatch(errorAlert({ msg: err?.response?.data?.message || 'Technical Error' })))
    .finally(() => {
      dispatch(updateMmoNotLoading());
    });
};

export const updateMmoIsLive = (mmoShortCode, formData, callback) => (dispatch) => {
  dispatch(updateMmoIsLoading('Updating MMO App Link'));
  adminService
    .post(`/mmos/${mmoShortCode}/live`, formData)
    .then(() => {
      dispatch(successAlert('MMO App Link Is Updated'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => {
      setTimeout(() => {
        dispatch(updateMmoNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

// MMO Services

const setMmoServices = (mmoServices) => {
  return { type: SET_MMO_SERVICES, payload: mmoServices };
};

export const fetchMmoServices = () => (dispatch) => {
  dispatch(mmoIsLoading('Loading Mmo Services'));
  adminService
    .get('/mmos/services')
    .then((res) => dispatch(setMmoServices(res.data)))
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading Mmo Services' })))
    .finally(() => dispatch(mmoNotLoading()));
};

export const updateMmoServices = (mmoShortCode, formData, callback) => (dispatch) => {
  dispatch(updateMmoIsLoading('Updating MMO Services'));
  adminService
    .post(`/mmos/${mmoShortCode}/services`, formData)
    .then(() => {
      dispatch(successAlert('Successfully Updated MMO Services'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => {
      setTimeout(() => {
        dispatch(updateMmoNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};
