import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import NotificationsLayout from 'src/components/notifications/NotificationsLayout';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from 'styled-components';
import Icon from 'src/components/common/Icon';
import { withRouter } from 'react-router-dom';
import { getSingleCardPayment } from 'src/store/actions/cardPaymentActions';
import { connect } from 'react-redux';
import { formatAmount, formatDate, formatTime } from 'src/utils/helpers';

const Wrapper = styled.div`
  .back {
    display: flex;
    width: max-content;
    grid-gap: 1rem;
    align-items: center;
  }
  .section {
    border-top: 1px solid grey;
    min-height: 10rem;
    margin-top: 1rem;
    padding: 1rem 0rem;
  }
  .avatar {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
  }
  .img {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    margin: 0;
    padding: 0;
  }
  .profile {
    display: flex;
    grid-gap: 1rem;
    align-items: center;
  }
  .details_body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
    width: 100%;
  }
  .details_item {
    display: grid;
    grid-gap: 0.5rem;
    min-width: 10rem;
  }
  .title_headers {
    padding-bottom: 1rem;
  }
  .item_header {
    color: ${(props) => props.theme.color.blue};
  }
`;

const ViewCardPayment = ({ match, getSingleCardPayment, cardPayment, isLoading }) => {
  const { paymentRef } = match.params;

  useEffect(() => {
    getSingleCardPayment(paymentRef);
  }, [paymentRef, getSingleCardPayment]);

  const displayDetails = (title, value) => (
    <div className="details_item">
      <p className="item_header">{title}</p>
      <h4 className="item_text">{value}</h4>
    </div>
  );

  return (
    <NotificationsLayout>
      <Wrapper>
        {isLoading ? (
          <React.Fragment />
        ) : cardPayment === null ? (
          <div>Card Payment Not Found</div>
        ) : (
          <React.Fragment>
            <div className="back">
              <Icon Icon={ArrowBackIcon} type="link" to="/notifications/cards" alt="Back to Card Payments" />
              <h3>Card Payment Details</h3>
            </div>
            <div className="section details">
              <div className="details_body">
                {displayDetails('Message', cardPayment.message || '')}
                {displayDetails('PTPS', cardPayment.ptsp)}
                {displayDetails('Payment Ref', cardPayment.ref || '')}
                {displayDetails('Transaction Ref', cardPayment.transactionRef || '')}
                {displayDetails('Masked PAN', cardPayment.maskedPan || '')}
                {displayDetails('Serial Number', cardPayment.serialNumber || '')}
                {displayDetails('Terminal', cardPayment?.meta?.terminal || '')}
                {displayDetails('Amount', formatAmount(cardPayment.amount))}
                {displayDetails('Status', cardPayment.status)}
                {displayDetails('Is Resolved', cardPayment.isResolved ? 'resolved' : 'unresolved')}
                {displayDetails('Is Settled', cardPayment.isSettled)}
                {displayDetails('AID', cardPayment.aid)}
                {displayDetails('Card Type', cardPayment.cardType)}
                {displayDetails('Vendor Ref', cardPayment.vendorRef)}
                {displayDetails('Payment Date', formatDate(cardPayment.createdAt))}
                {displayDetails('Payment Time', formatTime(cardPayment.createdAt))}
              </div>
              <br />
              <div className="details_body">
                <div className="details_item">
                  <p className="item_header">Meta</p>
                  <pre className="item_text" style={{ overflowX: 'auto' }}>
                    {JSON.stringify(cardPayment.meta, null, 2)}
                  </pre>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </Wrapper>
    </NotificationsLayout>
  );
};

ViewCardPayment.propTypes = {
  match: PropTypes.object.isRequired,
  cardPayment: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSingleCardPayment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { cardPayment, isLoading } = state.cardPayment;
  return { cardPayment, isLoading };
};

export default connect(mapStateToProps, { getSingleCardPayment })(withRouter(ViewCardPayment));
