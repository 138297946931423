import React from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/common/table/Table';
import { posesTableHeader } from './posTableHeader';
import { sortTableData } from 'src/utils/helpers';
import PosesSearch from './PosSearch';

const PosTable = ({ tableData, onPageChange, onLimitChange }) => {
  const leftHeader = <PosesSearch />;
  const rightHeader = null;

  return (
    <div>
      <Table
        headers={posesTableHeader}
        rightHeader={rightHeader}
        leftHeader={leftHeader}
        data={tableData && tableData.data ? sortTableData(tableData.data, {}) : []}
        limit={tableData && tableData.limit}
        page={tableData && tableData.page}
        count={tableData && tableData.count}
        showNav={true}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </div>
  );
};

PosTable.defaultProps = {
  tableData: { data: [], limit: 0, page: 1, count: 0 },
};

PosTable.propTypes = {
  tableData: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
};

export default PosTable;
