import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  getAllYawoAgents,
  setYawoAgentLimit,
  setYawoAgentPage,
  setYawoAgentDate,
  resetYawoAgentDate,
} from 'src/store/actions/yawo/agentActions';
import AsyncSelect from 'react-select/async';
import reactSelectUtil from 'src/utils/reactSelectUtil';
import { loadVendorOptions } from 'src/store/actions/yawo/vendorActions';
// import { formatDate } from 'src/utils/helpers';
// import { DateRangeInput } from 'src/components/common/inputs';
import YawoLayout from 'src/components/yawo/YawoLayout';
import AgentsTable from './AgentsTable';
// import { Card } from '@material-ui/core';
import DisplayCard from 'src/components/common/DisplayCard';
// import Icon from 'src/components/common/Icon';

// Table Icons
import BusinessIcon from '@material-ui/icons/Business';
// import RotateLeftIcon from '@material-ui/icons/RotateLeft';
// import DateRangeIcon from '@material-ui/icons/DateRange';

const Wrapper = styled.div`
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .wallet--body {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .date-selector {
    display: flex;
    justify-content: flex-end;

    .date_range {
      padding: 0rem 0.5rem;
      display: grid;
      border-radius: 5px;
      grid-template-columns: max-content max-content max-content;
      grid-gap: 0.5rem;
      align-items: center;
      justify-content: center;
    }
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .linked-shortcode {
    color: ${(props) => props.theme.color.blue};
  }
`;

const Agents = ({ yawoAgents, getAllYawoAgents, setYawoAgentLimit, setYawoAgentPage }) => {
  const [vendor, setVendor] = useState(null);
  const [status, setStatus] = useState('all');
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);

  const getYawoAgents = () => {
    const query = {};
    if (vendor?.value) {
      query.shortCode = vendor?.value;
    }
    if (status && status !== 'all') {
      query.status = status;
    }
    getAllYawoAgents(query);
  };

  useEffect(() => {
    setYawoAgentPage(1);
    setYawoAgentLimit(20);
    getYawoAgents();
  }, [status, vendor]);

  const onPageChange = (page) => {
    setYawoAgentPage(page);
    getYawoAgents();
  };

  const onLimitChange = (limit) => {
    setYawoAgentLimit(limit);
    getYawoAgents();
  };

  return (
    <YawoLayout>
      <Wrapper>
        <div className="wallet--body">
          <div className="cards">
            <DisplayCard icon={BusinessIcon} title="Total Agents" value={yawoAgents !== null && yawoAgents.count} />
          </div>
          {/* <div className="date-selector">
            <Card className="date_range">
              <h6>{`${formatDate('2019-01-01 00:00:00')} - ${formatDate('2022-02-10 00:00:00')}`}</h6>
              <Icon Icon={DateRangeIcon} alt="Set Date" onClick={toggle} />
              <Icon Icon={RotateLeftIcon} alt="Reset Date" onClick={null} />
            </Card>
          </div> */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <h4>Vendor:</h4>
            <div style={{ width: '20rem' }}>
              <AsyncSelect
                name="vendor"
                placeholder="Select Vendor"
                cacheOptions
                loadOptions={(val) => loadVendorOptions(val)}
                theme={reactSelectUtil.theme}
                styles={reactSelectUtil.styles}
                isClearable
                onChange={(newValue) => setVendor(newValue)}
                value={vendor}
              />
            </div>
          </div>
          <AgentsTable
            tableData={yawoAgents}
            status={status}
            setStatus={(val) => setStatus(val)}
            onPageChange={onPageChange}
            onLimitChange={onLimitChange}
          />
          {/* <div className="range_input">
            <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
          </div> */}
        </div>
      </Wrapper>
    </YawoLayout>
  );
};

Agents.propTypes = {
  yawoAgents: PropTypes.object.isRequired,
  getAllYawoAgents: PropTypes.func.isRequired,
  setYawoAgentLimit: PropTypes.func.isRequired,
  setYawoAgentPage: PropTypes.func.isRequired,
  setYawoAgentDate: PropTypes.func.isRequired,
  resetYawoAgentDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { yawoAgents } = state.yawoAgent;
  return { yawoAgents };
};

export default connect(mapStateToProps, {
  getAllYawoAgents,
  setYawoAgentLimit,
  setYawoAgentPage,
  setYawoAgentDate,
  resetYawoAgentDate,
})(Agents);
