import {
  STAFF_IS_LOADING,
  STAFF_NOT_LOADING,
  ADD_STAFFS,
  ADD_STAFF,
  CLEAR_STAFF,
  SET_STAFF_DATE,
  RESET_STAFF_DATE,
  SET_STAFF_LIMIT,
  SET_STAFF_PAGE,
  SET_STAFF_STATUS,
  SET_STAFF_DEPARTMENTS,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  staffs: null,
  staff: null,
  staffDepartments: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STAFF_IS_LOADING:
      return { ...state, isLoading: true };
    case STAFF_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_STAFFS:
      return { ...state, staffs: action.payload };
    case ADD_STAFF:
      return { ...state, staff: action.payload };
    case CLEAR_STAFF:
      return { ...state, staff: initialState.staff };
    case SET_STAFF_DATE:
      return {
        ...state,
        staffs: {
          ...state.staffs,
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
        },
      };
    case RESET_STAFF_DATE:
      return {
        ...state,
        staffs: {
          ...state.staffs,
          start_date: null,
          end_date: null,
        },
      };
    case SET_STAFF_LIMIT:
      return {
        ...state,
        staffs: { ...state.staffs, limit: action.payload },
      };
    case SET_STAFF_PAGE:
      return {
        ...state,
        staffs: { ...state.staffs, page: action.payload },
      };
    case SET_STAFF_STATUS:
      return {
        ...state,
        staffs: { ...state.staffs, status: action.payload },
      };
    case SET_STAFF_DEPARTMENTS:
      return { ...state, staffDepartments: action.payload };
    default:
      return state;
  }
}
