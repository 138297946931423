import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import ChargebacksComponent from 'src/components/finance/chargeback/Chargebacks';

const Chargeback = () => {
  return (
    <DashboardLayout>
      <ChargebacksComponent />
    </DashboardLayout>
  );
};

export default Chargeback;
