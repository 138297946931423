import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  getAllYawoLoans,
  setYawoLoanLimit,
  setYawoLoanPage,
  setYawoLoanDate,
  resetYawoLoanDate,
} from 'src/store/actions/yawo/loanActions';
import AsyncSelect from 'react-select/async';
import reactSelectUtil from 'src/utils/reactSelectUtil';
import { loadVendorOptions } from 'src/store/actions/yawo/vendorActions';
import { loadAgentOptions } from 'src/store/actions/yawo/agentActions';
import { loadCustomerOptions } from 'src/store/actions/yawo/customerActions';
import YawoLayout from 'src/components/yawo/YawoLayout';
import LoansTable from './LoansTable';
import DisplayCard from 'src/components/common/DisplayCard';
import MoneyIcon from '@material-ui/icons/Money';
import { DateRangeInput } from 'src/components/common/inputs';
import { formatDate } from 'src/utils/helpers';
import { Card } from '@material-ui/core';
import Icon from 'src/components/common/Icon';

// Table Icons
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import DateRangeIcon from '@material-ui/icons/DateRange';

const Wrapper = styled.div`
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .wallet--body {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .date-selector {
    display: flex;
    justify-content: flex-end;

    .date_range {
      padding: 0rem 0.5rem;
      display: grid;
      border-radius: 5px;
      grid-template-columns: max-content max-content max-content;
      grid-gap: 0.5rem;
      align-items: center;
      justify-content: center;
    }
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const Loans = ({ yawoLoans, getAllYawoLoans, setYawoLoanLimit, setYawoLoanPage,setYawoLoanDate,
  resetYawoLoanDate }) => {
  const [vendor, setVendor] = useState(null);
  const [agent, setAgent] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [status, setStatus] = useState('all');

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const getYawoLoans = () => {
    const query = {};
    if (agent?.value) {
      query.loanAgent = agent?.value;
    }
    if (customer?.value) {
      query.loanCustomer = customer?.value;
    }
    if (vendor?.value) {
      query.shortCode = vendor?.value;
    }
    if (status && status !== 'all') {
      query.status = status;
    }
    getAllYawoLoans(query);
  };

  useEffect(() => {
    setYawoLoanPage(1);
    setYawoLoanLimit(20);
    getYawoLoans();
  }, [status, customer, agent, vendor]);

  const onPageChange = (page) => {
    setYawoLoanPage(page);
    getYawoLoans();
  };

  const onLimitChange = (limit) => {
    setYawoLoanLimit(limit);
    getYawoLoans();
  };

  const onDateChange = async (date) => {
    await setYawoLoanDate(date.startDate, date.endDate);
    getYawoLoans();
  };

  const onResetDateClick = () => {
    resetYawoLoanDate();
    getYawoLoans();
  };

  return (
    <YawoLayout>
      <Wrapper>
        <div className="wallet--body">
          <div className="cards">
            <DisplayCard icon={MoneyIcon} title="Total Loans" value={yawoLoans?.count} />
            <DisplayCard icon={MoneyIcon} title="Total Approved" value={yawoLoans?.totalApproved} />
            <DisplayCard icon={MoneyIcon} title="Total Ongoing" value={yawoLoans?.totalOngoing} />
            <DisplayCard icon={MoneyIcon} title="Total Rejected" value={yawoLoans?.totalRejected} />
            <DisplayCard icon={MoneyIcon} title="Total Submitted  " value={yawoLoans?.totalSubmitted} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <h4>Vendor(MMO):</h4>
              <div style={{ width: '20rem' }}>
                <AsyncSelect
                  name="vendor"
                  placeholder="Select Vendor"
                  cacheOptions
                  loadOptions={(val) => loadVendorOptions(val)}
                  theme={reactSelectUtil.theme}
                  styles={reactSelectUtil.styles}
                  isClearable
                  onChange={(newValue) => setVendor(newValue)}
                  value={vendor}
                />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <h4>Agent:</h4>
              <div style={{ width: '20rem' }}>
                <AsyncSelect
                  name="agent"
                  placeholder="Select Agent"
                  cacheOptions
                  loadOptions={(val) => loadAgentOptions(val)}
                  theme={reactSelectUtil.theme}
                  styles={reactSelectUtil.styles}
                  isClearable
                  onChange={(newValue) => setAgent(newValue)}
                  value={agent}
                />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <h4>Customer:</h4>
              <div style={{ width: '20rem' }}>
                <AsyncSelect
                  name="customer"
                  placeholder="Select Customer"
                  cacheOptions
                  loadOptions={(val) => loadCustomerOptions(val)}
                  theme={reactSelectUtil.theme}
                  styles={reactSelectUtil.styles}
                  isClearable
                  onChange={(newValue) => setCustomer(newValue)}
                  value={customer}
                />
              </div>
            </div>
             <Card
              className="date_range"
              style={{ display: 'flex', alignItems: 'center', gap: '1rem', padding: '0 10px' }}
            >
              <h6>{`${yawoLoans === null ? '' : formatDate(yawoLoans.startDate)} - ${
                yawoLoans === null ? '' : formatDate(yawoLoans.endDate)
              }`}</h6>
              <Icon Icon={DateRangeIcon} alt="Set Date" onClick={toggle} />
              <Icon Icon={RotateLeftIcon} alt="Reset Date" onClick={onResetDateClick} />
            </Card>
          </div>
          <LoansTable
            tableData={yawoLoans}
            status={status}
            setStatus={(val) => setStatus(val)}
            onPageChange={onPageChange}
            onLimitChange={onLimitChange}
            onStatusChange={setStatus}
          />
           <div className="range_input">
            <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
          </div>
        </div>
      </Wrapper>
    </YawoLayout>
  );
};

Loans.propTypes = {
  yawoLoans: PropTypes.object,
  getAllYawoLoans: PropTypes.func.isRequired,
  setYawoLoanLimit: PropTypes.func.isRequired,
  setYawoLoanPage: PropTypes.func.isRequired,
  setYawoLoanDate: PropTypes.func.isRequired,
  resetYawoLoanDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { yawoLoans } = state.yawoLoan;
  return { yawoLoans };
};

export default connect(mapStateToProps, {
  getAllYawoLoans,
  setYawoLoanLimit,
  setYawoLoanPage,
  setYawoLoanDate,
  resetYawoLoanDate,
})(Loans);
