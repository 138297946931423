import React from 'react';
import EditPos from './EditPos';
import Icon from 'src/components/common/Icon';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from 'react-router-dom';
import PosStatus from './PosStatus';

export const posesTableHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Serial Number</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['serialNumber']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['mapping'] === null ? <PosStatus status="not mapped" /> : <PosStatus status="mapped" />}</p>;
    },
  },
  {
    title: function title() {
      return <p>MMO</p>;
    },
    render: function DateTaken(rowData) {
      return rowData['mapping'] === null ? (
        <span>nil</span>
      ) : (
        <Link className="linked" to={`/users/mmo/${rowData['mapping']?.mmoMapping?.mmoShortCode}`}>
          {rowData['mapping']?.mmoMapping?.mmoShortCode}
        </Link>
      );
    },
  },
  {
    title: function title() {
      return <p>Agent</p>;
    },
    render: function DateTaken(rowData) {
      return rowData['mapping'] === null ? (
        <span>nil</span>
      ) : (
        <Link className="linked" to={`/users/${rowData['mapping']?.agentMapping?.agentId}`}>
          {rowData['mapping']?.agentMapping?.agentId}
        </Link>
      );
    },
  },
  {
    title: function title() {
      return <p>Device Activation</p>;
    },
    render: function DateTaken(rowData) {
      return (
        <p>
          {rowData['mapping']?.isActivated?.status ? (
            <PosStatus status="activated" />
          ) : (
            <PosStatus status="not activated" />
          )}
        </p>
      );
    },
  },
  {
    title: function title() {
      return <p>Actions</p>;
    },
    render: function SerialNumber(rowData) {
      return (
        <div style={{ display: 'flex' }}>
          <span style={{ width: '100%' }}>
            <Icon Icon={OpenInNewIcon} type="link" to={`pos-new/${rowData['_id']}`} />
          </span>
          <span style={{ width: '100%' }}>
            <EditPos pos={rowData} />
          </span>
        </div>
      );
    },
  },
];
