import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import YawoLayout from 'src/components/yawo/YawoLayout';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from 'styled-components';
import Icon from 'src/components/common/Icon';
import { withRouter } from 'react-router-dom';
import { getSingleYawoWallet } from 'src/store/actions/yawo/walletActions';
import { connect } from 'react-redux';
import { formatAmount, dateFormatYMD, formatTime } from 'src/utils/helpers';
import WalletHistoryStatus from './WalletHistoryStatus';

const Wrapper = styled.div`
  .back {
    display: flex;
    width: max-content;
    grid-gap: 1rem;
    align-items: center;
  }
  .linked-btn {
    text-decoration: none;
  }
  .section {
    border-top: 1px solid grey;
    min-height: 10rem;
    margin-top: 1rem;
    padding: 1rem 0rem;
  }
  .avatar {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
  }
  .img {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    margin: 0;
    padding: 0;
  }
  .profile {
    display: flex;
    grid-gap: 1rem;
    align-items: center;
  }
  .details_body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .main_body {
    padding: 1rem;
    background-color: ${(props) => props.theme.color.white};
    border-radius: 0.5rem;
    min-height: calc(100vh - 6rem);
    & > :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .header_text {
    text-align: center;
  }
  .section_title {
    color: ${(props) => props.theme.color.deepGrey};
    margin-top: 1rem;
  }
  .item {
    display: flex;
    padding-top: 0.5rem;
  }
  .item_title {
    width: 10rem;
  }
  .item_value {
    color: ${(props) => props.theme.color.deepGrey};
  }
`;

const SingleWallet = ({ match, yawoWallet, getSingleYawoWallet, isLoading }) => {
  const { historyId } = match.params;

  const { walletHistoryData = {}, transactionData = {} } = yawoWallet || {};
  useEffect(() => {
    getSingleYawoWallet(historyId);
  }, [historyId]);

  const displayInfo = (title, value) => (
    <div className="item">
      <h4 className="item_title">{title || ''}</h4>
      <h4 className="item_value">
        {value || ''}
        {value && value[0] === '#' && (
          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              background: `${value}`,
              margin: 'auto 5px',
            }}
          />
        )}
      </h4>
    </div>
  );

  return (
    <YawoLayout>
      <Wrapper>
        <div className="main_body">
          <h2 className="header_text">Yawo Wallet Details</h2>
          <div className="back">
            <Icon Icon={ArrowBackIcon} type="link" to="/yawo/wallets" alt="Back to Yawo Wallets" text="Back" />
            <span>Back</span>
          </div>
          <div>
            {isLoading ? (
              <React.Fragment />
            ) : walletHistoryData === null ? (
              <div>Yawo Wallet not found</div>
            ) : (
              <React.Fragment>
                <div>
                  {displayInfo('IUWI:', walletHistoryData.iuwi || '')}
                  {displayInfo('Amount:', formatAmount(walletHistoryData.amount) || '')}
                  {displayInfo('Wallet History Type:', walletHistoryData.walletHistoryType || '')}
                  {displayInfo('Status:', <WalletHistoryStatus status={walletHistoryData.status || ''} />)}
                  {displayInfo('Wallet History Ref:', walletHistoryData.walletHistoryRef || '')}
                  {displayInfo('Yawo Ref:', walletHistoryData.yawoRef || '')}
                  {walletHistoryData.walletHistoryType === 'credit'
                    ? displayInfo('From:', walletHistoryData.from || '')
                    : displayInfo('To:', walletHistoryData.to || '')}
                  {displayInfo('Date:', dateFormatYMD(walletHistoryData.createdAt || ''))}
                  {displayInfo('Time:', formatTime(walletHistoryData.createdAt || ''))}
                </div>
                <br />
                <React.Fragment>
                  <div>
                    <h5 className="section_title">TRANSACTION DETAILS</h5>
                    {displayInfo('Sender IUWI:', transactionData.senderIuwi || '')}
                    {displayInfo('Sender Short Code:', transactionData.senderShortCode || '')}
                    {displayInfo('Receiver IUWI:', transactionData.receiverIuwi || '')}
                    {displayInfo('Receiver Short Code:', transactionData.receiverShortCode || '')}
                    {displayInfo('Transaction type:', transactionData.transactionType || '')}
                    {displayInfo('Message:', transactionData.message || '')}
                    {walletHistoryData.walletHistoryType === 'credit'
                      ? displayInfo('Receiver Ref:', transactionData.receiverHistoryRef || '')
                      : displayInfo('Sender Ref:', transactionData.senderHistoryRef || '')}
                  </div>
                </React.Fragment>
              </React.Fragment>
            )}
          </div>
        </div>
      </Wrapper>
    </YawoLayout>
  );
};

SingleWallet.propTypes = {
  match: PropTypes.object.isRequired,
  yawoWallet: PropTypes.object,
  isLoading: PropTypes.bool,
  getSingleYawoWallet: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { yawoWallet, isLoading } = state.yawoWallet;
  return { yawoWallet, isLoading };
};

export default connect(mapStateToProps, { getSingleYawoWallet })(withRouter(SingleWallet));
