import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import AgentsComponent from 'src/components/yawo/agents/Agents';

const Agents = () => {
  return (
    <DashboardLayout>
      <AgentsComponent />
    </DashboardLayout>
  );
};

export default Agents;
