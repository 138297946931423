import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NotificationsIcon from '@material-ui/icons/NotificationImportant';
import CardPaymentsIcon from '@material-ui/icons/CreditCard';
import PtspIcon from '@material-ui/icons/CallSplit';
import FwIcon from '@material-ui/icons/EmojiNature';
import SideNav from 'src/components/layout/SideNav';
import { useHistory, useLocation } from 'react-router-dom';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  .body {
    padding: 1.5rem;
    display: grid;
    grid-gap: 1rem;
    height: max-content;
  }
`;

const sideNavOptions = [
  { title: 'Transaction Notifications', value: '/notifications/transactions', icon: NotificationsIcon },
  { title: 'Card Payments', value: '/notifications/cards', icon: CardPaymentsIcon },
  { title: 'PTSPs', value: '/notifications/ptsps', icon: PtspIcon },
  { title: 'FW Card Payments', value: '/notifications/flutterwave', icon: FwIcon },
];

const NotificationsLayout = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const activeSidenavOption = sideNavOptions.slice(1).find((x) => {
    return pathname.toLowerCase().includes(x.value.toLowerCase());
  });
  const handleSetType = (value) => history.push(value);

  return (
    <Wrapper>
      <SideNav
        title={activeSidenavOption?.title || 'Reconciliation'}
        type={activeSidenavOption?.value || '/notifications/transactions'}
        setType={handleSetType}
        options={sideNavOptions}
      />
      <div className="body">{children}</div>
    </Wrapper>
  );
};

NotificationsLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default NotificationsLayout;
