import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import YawoLayout from 'src/components/yawo/YawoLayout';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from 'styled-components';
import Icon from 'src/components/common/Icon';
import { Link, withRouter } from 'react-router-dom';
import { getSingleYawoVendor } from 'src/store/actions/yawo/vendorActions';
import { connect } from 'react-redux';
import { formatDate, formatAmount } from 'src/utils/helpers';
import Button from 'src/components/common/Button';
import UpdateLoanLimit from './UpdateLoanLimit';
import ActivateVendor from './ActivateVendor';
import SuspendVendor from './SuspendVendor';
import VerifyVendor from './VerifyVendor';

const Wrapper = styled.div`
  .back {
    display: flex;
    width: max-content;
    grid-gap: 1rem;
    align-items: center;
  }
  .linked-btn {
    text-decoration: none;
  }
  .section {
    border-top: 1px solid grey;
    min-height: 10rem;
    margin-top: 1rem;
    padding: 1rem 0rem;
  }
  .avatar {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
  }
  .img {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    margin: 0;
    padding: 0;
  }
  .profile {
    display: flex;
    grid-gap: 1rem;
    align-items: center;
  }
  .details_body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }

  .main_body {
    padding: 1rem;
    background-color: ${(props) => props.theme.color.white};
    border-radius: 0.5rem;
    min-height: calc(100vh - 6rem);

    & > :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .header_text {
    text-align: center;
  }
  .mmo_body {
    padding: 0.5rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
  .left {
    border-right: 1px solid ${(props) => props.theme.color.grey};
  }
  .section_title {
    color: ${(props) => props.theme.color.deepGrey};
    margin-top: 1rem;
  }
  .item {
    display: flex;
    padding-top: 0.5rem;
  }
  .item_title {
    width: 10rem;
  }
  .item_value {
    color: ${(props) => props.theme.color.deepGrey};
  }
`;

const ViewVendor = ({ match, getSingleYawoVendor, yawoVendor, isLoading }) => {
  const { vendorId } = match.params;

  useEffect(() => {
    getSingleYawoVendor(vendorId);
  }, [vendorId]);

  const displayInfo = (title, value) => (
    <div className="item">
      <h4 className="item_title">{title || ''}</h4>
      <h4 className="item_value">
        {value || ''}
        {value && value[0] === '#' && (
          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              background: `${value}`,
              margin: 'auto 5px',
            }}
          />
        )}
      </h4>
    </div>
  );

  return (
    <YawoLayout>
      <Wrapper>
        <div className="main_body">
          <h2 className="header_text">Yawo Vendor Details</h2>
          <div className="back">
            <Icon Icon={ArrowBackIcon} type="link" to="/yawo/vendors" alt="Back to Yawo Vendors" text="Back" />
            <span>Back</span>
          </div>
          <div className="mmo_body">
            {isLoading ? (
              <React.Fragment />
            ) : yawoVendor === null ? (
              <div>Yawo Vendor Not Found</div>
            ) : (
              <React.Fragment>
                <div className="left">
                  <div style={{ display: 'flex' }}>
                    <div>
                      {displayInfo('Business Name:', yawoVendor.name || '')}
                      {displayInfo('Short Code:', yawoVendor.shortCode || '')}
                      <br />
                      <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                        {yawoVendor.isVerified ? (
                          <>
                            <UpdateLoanLimit
                              shortCode={yawoVendor.shortCode}
                              callback={() => getSingleYawoVendor(yawoVendor.shortCode)}
                            />
                            {yawoVendor.isActive ? (
                              <SuspendVendor shortCode={yawoVendor.shortCode} />
                            ) : (
                              <ActivateVendor shortCode={yawoVendor.shortCode} />
                            )}
                            {yawoVendor.yawoWallet && (
                              <Link to={`/yawo/wallets?yawoWalletIuwi=${yawoVendor.yawoWallet}`} className="linked-btn">
                                <Button text="View wallet" />
                              </Link>
                            )}
                          </>
                        ) : (
                          <VerifyVendor shortCode={yawoVendor.shortCode} />
                        )}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">GENERAL INFO</h5>
                    {displayInfo('Yawo Wallet:', yawoVendor.yawoWallet || '')}
                    {displayInfo('Loan Limit:', formatAmount(yawoVendor.loanLimit))}
                    {displayInfo('Is Verified:', yawoVendor.isVerified ? '✅' : '❌')}
                    {displayInfo('Is Active:', yawoVendor.isActive ? '✅' : '❌')}
                    {displayInfo('Owners Name:', yawoVendor.ownersName || '')}
                    {displayInfo('Residential Address:', yawoVendor.residentialAddress || '')}
                    {displayInfo('Email:', yawoVendor.email || '')}
                    {displayInfo('Phone:', yawoVendor.phone || '')}
                    {displayInfo('Business Address:', yawoVendor.businessAddress || '')}
                    {displayInfo(
                      'Created At:',
                      yawoVendor.createdAt && yawoVendor.createdAt !== 'None' ? formatDate(yawoVendor.createdAt) : ''
                    )}
                    {displayInfo(
                      'Updated At:',
                      yawoVendor.updatedAt && yawoVendor.updatedAt !== 'None' ? formatDate(yawoVendor.updatedAt) : ''
                    )}
                  </div>
                </div>
                <div>
                  <Link to={`/users/mmo/${yawoVendor.shortCode}`} className="linked-btn">
                    <Button text="View MMO details" />
                  </Link>
                  <br />
                  <div>
                    <h5 className="section_title">BANK DETAILS</h5>
                    {displayInfo('Account Number:', yawoVendor.accountNumber || '')}
                    {displayInfo('Account Name:', yawoVendor.accountName || '')}
                    {displayInfo('Bank:', yawoVendor.bank || '')}
                    {displayInfo('BVN:', yawoVendor.bvn || '')}
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">DOCUMENTS</h5>
                    <div className="item">
                      <h4 className="item_title">CAC Document:</h4>
                      <h4 className="item_value">
                        <a href={yawoVendor.cacDocument}>{yawoVendor.cacDocument}</a>
                      </h4>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </Wrapper>
    </YawoLayout>
  );
};

ViewVendor.propTypes = {
  match: PropTypes.object.isRequired,
  yawoVendor: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSingleYawoVendor: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { yawoVendor, isLoading } = state.yawoVendor;
  return { yawoVendor, isLoading };
};

export default connect(mapStateToProps, { getSingleYawoVendor })(withRouter(ViewVendor));
