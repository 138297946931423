import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  grid-gap: 0.5rem;
  .nav_item {
    display: grid;
    justify-content: center;
    padding: 0.5rem 2rem;
    color: ${(props) => props.theme.color.white};
    background-color: ${(props) => props.theme.color.blue};
    cursor: pointer;
    min-width: 7rem;
    width: max-content;
    border-radius: 1rem 1rem 0rem 0rem;
  }
  .isActive {
    background-color: ${(props) => props.theme.color.white};
    color: ${(props) => props.theme.color.blue};
  }
`;

const NavItem = ({ title, value, setIsActive, isActive }) => (
  <p onClick={() => setIsActive(value)} className={`nav_item ${isActive === value && 'isActive'}`}>
    {title}
  </p>
);

NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isActive: PropTypes.string.isRequired,
  setIsActive: PropTypes.func.isRequired,
};

NavItem.defaultProps = {
  options: [],
};

const TableNav = ({ showTopNav, options, isActive, setIsActive }) => {
  return (
    <Wrapper>
      {showTopNav &&
        options.map((option) => (
          <NavItem
            title={option.title}
            value={option.value}
            key={option.value}
            setIsActive={setIsActive}
            isActive={isActive}
          />
        ))}
    </Wrapper>
  );
};

TableNav.propTypes = {
  isActive: PropTypes.string.isRequired,
  setIsActive: PropTypes.func,
  options: PropTypes.array.isRequired,
  showTopNav: PropTypes.bool.isRequired,
};

TableNav.defaultProps = {
  showTopNav: false,
};

export default TableNav;
