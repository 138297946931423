import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import ViewStaffComponent from 'src/components/staffs/ViewStaff';

const ViewStaff = () => {
  return (
    <DashboardLayout>
      <ViewStaffComponent />
    </DashboardLayout>
  );
};

export default ViewStaff;
