import querystring from 'querystring';
import client from 'src/client';
import {
  ADD_YAWO_WALLETS,
  ADD_YAWO_WALLET,
  YAWO_WALLET_IS_LOADING,
  YAWO_WALLET_NOT_LOADING,
  SET_YAWO_WALLET_DATE,
  RESET_YAWO_WALLET_DATE,
  SET_YAWO_WALLET_LIMIT,
  SET_YAWO_WALLET_STATUS,
  SET_YAWO_WALLET_PAGE,
  SET_YAWO_WALLET_IUWI,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert } from 'src/store/actions/alertActions';
import { dateFormatYMD } from 'src/utils/helpers';

const { adminService } = client;

const yawoWalletIsLoading =
  (msg = 'Loading Yawo Wallet') =>
  (dispatch) => {
    dispatch({ type: YAWO_WALLET_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const yawoWalletNotLoading = () => (dispatch) => {
  dispatch({ type: YAWO_WALLET_NOT_LOADING });
};

const addYawoWallets = (yawoWallets) => {
  return { type: ADD_YAWO_WALLETS, payload: yawoWallets };
};

const addYawoWallet = (yawoWallet) => {
  return { type: ADD_YAWO_WALLET, payload: yawoWallet };
};

export const setYawoWalletDate = (startDate, endDate) => {
  return { type: SET_YAWO_WALLET_DATE, payload: { startDate, endDate } };
};

export const resetYawoWalletDate = () => {
  return { type: RESET_YAWO_WALLET_DATE };
};

export const setYawoWalletPage = (page) => {
  return { type: SET_YAWO_WALLET_PAGE, payload: page };
};

export const setYawoWalletLimit = (limit) => {
  return { type: SET_YAWO_WALLET_LIMIT, payload: limit };
};

export const setYawoWalletStatus = (status) => {
  return { type: SET_YAWO_WALLET_STATUS, payload: status };
};

export const setYawoWalletIuwi = (yawoWalletIuwi) => {
  return { type: SET_YAWO_WALLET_IUWI, payload: yawoWalletIuwi };
};

const generatePath = (endPoint, yawoWallet, query) => {
  const { startDate, endDate, limit, page } = yawoWallet || {};
  query = {
    page,
    limit,
    startDate: startDate ? dateFormatYMD(startDate) : undefined,
    endDate: endDate ? dateFormatYMD(endDate) : dateFormatYMD(Date.now()),
    ...query,
  };
  if (query.page === undefined) delete query.page;
  if (query.limit === undefined) delete query.limit;
  let url = endPoint;
  if (query && Object.keys(query).length > 0) {
    url += `?${querystring.encode(query)}`;
  }
  return url;
};

export const getAllYawoWallets =
  (query = {}) =>
  (dispatch, getState) => {
    dispatch(yawoWalletIsLoading(query?.search ? 'Searching...' : 'Loading Yawo Wallets'));
    const { yawoWallets } = getState().yawoWallet;
    const path = generatePath('/yawo/wallet-histories', yawoWallets, query);
    adminService
      .get(path)
      .then((res) => {
        dispatch(addYawoWallets(res?.data?.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Wallets' })))
      .finally(() => dispatch(yawoWalletNotLoading()));
  };

export const getSingleYawoWallet = (historyId, extraParams) => (dispatch) => {
  const { silently = false } = extraParams || {};
  if (!silently) {
    dispatch(yawoWalletIsLoading('Loading Yawo Wallet'));
  }
  adminService
    .get(`/yawo/wallet-history/${historyId}?fetchTransaction=true`)
    .then((res) => {
      dispatch(addYawoWallet(res?.data?.data));
      dispatch(clearAlert());
    })
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Wallet' })))
    .finally(() => dispatch(yawoWalletNotLoading()));
};

export const getSystemUser = () => (dispatch) => {
  adminService
    .get('/yawo/users/system')
    .then((res) => {
      dispatch(setYawoWalletIuwi(res?.data?.data?.yawoWallet));
      dispatch(clearAlert());
    })
    .catch(() => dispatch(errorAlert({ msg: 'Error Fetching System user' })));
};

export const getWalletStatsByIuwi = (yawoWalletIuwi, onSuccess) => (dispatch) => {
  adminService
    .get(`/yawo/wallet/${yawoWalletIuwi}`)
    .then((res) => {
      if (onSuccess) onSuccess(res?.data?.data);
      dispatch(clearAlert());
    })
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Wallet Stats' })));
};
