import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  .inline {
    display: flex;
    align-items: center;
  }
  .input {
    height: 2.2rem;
    width: 100%;
    padding: 0rem 0.5rem;
    outline: 0px;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.color.grey};
    :focus {
      border: 1px solid ${(props) => props.theme.color.grey};
    }
    :disabled {
      opacity: 0.55;
    }
  }
  .label {
    color: ${(props) => props.theme.color.black};
    min-width: max-content;
  }
`;

function TextInput({ type, onChange, value, label, formType, required, disabled, onBlur }) {
  return (
    <Wrapper>
      <div className={formType === 'inline' ? 'inline' : formType}>
        <p className="label">{label}</p>
        <input
          type={type}
          className="input"
          value={value}
          onChange={(e) => (type === 'file' ? onChange(e.target.files) : onChange(e.target.value))}
          onBlur={onBlur}
          required={required}
          disabled={disabled}
        />
      </div>
    </Wrapper>
  );
}

Text.defaultProps = {
  type: 'text',
  formType: 'block',
  required: false,
  disabled: false,
};

TextInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  formType: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
};

export { TextInput };
