import React from 'react';
import { formatDate, formatAmount, formatTime } from 'src/utils/helpers';
import Icon from 'src/components/common/Icon';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export const issuesTableHeader = [
  {
    title: function title() {
      return <p>ID</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Transaction Type</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['transactionType']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Mmo</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['mmo']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Amount</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{formatAmount(rowData['amount'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{rowData['status']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Time</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{formatTime(rowData['createdAt'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Date</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{formatDate(rowData['createdAt'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>View</p>;
    },
    render: function SerialNumber(rowData) {
      return <Icon Icon={OpenInNewIcon} type="link" to={`support/${rowData['_id']}`} />;
    },
  },
];
