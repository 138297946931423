import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from 'src/components/common/Icon';
import ReceiptIcon from '@material-ui/icons/Receipt';
/*
import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
 */

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  width: max-content;
  .icon {
    cursor: pointer;
  }
`;

const PosActions = ({ data }) => {
  return (
    <Wrapper>
      {/* 	<Tooltip title="View Transactions">
				<Link to= {`/pos/${data.walletId}/transactions`}>
					<ReceiptIcon
						className="icon edit"
					/>
				</Link>
			</Tooltip> */}
      <Icon Icon={ReceiptIcon} type="link" to={`/pos/${data.walletId}/transactions`} alt="View Pos Transactions" />
    </Wrapper>
  );
};

PosActions.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PosActions;
