import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Table from 'src/components/common/table/Table';
import { transactionsHeader } from './transactionHeaders';
import { sortTableData } from 'src/utils/helpers';
import { SelectInput } from 'src/components/common/inputs';
import RefreshIcon from '@material-ui/icons/Refresh';
import Icon from 'src/components/common/Icon';
import DownloadTransactions from './DownloadTransactions';
import TransactionsSearch from './TransactionsSearch';

const RightWrapper = styled.div`
  display: flex;
  grid-gap: 0.5rem;
  align-items: center;
`;

const subOptions = {
  withdrawals: [
    { text: 'Select Type', value: ' ' },
    { text: 'Pos', value: 'pos' },
    { text: 'Card', value: 'card' },
  ],
  transfers: [
    { text: 'Select Type', value: ' ' },
    { text: 'Peer', value: 'p2p' },
    { text: 'Cashout', value: 'bank' },
  ],
  collection: [
    { text: 'Select Type', value: ' ' },
    { text: 'Peer', value: 'peer_collection' },
    { text: 'Bank', value: 'bank_collection' },
  ],
};

const statusOptions = [
  { text: 'All', value: 'all' },
  { text: 'Success', value: 'success' },
  { text: 'Failed', value: 'failed' },
  { text: 'Pending', value: 'pending' },
  { text: 'Refunded', value: 'refunded' },
];

const TransactionsTable = ({
  type,
  platform,
  state,
  tableData,
  onPageChange,
  onLimitChange,
  subType,
  setSubType,
  onStatusChange,
  onRefreshClick,
  getTransactions,
}) => {
  const tableKey = {
    Amount: 'amount',
    Type: 'type',
    Status: 'status',
    Date: 'timeCreated',
    Time: 'timeCreated',
  };

  const leftHeader = (
    <div style={{ display: 'flex', gap: '1rem' }}>
      <div style={{ flexGrow: '1' }}>
        <TransactionsSearch getTransactions={getTransactions} />
      </div>
      {subOptions[type] && <SelectInput options={subOptions[type]} value={subType} onChange={setSubType} />}
    </div>
  );

  const rightHeader = (
    <RightWrapper>
      <DownloadTransactions state={state} platform={platform} limit={tableData === null ? 20 : tableData.limit} />
      <Icon Icon={RefreshIcon} alt="Refresh Transactions" onClick={onRefreshClick} />
      <SelectInput
        value={tableData === null ? 'all' : tableData.status}
        onChange={onStatusChange}
        options={statusOptions}
      />
    </RightWrapper>
  );

  return (
    <div>
      <Table
        headers={transactionsHeader}
        leftHeader={type === 'pos-activation' ? null : leftHeader}
        rightHeader={rightHeader}
        showNav={true}
        data={tableData && tableData.data ? sortTableData(tableData.data, tableKey) : []}
        limit={tableData === null ? 20 : tableData.limit}
        page={tableData === null ? 1 : tableData.page}
        count={tableData === null ? 1 : tableData.count}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </div>
  );
};

TransactionsTable.propTypes = {
  type: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  setType: PropTypes.func,
  tableData: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  leftHeader: PropTypes.any,
  rightHeader: PropTypes.any,
  subType: PropTypes.string,
  setSubType: PropTypes.func,
  onStatusChange: PropTypes.func.isRequired,
  onRefreshClick: PropTypes.func.isRequired,
  getTransactions: PropTypes.func,
};

export default TransactionsTable;
