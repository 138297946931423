import querystring from 'querystring';
import client from 'src/client';
import {
  ADD_YAWO_CUSTOMERS,
  ADD_YAWO_CUSTOMER,
  YAWO_CUSTOMER_IS_LOADING,
  YAWO_CUSTOMER_NOT_LOADING,
  SET_YAWO_CUSTOMER_DATE,
  RESET_YAWO_CUSTOMER_DATE,
  SET_YAWO_CUSTOMER_LIMIT,
  SET_YAWO_CUSTOMER_STATUS,
  SET_YAWO_CUSTOMER_PAGE,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from 'src/store/actions/alertActions';

const { adminService } = client;

const yawoCustomerIsLoading =
  (msg = 'Loading Yawo Customers') =>
  (dispatch) => {
    dispatch({ type: YAWO_CUSTOMER_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const yawoCustomerNotLoading = () => (dispatch) => {
  dispatch({ type: YAWO_CUSTOMER_NOT_LOADING });
};

const addYawoCustomers = (yawoCustomers) => {
  return { type: ADD_YAWO_CUSTOMERS, payload: yawoCustomers };
};

const addYawoCustomer = (yawoCustomer) => {
  return { type: ADD_YAWO_CUSTOMER, payload: yawoCustomer };
};

export const setYawoCustomerDate = (start_date, end_date) => {
  return { type: SET_YAWO_CUSTOMER_DATE, payload: { start_date, end_date } };
};

export const resetYawoCustomerDate = () => {
  return { type: RESET_YAWO_CUSTOMER_DATE };
};

export const setYawoCustomerPage = (page) => {
  return { type: SET_YAWO_CUSTOMER_PAGE, payload: page };
};

export const setYawoCustomerLimit = (limit) => {
  return { type: SET_YAWO_CUSTOMER_LIMIT, payload: limit };
};

export const setYawoCustomerStatus = (status) => {
  return { type: SET_YAWO_CUSTOMER_STATUS, payload: status };
};

const generatePath = (endPoint, yawoCustomer, query) => {
  const { limit, page } = yawoCustomer || {};
  query = {
    page,
    limit,
    ...query,
  };
  if (query.page === undefined) delete query.page;
  if (query.limit === undefined) delete query.limit;
  let url = endPoint;
  if (query && Object.keys(query).length > 0) {
    url += `?${querystring.encode(query)}`;
  }
  return url;
};

export const getAllYawoCustomers =
  (query = {}) =>
  (dispatch, getState) => {
    dispatch(yawoCustomerIsLoading(query?.search ? 'Searching...' : 'Loading Yawo Customers'));
    const { yawoCustomers } = getState().yawoCustomer;
    const path = generatePath('/yawo/users/customers', yawoCustomers, query);
    adminService
      .get(path)
      .then((res) => {
        dispatch(addYawoCustomers(res?.data?.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Customers' })))
      .finally(() => dispatch(yawoCustomerNotLoading()));
  };

export const getSingleYawoCustomer = (userId, extraParams) => (dispatch) => {
  const { silently = false } = extraParams || {};
  if (!silently) {
    dispatch(yawoCustomerIsLoading('Loading Yawo Customer'));
  }
  adminService
    .get(`/yawo/users/${userId}`)
    .then((res) => {
      dispatch(addYawoCustomer(res?.data?.data));
      dispatch(clearAlert());
    })
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Customer' })))
    .finally(() => dispatch(yawoCustomerNotLoading()));
};

export const activateCustomer = (userId) => (dispatch) => {
  dispatch(yawoCustomerIsLoading('Activating Customer ...'));
  adminService
    .get(`/yawo/users/${userId}/activate`)
    .then((res) => {
      dispatch(addYawoCustomer(res?.data?.data));
      dispatch(successAlert('Customer Activated'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data?.message || 'Error activating customer' }));
    })
    .finally(() => dispatch(yawoCustomerNotLoading()));
};

export const suspendCustomer = (userId) => (dispatch) => {
  dispatch(yawoCustomerIsLoading('Suspending Customer ...'));
  adminService
    .get(`/yawo/users/${userId}/suspend`)
    .then((res) => {
      dispatch(addYawoCustomer(res?.data?.data));
      dispatch(successAlert('Customer Suspended'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data?.message || 'Error suspending customer' }));
    })
    .finally(() => dispatch(yawoCustomerNotLoading()));
};

export const verifyCustomer = (customerId) => (dispatch) => {
  dispatch(yawoCustomerIsLoading('Verifying Customer ...'));
  adminService
    .post('/yawo/users/customers/verify', { customerId })
    .then((res) => {
      dispatch(addYawoCustomer(res?.data?.data));
      dispatch(successAlert('Customer Verified'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data?.message || 'Error verifying customer' }));
    })
    .finally(() => dispatch(yawoCustomerNotLoading()));
};

export const loadCustomerOptions = async (searchString) => {
  const options = await adminService
    .get(`/yawo/users/customers?search=${searchString}&limit=50`)
    .then((res) => res?.data?.data?.data || [])
    .catch(() => []);

  return options
    .map((x) => ({
      ...x,
      label: `${x.firstName} ${x.lastName} (${x?.phoneNumber?.number})`,
      value: x._id,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
};
