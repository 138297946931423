import React from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'materialui-daterange-picker';

function DateRangeInput({ onChange, toggleOpen, isOpen, initialDateRange }) {
  return (
    <DateRangePicker
      open={isOpen}
      toggle={toggleOpen}
      onChange={(range) => onChange(range)}
      initialDateRange={initialDateRange}
    />
  );
}

DateRangeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  initialDateRange: PropTypes.object,
};

export { DateRangeInput };
