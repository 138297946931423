import querystring from 'querystring';
import client from 'src/client';
import {
  VIRTUAL_WALLET_REQUESTS_IS_LOADING,
  VIRTUAL_WALLET_REQUESTS_NOT_LOADING,
  ADD_VIRTUAL_WALLET_REQUESTS,
  SET_VIRTUAL_WALLET_REQUESTS_PAGE,
  SET_VIRTUAL_WALLET_REQUESTS_LIMIT,
  SET_VIRTUAL_WALLET_REQUESTS_STATUS,
  ADD_VIRTUAL_WALLET_REQUEST,
  SET_VIRTUAL_WALLET_REQUEST_DATE,
  RESET_VIRTUAL_WALLET_REQUEST_DATE,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from './alertActions';

const { adminService } = client;

const virtualWalletRequestIsLoading =
  (msg = 'Loading Virtual Wallet Request') =>
  (dispatch) => {
    dispatch({ type: VIRTUAL_WALLET_REQUESTS_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const virtualWalletRequestNotLoading = () => (dispatch) => {
  dispatch({ type: VIRTUAL_WALLET_REQUESTS_NOT_LOADING });
};

const addVirtualWalletRequests = (virtualWalletRequests) => {
  return { type: ADD_VIRTUAL_WALLET_REQUESTS, payload: virtualWalletRequests };
};

const addVirtualWalletRequest = (virtualWalletRequest) => {
  return { type: ADD_VIRTUAL_WALLET_REQUEST, payload: virtualWalletRequest };
};

export const setVirtualWalletRequestDate = (start_date, end_date) => {
  return { type: SET_VIRTUAL_WALLET_REQUEST_DATE, payload: { start_date, end_date } };
};

export const resetVirtualWalletRequestDate = () => {
  return { type: RESET_VIRTUAL_WALLET_REQUEST_DATE };
};

export const setVirtualWalletRequestPage = (page) => {
  return { type: SET_VIRTUAL_WALLET_REQUESTS_PAGE, payload: page };
};

export const setVirtualWalletRequestLimit = (limit) => {
  return { type: SET_VIRTUAL_WALLET_REQUESTS_LIMIT, payload: limit };
};

export const setVirtualWalletRequestStatus = (status) => {
  return { type: SET_VIRTUAL_WALLET_REQUESTS_STATUS, payload: status };
};

const generatePath = (endPoint, virtualWalletRequest, query) => {
  const { limit, page } = virtualWalletRequest || {};
  query = {
    page,
    limit,
    ...query,
  };
  if (query.page === undefined) delete query.page;
  if (query.limit === undefined) delete query.limit;
  let url = endPoint;
  if (query && Object.keys(query).length > 0) {
    url += `?${querystring.encode(query)}`;
  }
  return url;
};

export const getAllVirtualWalletRequests =
  (query = {}, start_date, end_date) =>
  (dispatch, getState) => {
    dispatch(virtualWalletRequestIsLoading(query?.search ? 'Searching...' : 'Loading Virtual Wallet Requests'));
    const { virtualWalletRequests } = getState().virtualWalletRequest;
    const path = generatePath('/virtual-wallet-request/requests', virtualWalletRequests, query, start_date, end_date);
    adminService
      .get(path)
      .then((res) => {
        dispatch(addVirtualWalletRequests(res?.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading Virtual Wallet Requests' })))
      .finally(() => dispatch(virtualWalletRequestNotLoading()));
  };

export const approveVirtualWalletRequest = (requestId, extraParams) => (dispatch) => {
  const { silently = false } = extraParams || {};
  if (!silently) {
    dispatch(virtualWalletRequestIsLoading('Approving Request'));
  }
  adminService
    .get(`/virtual-wallet-request/${requestId}/approve`)
    .then((res) => {
      dispatch(addVirtualWalletRequest(res?.data?.data));
      dispatch(successAlert(res.data.message));
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message })))
    .finally(() => dispatch(virtualWalletRequestNotLoading()));
};

export const declineVirtualWalletRequest = (requestId, reason, extraParams) => (dispatch) => {
  const { silently = false } = extraParams || {};
  if (!silently) {
    dispatch(virtualWalletRequestIsLoading('Declining Request'));
  }
  adminService
    .post(`/virtual-wallet-request/${requestId}/decline`, { reason })
    .then((res) => {
      dispatch(addVirtualWalletRequest(res?.data?.data));
      dispatch(successAlert(res.data.message));
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message })))
    .finally(() => dispatch(virtualWalletRequestNotLoading()));
};
