import React from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/common/table/Table';
import { posHeader } from './posHeader';

const PosTable = ({ tableData, onLimitChange, onPageChange }) => {
  return (
    <div>
      <Table
        headers={posHeader}
        data={tableData === null ? [] : tableData.data}
        showNav={true}
        limit={tableData === null ? 20 : tableData.limit}
        page={tableData === null ? 1 : tableData.page}
        count={tableData === null ? 1 : tableData.count}
        onLimitChange={onLimitChange}
        onPageChange={onPageChange}
      />
    </div>
  );
};

PosTable.propTypes = {
  tableData: PropTypes.object,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default PosTable;
