import {
  CHARGEBACK_IS_LOADING,
  CHARGEBACK_NOT_LOADING,
  ADD_CHARGEBACKS,
  SET_CHARGEBACK_LIMIT,
  SET_CHARGEBACK_PAGE,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  chargebacks: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHARGEBACK_IS_LOADING:
      return { ...state, isLoading: true };
    case CHARGEBACK_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_CHARGEBACKS:
      return { ...state, chargebacks: { data: action.payload, count: action.payload?.length } };
    case SET_CHARGEBACK_LIMIT:
      return {
        ...state,
        chargebacks: { ...state.chargebacks, limit: action.payload },
      };
    case SET_CHARGEBACK_PAGE:
      return {
        ...state,
        chargebacks: { ...state.chargebacks, page: action.payload },
      };
    default:
      return state;
  }
}
