import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import TerminalsComponent from 'src/components/devices/terminals/Terminals';

const Terminals = () => {
  return (
    <DashboardLayout>
      <TerminalsComponent />
    </DashboardLayout>
  );
};

export default Terminals;
