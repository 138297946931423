import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import Pagination from './Pagination';
import TableNav from './TableNav';

const Wrapper = styled(Paper)`
  padding: 0.5rem;
  .top_actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
  }
  .content,
  .th {
    min-width: 3rem;
    width: max-content;
    padding: 0.3rem;
  }
  .table {
    width: 100%;
    margin-top: 1rem;
  }
  .thead {
    text-align: left;
    min-width: 20rem;
    background: ${(props) => props.theme.color.grey};
  }
  tr:nth-child(even) {
    background: ${(props) => props.theme.color.grey};
  }
`;

function Table({
  headers,
  data,
  page,
  limit,
  count,
  onPageChange,
  onLimitChange,
  leftHeader,
  rightHeader,
  showNav,
  showTopNav,
  topNavOptions,
  topNavIsActive,
  onTopNavChange,
}) {
  return (
    <div>
      <TableNav
        showTopNav={showTopNav}
        options={topNavOptions}
        isActive={topNavIsActive}
        setIsActive={onTopNavChange}
      />
      <Wrapper>
        <div className="top_actions">
          <div>{leftHeader}</div>
          <div>{rightHeader}</div>
        </div>
        <table className="table">
          <thead className="thead">
            <tr>
              {headers.map((headerItem, index) => (
                <th nowrap="true" className="th" key={index}>
                  {headerItem.title(index)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                {headers.map((col, key) => (
                  <td nowrap="true" className="content" key={key}>
                    {col.render(item, index)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {showNav && (
          <Pagination
            page={page}
            limit={limit}
            count={count}
            onNextPageClick={onPageChange}
            onLimitChange={onLimitChange}
          />
        )}
      </Wrapper>
    </div>
  );
}

Table.defaultProps = {
  headers: [],
  data: [],
  showNav: false,
  topNavOptions: [],
  topNavIsActive: '',
};

Table.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  topNavOptions: PropTypes.array.isRequired,
  showNav: PropTypes.bool.isRequired,
  topNavIsActive: PropTypes.string.isRequired,
  limit: PropTypes.number,
  count: PropTypes.number,
  page: PropTypes.number,
  showTopNav: PropTypes.bool,
  onPageChange: PropTypes.func,
  onLimitChange: PropTypes.func,
  leftHeader: PropTypes.any,
  rightHeader: PropTypes.any,
  onTopNavChange: PropTypes.func,
};

export default Table;
