import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import UserTransactionsComponent from 'src/components/users/UserTransactions';

const UserTransactions = () => {
  return (
    <DashboardLayout>
      <UserTransactionsComponent />
    </DashboardLayout>
  );
};

export default UserTransactions;
