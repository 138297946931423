import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card } from '@material-ui/core';
import Icon from './Icon';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';

const Wrapper = styled(Card)`
  width: max-content;
  height: max-content;
  min-height: 5rem;
  min-width: 10rem;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 0.5rem;
  justify-content: center;
  align-items: center;
`;

function DisplayCard({ title, value, isLoading, icon }) {
  return (
    <Wrapper>
      <Icon Icon={icon} alt="" disabled={true} />
      <div>
        <h2>{!isLoading && value}</h2>
        <p>{title}</p>
      </div>
    </Wrapper>
  );
}

DisplayCard.defaultProps = {
  isLoading: false,
  value: ' ',
  icon: WorkOutlineIcon,
};

DisplayCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.any,
  value: PropTypes.any.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default DisplayCard;
