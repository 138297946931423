import React from 'react';
import { formatDate, formatAmount, formatTime } from 'src/utils/helpers';

export const reconciliationTableHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Type</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['type']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Platform</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['platform']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Amount</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{formatAmount(rowData['amount'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Date</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{formatDate(rowData['createdAt'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Time</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{formatTime(rowData['createdAt'])}</p>;
    },
  },
];
