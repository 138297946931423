import React from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/common/table/Table';
import { walletsTableHeader } from './walletsTableHeader';
import WalletsHistorySearch from './WalletsHistorySearch';
import WalletHistoryIuwiSearch from './WalletHistoryIuwiSearch';
import { SelectInput } from 'src/components/common/inputs';
import { sortTableData } from 'src/utils/helpers';

const typeOptions = [
  { text: 'All', value: 'all' },
  { text: 'Credit', value: 'credit' },
  { text: 'Debit', value: 'debit' },
];

const statusOptions = [
  { text: 'All', value: 'all' },
  { text: 'Success', value: 'success' },
  { text: 'Failed', value: 'failed' },
];

const WalletsTable = ({ tableData, onPageChange, onLimitChange, type, setType, status, setStatus }) => {
  const leftHeader = (
    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
      <div>
        <WalletsHistorySearch />
      </div>
      <div>
        <WalletHistoryIuwiSearch />
      </div>
    </div>
  );

  const rightHeader = (
    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <h4>Type:</h4>
        <div>
          <SelectInput options={typeOptions} value={type} onChange={setType} />
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <h4>Status:</h4>
        <div>
          <SelectInput options={statusOptions} value={status} onChange={setStatus} />
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <Table
        showTopNav={true}
        rightHeader={rightHeader}
        leftHeader={leftHeader}
        headers={walletsTableHeader}
        data={tableData && tableData.data ? sortTableData(tableData.data, {}) : []}
        limit={tableData === null ? 10 : tableData.limit}
        page={tableData === null ? 1 : tableData.page}
        count={tableData === null ? 1 : tableData.count}
        showNav={true}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </div>
  );
};

WalletsTable.propTypes = {
  tableData: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  setType: PropTypes.func,
  status: PropTypes.string,
  setStatus: PropTypes.func,
};

export default WalletsTable;
