import {
  POS_IS_LOADING,
  POS_NOT_LOADING,
  ADD_POSES,
  ADD_POS,
  CLEAR_POS,
  SET_POS_DATE,
  RESET_POS_DATE,
  SET_POS_LIMIT,
  SET_POS_PAGE,
  SET_POS_STATUS,
  SET_POS_TYPES,
  SET_POS_OS_TYPES,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  poses: null,
  pos: null,
  posTypes: null,
  posOsTypes: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case POS_IS_LOADING:
      return { ...state, isLoading: true };
    case POS_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_POSES:
      return { ...state, poses: action.payload };
    case ADD_POS:
      return { ...state, pos: action.payload };
    case CLEAR_POS:
      return { ...state, pos: initialState.pos };
    case SET_POS_DATE:
      return {
        ...state,
        poses: {
          ...state.poses,
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
        },
      };
    case RESET_POS_DATE:
      return {
        ...state,
        poses: {
          ...state.poses,
          start_date: null,
          end_date: null,
        },
      };
    case SET_POS_LIMIT:
      return {
        ...state,
        poses: { ...state.poses, limit: action.payload },
      };
    case SET_POS_PAGE:
      return {
        ...state,
        poses: { ...state.poses, page: action.payload },
      };
    case SET_POS_STATUS:
      return {
        ...state,
        poses: { ...state.poses, status: action.payload },
      };
    case SET_POS_TYPES:
      return { ...state, posTypes: action.payload };
    case SET_POS_OS_TYPES:
      return { ...state, posOsTypes: action.payload };
    default:
      return state;
  }
}
