import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  .credit_text {
    color: ${(props) => props.theme.color.blue};
  }
  .debit_text {
    color: ${(props) => props.theme.color.red};
  }
`;

function WalletHistoryType({ type }) {
  const displayCredit = () => <p className="credit_text">credit</p>;

  const displayDebit = () => <p className="debit_text">debit</p>;

  return (
    <Wrapper>
      {type === 'credit' && displayCredit()}
      {type === 'debit' && displayDebit()}
    </Wrapper>
  );
}

WalletHistoryType.propTypes = {
  type: PropTypes.string.isRequired,
};

export default WalletHistoryType;
