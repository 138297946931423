import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import ViewLoanComponent from 'src/components/yawo/loans/ViewLoan';

const ViewLoan = () => {
  return (
    <DashboardLayout>
      <ViewLoanComponent />
    </DashboardLayout>
  );
};

export default ViewLoan;
