import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import UserLayout from './UserLayout';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from 'styled-components';
import Icon from 'src/components/common/Icon';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Button from 'src/components/common/Button';
import { Link, withRouter } from 'react-router-dom';
import { getUserAccount, fetchUserServicesList, fetchUserServices } from 'src/store/actions/accountActions';
import { connect } from 'react-redux';
import SuspendUser from './SuspendUser';
import ActivateUser from './ActivateUser';
import UserResetPin from './UserResetPin';
import DownloadWalletHistory from './DownloadWalletHistory';
import UpdateWalletLimit from './UpdateWalletLimit';
import { formatAmount } from 'src/utils/helpers';
import EditUserServices from './EditUserServices';

const Wrapper = styled.div`
  .back {
    display: flex;
    width: max-content;
    grid-gap: 1rem;
    align-items: center;
  }
  .section {
    border-top: 1px solid grey;
    min-height: 10rem;
    margin-top: 1rem;
    padding: 1rem 0rem;
  }
  .avatar {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
  }
  .img {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    margin: 0;
    padding: 0;
  }
  .profile {
    display: flex;
    grid-gap: 1rem;
    align-items: center;
  }
  .details_body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .details_item {
    display: grid;
    grid-gap: 0.5rem;
    min-width: 10rem;
  }
  .title_headers {
    padding-bottom: 1rem;
  }
  .item_header {
    color: ${(props) => props.theme.color.blue};
  }
`;

const User = ({
  match,
  getUserAccount,
  account,
  fetchUserServicesList,
  fetchUserServices,
  isLoading,
  userServices,
}) => {
  const { userId } = match.params;

  useEffect(() => {
    fetchUserServicesList();
    fetchUserServices({ userId });
    getUserAccount({ userId });
  }, [userId, getUserAccount]);

  const displayDetails = (title, value) => (
    <div className="details_item">
      <p className="item_header">{title}</p>
      <h4 className="item_text">{value}</h4>
    </div>
  );

  const mappedUserServices = [];
  if (typeof userServices === 'object') {
    for (const service in userServices) {
      if (userServices[service]) {
        mappedUserServices.push(<React.Fragment key={service}>{service}</React.Fragment>);
      }
    }
  }

  return (
    <UserLayout>
      <Wrapper>
        {isLoading ? (
          <React.Fragment />
        ) : account === null ? (
          <div>Account Not Found</div>
        ) : (
          <React.Fragment>
            <div className="back">
              <Icon Icon={ArrowBackIcon} type="link" to="/users" alt="Back to Accounts" />
              <h3>{`${account.firstName || ''} ${account.lastName || ''}`}</h3>
            </div>
            <div className="section profile">
              <div className="details_body" style={{ alignItems: 'center' }}>
                <div className="avatar">
                  <AccountCircleIcon className="img" />
                </div>
                <Link
                  to={{
                    pathname: `/users/${account.walletId}/transactions`,
                    state: {
                      userId: account._id,
                      firstName: account.firstName,
                      lastName: account.lastName,
                    },
                  }}
                  className="link"
                >
                  <Button text="View Transactions" />
                </Link>
                {account.status === 'active' && <SuspendUser userId={account._id} />}
                {account.status === 'suspended' && <ActivateUser userId={account._id} />}
                <UserResetPin userId={account._id} />
                <DownloadWalletHistory walletId={account.walletId} />
                <UpdateWalletLimit userId={account._id} callback={() => getUserAccount({ userId })} />
              </div>
            </div>
            <div className="section details">
              <div className="title_headers">
                <h3>Personal Details</h3>
              </div>
              <div className="details_body">
                {displayDetails('Full name', `${account.firstName || ''} ${account.lastName || ''}`)}
                {displayDetails('Phone Number', account.phoneNumber)}
                {displayDetails('Email address', account.email)}
                {displayDetails('Date of Birth', account.dob)}
                {displayDetails('Account Type', account.accountType)}
                {displayDetails('Status', account.status)}
                {displayDetails('Home address', account.homeAddress)}
                {displayDetails('Wallet Balance', formatAmount(account.walletBalance))}
                {displayDetails('Wallet Limit', formatAmount(account.walletLimit))}
              </div>
            </div>
            <div className="section details">
              <div className="title_headers">
                <h3>Transaction Details</h3>
              </div>
              <div className="details_body">
                {displayDetails('Transfer ID', account.iuwi)}
                {displayDetails('Bank Account', account.accountNumber)}
                {displayDetails('Bank Name', account.accountBank)}
                {displayDetails('Platform', account.platform)}
              </div>
            </div>
            {/* Services */}
            <div className="section">
              <div className="title_headers">
                <h3>Services</h3>
              </div>
              <div className="details_body">
                {mappedUserServices.length > 0 ? mappedUserServices : <p>No extra services</p>}
              </div>
              <br />
              <EditUserServices />
            </div>
          </React.Fragment>
        )}
      </Wrapper>
    </UserLayout>
  );
};

User.propTypes = {
  match: PropTypes.object.isRequired,
  account: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  fetchUserServicesList: PropTypes.func.isRequired,
  fetchUserServices: PropTypes.func.isRequired,
  getUserAccount: PropTypes.func.isRequired,
  userServices: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { account, isLoading, userServices } = state.account;
  return { account, isLoading, userServices };
};

export default connect(mapStateToProps, { getUserAccount, fetchUserServicesList, fetchUserServices })(withRouter(User));
