import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReconciliationIcon from '@material-ui/icons/Notes';
import ChangebackIcon from '@material-ui/icons/Refresh';
import SideNav from 'src/components/layout/SideNav';
import { useHistory, useLocation } from 'react-router-dom';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  .body {
    padding: 1.5rem;
    display: grid;
    grid-gap: 1rem;
    height: max-content;
  }
`;

const sideNavOptions = [
  { title: 'Reconciliation', value: '/finance/reconciliation', icon: ReconciliationIcon },
  { title: 'Chargeback', value: '/finance/chargeback', icon: ChangebackIcon },
];

const FinanceLayout = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const activeSidenavOption = sideNavOptions.slice(1).find((x) => {
    return pathname.toLowerCase().includes(x.value.toLowerCase());
  });
  const handleSetType = (value) => history.push(value);

  return (
    <Wrapper>
      <SideNav
        title={activeSidenavOption?.title || 'Reconciliation'}
        type={activeSidenavOption?.value || '/finance/reconciliation'}
        setType={handleSetType}
        options={sideNavOptions}
      />
      <div className="body">{children}</div>
    </Wrapper>
  );
};

FinanceLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default FinanceLayout;
