import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import UserLayout from 'src/components/users/UserLayout';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from 'styled-components';
import Icon from 'src/components/common/Icon';
import { withRouter, useHistory } from 'react-router-dom';
import { getSingleMmo, fetchMmoServices } from 'src/store/actions/mmoActions';
import { connect } from 'react-redux';
import { formatDate } from 'src/utils/helpers';
import EditMmo from './EditMmo';
import UploadMmoApp from './UploadMmoApp';
import UploadMmoLogo from './UploadMmoLogo';
import EditMmoServices from './EditMmoServices';

const Wrapper = styled.div`
  .back {
    cursor: pointer;
    border: none;
    background-color: transparent;
    display: flex;
    width: max-content;
    grid-gap: 1rem;
    align-items: center;
  }
  .section {
    border-top: 1px solid grey;
    min-height: 10rem;
    margin-top: 1rem;
    padding: 1rem 0rem;
  }
  .avatar {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
  }
  .img {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    margin: 0;
    padding: 0;
  }
  .profile {
    display: flex;
    grid-gap: 1rem;
    align-items: center;
  }
  .details_body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }

  .main_body {
    padding: 1rem;
    background-color: ${(props) => props.theme.color.white};
    border-radius: 0.5rem;
    min-height: calc(100vh - 6rem);

    & > :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .header_text {
    text-align: center;
  }
  .mmo_body {
    padding: 0.5rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
  .left {
    border-right: 1px solid ${(props) => props.theme.color.grey};
  }
  .section_title {
    color: ${(props) => props.theme.color.deepGrey};
    margin-top: 1rem;
  }
  .item {
    display: flex;
    padding-top: 0.5rem;
  }
  .item_title {
    width: 10rem;
  }
  .item_value {
    color: ${(props) => props.theme.color.deepGrey};
  }
`;

const ViewMmo = ({ match, getSingleMmo, mmo, isLoading, fetchMmoServices, mmoServices }) => {
  const { mmoId } = match.params;

  useEffect(() => {
    fetchMmoServices();
    getSingleMmo(mmoId);
  }, [mmoId]);

  const history = useHistory();

  const displayInfo = (title, value) => (
    <div className="item">
      <h4 className="item_title">{title || ''}</h4>
      <h4 className="item_value">
        {value || ''}
        {value && value[0] === '#' && (
          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              background: `${value}`,
              margin: 'auto 5px',
            }}
          />
        )}
      </h4>
    </div>
  );

  return (
    <UserLayout>
      <Wrapper>
        <div className="main_body">
          <h2 className="header_text">MMO Details</h2>
          <button className="back" onClick={() => history.goBack()}>
            <Icon Icon={ArrowBackIcon} alt="Back to MMO" text="Back" />
            <span>Back</span>
          </button>
          <div className="mmo_body">
            {isLoading ? (
              <React.Fragment />
            ) : mmo === null ? (
              <div>MMO Not Found</div>
            ) : (
              <React.Fragment>
                <div className="left">
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '1rem' }}>
                      <UploadMmoLogo getSingleMmo={getSingleMmo} />
                    </div>
                    <div>
                      {displayInfo('Name:', mmo.name || '')}
                      {displayInfo('Short Code:', mmo.shortCode || '')}
                      <br />
                      <EditMmo getSingleMmo={getSingleMmo} />
                      <br />
                      <UploadMmoApp getSingleMmo={getSingleMmo} />
                    </div>
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">GENERAL INFO</h5>
                    {displayInfo('Email:', mmo.email || '')}
                    {displayInfo('Phone:', mmo.phoneNumber || '')}
                    {displayInfo('Motto:', mmo.motto || '')}
                    {displayInfo('Address:', mmo.address || '')}
                    {displayInfo('Primary Color:', mmo.primaryColor || '')}
                    {displayInfo('Secondary Color:', mmo.secondaryColor || '')}
                    {displayInfo('Skin:', mmo.skin || '')}
                    {displayInfo('Icon:', mmo.iconPack || '')}
                    {displayInfo('App Is Uploaded:', mmo.isUploaded ? 'True' : 'False')}
                    {displayInfo('App Is Live:', mmo.isLive ? 'True' : 'False')}
                    <div className="item">
                      <h4 className="item_title">App Link:</h4>
                      <h4 className="item_value">
                        <a href={mmo.appLink}>{mmo.appLink}</a>
                      </h4>
                    </div>
                    {displayInfo(
                      'Created At:',
                      mmo.createdAt && mmo.createdAt !== 'None' ? formatDate(mmo.createdAt) : ''
                    )}
                    {displayInfo(
                      'Updated At:',
                      mmo.updatedAt && mmo.updatedAt !== 'None' ? formatDate(mmo.updatedAt) : ''
                    )}
                  </div>
                </div>
                <div>
                  <div>
                    <h5 className="section_title">SERVICES</h5>
                    {mmoServices &&
                      mmo.services &&
                      mmoServices.map((service, index) => {
                        if (mmo.services.split(';').some((x) => x === service.code)) {
                          return (
                            <React.Fragment key={index}>
                              {displayInfo(service.title || '', service.description || '')}
                            </React.Fragment>
                          );
                        }
                        return null;
                      })}
                    <br />
                    <EditMmoServices getSingleMmo={getSingleMmo} />
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">TRANSACTIONS</h5>
                    {displayInfo('Total Volume:', 0)}
                    {displayInfo('Total Value:', 0)}
                  </div>
                  <div>
                    <h5 className="section_title">AGENTS</h5>
                    {displayInfo('Total Agents:', 0)}
                    {displayInfo('Active Agents:', 0)}
                    {displayInfo('Inactive Agents:', 0)}
                  </div>
                  <div>
                    <h5 className="section_title">POS</h5>
                    {displayInfo('Total POS:', 0)}
                    {displayInfo('Active POS:', 0)}
                    {displayInfo('Inactive POS:', 0)}
                    {displayInfo('Churned POS:', 0)}
                    {displayInfo('Unmapped POS:', 0)}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </Wrapper>
    </UserLayout>
  );
};

ViewMmo.propTypes = {
  match: PropTypes.object.isRequired,
  mmo: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSingleMmo: PropTypes.func.isRequired,
  fetchMmoServices: PropTypes.func.isRequired,
  mmoServices: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { mmo, isLoading, mmoServices } = state.mmo;
  return { mmo, isLoading, mmoServices };
};

export default connect(mapStateToProps, { getSingleMmo, fetchMmoServices })(withRouter(ViewMmo));
