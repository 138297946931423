import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import ModalTwo from 'src/components/common/ModalTwo';
import { TextInput, SelectInput, ColorInput } from 'src/components/common/inputs';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { makeSlug } from 'src/utils/helpers';
import { createMmo } from 'src/store/actions/mmoActions';

const Wrapper = styled.div`
  h2 {
    font-size: x-large;
  }

  .form-section-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    align-items: end;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .long {
      grid-column-end: span 1;
    }

    .longer {
      grid-column-end: span 2;

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .longest {
      grid-column-end: span 3;

      @media (max-width: 768px) {
        grid-column-end: span 2;
      }

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .align-start {
      align-self: start;
    }

    .align-center {
      align-self: center;
    }

    .align-end {
      align-self: end;
    }

    .justify-end {
      justify-self: end;
    }
  }

  .label {
    color: ${(props) => props.theme.color.black};
    min-width: max-content;
  }
`;

const CreateMmo = ({ createMmo, getAllMmos }) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState('');
  const [shortCode, setShortCode] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [motto, setMotto] = useState('');
  const [address, setAddress] = useState('');
  const [primaryColor, setPrimaryColor] = useState('#000000');
  const [secondaryColor, setSecondaryColor] = useState('#000000');
  const [skin, setSkin] = useState('');
  const [iconPack, setIconPack] = useState('');

  const resetFormState = () => {
    document.getElementById('createMmoForm').reset();
    setName('');
    setShortCode('');
    setEmail('');
    setPhoneNumber('');
    setMotto('');
    setAddress('');
    setPrimaryColor('');
    setSecondaryColor('');
    setSkin('');
    setIconPack('');
  };

  const submitForm = (e) => {
    e.preventDefault();

    const data = {
      name,
      shortCode,
      email,
      phoneNumber,
      motto,
      address,
      primaryColor,
      secondaryColor,
      skin,
      iconPack,
    };

    setIsSubmitting(true);
    createMmo(data, {
      always: () => {
        setIsSubmitting(false);
      },
      success: () => {
        setShow(false);
        resetFormState();
        if (getAllMmos) getAllMmos();
      },
    });
  };

  return (
    <Wrapper>
      <div className="form-section-grid">
        <div className="longest justify-end">
          <Button text="Create MMO" onClick={toggleShow} />
        </div>
      </div>

      <ModalTwo size="large" active={show} onModalCloseCallback={toggleShow}>
        <div>
          <center>
            <h2>Create MMO</h2>
          </center>

          <br />

          <form id="createMmoForm" onSubmit={submitForm} className="form-section-grid">
            <div>
              <TextInput label="Name" value={name} onChange={setName} required />
            </div>

            <div>
              <TextInput
                label="Short Code"
                value={shortCode}
                onChange={setShortCode}
                onBlur={(e) => setShortCode(makeSlug(e.target.value))}
                required
              />
            </div>

            <div>
              <TextInput label="Email" type="email" value={email} onChange={setEmail} required />
            </div>

            <div>
              <TextInput label="Phone Number" type="tel" value={phoneNumber} onChange={setPhoneNumber} required />
            </div>

            <div>
              <TextInput label="Motto" value={motto} onChange={setMotto} required />
            </div>

            <div>
              <TextInput label="Address" value={address} onChange={setAddress} required />
            </div>

            <div>
              <ColorInput label="Primary Color (hex code)" value={primaryColor} onChange={setPrimaryColor} required />
            </div>

            <div>
              <ColorInput
                label="Secondary Color (hex code)"
                value={secondaryColor}
                onChange={setSecondaryColor}
                required
              />
            </div>

            <div>
              <SelectInput label="Select Skin" options={skinOptions} value={skin} onChange={setSkin} required />
            </div>

            <div>
              <SelectInput
                label="Select Icon Pack"
                options={iconPackOptions}
                value={iconPack}
                onChange={setIconPack}
                required
              />
            </div>

            <div className="longest justify-end">
              <Button type="submit" text={isSubmitting ? 'Creating MMO ...' : '+ Create MMO'} disabled={isSubmitting} />
            </div>
          </form>
        </div>
      </ModalTwo>
    </Wrapper>
  );
};

const skinOptions = [
  {
    text: 'pluto',
    value: 'pluto',
  },
  {
    text: 'venus',
    value: 'venus',
  },
  {
    text: 'jupiter',
    value: 'jupiter',
  },
  {
    text: 'mars',
    value: 'mars',
  },
];

const iconPackOptions = [
  {
    text: 'material',
    value: 'material',
  },
  {
    text: 'feather',
    value: 'feather',
  },
];

CreateMmo.propTypes = {
  createMmo: PropTypes.func.isRequired,
  getAllMmos: PropTypes.func,
};

export default connect(null, { createMmo })(CreateMmo);
