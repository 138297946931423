import {
  ADD_CARD_PAYMENTS,
  ADD_CARD_PAYMENT,
  CARD_PAYMENT_IS_LOADING,
  CARD_PAYMENT_NOT_LOADING,
  SET_CARD_PAYMENTS_PAGE,
  SET_CARD_PAYMENTS_LIMIT,
  SET_CARD_PAYMENTS_DATE,
  RESET_CARD_PAYMENTS_DATE,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  cardPayments: null,
  cardPayment: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CARD_PAYMENT_IS_LOADING:
      return { ...state, isLoading: true };
    case CARD_PAYMENT_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_CARD_PAYMENTS:
      return { ...state, cardPayments: action.payload?.data?.data };
    case ADD_CARD_PAYMENT:
      return { ...state, cardPayment: action.payload?.data?.data };
    case SET_CARD_PAYMENTS_DATE:
      return {
        ...state,
        cardPayments: {
          ...state.cardPayments,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };
    case RESET_CARD_PAYMENTS_DATE:
      return {
        ...state,
        cardPayments: {
          ...state.cardPayments,
          startDate: null,
          endDate: null,
        },
      };
    case SET_CARD_PAYMENTS_LIMIT:
      return {
        ...state,
        cardPayments: { ...state.cardPayments, limit: action.payload },
      };
    case SET_CARD_PAYMENTS_PAGE:
      return {
        ...state,
        cardPayments: { ...state.cardPayments, page: action.payload },
      };
    default:
      return state;
  }
}
