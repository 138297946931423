import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import { sortPlatforms } from 'src/utils/helpers';
import { SelectInput } from 'src/components/common/inputs';
import PosTable from './PosTable';
import DisplayCard from 'src/components/common/DisplayCard';
import { getAllPos, setPosLimit, setPosPage } from 'src/store/actions/oldposActions';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .platform {
    display: grid;
    width: max-content;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 0.5rem;
  }
`;

const AllPos = ({ platforms, getAllPos, allPos, setPosLimit, setPosPage }) => {
  const [platform, setPlatform] = useState('ikonik');

  const getPos = useCallback(
    (platform) => {
      getAllPos(platform === 'ikonik' ? 'all' : platform);
    },
    [getAllPos]
  );

  useEffect(() => {
    getPos(platform);
  }, [platform]);

  const onPageChange = (page) => {
    setPosPage(page);
    getPos(platform);
  };

  const onLimitChange = (limit) => {
    setPosLimit(limit);
    getPos(platform);
  };

  return (
    <Wrapper>
      <div className="cards">
        <DisplayCard icon={ChromeReaderModeIcon} title="Total Volume" value={allPos !== null && allPos.count} />
      </div>
      <div className="platform">
        <h4>Select platform:</h4>
        <SelectInput options={sortPlatforms(platforms)} value={platform} onChange={setPlatform} />
      </div>
      <PosTable tableData={allPos} onLimitChange={onLimitChange} onPageChange={onPageChange} />
    </Wrapper>
  );
};

AllPos.propTypes = {
  platforms: PropTypes.array.isRequired,
  allPos: PropTypes.array.isRequired,
  getAllPos: PropTypes.func.isRequired,
  setPosLimit: PropTypes.func.isRequired,
  setPosPage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { platforms } = state.platform;
  const { allPos } = state.oldpos;
  return { platforms, allPos };
};

export default connect(mapStateToProps, { getAllPos, setPosLimit, setPosPage })(AllPos);
