import QS from 'qs';
import client from 'src/client';
import {
  ADD_RECONCILIATIONS,
  RECONCILIATION_IS_LOADING,
  RECONCILIATION_NOT_LOADING,
  SET_RECONCILIATION_DATE,
  RESET_RECONCILIATION_DATE,
  SET_RECONCILIATION_LIMIT,
  SET_RECONCILIATION_STATUS,
  SET_RECONCILIATION_PAGE,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from './alertActions';

const { adminService } = client;

const reconciliationIsLoading = (msg = 'Reconciliation Loading') => {
  return (dispatch) => {
    dispatch({ type: RECONCILIATION_IS_LOADING });
    dispatch(infoAlert(msg));
  };
};

const reconciliationNotLoading = () => (dispatch) => {
  dispatch({ type: RECONCILIATION_NOT_LOADING });
};

const addReconciliations = (reconciliations) => {
  return { type: ADD_RECONCILIATIONS, payload: reconciliations };
};

export const setReconciliationDate = (start_date, end_date) => {
  return { type: SET_RECONCILIATION_DATE, payload: { start_date, end_date } };
};

export const resetReconciliationDate = () => {
  return { type: RESET_RECONCILIATION_DATE };
};

export const setReconciliationPage = (page) => {
  return { type: SET_RECONCILIATION_PAGE, payload: page };
};

export const setReconciliationLimit = (limit) => {
  return { type: SET_RECONCILIATION_LIMIT, payload: limit };
};

export const setReconciliationStatus = (status) => {
  return { type: SET_RECONCILIATION_STATUS, payload: status };
};

const generatePath = (endPoint, reconciliations, query) => {
  const { limit, page } = reconciliations || {};
  let url = `${endPoint}?limit=${limit}&page=${page}`;
  if (query && Object.keys(query).length > 0) {
    url += `&${QS.stringify(query)}`;
  }
  return url;
};

export const getAllReconciliations = (platform = 'all', query = {}) => {
  return (dispatch, getState) => {
    dispatch(reconciliationIsLoading('Loading Reconciliations'));
    const { reconciliations } = getState().reconciliation;
    const path = generatePath(`/reconciliation/${platform}`, reconciliations, query);
    adminService
      .get(path)
      .then((res) => {
        dispatch(addReconciliations(res.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading Reconciliations' })))
      .finally(() => dispatch(reconciliationNotLoading()));
  };
};

export const searchReconciliation = (platform = 'all', searchString) => {
  return (dispatch) => {
    dispatch(reconciliationIsLoading('Searching...'));
    adminService
      .get(`/reconciliation/${platform}?search=${searchString}`)
      .then((res) => {
        dispatch(addReconciliations(res.data));
        dispatch(clearAlert());
      })
      .catch(() => {
        dispatch(errorAlert({ msg: 'Error Loading Reconciliations' }));
      })
      .finally(() => dispatch(reconciliationNotLoading()));
  };
};

// Upload RECONCILIATION / Extract Transactions

const uploadReconciliationReportIsLoading = (msg = 'Extracting Transaction') => {
  return (dispatch) => dispatch(infoAlert(msg));
};

const uploadReconciliationReportNotLoading = () => (dispatch) => dispatch(clearAlert());

export const uploadReconciliationReport = (formData, callback) => (dispatch) => {
  dispatch(uploadReconciliationReportIsLoading());
  adminService
    .post('/reconciliation/report/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(() => {
      dispatch(successAlert('Transactions Extracted'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => {
      setTimeout(() => {
        dispatch(uploadReconciliationReportNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

// Resolve RECONCILIATION

const resolveReconciliationsIsLoading = (msg = 'Resolving Reconciliations') => {
  return (dispatch) => dispatch(infoAlert(msg));
};

const resolveReconciliationsNotLoading = () => (dispatch) => dispatch(clearAlert());

export const resolveReconciliations = (callback) => (dispatch) => {
  dispatch(resolveReconciliationsIsLoading());
  adminService
    .post('/reconciliation/resolve/up')
    .then(() => {
      dispatch(successAlert('Reconciliations Resolved'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => {
      setTimeout(() => {
        dispatch(resolveReconciliationsNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};
