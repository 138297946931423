import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import RolesComponent from 'src/components/staffs/roles/Roles';

const Role = () => {
  return (
    <DashboardLayout>
      <RolesComponent />
    </DashboardLayout>
  );
};

export default Role;
