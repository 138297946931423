import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  getAllNotifications,
  setNotificationLimit,
  setNotificationPage,
  setNotificationDate,
  resetNotificationDate,
} from 'src/store/actions/notificationActions';
import { formatDate } from 'src/utils/helpers';
import { DateRangeInput, SelectInput } from 'src/components/common/inputs';
import NotificationsTable from './NotificationsTable';
import NotificationsLayout from './NotificationsLayout';
import DisplayCard from 'src/components/common/DisplayCard';
import BusinessIcon from '@material-ui/icons/Business';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SupervisedUserCircleIcon from '@material-ui/icons/LocalActivityRounded';
import { Card } from '@material-ui/core';
import Icon from 'src/components/common/Icon';

// Table Icons
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import DateRangeIcon from '@material-ui/icons/DateRange';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  height: max-content;
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .notification-filters__wrapper {
    display: flex;
    justify-content: space-between;
  }
  .notification-filters {
    display: flex;

    & > :not(:last-child) {
      margin-right: 1rem;
    }
  }
  .notification-filter {
    display: grid;
    width: max-content;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 0.5rem;
  }
  .date_range {
    padding: 0rem 0.5rem;
    display: grid;
    border-radius: 5px;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const Notifications = ({
  notifications,
  getAllNotifications,
  setNotificationLimit,
  setNotificationPage,
  setNotificationDate,
  resetNotificationDate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [notificationStatus, setNotificationStatus] = useState('all');

  useEffect(() => {
    setNotificationPage(1);
    setNotificationLimit(20);
    const query = {};
    if (notificationStatus !== 'all') {
      query.status = notificationStatus;
    }
    getAllNotifications(query);
  }, [notificationStatus]);

  const onNotificationStatusChange = (value) => {
    setNotificationPage(1);
    setNotificationLimit(20);
    setNotificationStatus(value);
  };

  const getNotifications = () => {
    const query = {};
    if (notificationStatus !== 'all') {
      query.status = notificationStatus;
    }
    getAllNotifications(query);
  };

  const onPageChange = (page) => {
    setNotificationPage(page);
    getNotifications();
  };

  const onLimitChange = (limit) => {
    setNotificationLimit(limit);
    getNotifications();
  };

  const onDateChange = (date) => {
    setNotificationDate(date.startDate, date.endDate);
    getNotifications();
  };

  const onResetDateClick = () => {
    resetNotificationDate();
    getAllNotifications();
  };

  return (
    <NotificationsLayout>
      <Wrapper>
        <div className="cards">
          <DisplayCard
            icon={BusinessIcon}
            title="Total Notifications"
            value={notifications !== null && notifications.count}
          />
          <DisplayCard
            icon={CloudUploadIcon}
            title="Total Pending"
            value={notifications !== null && notifications.pending}
          />
          <DisplayCard
            icon={SupervisedUserCircleIcon}
            title="Total Successful"
            value={notifications !== null && notifications.success}
          />
        </div>
        <div className="notification-filters__wrapper">
          <div className="notification-filters">
            <div className="notification-filter">
              <h4>Notification Status:</h4>
              <SelectInput
                options={notificationStatusOptions}
                value={notificationStatus}
                onChange={onNotificationStatusChange}
              />
            </div>
          </div>
          <Card className="date_range">
            <h6>
              {`${notifications && notifications.start_date ? formatDate(notifications.start_date) : ''} - ${
                notifications && notifications.end_date ? formatDate(notifications.end_date) : ''
              }`}
            </h6>
            <Icon Icon={DateRangeIcon} alt="Set Date" onClick={toggle} />
            <Icon Icon={RotateLeftIcon} alt="Reset Date" onClick={onResetDateClick} />
          </Card>
        </div>
        <NotificationsTable tableData={notifications} onPageChange={onPageChange} onLimitChange={onLimitChange} />
        <div className="range_input">
          <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
        </div>
      </Wrapper>
    </NotificationsLayout>
  );
};

const notificationStatusOptions = [
  {
    text: 'All',
    value: 'all',
  },
  {
    text: 'PENDING',
    value: 'PENDING',
  },
  {
    text: 'SUCCESS',
    value: 'SUCCESS',
  },
];

Notifications.propTypes = {
  notifications: PropTypes.object.isRequired,
  getAllNotifications: PropTypes.func.isRequired,
  setNotificationLimit: PropTypes.func.isRequired,
  setNotificationPage: PropTypes.func.isRequired,
  setNotificationDate: PropTypes.func.isRequired,
  resetNotificationDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { notifications } = state.notification;
  return { notifications };
};

export default connect(mapStateToProps, {
  getAllNotifications,
  setNotificationPage,
  setNotificationLimit,
  setNotificationDate,
  resetNotificationDate,
})(Notifications);
