import React from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/common/table/Table';
import { loansTableHeader } from './loansTableHeader';
import { sortTableData } from 'src/utils/helpers';
import { SelectInput } from 'src/components/common/inputs';
import LoansSearch from './LoansSearch';

const statusOptions = [
  { text: 'All', value: 'all' },
  { text: 'Submitted', value: 'submitted' },
  { text: 'Rejected', value: 'rejected' },
  { text: 'Approved', value: 'approved' },
  { text: 'Ongoing', value: 'ongoing' },
  { text: 'Completed', value: 'completed' },
];

const LoansTable = ({ tableData, onPageChange, onLimitChange, status, onStatusChange }) => {
  const leftHeader = (
    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
      <div>
        <LoansSearch />
      </div>
    </div>
  );

  const rightHeader = <SelectInput value={status} onChange={onStatusChange} options={statusOptions} />;

  return (
    <div>
      <Table
        showTopNav={true}
        rightHeader={rightHeader}
        leftHeader={leftHeader}
        headers={loansTableHeader}
        data={tableData && tableData.data ? sortTableData(tableData.data, {}) : []}
        limit={tableData && tableData.limit}
        page={tableData && tableData.page}
        count={tableData && tableData.count}
        showNav={true}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </div>
  );
};

LoansTable.propTypes = {
  tableData: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
};

export default LoansTable;
