import {
  ROLE_IS_LOADING,
  ROLE_NOT_LOADING,
  ADD_ROLES,
  ADD_ROLE,
  CLEAR_ROLE,
  SET_ROLE_DATE,
  RESET_ROLE_DATE,
  SET_ROLE_LIMIT,
  SET_ROLE_PAGE,
  SET_ROLE_STATUS,
  SET_ROLE_PERMISSIONS,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  roles: null,
  role: null,
  rolePermissions: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ROLE_IS_LOADING:
      return { ...state, isLoading: true };
    case ROLE_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_ROLES:
      return { ...state, roles: { data: action.payload, count: action.payload?.length } };
    case ADD_ROLE:
      return { ...state, role: action.payload };
    case CLEAR_ROLE:
      return { ...state, role: initialState.role };
    case SET_ROLE_DATE:
      return {
        ...state,
        roles: {
          ...state.roles,
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
        },
      };
    case RESET_ROLE_DATE:
      return {
        ...state,
        roles: {
          ...state.roles,
          start_date: null,
          end_date: null,
        },
      };
    case SET_ROLE_LIMIT:
      return {
        ...state,
        roles: { ...state.roles, limit: action.payload },
      };
    case SET_ROLE_PAGE:
      return {
        ...state,
        roles: { ...state.roles, page: action.payload },
      };
    case SET_ROLE_STATUS:
      return {
        ...state,
        roles: { ...state.roles, status: action.payload },
      };
    case SET_ROLE_PERMISSIONS:
      return { ...state, rolePermissions: action.payload };
    default:
      return state;
  }
}
