import React from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/common/table/Table';
import { issuesTableHeader } from './issuesTableHeader';

const topNavOptions = [
  { title: 'Open', value: 'open' },
  { title: 'Closed', value: 'closed' },
  { title: 'Resolved', value: 'resolved' },
];

const IssuesTable = ({ tableData, onPageChange, onLimitChange, status, onStatusChange }) => {
  return (
    <div>
      <Table
        showTopNav={true}
        topNavIsActive={status}
        onTopNavChange={onStatusChange}
        headers={issuesTableHeader}
        topNavOptions={topNavOptions}
        data={tableData === null ? [] : tableData.data}
        showNav={true}
        limit={tableData === null ? 10 : tableData.limit}
        page={tableData === null ? 1 : tableData.page}
        count={tableData === null ? 1 : tableData.count}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </div>
  );
};

IssuesTable.propTypes = {
  tableData: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
  status: PropTypes.string.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default IssuesTable;
