import QS from 'qs';
import client from 'src/client';
import {
  ADD_NOTIFICATIONS,
  NOTIFICATION_IS_LOADING,
  NOTIFICATION_NOT_LOADING,
  SET_NOTIFICATION_DATE,
  RESET_NOTIFICATION_DATE,
  SET_NOTIFICATION_LIMIT,
  SET_NOTIFICATION_STATUS,
  SET_NOTIFICATION_PAGE,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from './alertActions';
import { dateFormatYMD } from 'src/utils/helpers';

const { appService } = client;

const notificationIsLoading =
  (msg = 'Notification Loading') =>
  (dispatch) => {
    dispatch({ type: NOTIFICATION_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const notificationNotLoading = () => (dispatch) => {
  dispatch({ type: NOTIFICATION_NOT_LOADING });
  dispatch(clearAlert());
};

const addNotifications = (notifications) => {
  return { type: ADD_NOTIFICATIONS, payload: notifications };
};

export const setNotificationDate = (start_date, end_date) => {
  return { type: SET_NOTIFICATION_DATE, payload: { start_date, end_date } };
};

export const resetNotificationDate = () => {
  return { type: RESET_NOTIFICATION_DATE };
};

export const setNotificationPage = (page) => {
  return { type: SET_NOTIFICATION_PAGE, payload: page };
};

export const setNotificationLimit = (limit) => {
  return { type: SET_NOTIFICATION_LIMIT, payload: limit };
};

export const setNotificationStatus = (status) => {
  return { type: SET_NOTIFICATION_STATUS, payload: status };
};

const generatePath = (endPoint, notification, query) => {
  const { limit = 20, page = 1, start_date, end_date } = notification;
  let url = `${endPoint}?limit=${limit}&page=${page}`;
  if (query && Object.keys(query).length > 0) {
    url += `&${QS.stringify(query)}`;
  }
  if (start_date && end_date) {
    url += `&start_date=${dateFormatYMD(start_date)}&end_date=${dateFormatYMD(end_date)}`;
  }
  return url;
};

export const getAllNotifications =
  (query = {}) =>
  (dispatch, getState) => {
    dispatch(notificationIsLoading('Loading Notifications'));
    const { notifications } = getState().notification;
    const path = generatePath('/pos/notifications', notifications, query);
    appService
      .get(path)
      .then((res) => dispatch(addNotifications(res.data)))
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading Notifications' })))
      .finally(() => dispatch(notificationNotLoading()));
  };

export const searchNotification = (vendorRef) => (dispatch) => {
  dispatch(notificationIsLoading('Searching...'));
  appService
    .get(`/pos/notifications/search/${vendorRef}`)
    .then((res) => {
      dispatch(addNotifications(res.data));
    })
    .catch(() => {
      dispatch(errorAlert({ msg: 'Error Loading Notifications' }));
    })
    .finally(() => dispatch(notificationNotLoading()));
};

// Refresh Notification

const refreshNotificationIsLoading =
  (msg = 'Refreshing Notification') =>
  (dispatch) =>
    dispatch(infoAlert(msg));
const refreshNotificationNotLoading = () => (dispatch) => dispatch(clearAlert());

export const refreshNotification = (notificationId, callback) => (dispatch) => {
  dispatch(refreshNotificationIsLoading());
  appService
    .get(`/pos/notifications/refresh/${notificationId}`)
    .then((res) => {
      const { message } = res.data || {};
      dispatch(successAlert(message || 'Notification Refreshed'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((error) => {
      const { message } = error?.response?.data || {};
      dispatch(errorAlert({ msg: message || 'Technical Error' }));
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(refreshNotificationNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};
