import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import YawoLayout from 'src/components/yawo/YawoLayout';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from 'styled-components';
import Icon from 'src/components/common/Icon';
import { withRouter } from 'react-router-dom';
import { getSingleYawoLoan } from 'src/store/actions/yawo/loanActions';
import { connect } from 'react-redux';
import { formatAmount, formatDate } from 'src/utils/helpers';
import ApproveLoan from './ApproveLoan';
import RejectLoan from './RejectLoan';
import CompleteLoan from './CompleteLoan';
import LoanStatus from './LoanStatus';
import ImageViewer from 'src/components/common/ImageViewer';

const Wrapper = styled.div`
  .back {
    display: flex;
    width: max-content;
    grid-gap: 1rem;
    align-items: center;
  }
  .section {
    border-top: 1px solid grey;
    min-height: 10rem;
    margin-top: 1rem;
    padding: 1rem 0rem;
  }
  .avatar {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
  }
  .img {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    margin: 0;
    padding: 0;
  }
  .profile {
    display: flex;
    grid-gap: 1rem;
    align-items: center;
  }
  .details_body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }

  .main_body {
    padding: 1rem;
    background-color: ${(props) => props.theme.color.white};
    border-radius: 0.5rem;
    min-height: calc(100vh - 6rem);

    & > :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .header_text {
    text-align: center;
  }
  .mmo_body {
    padding: 0.5rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
  .left {
    border-right: 1px solid ${(props) => props.theme.color.grey};
  }
  .section_title {
    color: ${(props) => props.theme.color.deepGrey};
    margin-top: 1rem;
  }
  .item {
    display: flex;
    padding-top: 0.5rem;
  }
  .item_title {
    width: 10rem;
  }
  .item_value {
    color: ${(props) => props.theme.color.deepGrey};
  }
`;

const ViewLoan = ({ match, getSingleYawoLoan, yawoLoan = {}, isLoading }) => {
  const { loanId } = match.params;
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const toggleViewer = () => setViewerIsOpen(!viewerIsOpen);

  useEffect(() => {
    getSingleYawoLoan(loanId);
  }, [loanId]);

  const displayInfo = (title, value) => (
    <div className="item">
      <h4 className="item_title">{title || ''}</h4>
      <h4 className="item_value">
        {value || ''}
        {value && value[0] === '#' && (
          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              background: `${value}`,
              margin: 'auto 5px',
            }}
          />
        )}
      </h4>
    </div>
  );

  const interestRatePercentage = Number(yawoLoan?.interestRate) / 100;
  const dailyCollectionAmount =
    (Number(yawoLoan?.amount) * interestRatePercentage + Number(yawoLoan?.amount)) / Number(yawoLoan?.durationInDays);
  const totalToPay = Number(yawoLoan?.amount) + Number(yawoLoan?.interestAmount);

  const adminHasNotApproved = !yawoLoan?.adminApproved?.status;
  const loanStatusIsNotRejected = yawoLoan?.status !== 'rejected';
  const showApproveAndRejectButtons = adminHasNotApproved && loanStatusIsNotRejected;
  const loanStatusIsOngoing = yawoLoan?.status === 'ongoing';

  return (
    <YawoLayout>
      <Wrapper>
        <div className="main_body">
          <h2 className="header_text">Loan Details</h2>
          <div className="back">
            <Icon Icon={ArrowBackIcon} type="link" to="/yawo/Loans" alt="Back to Yawo Loans" text="Back" />
            <span>Back</span>
          </div>
          <div className="mmo_body">
            {isLoading ? (
              <React.Fragment />
            ) : yawoLoan === null ? (
              <div>Yawo Loan Not Found</div>
            ) : (
              <React.Fragment>
                <div className="left">
                  <div style={{ display: 'flex' }}>
                    <div>
                      {displayInfo('Loan Ref:', yawoLoan.loanRef || '')}
                      {displayInfo('Loan Type:', (yawoLoan.loanType || '').replaceAll('_', ' '))}
                      {displayInfo('Loan Status:', <LoanStatus status={yawoLoan.status} />)}
                      {yawoLoan.message && displayInfo('Rejection Reason:', yawoLoan.message || '')}
                      {showApproveAndRejectButtons && (
                        <>
                          <br />
                          <div style={{ display: 'flex', gap: '1rem' }}>
                            <ApproveLoan loanId={yawoLoan._id} />
                            <RejectLoan loanId={yawoLoan._id} />
                          </div>
                        </>
                      )}
                      {loanStatusIsOngoing && (
                        <>
                          <br />
                          <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                            <CompleteLoan loanId={yawoLoan._id} />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">LOAN INFO</h5>
                    {displayInfo('Loan Amount:', formatAmount(yawoLoan.amount))}
                    {displayInfo('Interest Amount:', formatAmount(yawoLoan.interestAmount))}
                    {displayInfo('Interest Rate:', yawoLoan.interestRate)}
                    {displayInfo('Collection Wallet:', yawoLoan.collectionWallet || '')}
                    {displayInfo('Daily Collection Amount:', dailyCollectionAmount || '')}
                    {displayInfo('Total to Pay:', totalToPay || '')}
                    {displayInfo(
                      'Loan Duration:',
                      yawoLoan.durationInDays ? `${yawoLoan.durationInDays} working days` : ''
                    )}
                    {displayInfo('Vendor Approval:', yawoLoan?.vendorApproved?.status ? '✅' : '❌')}
                    {displayInfo('Admin Approval:', yawoLoan?.adminApproved?.status ? '✅' : '❌')}
                    {displayInfo(
                      'Created At:',
                      yawoLoan.createdAt && yawoLoan.createdAt !== 'None' ? formatDate(yawoLoan.createdAt) : ''
                    )}
                    {displayInfo(
                      'Updated At:',
                      yawoLoan.updatedAt && yawoLoan.updatedAt !== 'None' ? formatDate(yawoLoan.updatedAt) : ''
                    )}
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">COMMISSIONS</h5>
                    {displayInfo('Agent Commission Percentage:', yawoLoan.agentCommissionPercentage || '')}
                    {displayInfo('Vendor Commission Percentage:', yawoLoan.vendorCommissionPercentage || '')}
                    {displayInfo('Ikonik Commission Percentage:', yawoLoan.ikonikCommissionPercentage || '')}
                  </div>
                </div>
                <div>
                  <div>
                    <h5 className="section_title">AGENT DETAILS</h5>
                    {displayInfo('Agent Email:', yawoLoan?.loanAgent?.email?.address || '')}
                    {displayInfo('First Name:', yawoLoan?.loanAgent?.firstName || '')}
                    {displayInfo('Last Name:', yawoLoan?.loanAgent.lastName || '')}
                    {displayInfo('Phone Number:', yawoLoan?.loanAgent?.phoneNumber?.number || '')}
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">CUSTOMER DETAILS</h5>
                    {displayInfo('First Name:', yawoLoan?.loanCustomer?.firstName || '')}
                    {displayInfo('Last Name:', yawoLoan?.loanCustomer?.lastName || '')}
                    {displayInfo('Phone Number:', yawoLoan?.loanCustomer?.phoneNumber?.number || '')}
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">GUARANTOR DETAILS</h5>
                    {displayInfo('First Name:', yawoLoan?.loanGuarantor?.firstName || '')}
                    {displayInfo('Last Name:', yawoLoan?.loanGuarantor?.lastName || '')}
                    {displayInfo('Phone Number:', yawoLoan?.loanGuarantor?.phoneNumber?.number || '')}
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">PHOTOS</h5>
                    <div style={{ display: 'flex', gap: '1rem', width: '100%' }}>
                      <div style={{ width: '31%' }}>
                        <div style={{ fontSize: 'small' }}>customer</div>
                        <img src={yawoLoan?.loanCustomer?.photo} style={{ width: '100%' }} onClick={toggleViewer} />
                      </div>
                      <div style={{ width: '31%' }}>
                        <div style={{ fontSize: 'small' }}>loan photo</div>
                        <img src={yawoLoan?.photo} style={{ width: '100%' }} onClick={toggleViewer} />
                      </div>
                      <div style={{ width: '31%' }}>
                        <div style={{ fontSize: 'small' }}>guarantor</div>
                        <img src={yawoLoan?.loanGuarantor?.photo} style={{ width: '100%' }} onClick={toggleViewer} />
                      </div>
                    </div>
                    <ImageViewer
                      isOpen={viewerIsOpen}
                      onClose={toggleViewer}
                      images={[yawoLoan?.loanCustomer?.photo, yawoLoan?.photo, yawoLoan?.loanGuarantor?.photo]}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </Wrapper>
    </YawoLayout>
  );
};

ViewLoan.propTypes = {
  match: PropTypes.object.isRequired,
  yawoLoan: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSingleYawoLoan: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { yawoLoan, isLoading } = state.yawoLoan;
  return { yawoLoan, isLoading };
};

export default connect(mapStateToProps, { getSingleYawoLoan })(withRouter(ViewLoan));
