import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import YawoLayout from 'src/components/yawo/YawoLayout';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from 'styled-components';
import Icon from 'src/components/common/Icon';
import { Link, withRouter } from 'react-router-dom';
import { getSingleYawoCustomer } from 'src/store/actions/yawo/customerActions';
import { connect } from 'react-redux';
import { formatDate } from 'src/utils/helpers';
import Button from 'src/components/common/Button';
import ActivateCustomer from './ActivateCustomer';
import SuspendCustomer from './SuspendCustomer';
import VerifyCustomer from './VerifyCustomer';
import ImageViewer from 'src/components/common/ImageViewer';

const Wrapper = styled.div`
  .back {
    display: flex;
    width: max-content;
    grid-gap: 1rem;
    align-items: center;
  }
  .linked-btn {
    text-decoration: none;
  }
  .section {
    border-top: 1px solid grey;
    min-height: 10rem;
    margin-top: 1rem;
    padding: 1rem 0rem;
  }
  .avatar {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
  }
  .img {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    margin: 0;
    padding: 0;
  }
  .profile {
    display: flex;
    grid-gap: 1rem;
    align-items: center;
  }
  .details_body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }

  .main_body {
    padding: 1rem;
    background-color: ${(props) => props.theme.color.white};
    border-radius: 0.5rem;
    min-height: calc(100vh - 6rem);

    & > :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .header_text {
    text-align: center;
  }
  .mmo_body {
    padding: 0.5rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
  .left {
    border-right: 1px solid ${(props) => props.theme.color.grey};
  }
  .section_title {
    color: ${(props) => props.theme.color.deepGrey};
    margin-top: 1rem;
  }
  .item {
    display: flex;
    padding-top: 0.5rem;
  }
  .item_title {
    width: 10rem;
  }
  .item_value {
    color: ${(props) => props.theme.color.deepGrey};
  }
`;

const ViewCustomer = ({ match, getSingleYawoCustomer, yawoCustomer, isLoading }) => {
  const { customerId } = match.params;
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const toggleViewer = () => setViewerIsOpen(!viewerIsOpen);

  useEffect(() => {
    getSingleYawoCustomer(customerId);
  }, [customerId]);

  const displayInfo = (title, value) => (
    <div className="item">
      <h4 className="item_title">{title || ''}</h4>
      <h4 className="item_value">
        {value || ''}
        {value && value[0] === '#' && (
          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              background: `${value}`,
              margin: 'auto 5px',
            }}
          />
        )}
      </h4>
    </div>
  );

  return (
    <YawoLayout>
      <Wrapper>
        <div className="main_body">
          <h2 className="header_text">Yawo Customer Details</h2>
          <div className="back">
            <Icon Icon={ArrowBackIcon} type="link" to="/yawo/customers" alt="Back to Yawo Customers" text="Back" />
            <span>Back</span>
          </div>
          <div className="mmo_body">
            {isLoading ? (
              <React.Fragment />
            ) : yawoCustomer === null ? (
              <div>Yawo Customer Not Found</div>
            ) : (
              <React.Fragment>
                <div className="left">
                  <div style={{ display: 'flex' }}>
                    <div>
                      {displayInfo('First Name:', yawoCustomer.firstName || '')}
                      {displayInfo('Last Name:', yawoCustomer.lastName || '')}
                      {displayInfo('Other Names:', yawoCustomer.otherNames || '')}
                      {displayInfo('Vendor:', yawoCustomer?.vendor?.shortCode || '')}
                      <br />
                      <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                        {yawoCustomer?.adminVerification?.isVerified ? (
                          <>
                            {yawoCustomer.isActive ? (
                              <SuspendCustomer customerId={yawoCustomer._id} />
                            ) : (
                              <ActivateCustomer customerId={yawoCustomer._id} />
                            )}
                            {yawoCustomer.yawoWallet && (
                              <Link
                                to={`/yawo/wallets?yawoWalletIuwi=${yawoCustomer.yawoWallet}`}
                                className="linked-btn"
                              >
                                <Button text="View Wallet" />
                              </Link>
                            )}
                          </>
                        ) : (
                          <VerifyCustomer customerId={yawoCustomer._id} />
                        )}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">GENERAL INFO</h5>
                    {displayInfo('Yawo Wallet:', yawoCustomer.yawoWallet || '')}
                    {displayInfo('Is Vendor Verified:', yawoCustomer?.vendor?.isVerified ? '✅' : '❌')}
                    {displayInfo('Is Admin Verified:', yawoCustomer?.adminVerification?.isVerified ? '✅' : '❌')}
                    {displayInfo('Is Active:', yawoCustomer.isActive ? '✅' : '❌')}
                    {displayInfo('Residential Address', '')}
                    {displayInfo('Address House No.:', yawoCustomer?.address?.houseNumber || '')}
                    {displayInfo('Address Street Address:', yawoCustomer?.address?.streetAddress || '')}
                    {displayInfo('Address City:', yawoCustomer?.address?.city || '')}
                    {displayInfo('Address State:', yawoCustomer?.address?.state || '')}
                    {displayInfo('Address Zip Code:', yawoCustomer?.address?.zipCode || '')}
                    {displayInfo('Email:', yawoCustomer?.email?.address || '')}
                    {displayInfo('Phone:', yawoCustomer?.phoneNumber?.number || '')}
                    {displayInfo(
                      'Created At:',
                      yawoCustomer.createdAt && yawoCustomer.createdAt !== 'None'
                        ? formatDate(yawoCustomer.createdAt)
                        : ''
                    )}
                    {displayInfo(
                      'Updated At:',
                      yawoCustomer.updatedAt && yawoCustomer.updatedAt !== 'None'
                        ? formatDate(yawoCustomer.updatedAt)
                        : ''
                    )}
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">AGENT INFO</h5>
                    {displayInfo(
                      'Name:',
                      `${yawoCustomer?.loanAgent?.firstName}  ${yawoCustomer?.loanAgent?.lastName}` || ''
                    )}
                    {displayInfo('Email:', yawoCustomer?.loanAgent?.email?.address || '')}
                  </div>
                </div>
                <div>
                  <div>
                    <h5 className="section_title">BANK DETAILS</h5>
                    {displayInfo('Account Number:', yawoCustomer?.bank?.accountNumber || '')}
                    {displayInfo('Account Name:', yawoCustomer?.bank?.accountName || '')}
                    {displayInfo('Bank:', yawoCustomer?.bank?.bankName || '')}
                    {displayInfo('BVN:', yawoCustomer?.bvn?.number || '')}
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">BUSINESS INFO</h5>
                    {displayInfo('Business Name:', yawoCustomer?.business?.businessName || '')}
                    {displayInfo('Address House No.:', yawoCustomer?.business?.businessAddress?.houseNumber || '')}
                    {displayInfo(
                      'Address Street Address:',
                      yawoCustomer?.business?.businessAddress?.streetAddress || ''
                    )}
                    {displayInfo('Address City:', yawoCustomer?.business?.businessAddress?.city || '')}
                    {displayInfo('Address State:', yawoCustomer?.business?.businessAddress?.state || '')}
                    {displayInfo('Address Zip Code:', yawoCustomer?.business?.businessAddress?.zipCode || '')}
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">PHOTOS</h5>
                    <div style={{ display: 'flex', gap: '1rem', width: '100%' }}>
                      <div style={{ width: '45%' }}>
                        <div style={{ fontSize: 'small' }}>Customer photo</div>
                        <img src={yawoCustomer?.photo} style={{ maxWidth: '100%' }} onClick={toggleViewer} />
                      </div>
                      <div style={{ width: '45%' }}>
                        <div style={{ fontSize: 'small' }}>Business photo</div>
                        <img
                          src={yawoCustomer?.business?.businessPhoto}
                          style={{ maxWidth: '100%' }}
                          onClick={toggleViewer}
                        />
                      </div>
                    </div>
                    <ImageViewer
                      isOpen={viewerIsOpen}
                      onClose={toggleViewer}
                      images={[yawoCustomer?.photo, yawoCustomer?.business?.businessPhoto]}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </Wrapper>
    </YawoLayout>
  );
};

ViewCustomer.propTypes = {
  match: PropTypes.object.isRequired,
  yawoCustomer: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSingleYawoCustomer: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { yawoCustomer, isLoading } = state.yawoCustomer;
  return { yawoCustomer, isLoading };
};

export default connect(mapStateToProps, { getSingleYawoCustomer })(withRouter(ViewCustomer));
