import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import FwCardPaymentsComponent from 'src/components/notifications/fw/FwCardPayments';

const FwCardPayments = () => {
  return (
    <DashboardLayout>
      <FwCardPaymentsComponent />
    </DashboardLayout>
  );
};

export default FwCardPayments;
