import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  getAllUserAccounts,
  setAccountLimit,
  setAccountPage,
  setAccountStatus,
} from 'src/store/actions/accountActions';
import { sortPlatforms } from 'src/utils/helpers';
import { SelectInput } from 'src/components/common/inputs';
import UsersTable from './UsersTable';
import UserLayout from './UserLayout';
import DisplayCard from 'src/components/common/DisplayCard';
import PeopleIcon from '@material-ui/icons/People';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  height: max-content;
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .platform {
    display: grid;
    width: max-content;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 0.5rem;
  }
`;

const Users = ({ platforms, accounts, getAllUserAccounts, setAccountLimit, setAccountPage, setAccountStatus }) => {
  const [platform, setPlatform] = useState('ikonik');

  const getUsers = useCallback(
    (platform) => {
      platform === 'ikonik' ? getAllUserAccounts('all') : getAllUserAccounts(platform);
    },
    [getAllUserAccounts]
  );

  useEffect(() => {
    getUsers(platform);
  }, [getAllUserAccounts, platform]);

  const onPlatformChange = (platform) => {
    setAccountPage(1);
    setAccountLimit(20);
    setPlatform(platform);
  };

  const onPageChange = (page) => {
    setAccountPage(page);
    getUsers(platform);
  };

  const onLimitChange = (limit) => {
    setAccountLimit(limit);
    getUsers(platform);
  };

  const onStatusChange = (status) => {
    setAccountStatus(status);
    getUsers(platform);
  };

  return (
    <UserLayout>
      <Wrapper>
        <div className="cards">
          <DisplayCard icon={PeopleIcon} title="Total Users" value={accounts !== null && accounts.count} />
          <DisplayCard
            icon={SupervisorAccountIcon}
            title="Active Users"
            value={accounts !== null && accounts.active_users}
          />
          <DisplayCard
            icon={SupervisedUserCircleIcon}
            title="Suspended Users"
            value={accounts !== null && accounts.suspended_users}
          />
        </div>
        <div className="platform">
          <h4>Select platform:</h4>
          <SelectInput options={sortPlatforms(platforms)} value={platform} onChange={onPlatformChange} />
        </div>
        <UsersTable
          platform={platform}
          tableData={accounts}
          onPageChange={onPageChange}
          onLimitChange={onLimitChange}
          onStatusChange={onStatusChange}
        />
      </Wrapper>
    </UserLayout>
  );
};

Users.propTypes = {
  platforms: PropTypes.array.isRequired,
  accounts: PropTypes.object.isRequired,
  getAllUserAccounts: PropTypes.func.isRequired,
  setAccountLimit: PropTypes.func.isRequired,
  setAccountPage: PropTypes.func.isRequired,
  setAccountStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { platforms } = state.platform;
  const { accounts } = state.account;
  return { platforms, accounts };
};

export default connect(mapStateToProps, { getAllUserAccounts, setAccountPage, setAccountLimit, setAccountStatus })(
  Users
);
