import querystring from 'querystring';
import client from 'src/client';
import {
  ADD_YAWO_VENDORS,
  ADD_YAWO_VENDOR,
  YAWO_VENDOR_IS_LOADING,
  YAWO_VENDOR_NOT_LOADING,
  SET_YAWO_VENDOR_DATE,
  RESET_YAWO_VENDOR_DATE,
  SET_YAWO_VENDOR_LIMIT,
  SET_YAWO_VENDOR_STATUS,
  SET_YAWO_VENDOR_PAGE,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from 'src/store/actions/alertActions';

const { adminService } = client;

const yawoVendorIsLoading =
  (msg = 'Loading Yawo Vendor') =>
  (dispatch) => {
    dispatch({ type: YAWO_VENDOR_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const yawoVendorNotLoading = () => (dispatch) => {
  dispatch({ type: YAWO_VENDOR_NOT_LOADING });
};

const addYawoVendors = (yawoVendors) => {
  return { type: ADD_YAWO_VENDORS, payload: yawoVendors };
};

const addYawoVendor = (yawoVendor) => {
  return { type: ADD_YAWO_VENDOR, payload: yawoVendor };
};

export const setYawoVendorDate = (start_date, end_date) => {
  return { type: SET_YAWO_VENDOR_DATE, payload: { start_date, end_date } };
};

export const resetYawoVendorDate = () => {
  return { type: RESET_YAWO_VENDOR_DATE };
};

export const setYawoVendorPage = (page) => {
  return { type: SET_YAWO_VENDOR_PAGE, payload: page };
};

export const setYawoVendorLimit = (limit) => {
  return { type: SET_YAWO_VENDOR_LIMIT, payload: limit };
};

export const setYawoVendorStatus = (status) => {
  return { type: SET_YAWO_VENDOR_STATUS, payload: status };
};

const generatePath = (endPoint, yawoVendor, query) => {
  const { limit, page } = yawoVendor || {};
  query = {
    page,
    limit,
    ...query,
  };
  if (query.page === undefined) delete query.page;
  if (query.limit === undefined) delete query.limit;
  let url = endPoint;
  if (query && Object.keys(query).length > 0) {
    url += `?${querystring.encode(query)}`;
  }
  return url;
};

export const getAllYawoVendors =
  (query = {}) =>
  (dispatch, getState) => {
    dispatch(yawoVendorIsLoading(query?.search ? 'Searching...' : 'Loading Yawo Vendors'));
    const { yawoVendors } = getState().yawoVendor;
    const path = generatePath('/yawo/vendors/all', yawoVendors, query);
    adminService
      .get(path)
      .then((res) => {
        dispatch(addYawoVendors(res?.data?.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Vendors' })))
      .finally(() => dispatch(yawoVendorNotLoading()));
  };

export const getSingleYawoVendor = (shortCode, extraParams) => (dispatch) => {
  const { silently = false } = extraParams || {};
  if (!silently) {
    dispatch(yawoVendorIsLoading('Loading Yawo Vendor'));
  }
  adminService
    .get(`/yawo/vendors/${shortCode}`)
    .then((res) => {
      dispatch(addYawoVendor(res?.data?.data));
      dispatch(clearAlert());
    })
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Vendor' })))
    .finally(() => dispatch(yawoVendorNotLoading()));
};

export const updateLoanLimit =
  ({ shortCode, loanLimit }, callback) =>
  (dispatch) => {
    dispatch(yawoVendorIsLoading('Updating Loan Limit ...'));
    adminService
      .put('/yawo/vendors/loan-limit', { shortCode, loanLimit })
      .then((res) => {
        callback(res?.data?.data);
        dispatch(successAlert('Loan Limit Updated'));
      })
      .catch((err) => {
        dispatch(errorAlert({ msg: err?.response?.data?.message || 'Error updating loan limit' }));
      })
      .finally(() => dispatch(yawoVendorNotLoading()));
  };

export const getAvailableLoanLimit = (shortCode, callback) => (dispatch) => {
  adminService
    .get(`/yawo/vendors/${shortCode}/loan-limit`)
    .then((res) => {
      callback(res?.data?.data);
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data?.message || 'Error gettting available loan limit' }));
    });
};

export const activateVendor = (shortCode) => (dispatch) => {
  dispatch(yawoVendorIsLoading('Activating Vendor ...'));
  adminService
    .get(`/yawo/vendors/${shortCode}/activate`)
    .then((res) => {
      dispatch(addYawoVendor(res?.data?.data));
      dispatch(successAlert('Vendor Activated'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data?.message || 'Error activating vendor' }));
    })
    .finally(() => dispatch(yawoVendorNotLoading()));
};

export const suspendVendor = (shortCode) => (dispatch) => {
  dispatch(yawoVendorIsLoading('Suspending Vendor ...'));
  adminService
    .get(`/yawo/vendors/${shortCode}/suspend`)
    .then((res) => {
      dispatch(addYawoVendor(res?.data?.data));
      dispatch(successAlert('Vendor Suspended'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data?.message || 'Error suspending vendor' }));
    })
    .finally(() => dispatch(yawoVendorNotLoading()));
};

export const verifyVendor = (shortCode) => (dispatch) => {
  dispatch(yawoVendorIsLoading('Verifying Vendor ...'));
  adminService
    .get(`/yawo/vendors/${shortCode}/verify`)
    .then((res) => {
      dispatch(addYawoVendor(res?.data?.data));
      dispatch(successAlert('Vendor Verified'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data?.message || 'Error verifying vendor' }));
    })
    .finally(() => dispatch(yawoVendorNotLoading()));
};

export const loadVendorOptions = async (searchString) => {
  const options = await adminService
    .get(`/yawo/vendors/all?search=${searchString}&limit=50`)
    .then((res) => res?.data?.data?.data || [])
    .catch(() => []);

  return options
    .map((x) => ({
      ...x,
      label: `${x.shortCode} (${x?.yawoWallet})`,
      value: x.shortCode,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
};
