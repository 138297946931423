import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styled from 'styled-components';
import { dateFormatYMD, formatTime } from 'src/utils/helpers';
import Icon from 'src/components/common/Icon';
import VirtualWalletRequestStatus from './VirtualWalletRequestStatus';

const Wrapper = styled.div`
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
    min-width: 30rem;
  }
  .header_title {
    display: flex;
    justify-content: center;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .body {
    background-color: ${(props) => props.theme.color.lightGrey};
    padding: 1rem;
    margin: 0.5rem;
  }
  .item {
    display: flex;
    justify-content: space-between;
    color: black;
    margin-bottom: 0.5rem;
    grid-gap: 1rem;
  }
  .value {
    color: black;
    font-weight: bold;
    font-size: 0.9rem;
  }
`;

const ViewVirtualWalletRequest = ({ virtualWalletRequest }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Icon Icon={OpenInNewIcon} alt="View Wallet Request" onClick={toggleModal} />
      <Modal isOpen={isOpen} handleClose={toggleModal}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h6>Virtual Wallet Request Details</h6>
            </div>

            <CloseIcon onClick={toggleModal} className="cancel" />
          </div>
          <div className="body">
            <div className="item">
              <p>Name</p>
              <h6 className="value">
                {virtualWalletRequest && `${virtualWalletRequest['firstName']} ${virtualWalletRequest['lastName']}`}
              </h6>
            </div>
            <div className="item">
              <p>Email</p>
              <h6 className="value">{virtualWalletRequest && virtualWalletRequest.email}</h6>
            </div>
            <div className="item">
              <p>Phone Number</p>
              <h6 className="value">{virtualWalletRequest && virtualWalletRequest.phone}</h6>
            </div>
            <div className="item">
              <p>BVN</p>
              <h6 className="value">{virtualWalletRequest && virtualWalletRequest.bvn}</h6>
            </div>
            <div className="item">
              <p>Vendor</p>
              <h6 className="value">{virtualWalletRequest && virtualWalletRequest.vendor}</h6>
            </div>
            <div className="item">
              <p>Status</p>
              <h6 className="value">
                {virtualWalletRequest && <VirtualWalletRequestStatus status={virtualWalletRequest.status} />}
              </h6>
            </div>
            {virtualWalletRequest && virtualWalletRequest.status === 'declined' && (
              <div className="item">
                <p>Reason</p>
                <h6 className="value">{virtualWalletRequest.reason}</h6>
              </div>
            )}
            <div className="item">
              <p>Date</p>
              <h6 className="value">{virtualWalletRequest && dateFormatYMD(virtualWalletRequest.timeCreated)}</h6>
            </div>
            <div className="item">
              <p>Time</p>
              <h6 className="value">{virtualWalletRequest && formatTime(virtualWalletRequest.timeCreated)}</h6>
            </div>
          </div>
        </Wrapper>
      </Modal>
    </div>
  );
};

ViewVirtualWalletRequest.propTypes = {
  virtualWalletRequest: PropTypes.object,
};

export default ViewVirtualWalletRequest;
