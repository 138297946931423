import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import ModalTwo from 'src/components/common/ModalTwo';
import { TextInput, SelectInput } from 'src/components/common/inputs';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { createPtsp, getAllPtsps } from 'src/store/actions/ptspActions';

const Wrapper = styled.div`
  h2 {
    font-size: x-large;
  }

  .form-section-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    margin-bottom: 1.5rem;
    align-items: end;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .long {
      grid-column-end: span 1;
    }

    .longer {
      grid-column-end: span 2;

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .longest {
      grid-column-end: span 3;

      @media (max-width: 768px) {
        grid-column-end: span 2;
      }

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .align-start {
      align-self: start;
    }

    .align-center {
      align-self: center;
    }

    .align-end {
      align-self: end;
    }

    .justify-end {
      justify-self: end;
    }
  }

  .label {
    color: ${(props) => props.theme.color.black};
    min-width: max-content;
  }
`;

const CreatePtsp = ({ cardTypes, ptspOptions, createPtsp, getAllPtsps }) => {
  if (!cardTypes) return null;
  if (!ptspOptions) return null;

  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardType, setCardType] = useState('');
  const [ptspOption, setPtspOption] = useState('');
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');

  const resetFormState = () => {
    document.getElementById('createPtspForm').reset();
    setCardType('');
    setPtspOption('');
    setMin('');
    setMax('');
  };

  const submitForm = (e) => {
    e.preventDefault();

    const data = {
      cardType,
      ptsp: ptspOption,
      min,
      max,
    };

    setIsSubmitting(true);
    createPtsp(data, {
      always: () => {
        setIsSubmitting(false);
      },
      success: () => {
        setShow(false);
        resetFormState();
        if (getAllPtsps) getAllPtsps();
      },
    });
  };

  const cardTypeOptions = Array.isArray(cardTypes) ? cardTypes.map((type) => ({ text: type, value: type })) : [];
  ptspOptions = Array.isArray(ptspOptions) ? ptspOptions.map((ptsp) => ({ text: ptsp, value: ptsp })) : [];

  return (
    <Wrapper>
      <div className="form-section-grid">
        <div className="longest justify-end">
          <Button text="Create PTSP" onClick={toggleShow} />
        </div>
      </div>

      <ModalTwo size="large" active={show} onModalCloseCallback={toggleShow}>
        <div>
          <center>
            <h2>Create PTSP</h2>
          </center>

          <br />

          <form id="createPtspForm" onSubmit={submitForm} className="form-section-grid">
            <div>
              <SelectInput label="Select Card Type" options={cardTypeOptions} value={cardType} onChange={setCardType} required />
            </div>

            <div>
              <SelectInput label="Select PTSP" options={ptspOptions} value={ptspOption} onChange={setPtspOption} required />
            </div>

            <div>
              <TextInput label="Min amount" value={min} onChange={setMin} required />
            </div>

            <div>
              <TextInput label="Max amount" value={max} onChange={setMax} required />
            </div>

            <div className="longest justify-end">
              <Button type="submit" text={isSubmitting ? 'Creating PTSP ...' : '+ Create PTSP'} disabled={isSubmitting} />
            </div>
          </form>
        </div>
      </ModalTwo>
    </Wrapper>
  );
};

CreatePtsp.propTypes = {
  cardTypes: PropTypes.array.isRequired,
  ptspOptions: PropTypes.array.isRequired,
  createPtsp: PropTypes.func.isRequired,
  getAllPtsps: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { cardTypes, ptspOptions } = state.ptsp;
  return { cardTypes, ptspOptions };
};

export default connect(mapStateToProps, { createPtsp, getAllPtsps })(CreatePtsp);
