import React from 'react';
import AuthLayout from 'src/components/layout/AuthLayout';
//import PropTypes from 'prop-types'
import ResetPasswordForm from 'src/components/auth/ResetPasswordForm';

function ResetPassword() {
  return (
    <AuthLayout>
      <ResetPasswordForm />
    </AuthLayout>
  );
}

ResetPassword.propTypes = {};

export default ResetPassword;
