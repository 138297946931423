import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import ViewYawoTransactionComponent from 'src/components/yawo/transactions/ViewYawoTransaction';

const ViewYawoTransaction = () => {
  return (
    <DashboardLayout>
      <ViewYawoTransactionComponent />
    </DashboardLayout>
  );
};

export default ViewYawoTransaction;
