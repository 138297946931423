import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DashboardLayout from 'src/components/layout/DashboardLayout';

const Yawo = () => {
  const history = useHistory();
  useEffect(() => {
    history.push('/yawo/loans'); // redirect to /yawo/loans
  }, []);

  return <DashboardLayout />;
};

export default Yawo;
