import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import VirtualWalletRequestsComponent from 'src/components/support/virtualWallet/VirtualWalletRequests';

const VirtualWalletRequests = () => {
  return (
    <DashboardLayout>
      <VirtualWalletRequestsComponent />
    </DashboardLayout>
  );
};

export default VirtualWalletRequests;
