import React from 'react';
import { formatAmount, formatDate, formatTime } from 'src/utils/helpers';
import ResolveFwCardPayment from './ResolveFwCardPayment';

export const fwCardPaymentsTableHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function getTableNo(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Amount</p>;
    },
    render: function getAmount(rowData) {
      return <p>{formatAmount(rowData['amount'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Ref</p>;
    },
    render: function getAmount(rowData) {
      return <p>{formatAmount(rowData['ref'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function getStatus(rowData) {
      return <p style={{ color: statusColorMap[rowData['status']] }}>{rowData['status']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Txn Time</p>;
    },
    render: function getPaymentTime(rowData) {
      return (
        <p>
          <span>{formatDate(rowData['timeCreated'])} </span>
          <span>{formatTime(rowData['timeCreated'])}</span>
        </p>
      );
    },
  },
  {
    title: function title() {
      return <p>Actions</p>;
    },
    render: function actions(rowData) {
      return (
        <p style={{ display: 'flex', gap: '1rem', maxWidth: '5rem' }}>
          <div style={{ width: '2rem' }}>
            <ResolveFwCardPayment paymentRef={rowData['ref']} />
          </div>
        </p>
      );
    },
  },
];

const statusColorMap = {
  SUCCESS: 'green',
  PENDING: 'darkorange',
};

