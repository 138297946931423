import {
  ADD_FW_CARD_PAYMENTS,
  FW_CARD_PAYMENT_IS_LOADING,
  FW_CARD_PAYMENT_NOT_LOADING,
  SET_FW_CARD_PAYMENTS_PAGE,
  SET_FW_CARD_PAYMENTS_LIMIT,
  SET_FW_CARD_PAYMENTS_DATE,
  RESET_FW_CARD_PAYMENTS_DATE,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  fwCardPayments: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FW_CARD_PAYMENT_IS_LOADING:
      return { ...state, isLoading: true };
    case FW_CARD_PAYMENT_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_FW_CARD_PAYMENTS:
      return { ...state, fwCardPayments: action.payload?.data };
    case SET_FW_CARD_PAYMENTS_DATE:
      return {
        ...state,
        fwCardPayments: {
          ...state.fwCardPayments,
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
        },
      };
    case RESET_FW_CARD_PAYMENTS_DATE:
      return {
        ...state,
        fwCardPayments: {
          ...state.fwCardPayments,
          start_date: null,
          end_date: null,
        },
      };
    case SET_FW_CARD_PAYMENTS_LIMIT:
      return {
        ...state,
        fwCardPayments: { ...state.fwCardPayments, limit: action.payload },
      };
    case SET_FW_CARD_PAYMENTS_PAGE:
      return {
        ...state,
        fwCardPayments: { ...state.fwCardPayments, page: action.payload },
      };
    default:
      return state;
  }
}
