import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MoneyIcon from '@material-ui/icons/Money';
import ReceiptIcon from '@material-ui/icons/Receipt';
import WalletIcon from '@material-ui/icons/CreditCard';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import TransformIcon from '@material-ui/icons/Transform';
import SideNav from 'src/components/layout/SideNav';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSystemUser } from 'src/store/actions/yawo/walletActions';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  .body {
    padding: 1.5rem;
    display: grid;
    grid-gap: 1rem;
    height: max-content;
  }
`;

const sideNavOptions = [
  { title: 'Loans', value: '/yawo/loans', icon: MoneyIcon },
  { title: 'Collections', value: '/yawo/collections', icon: TransformIcon },
  { title: 'Wallets', value: '/yawo/wallets', icon: WalletIcon },
  { title: 'Transactions', value: '/yawo/transactions', icon: ReceiptIcon },
  { title: 'Vendors', value: '/yawo/vendors', icon: BusinessIcon },
  { title: 'Agents', value: '/yawo/agents', icon: PeopleIcon },
  { title: 'Customers', value: '/yawo/customers', icon: PeopleOutlinedIcon },
  { title: 'Guarantors', value: '/yawo/guarantors', icon: EmojiPeopleIcon },
];

const YawoLayout = ({ children, getSystemUser, yawoWalletIuwi }) => {
  useEffect(() => {
    if (!yawoWalletIuwi) getSystemUser();
  }, [yawoWalletIuwi]);

  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const activeSidenavOption = sideNavOptions.slice(1).find((x) => {
    return pathname.toLowerCase().includes(x.value.toLowerCase());
  });
  const handleSetType = (value) => history.push(value);

  return (
    <Wrapper>
      <SideNav
        title="Yawo"
        type={activeSidenavOption?.value || '/yawo/loans'}
        setType={handleSetType}
        options={sideNavOptions}
      />
      <div className="body">{children}</div>
    </Wrapper>
  );
};

YawoLayout.propTypes = {
  children: PropTypes.any.isRequired,
  getSystemUser: PropTypes.func.isRequired,
  yawoWalletIuwi: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { yawoWalletIuwi } = state.yawoWallet;
  return { yawoWalletIuwi };
};

export default connect(mapStateToProps, { getSystemUser })(YawoLayout);
