import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import ModalTwo from 'src/components/common/ModalTwo';
import { TextInput, SelectInput } from 'src/components/common/inputs';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { createTerminal } from 'src/store/actions/terminalActions';

const Wrapper = styled.div`
  h2 {
    font-size: x-large;
  }

  .form-section-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    margin-bottom: 1.5rem;
    align-items: end;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .long {
      grid-column-end: span 1;
    }

    .longer {
      grid-column-end: span 2;

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .longest {
      grid-column-end: span 3;

      @media (max-width: 768px) {
        grid-column-end: span 2;
      }

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .align-start {
      align-self: start;
    }

    .align-center {
      align-self: center;
    }

    .align-end {
      align-self: end;
    }

    .justify-end {
      justify-self: end;
    }
  }

  .label {
    color: ${(props) => props.theme.color.black};
    min-width: max-content;
  }
`;

const CreateTerminal = ({ terminalTypes, createTerminal, getAllTerminals }) => {
  if (!terminalTypes) return null;

  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ptsp, setPtsp] = useState('');
  const [terminalId, setTerminalId] = useState('');

  const resetFormState = () => {
    document.getElementById('createTerminalForm').reset();
    setPtsp('');
    setTerminalId('');
  };

  const submitForm = (e) => {
    e.preventDefault();

    const data = {
      ptsp,
      terminalIds: terminalId.split(/,\s*/),
    };

    setIsSubmitting(true);
    createTerminal(data, {
      always: () => {
        setIsSubmitting(false);
      },
      success: () => {
        setShow(false);
        resetFormState();
        if (getAllTerminals) getAllTerminals();
      },
    });
  };

  const ptspOptions = Array.isArray(terminalTypes) ? terminalTypes.map((type) => ({ text: type, value: type })) : [];

  return (
    <Wrapper>
      <div className="form-section-grid">
        <div className="longest justify-end">
          <Button text="Create Terminal IDs" onClick={toggleShow} />
        </div>
      </div>

      <ModalTwo size="large" active={show} onModalCloseCallback={toggleShow}>
        <div>
          <center>
            <h2>Create Terminal IDs</h2>
          </center>

          <br />

          <form id="createTerminalForm" onSubmit={submitForm} className="form-section-grid">
            <div className="align-start">
              <SelectInput label="Select PTSP" options={ptspOptions} value={ptsp} onChange={setPtsp} required />
            </div>

            <div className="longer">
              <TextInput label="Terminal Id" value={terminalId} onChange={setTerminalId} required />
              <div className="color-red">
                separate multiplle terminal ids with comma e.g. <samp>TERMINALID1, TERMINALID2</samp>
              </div>
            </div>

            <div className="longest justify-end">
              <Button
                type="submit"
                text={isSubmitting ? 'Creating ...' : '+ Create Terminal IDs'}
                disabled={isSubmitting}
              />
            </div>
          </form>
        </div>
      </ModalTwo>
    </Wrapper>
  );
};

CreateTerminal.propTypes = {
  terminalTypes: PropTypes.array.isRequired,
  createTerminal: PropTypes.func.isRequired,
  getAllTerminals: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { terminalTypes } = state.terminal;
  return { terminalTypes };
};

export default connect(mapStateToProps, { createTerminal })(CreateTerminal);
