import React from 'react';
import { formatAmount } from 'src/utils/helpers';
import ViewTransaction from './ViewTransaction';
import TransactionStatus from './TransactionStatus';
import ViewPlatform from 'src/components/platform/ViewPlatform';
import RefreshTransaction from './RefreshTransaction';
import UpdateTransaction from './UpdateTransaction';

export const transactionsHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Vendor</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['vendor'] || rowData['ptsp']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Type</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['Type']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Platform</p>;
    },
    render: function DateTaken(rowData) {
      return <ViewPlatform platform={rowData['platform']} />;
    },
  },
  {
    title: function title() {
      return <p>Amount</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{formatAmount(rowData['Amount'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function DateTaken(rowData) {
      return <TransactionStatus status={rowData['Status']} />;
    },
  },
  {
    title: function title() {
      return <p>Date</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['Date']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Time</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{rowData['Time']}</p>;
    },
  },
  {
    title: function title() {
      return <p>View</p>;
    },
    render: function SerialNumber(rowData) {
      return (
        <div style={{ display: 'flex' }}>
          <span style={{ width: '100%' }}>
            <ViewTransaction transactionRef={rowData.transactionRef} />
          </span>
          {rowData?.status?.includes('PENDING') && (
            <span style={{ width: '100%' }}>
              <RefreshTransaction transactionRef={rowData.transactionRef} />
            </span>
          )}
          {(rowData?.status?.includes('SUCCESS') || rowData?.status?.includes('PENDING')) && (
            <span style={{ width: '100%' }}>
              <UpdateTransaction transaction={rowData} />
            </span>
          )}
        </div>
      );
    },
  },
];
