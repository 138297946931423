import React from 'react';
import ViewTerminal from './ViewTerminal';
import EditTerminal from './EditTerminal';

export const terminalsTableHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>PTSP</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['ptsp']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Terminal ID</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['terminalId']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Actions</p>;
    },
    render: function SerialNumber(rowData) {
      return (
        <div style={{ display: 'flex' }}>
          <span style={{ width: '100%' }}>
            <ViewTerminal terminalId={rowData['_id']} />
          </span>
          <span style={{ width: '100%' }}>
            <EditTerminal terminal={rowData} />
          </span>
        </div>
      );
    },
  },
];
