import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
// import CloseIcon from '@material-ui/icons/Close';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import styled from 'styled-components';
import Icon from 'src/components/common/Icon';
import { connect } from 'react-redux';
import {
  getAllVirtualWalletRequests,
  declineVirtualWalletRequest,
} from 'src/store/actions/virtualWalletRequestsActions';
import Button from 'src/components/common/Button';
import { TextInput } from 'src/components/common/inputs';

const Wrapper = styled.div`
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    display: flex;
    justify-content: space-between;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .confirm {
    display: grid;
    grid-gap: 1rem;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0rem;
  }
  .buttons {
    padding-top: 0.5rem;
    display: flex;
    justify-content: space-between;
  }
  .buttons > * {
    width: 7rem;
  }
`;

const DeclineRequest = ({ requestId, declineVirtualWalletRequest, getAllVirtualWalletRequests }) => {
  const [virtualWalletDeclineReason, setVirtualWalletDeclineReason] = useState('');

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const declineRequest = () => {
    declineVirtualWalletRequest(requestId, virtualWalletDeclineReason);
    setVirtualWalletDeclineReason('');
    toggleModal();
    setTimeout(() => {
      //to refresh table data
      getAllVirtualWalletRequests();
    }, 1500);
  };

  return (
    <div>
      <Icon Icon={ThumbDownIcon} alt="Decline request" onClick={toggleModal} />
      <Modal isOpen={isOpen} handleClose={toggleModal}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h1>Decline Request</h1>
            </div>

            <h6 onClick={toggleModal} className="cancel">
              X
            </h6>
          </div>

          <div className="confirm">
            <TextInput
              label="Enter reason for Virtual Wallet Request Decline"
              value={virtualWalletDeclineReason}
              onChange={setVirtualWalletDeclineReason}
              required
            />
            <div className="buttons">
              <div>
                <Button
                  text="Decline"
                  onClick={declineRequest}
                  theme="purple"
                  disabled={virtualWalletDeclineReason.length === 0}
                />
              </div>
              <div>
                <Button text="Cancel" onClick={toggleModal} theme="purple" />
              </div>
            </div>
          </div>
        </Wrapper>
      </Modal>
    </div>
  );
};
DeclineRequest.propTypes = {
  getAllVirtualWalletRequests: PropTypes.func.isRequired,
  declineVirtualWalletRequest: PropTypes.func.isRequired,
  requestId: PropTypes.string.isRequired,
};

export default connect(null, { getAllVirtualWalletRequests, declineVirtualWalletRequest })(DeclineRequest);
