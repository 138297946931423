import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import YawoLayout from 'src/components/yawo/YawoLayout';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from 'styled-components';
import Icon from 'src/components/common/Icon';
import { withRouter } from 'react-router-dom';
import { getSingleYawoCollection } from 'src/store/actions/yawo/collectionActions';
import { connect } from 'react-redux';
import { formatDate, formatAmount } from 'src/utils/helpers';
import CompleteCollection from './CompleteCollection';
import { Link } from 'react-router-dom';
import CollectionStatus from './CollectionStatus';
import ImageViewer from 'src/components/common/ImageViewer';

const Wrapper = styled.div`
  .back {
    display: flex;
    width: max-content;
    grid-gap: 1rem;
    align-items: center;
  }
  .linked-btn {
    text-decoration: none;
  }
  .section {
    border-top: 1px solid grey;
    min-height: 10rem;
    margin-top: 1rem;
    padding: 1rem 0rem;
  }
  .avatar {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
  }
  .img {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    margin: 0;
    padding: 0;
  }
  .profile {
    display: flex;
    grid-gap: 1rem;
    align-items: center;
  }
  .details_body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }

  .main_body {
    padding: 1rem;
    background-color: ${(props) => props.theme.color.white};
    border-radius: 0.5rem;
    min-height: calc(100vh - 6rem);

    & > :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .header_text {
    text-align: center;
  }
  .mmo_body {
    padding: 0.5rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
  .left {
    border-right: 1px solid ${(props) => props.theme.color.grey};
  }
  .section_title {
    color: ${(props) => props.theme.color.deepGrey};
    margin-top: 1rem;
  }
  .item {
    display: flex;
    padding-top: 0.5rem;
  }
  .item_title {
    width: 10rem;
  }
  .item_value {
    color: ${(props) => props.theme.color.deepGrey};
  }
`;

const ViewCollection = ({ match, getSingleYawoCollection, yawoCollection, isLoading }) => {
  const { collectionId } = match.params;
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const toggleViewer = () => setViewerIsOpen(!viewerIsOpen);

  useEffect(() => {
    getSingleYawoCollection(collectionId);
  }, [collectionId]);

  const displayInfo = (title, value) => (
    <div className="item">
      <h4 className="item_title">{title || ''}</h4>
      <h4 className="item_value">
        {typeof value === 'function' ? value() : value || ''}
        {value && value[0] === '#' && (
          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              background: `${value}`,
              margin: 'auto 5px',
            }}
          />
        )}
      </h4>
    </div>
  );

  return (
    <YawoLayout>
      <Wrapper>
        <div className="main_body">
          <h2 className="header_text">Yawo Collection Details</h2>
          <div className="back">
            <Icon Icon={ArrowBackIcon} type="link" to="/yawo/collections" alt="Back to Yawo Collections" text="Back" />
            <span>Back</span>
          </div>
          <div className="mmo_body">
            {isLoading ? (
              <React.Fragment />
            ) : yawoCollection === null ? (
              <div>Yawo Collection Not Found</div>
            ) : (
              <React.Fragment>
                <div className="left">
                  <div style={{ display: 'flex' }}>
                    <div>
                      {displayInfo('Collection Ref:', yawoCollection.collectionRef || '')}
                      {displayInfo('Collection Wallet:', yawoCollection.collectionWallet || '')}
                      {displayInfo('Collection Status:', <CollectionStatus status={yawoCollection.collectionStatus} />)}
                      {displayInfo('Vendor:', () => (
                        <Link className="linked-shortcode" to={`/users/mmo/${yawoCollection['shortCode']}`}>
                          {yawoCollection['shortCode']}
                        </Link>
                      ))}
                      {yawoCollection.collectionStatus !== 'success' && (
                        <>
                          <br />
                          <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                            <CompleteCollection collectionId={yawoCollection._id} />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">GENERAL INFO</h5>
                    {displayInfo('Amount:', formatAmount(yawoCollection.amount))}
                    {displayInfo('Day:', yawoCollection.day)}
                    {displayInfo('Collection Date:', yawoCollection.collectionDate)}
                    {displayInfo(
                      'Created At:',
                      yawoCollection.createdAt && yawoCollection.createdAt !== 'None'
                        ? formatDate(yawoCollection.createdAt)
                        : ''
                    )}
                    {displayInfo(
                      'Updated At:',
                      yawoCollection.updatedAt && yawoCollection.updatedAt !== 'None'
                        ? formatDate(yawoCollection.updatedAt)
                        : ''
                    )}
                  </div>
                </div>
                <div className="right">
                  <div>
                    <h5 className="section_title">AGENT DETAILS</h5>
                    {displayInfo('Agent Email:', yawoCollection?.loanAgent?.email?.address || '')}
                    {displayInfo('First Name:', yawoCollection?.loanAgent?.firstName || '')}
                    {displayInfo('Last Name:', yawoCollection?.loanAgent.lastName || '')}
                    {displayInfo('Phone Number:', yawoCollection?.loanAgent?.phoneNumber?.number || '')}
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">CUSTOMER DETAILS</h5>
                    {displayInfo('First Name:', yawoCollection?.loanCustomer?.firstName || '')}
                    {displayInfo('Last Name:', yawoCollection?.loanCustomer?.lastName || '')}
                    {displayInfo('Phone Number:', yawoCollection?.loanCustomer?.phoneNumber?.number || '')}
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">PHOTOS</h5>
                    <div style={{ display: 'flex', gap: '1rem', width: '100%' }}>
                      <div style={{ width: '45%' }}>
                        <div style={{ fontSize: 'small' }}>Agent photo</div>
                        <img
                          src={yawoCollection?.loanAgent?.photo}
                          style={{ maxWidth: '100%' }}
                          onClick={toggleViewer}
                        />
                      </div>
                      <div style={{ width: '45%' }}>
                        <div style={{ fontSize: 'small' }}>Customer photo</div>
                        <img
                          src={yawoCollection?.loanCustomer?.photo}
                          style={{ maxWidth: '100%' }}
                          onClick={toggleViewer}
                        />
                      </div>
                    </div>
                    <ImageViewer
                      isOpen={viewerIsOpen}
                      onClose={toggleViewer}
                      images={[yawoCollection?.loanAgent?.photo, yawoCollection?.loanCustomer?.photo]}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </Wrapper>
    </YawoLayout>
  );
};

ViewCollection.propTypes = {
  match: PropTypes.object.isRequired,
  yawoCollection: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSingleYawoCollection: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { yawoCollection, isLoading } = state.yawoCollection;
  return { yawoCollection, isLoading };
};

export default connect(mapStateToProps, { getSingleYawoCollection })(withRouter(ViewCollection));
