import React from 'react';
import DevicesLayout from 'src/components/devices/DevicesLayout';
import PropTypes from 'prop-types';
import AllPos from './AllPos';

const Pos = () => {
  return (
    <DevicesLayout>
      <AllPos />
    </DevicesLayout>
  );
};

Pos.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Pos;
