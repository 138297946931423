import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  .approved_text {
    color: ${(props) => props.theme.color.green};
  }
  .declined_text {
    color: ${(props) => props.theme.color.red};
  }
`;

function VirtualWalletRequestStatus({ status }) {
  const displayPending = () => <p>pending</p>;

  const displayApproved = () => <p className="approved_text">approved</p>;

  const displayDeclined = () => <p className="declined_text">declined</p>;

  return (
    <Wrapper>
      {status === 'pending' && displayPending()}
      {status === 'approved' && displayApproved()}
      {status === 'declined' && displayDeclined()}
    </Wrapper>
  );
}

VirtualWalletRequestStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default VirtualWalletRequestStatus;
