import { saveAs } from 'file-saver';

const DEFAULT_FILE_FORMAT = 'csv';
const DEFAULT_FILE_NAME = 'json-to-csv';
const BLOB_TYPE_TEXT = 'text/csv';
const BLOB_CHARSET_UTF8 = 'utf-8';
const COMMA = ',';

const saveCsv = ({ data, fileFormat, filename }) => {
  const blob = new Blob([data], {
    type: BLOB_TYPE_TEXT,
    charset: BLOB_CHARSET_UTF8,
  });
  saveAs(blob, [filename + '.' + fileFormat]);
};

const getBodyData = ({ data, fieldKeys, separator }) => {
  return data
    .map((row) => {
      return fieldKeys
        .map((key) => {
          if (key === 'tid') {
            return row?.meta?.terminalInformation?.tid || '';
          }
          if (key === 'mPan') {
            return row?.meta?.terminalInformation?.ejournals?.ejournal?.[0]?.mPan || '';
          }
          if (key === 'PaymentLogId') {
            return row?.meta?.data?.meta?.PaymentLogId || '';
          }
          if (Object.prototype.hasOwnProperty.call(row, key)) {
            if (row[key] instanceof Object) {
              // eslint-disable-next-line quotes
              return `"${JSON.stringify(row[key]).split('"').join("'")}"`;
            }
            return row[key];
          }
          return null;
        })
        .join(separator);
    })
    .join('\n');
};

const convertToCsv = ({ data, fieldKeys, headers, separator }) => {
  const body = getBodyData({ data, fieldKeys, separator }),
    header = headers.join(separator);
  return header + '\n' + body;
};

export const useJsonToCsv = () => {
  const saveAsCsv = ({
    data,
    fields,
    fileFormat = DEFAULT_FILE_FORMAT,
    filename = DEFAULT_FILE_NAME,
    separator = COMMA,
  }) => {
    const fieldKeys = Object.keys(fields);
    const headers = Object.values(fields);

    saveCsv({
      data: convertToCsv({ data, fieldKeys: fieldKeys, headers, separator }),
      fileFormat,
      filename,
    });
  };

  return { saveAsCsv };
};
