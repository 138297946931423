import React from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/common/table/Table';
import { transactionsTableHeader } from './transactionsTableHeader';
import TransactionIuwiSearch from './TransactionIuwiSearch';
import TransactionSearch from './TransactionSearch';
import { SelectInput } from 'src/components/common/inputs';
import { sortTableData } from 'src/utils/helpers';

const statusOptions = [
  { text: 'All', value: 'all' },
  { text: 'Success', value: 'success' },
  { text: 'Failed', value: 'failed' },
];

const typeOptions = [
  { text: 'All', value: 'all' },
  { text: 'Agent Onboarding', value: 'agent_onboarding' },
  { text: 'Loan Collection', value: 'loan_collection' },
  { text: 'Loan Disbursement', value: 'loan_disbursement' },
];

const TransactionsTable = ({ tableData, onPageChange, onLimitChange, status, setStatus, type, setType }) => {
  const leftHeader = (
    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center', flexWrap: 'wrap' }}>
      <div>
        <TransactionSearch />
      </div>
      <div>
        <TransactionIuwiSearch />
      </div>
    </div>
  );
  const rightHeader = (
    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
      <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <h4>Status:</h4>
        <div>
          <SelectInput options={statusOptions} value={status} onChange={setStatus} />
        </div>
      </div>
      <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <h4>Type:</h4>
        <div>
          <SelectInput options={typeOptions} value={type} onChange={setType} />
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <Table
        showTopNav={true}
        rightHeader={rightHeader}
        leftHeader={leftHeader}
        headers={transactionsTableHeader}
        data={tableData && tableData.data ? sortTableData(tableData.data, {}) : []}
        limit={tableData && tableData.limit}
        page={tableData && tableData.page}
        count={tableData && tableData.count}
        showNav={true}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </div>
  );
};

TransactionsTable.propTypes = {
  tableData: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  setType: PropTypes.func,
  status: PropTypes.string,
  setStatus: PropTypes.func,
};

export default TransactionsTable;
