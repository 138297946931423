import QS from 'qs';
import client from 'src/client';
import {
  ADD_FW_CARD_PAYMENTS,
  FW_CARD_PAYMENT_IS_LOADING,
  FW_CARD_PAYMENT_NOT_LOADING,
  SET_FW_CARD_PAYMENTS_PAGE,
  SET_FW_CARD_PAYMENTS_LIMIT,
  SET_FW_CARD_PAYMENTS_DATE,
  RESET_FW_CARD_PAYMENTS_DATE,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from './alertActions';
import { dateFormatYMD } from 'src/utils/helpers';

const { adminService } = client;

const fwCardPaymentIsLoading = (msg = 'FW Card Payment Loading') => {
  return (dispatch) => {
    dispatch({ type: FW_CARD_PAYMENT_IS_LOADING });
    dispatch(infoAlert(msg));
  };
};

const fwCardPaymentNotLoading = () => (dispatch) => {
  dispatch({ type: FW_CARD_PAYMENT_NOT_LOADING });
};

const addFwCardPayments = (fwCardPayments) => {
  return { type: ADD_FW_CARD_PAYMENTS, payload: fwCardPayments };
};

export const setFwCardPaymentsDate = (start_date, end_date) => {
  return { type: SET_FW_CARD_PAYMENTS_DATE, payload: { start_date, end_date } };
};

export const resetFwCardPaymentsDate = () => {
  return { type: RESET_FW_CARD_PAYMENTS_DATE };
};

export const setFwCardPaymentsPage = (page) => {
  return { type: SET_FW_CARD_PAYMENTS_PAGE, payload: page };
};

export const setFwCardPaymentsLimit = (limit) => {
  return { type: SET_FW_CARD_PAYMENTS_LIMIT, payload: limit };
};

const generatePath = (endPoint, fwCardPayments, query) => {
  if (fwCardPayments === null) {
    return endPoint;
  }

  const { start_date, end_date, limit = 20, page = 1 } = fwCardPayments;

  const queryString = QS.stringify({
    limit,
    page,
    startDate: dateFormatYMD(start_date),
    endDate: dateFormatYMD(end_date),
    ...query,
  });

  return `${endPoint}?${queryString}`;
};

export const getAllFwCardPayments = (query = {}) => {
  return (dispatch, getState) => {
    dispatch(fwCardPaymentIsLoading('Loading FW Card Payments'));
    const { fwCardPayments } = getState().fwCardPayment;
    const path = generatePath('/fw/card-payments', fwCardPayments, query);
    adminService
      .get(path)
      .then((res) => {
        dispatch(addFwCardPayments(res.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading FW Card Payments' })))
      .finally(() => dispatch(fwCardPaymentNotLoading()));
  };
};

// Resolve FW Card Payment

const resolveFwCardPaymentIsLoading = (msg = 'Resolving FW Card Payment') => {
  return (dispatch) => {
    return dispatch(infoAlert(msg));
  };
};

const resolveFwCardPaymentsNotLoading = () => (dispatch) => dispatch(clearAlert());

export const resolveFwCardPayments = (paymentRef, callback) => {
  return (dispatch) => {
    dispatch(resolveFwCardPaymentIsLoading());
    adminService
      .get(`/fw/card-payments/resolve/${paymentRef}`)
      .then(() => {
        // Manually update current FW card payment isResolved value in the FW cardPayment store
        // let { fwCardPayments } = getState().fwCardPayment || {};
        // const { data = [] } = fwCardPayments || {};
        // const currentFwCardPaymentIndex = data.findIndex((x) => x.ref === paymentRef);
        // if (currentFwCardPaymentIndex) {
        //   let currentFwCardPayment = data.slice().splice(currentFwCardPaymentIndex, 1)[0];
        //   currentFwCardPayment = { ...currentFwCardPayment, isResolved: true };
        //   data[currentFwCardPaymentIndex] = currentFwCardPayment;
        //   fwCardPayments = { ...fwCardPayments, data };
        //   const finalPayload = { data: { data: fwCardPayments } };
        //   dispatch(addFwCardPayments(finalPayload));
        // }
        // ---
        dispatch(successAlert('FW Card Payment Resolved'));
        setTimeout(() => {
          if (callback && callback.success) callback.success();
        }, 1000);
      })
      .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
      .finally(() => {
        setTimeout(() => {
          dispatch(resolveFwCardPaymentsNotLoading());
          if (callback && callback.always) callback.always();
        }, 1000);
      });
  };
};
