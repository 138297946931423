import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import ViewGuarantorComponent from 'src/components/yawo/guarantors/ViewGuarantor';

const ViewGuarantor = () => {
  return (
    <DashboardLayout>
      <ViewGuarantorComponent />
    </DashboardLayout>
  );
};

export default ViewGuarantor;
