import {
  YAWO_VENDOR_IS_LOADING,
  YAWO_VENDOR_NOT_LOADING,
  ADD_YAWO_VENDORS,
  ADD_YAWO_VENDOR,
  SET_YAWO_VENDOR_DATE,
  RESET_YAWO_VENDOR_DATE,
  SET_YAWO_VENDOR_LIMIT,
  SET_YAWO_VENDOR_PAGE,
  SET_YAWO_VENDOR_STATUS,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  yawoVendors: null,
  yawoVendor: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case YAWO_VENDOR_IS_LOADING:
      return { ...state, isLoading: true };
    case YAWO_VENDOR_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_YAWO_VENDORS:
      return { ...state, yawoVendors: action.payload };
    case ADD_YAWO_VENDOR:
      return { ...state, yawoVendor: action.payload };
    case SET_YAWO_VENDOR_DATE:
      return {
        ...state,
        yawoVendors: {
          ...state,
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
        },
      };
    case RESET_YAWO_VENDOR_DATE:
      return {
        ...state,
        terminals: {
          ...state,
          start_date: null,
          end_date: null,
        },
      };
    case SET_YAWO_VENDOR_LIMIT:
      return {
        ...state,
        yawoVendors: { ...state, limit: action.payload },
      };
    case SET_YAWO_VENDOR_PAGE:
      return {
        ...state,
        yawoVendors: { ...state, page: action.payload },
      };
    case SET_YAWO_VENDOR_STATUS:
      return {
        ...state,
        yawoVendors: { ...state, status: action.payload },
      };
    default:
      return state;
  }
}
