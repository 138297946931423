import React from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/common/table/Table';
import { terminalsTableHeader } from './terminalsTableHeader';
import { sortTableData } from 'src/utils/helpers';
import TerminalsSearch from './TerminalsSearch';

const TerminalsTable = ({ tableData, onPageChange, onLimitChange }) => {
  const leftHeader = <TerminalsSearch />;
  const rightHeader = null;

  return (
    <div>
      <Table
        headers={terminalsTableHeader}
        rightHeader={rightHeader}
        leftHeader={leftHeader}
        data={tableData && tableData.data ? sortTableData(tableData.data, {}) : []}
        limit={tableData && tableData.limit}
        page={tableData && tableData.page}
        count={tableData && tableData.count}
        showNav={true}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </div>
  );
};

TerminalsTable.defaultProps = {
  tableData: { data: [], limit: 0, page: 1, count: 0 },
};

TerminalsTable.propTypes = {
  tableData: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
};

export default TerminalsTable;
