import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import CardPaymentsComponent from 'src/components/notifications/cards/CardPayments';

const CardPayments = () => {
  return (
    <DashboardLayout>
      <CardPaymentsComponent />
    </DashboardLayout>
  );
};

export default CardPayments;
