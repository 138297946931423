import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styled from 'styled-components';
import { formatAmount, dateFormatYMD, formatTime } from 'src/utils/helpers';
import Icon from 'src/components/common/Icon';
import { connect } from 'react-redux';
import { getTransaction } from 'src/store/actions/transactionActions';
import Loader from 'src/components/notification/Loader';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    display: flex;
    justify-content: center;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .body {
    background-color: ${(props) => props.theme.color.lightGrey};
    padding: 1rem;
    margin: 0.5rem;
  }
  .item {
    display: flex;
    justify-content: space-between;
    color: black;
    margin-bottom: 0.5rem;
    grid-gap: 1rem;
  }
  .value {
    color: black;
    font-weight: bold;
    font-size: 0.9rem;
  }
`;

const ViewTransaction = ({ transactionRef, transaction, getTransaction, isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      getTransaction(transactionRef);
    }
  }, [isOpen, getTransaction, transactionRef]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Icon Icon={OpenInNewIcon} alt="View Transaction" onClick={toggleModal} />
      <Modal isOpen={isOpen} handleClose={toggleModal}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h6>Transaction Details</h6>
            </div>

            <CloseIcon onClick={toggleModal} className="cancel" />
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="body">
              <div className="item">
                <p>Amount</p>
                <h6 className="value">{formatAmount(transaction && transaction.amount)}</h6>
              </div>
              <div className="item">
                <p>Transaction Type</p>
                <h6 className="value">{transaction && transaction.type}</h6>
              </div>
              <div className="item">
                <p>Platform</p>
                <h6 className="value">{transaction && transaction.platform.toUpperCase()}</h6>
              </div>
              <div className="item">
                <p>User</p>
                <Link className="value" to={`/users/${transaction && transaction.userId}`}>
                  {transaction && transaction.user}
                </Link>
              </div>
              <div className="item">
                <p>Email</p>
                <h6 className="value">{transaction && transaction.email}</h6>
              </div>
              <div className="item">
                <p>Date</p>
                <h6 className="value">{transaction && dateFormatYMD(transaction.timeCreated)}</h6>
              </div>
              <div className="item">
                <p>Time</p>
                <h6 className="value">{transaction && formatTime(transaction.timeCreated)}</h6>
              </div>
              <div className="item">
                <p>Status</p>
                <h6 className="value">{transaction && transaction.status}</h6>
              </div>
              <div className="item">
                <p>Transaction Reference</p>
                <h6 className="value">{transaction && transaction.transactionRef}</h6>
              </div>
              <div className="item">
                <p>Transaction Fee</p>
                <h6 className="value">{formatAmount(Number(transaction && transaction.totalCharge))}</h6>
              </div>
              {transaction && transaction.type === 'POS_WALLET_TOPUP' && (
                <React.Fragment>
                  <div className="item">
                    <p>Vendor Reference</p>
                    <h6 className="value">{transaction && transaction.vendorRef}</h6>
                  </div>
                  <div className="item">
                    <p>Card</p>
                    <h6 className="value">{transaction && transaction.card}</h6>
                  </div>
                </React.Fragment>
              )}
              {transaction && transaction.billingType === 'AIRTIME' && (
                <React.Fragment>
                  <div className="item">
                    <p>Phone Number</p>
                    <h6 className="value">{transaction && transaction.meta.phoneNumber}</h6>
                  </div>
                  <div className="item">
                    <p>Product Code</p>
                    <h6 className="value">{transaction && transaction.meta.productCode}</h6>
                  </div>
                </React.Fragment>
              )}
              {transaction && transaction.type === 'CASHOUT' && (
                <React.Fragment>
                  <div className="item">
                    <p>Account Number</p>
                    <h6 className="value">{transaction && transaction.meta.accountNumber}</h6>
                  </div>
                  <div className="item">
                    <p>Account Bank</p>
                    <h6 className="value">{transaction && transaction.meta.accountBank}</h6>
                  </div>
                  <div className="item">
                    <p>Receiver</p>
                    <h6 className="value">{transaction && transaction.receiver}</h6>
                  </div>
                </React.Fragment>
              )}
              {transaction && transaction.device && (
                <div className="item">
                  <p>Device</p>
                  <h6 className="value">{transaction.device}</h6>
                </div>
              )}
            </div>
          )}
        </Wrapper>
      </Modal>
    </div>
  );
};

ViewTransaction.propTypes = {
  transaction: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getTransaction: PropTypes.func.isRequired,
  transactionRef: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading, transaction } = state.transaction;
  return { isLoading, transaction };
};

export default connect(mapStateToProps, { getTransaction })(ViewTransaction);
