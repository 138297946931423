import React from 'react';
import PropTypes from 'prop-types';
import './ModalTwo.css';

const ModalTwo = ({
  id,
  title,
  children,
  footer,
  size,
  active,
  centered,
  onModalCloseCallback = null,
  transparent,
}) => {
  const modalSizes = {
    small: 'sm',
    medium: 'md',
    large: 'lg',
    full: 'full',
  };

  const modalCloseHash = '#';

  const closeModal = () => {
    window.location.replace(window.location.pathname);
    setTimeout(() => {
      window.location.replace(window.location.pathname);
      if (onModalCloseCallback) onModalCloseCallback();
    }, 100);
  };

  return (
    <div
      id={id || 'modal-id'}
      className={`${size ? `modal modal-${modalSizes[size]}` : 'modal'}${active ? ' active' : ''}${
        centered ? ' centered' : ''
      }${transparent ? ' transparent' : ''}`}
    >
      {id ? (
        <a href={modalCloseHash} className="modal-overlay" onClick={closeModal} />
      ) : (
        <span className="modal-overlay" onClick={onModalCloseCallback} />
      )}
      <div className="modal-container">
        {title && (
          <div className="modal-header">
            {id ? (
              <a href={modalCloseHash} className="modal-close-btn" aria-label="Close" onClick={closeModal}>
                ✕
              </a>
            ) : (
              <button type="button" className="modal-close-btn" aria-label="Close" onClick={onModalCloseCallback}>
                ✕
              </button>
            )}
            <div className="modal-title">{title || ' '}</div>
          </div>
        )}
        <div className="modal-body">{children}</div>
        {footer && <div className="modal-footer">{footer || ' '}</div>}
      </div>
    </div>
  );
};

ModalTwo.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  footer: PropTypes.node,
  size: PropTypes.string,
  active: PropTypes.bool,
  centered: PropTypes.bool,
  onModalCloseCallback: PropTypes.func,
  transparent: PropTypes.bool,
};

export default ModalTwo;
