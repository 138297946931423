/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { uploadMmoLogo } from 'src/store/actions/mmoActions';
import { errorAlert, clearAlert } from 'src/store/actions/alertActions';
import { bytesToMegaBytes } from 'src/utils/helpers';

const Wrapper = styled.div`
  h2 {
    font-size: x-large;
  }

  .file-dropzone {
    position: relative;
    height: 150px;

    .file-dropzone--remove-file {
      position: absolute;
      top: 10px;
      right: 10px;
      appearance: none;
      border: 0;
      outline: none;
      background-color: #dd0000;
      color: white;
      cursor: pointer;
      width: 20px;
      height: 20px;
      font-size: 20px;
      line-height: 20px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      z-index: 2;
    }

    input[type='file'] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 150px;
      opacity: 0;
      background-color: transparent;
      cursor: pointer;
      z-index: 1;

      &:hover {
        & + .file-dropzone--ui {
          opacity: 1;
        }
      }
    }

    .file-dropzone--ui {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 150px;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
`;

const UploadMmoLogo = ({ mmo, uploadMmoLogo, getSingleMmo, errorAlert, clearAlert }) => {
  if (!mmo) return null;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [logo, setLogo] = useState(null);
  const onFileChange = (e) => setLogo(e.target.files[0] || null);
  const resetFile = () => {
    setLogo(null);
    document.getElementById('file').files = new DataTransfer().files;
  };

  const uploadImageFile = () => {
    if (logo) {
      const imageSizeInMb = bytesToMegaBytes(logo.size);
      if (imageSizeInMb > 2) {
        errorAlert({ msg: 'Image size is too large!' });
        return setTimeout(() => {
          clearAlert();
        }, 3000);
      }
    }

    const imageFormData = new FormData();
    imageFormData.append('shortCode', mmo.shortCode);
    imageFormData.append('image', logo, logo.name);

    setIsSubmitting(true);
    uploadMmoLogo(imageFormData, {
      always: () => setIsSubmitting(false),
      success: () => {
        if (getSingleMmo) getSingleMmo(mmo.shortCode, { silently: true });
      },
    });
  };

  return (
    <Wrapper>
      <div className="file-dropzone">
        {logo ? (
          <img
            width="150px"
            height="150px"
            src={URL.createObjectURL(logo)}
            alt="..."
            title={logo.name}
            style={{ objectFit: 'contain' }}
          />
        ) : (
          <img
            width="150px"
            height="150px"
            src={mmo.logo || 'https://via.placeholder.com/150/999/FFF/?text=NO+LOGO'}
            alt="logo"
            style={{ objectFit: 'contain' }}
          />
        )}
        {logo && (
          <button type="button" className="file-dropzone--remove-file" onClick={resetFile}>
            &times;
          </button>
        )}
        <input id="file" type="file" onChange={onFileChange} accept="image/*" required />
        <div className="file-dropzone--ui">click to change</div>
      </div>

      <br />

      {logo ? (
        <Button
          type="button"
          text={isSubmitting ? 'Uploading ...' : 'Upload Logo'}
          disabled={isSubmitting}
          onClick={uploadImageFile}
        />
      ) : null}
    </Wrapper>
  );
};

UploadMmoLogo.propTypes = {
  mmo: PropTypes.object,
  uploadMmoLogo: PropTypes.func.isRequired,
  errorAlert: PropTypes.func.isRequired,
  clearAlert: PropTypes.func.isRequired,
  getSingleMmo: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { mmo } = state.mmo;
  return { mmo };
};

export default connect(mapStateToProps, { uploadMmoLogo, errorAlert, clearAlert })(UploadMmoLogo);
