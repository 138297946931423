import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getAllPtsps, setPtspsLimit, setPtspsPage, fetchCardTypes, fetchPtspOptions } from 'src/store/actions/ptspActions';
import PtspsTable from './PtspsTable';
import NotificationsLayout from 'src/components/notifications/NotificationsLayout';
import { SelectInput } from 'src/components/common/inputs';
import CreatePtsp from './CreatePtsp';
import ResetPriceSettings from './ResetPriceSettings';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  height: max-content;
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .flex-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    grid-gap: 1rem;
    margin-left: auto;
  }
  .notification-filters__wrapper {
    display: flex;
    justify-content: space-between;
  }
  .notification-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .notification-filter {
    display: grid;
    width: max-content;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 0.5rem;
  }
`;

// eslint-disable-next-line no-unused-vars
const Ptsps = ({ ptsps, getAllPtsps, setPtspsLimit, setPtspsPage, cardTypes, fetchCardTypes, fetchPtspOptions, }) => {
  useEffect(() => {
    fetchCardTypes();
    fetchPtspOptions();
  }, []);

  const [cardType, setCardType] = useState('all');

  const getQuery = () => ({
    cardType: cardType !== 'all' ? cardType : undefined,
  });

  const getPtsps = (params) => {
    const { resetSearchString = false } = params || {};
    const query = getQuery();
    if (resetSearchString) {
      query.search = undefined;
    }
    getAllPtsps(query);
  };

  useEffect(() => {
    setPtspsPage(1);
    setPtspsLimit(20);
    getPtsps();
  }, [cardType]);

  const onPageChange = (page) => {
    setPtspsPage(page);
    getPtsps();
  };

  const onLimitChange = (limit) => {
    setPtspsLimit(limit);
    getPtsps();
  };

  const cardTypeOptions = [
    {
      text: 'All',
      value: 'all',
    },
    ...cardTypes.map((cardType) => ({
      text: cardType,
      value: cardType,
    })),
  ];

  return (
    <NotificationsLayout>
      <Wrapper>
        <div className="flex-right">
          <CreatePtsp />
          <ResetPriceSettings />
        </div>
        <div className="notification-filters__wrapper">
          <div className="notification-filters">
            <div className="notification-filter">
              <h4>Card Type:</h4>
              <SelectInput options={cardTypeOptions} value={cardType} onChange={setCardType} />
            </div>
          </div>
        </div>
        <PtspsTable tableData={ptsps} onPageChange={onPageChange} onLimitChange={onLimitChange} />
      </Wrapper>
    </NotificationsLayout>
  );
};

Ptsps.propTypes = {
  platforms: PropTypes.array.isRequired,
  ptsps: PropTypes.object.isRequired,
  getAllPtsps: PropTypes.func.isRequired,
  setPtspsLimit: PropTypes.func.isRequired,
  setPtspsPage: PropTypes.func.isRequired,
  fetchCardTypes: PropTypes.func.isRequired,
  fetchPtspOptions: PropTypes.func.isRequired,
  cardTypes: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { ptsps, cardTypes = [] } = state.ptsp;
  return { ptsps, cardTypes };
};

export default connect(mapStateToProps, {
  getAllPtsps,
  setPtspsPage,
  setPtspsLimit,
  fetchCardTypes,
  fetchPtspOptions,
})(Ptsps);
