import {
  ISSUES_IS_LOADING,
  ISSUES_NOT_LOADING,
  ADD_ISSUES,
  CLEAR_ISSUES,
  SET_ISSUES_PAGE,
  SET_ISSUES_LIMIT,
  ADD_ISSUE,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  allIssues: {
    data: [],
    limit: 20,
    page: 1,
  },
  issue: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ISSUES_IS_LOADING:
      return { ...state, isLoading: true };
    case ISSUES_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_ISSUES:
      return { ...state, allIssues: action.payload };
    case ADD_ISSUE:
      return { ...state, issue: action.payload };
    case CLEAR_ISSUES:
      return { ...state, allIssues: initialState.allIssues };
    case SET_ISSUES_LIMIT:
      return {
        ...state,
        allIssues: { ...state.allIssues, limit: action.payload },
      };
    case SET_ISSUES_PAGE:
      return {
        ...state,
        allIssues: { ...state.allIssues, page: action.payload },
      };
    default:
      return state;
  }
}
