import {
  TERMINAL_IS_LOADING,
  TERMINAL_NOT_LOADING,
  ADD_TERMINALS,
  ADD_TERMINAL,
  CLEAR_TERMINAL,
  SET_TERMINAL_DATE,
  RESET_TERMINAL_DATE,
  SET_TERMINAL_LIMIT,
  SET_TERMINAL_PAGE,
  SET_TERMINAL_STATUS,
  SET_TERMINAL_TYPES,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  terminals: null,
  terminal: null,
  terminalTypes: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TERMINAL_IS_LOADING:
      return { ...state, isLoading: true };
    case TERMINAL_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_TERMINALS:
      return { ...state, terminals: action.payload };
    case ADD_TERMINAL:
      return { ...state, terminal: action.payload };
    case CLEAR_TERMINAL:
      return { ...state, terminal: initialState.terminal };
    case SET_TERMINAL_DATE:
      return {
        ...state,
        terminals: {
          ...state.terminals,
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
        },
      };
    case RESET_TERMINAL_DATE:
      return {
        ...state,
        terminals: {
          ...state.terminals,
          start_date: null,
          end_date: null,
        },
      };
    case SET_TERMINAL_LIMIT:
      return {
        ...state,
        terminals: { ...state.terminals, limit: action.payload },
      };
    case SET_TERMINAL_PAGE:
      return {
        ...state,
        terminals: { ...state.terminals, page: action.payload },
      };
    case SET_TERMINAL_STATUS:
      return {
        ...state,
        terminals: { ...state.terminals, status: action.payload },
      };
    case SET_TERMINAL_TYPES:
      return { ...state, terminalTypes: action.payload };
    default:
      return state;
  }
}
