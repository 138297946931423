import React from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/common/table/Table';
import { transactionsHeader } from 'src/components/transactions/transactionHeaders';
import { sortTableData } from 'src/utils/helpers';
import { SelectInput } from 'src/components/common/inputs';

const subOptions = {
  withdrawals: [
    { text: 'Select Type', value: ' ' },
    { text: 'Pos', value: 'pos' },
    { text: 'Card', value: 'card' },
  ],
  transfers: [
    { text: 'Select Type', value: ' ' },
    { text: 'Peer', value: 'p2p' },
    { text: 'Cashout', value: 'bank' },
  ],
};

const statusOptions = [
  { text: 'All', value: 'all' },
  { text: 'Success', value: 'success' },
  { text: 'Failed', value: 'failed' },
  { text: 'Pending', value: 'pending' },
  { text: 'Refunded', value: 'refunded' },
];

const topNavOptions = [
  { title: 'Overview', value: 'overview' },
  { title: 'Withdrawals', value: 'withdrawals' },
  { title: 'Transfers', value: 'transfers' },
  { title: 'Airtime', value: 'airtime' },
  { title: 'Data', value: 'data' },
  { title: 'Tv Cable', value: 'tv-cable' },
  { title: 'PHCN', value: 'electricity' },
  { title: 'Collections', value: 'collection' },
];

const UserTransactionsTable = ({
  type,
  setType,
  tableData,
  onPageChange,
  onLimitChange,
  subType,
  setSubType,
  onStatusChange,
}) => {
  const tableKey = {
    Amount: 'amount',
    Type: 'type',
    Status: 'status',
    Date: 'timeCreated',
    Time: 'timeCreated',
  };

  const leftHeader = subOptions[type] && (
    <SelectInput options={subOptions[type]} value={subType} onChange={setSubType} />
  );

  const rightHeader = (
    <SelectInput
      value={tableData === null ? 'all' : tableData.status}
      onChange={onStatusChange}
      options={statusOptions}
    />
  );

  return (
    <div>
      <Table
        headers={transactionsHeader}
        topNavOptions={topNavOptions}
        topNavIsActive={type}
        onTopNavChange={setType}
        leftHeader={leftHeader}
        rightHeader={rightHeader}
        showNav={true}
        showTopNav={true}
        data={tableData === null ? [] : sortTableData(tableData.data, tableKey)}
        limit={tableData === null ? 20 : tableData.limit}
        page={tableData === null ? 1 : tableData.page}
        count={tableData === null ? 1 : tableData.count}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </div>
  );
};

UserTransactionsTable.propTypes = {
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
  tableData: PropTypes.array.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  leftHeader: PropTypes.any,
  rightHeader: PropTypes.any,
  subType: PropTypes.string,
  setSubType: PropTypes.func,
  onStatusChange: PropTypes.func.isRequired,
};

export default UserTransactionsTable;
