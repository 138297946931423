import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import YawoLayout from 'src/components/yawo/YawoLayout';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from 'styled-components';
import Icon from 'src/components/common/Icon';
import { withRouter } from 'react-router-dom';
import { getSingleYawoGuarantor } from 'src/store/actions/yawo/guarantorActions';
import { connect } from 'react-redux';
import { formatDate } from 'src/utils/helpers';
import ActivateGuarantor from './ActivateGuarantor';
import SuspendGuarantor from './SuspendGuarantor';
import VerifyGuarantor from './VerifyGuarantor';
import ImageViewer from 'src/components/common/ImageViewer';

const Wrapper = styled.div`
  .back {
    display: flex;
    width: max-content;
    grid-gap: 1rem;
    align-items: center;
  }
  .section {
    border-top: 1px solid grey;
    min-height: 10rem;
    margin-top: 1rem;
    padding: 1rem 0rem;
  }
  .avatar {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
  }
  .img {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    margin: 0;
    padding: 0;
  }
  .profile {
    display: flex;
    grid-gap: 1rem;
    align-items: center;
  }
  .details_body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }

  .main_body {
    padding: 1rem;
    background-color: ${(props) => props.theme.color.white};
    border-radius: 0.5rem;
    min-height: calc(100vh - 6rem);

    & > :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .header_text {
    text-align: center;
  }
  .mmo_body {
    padding: 0.5rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
  .left {
    border-right: 1px solid ${(props) => props.theme.color.grey};
  }
  .section_title {
    color: ${(props) => props.theme.color.deepGrey};
    margin-top: 1rem;
  }
  .item {
    display: flex;
    padding-top: 0.5rem;
  }
  .item_title {
    width: 10rem;
  }
  .item_value {
    color: ${(props) => props.theme.color.deepGrey};
  }
`;

const ViewGuarantor = ({ match, getSingleYawoGuarantor, yawoGuarantor, isLoading }) => {
  const { guarantorId } = match.params;
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const toggleViewer = () => setViewerIsOpen(!viewerIsOpen);

  useEffect(() => {
    getSingleYawoGuarantor(guarantorId);
  }, [guarantorId]);

  const displayInfo = (title, value) => (
    <div className="item">
      <h4 className="item_title">{title || ''}</h4>
      <h4 className="item_value">
        {value || ''}
        {value && value[0] === '#' && (
          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              background: `${value}`,
              margin: 'auto 5px',
            }}
          />
        )}
      </h4>
    </div>
  );

  return (
    <YawoLayout>
      <Wrapper>
        <div className="main_body">
          <h2 className="header_text">Yawo Guarantor Details</h2>
          <div className="back">
            <Icon Icon={ArrowBackIcon} type="link" to="/yawo/guarantors" alt="Back to Yawo Guarantors" text="Back" />
            <span>Back</span>
          </div>
          <div className="mmo_body">
            {isLoading ? (
              <React.Fragment />
            ) : yawoGuarantor === null ? (
              <div>Yawo Guarantor Not Found</div>
            ) : (
              <React.Fragment>
                <div className="left">
                  <div style={{ display: 'flex' }}>
                    <div>
                      {displayInfo('First Name:', yawoGuarantor.firstName || '')}
                      {displayInfo('Last Name:', yawoGuarantor.lastName || '')}
                      {displayInfo('Other Names:', yawoGuarantor.otherNames || '')}
                      {displayInfo('Vendor:', yawoGuarantor?.vendor?.shortCode || '')}
                      <br />
                      <div style={{ display: 'flex', gap: '1rem' }}>
                        {yawoGuarantor?.adminVerification?.isVerified ? (
                          <>
                            {yawoGuarantor.isActive ? (
                              <SuspendGuarantor guarantorId={yawoGuarantor._id} />
                            ) : (
                              <ActivateGuarantor guarantorId={yawoGuarantor._id} />
                            )}
                          </>
                        ) : (
                          <VerifyGuarantor guarantorId={yawoGuarantor._id} />
                        )}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">GENERAL INFO</h5>
                    {displayInfo('Yawo Wallet:', yawoGuarantor.yawoWallet || '')}
                    {displayInfo('Is Vendor Verified:', yawoGuarantor?.vendor?.isVerified ? '✅' : '❌')}
                    {displayInfo('Is Admin Verified:', yawoGuarantor?.adminVerification?.isVerified ? '✅' : '❌')}
                    {displayInfo('Is Active:', yawoGuarantor.isActive ? '✅' : '❌')}
                    {displayInfo('Residential Address', '')}
                    {displayInfo('Address House No.:', yawoGuarantor?.address?.houseNumber || '')}
                    {displayInfo('Address Street Address:', yawoGuarantor?.address?.streetAddress || '')}
                    {displayInfo('Address City:', yawoGuarantor?.address?.city || '')}
                    {displayInfo('Address State:', yawoGuarantor?.address?.state || '')}
                    {displayInfo('Address Zip Code:', yawoGuarantor?.address?.zipCode || '')}
                    {displayInfo('Email:', yawoGuarantor?.email?.address || '')}
                    {displayInfo('Phone:', yawoGuarantor?.phoneNumber?.number || '')}
                    {displayInfo(
                      'Created At:',
                      yawoGuarantor.createdAt && yawoGuarantor.createdAt !== 'None'
                        ? formatDate(yawoGuarantor.createdAt)
                        : ''
                    )}
                    {displayInfo(
                      'Updated At:',
                      yawoGuarantor.updatedAt && yawoGuarantor.updatedAt !== 'None'
                        ? formatDate(yawoGuarantor.updatedAt)
                        : ''
                    )}
                  </div>
                </div>
                <div>
                  <div>
                    <h5 className="section_title">BANK DETAILS</h5>
                    {displayInfo('Account Number:', yawoGuarantor?.bank?.accountNumber || '')}
                    {displayInfo('Account Name:', yawoGuarantor?.bank?.accountName || '')}
                    {displayInfo('Bank:', yawoGuarantor?.bank?.bankName || '')}
                    {displayInfo('BVN:', yawoGuarantor?.bvn?.number || '')}
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">CUSTOMER INFO</h5>
                    {displayInfo(
                      'Name:',
                      `${yawoGuarantor?.loanCustomer?.firstName}  ${yawoGuarantor?.loanCustomer?.lastName}` || ''
                    )}
                    {displayInfo('Phone:', yawoGuarantor?.loanCustomer?.phoneNumber?.number || '')}
                  </div>
                  <br />
                  <div>
                    <h5 className="section_title">PHOTO(S)</h5>
                    <div className="item">
                      <h4 className="item_title">Guarantor Photo:</h4>
                    </div>
                    <div>
                      <img src={yawoGuarantor?.photo} style={{ maxWidth: '100%' }} onClick={toggleViewer} />
                    </div>
                    <ImageViewer isOpen={viewerIsOpen} onClose={toggleViewer} images={[yawoGuarantor?.photo]} />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </Wrapper>
    </YawoLayout>
  );
};

ViewGuarantor.propTypes = {
  match: PropTypes.object.isRequired,
  yawoGuarantor: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSingleYawoGuarantor: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { yawoGuarantor, isLoading } = state.yawoGuarantor;
  return { yawoGuarantor, isLoading };
};

export default connect(mapStateToProps, { getSingleYawoGuarantor })(withRouter(ViewGuarantor));
