import React from 'react';
import { formatDate, formatTime } from 'src/utils/helpers';
import ApproveRequest from './ApproveRequest';
import DeclineRequest from './DeclineRequest';
import VirtualWalletRequestStatus from './VirtualWalletRequestStatus';
import ViewVirtualWalletRequest from './ViewVirtualWalletRequest';

export const VirtualWalletRequestsTableHeader = [
  {
    title: function title() {
      return <p>Name</p>;
    },
    render: function DateTaken(rowData) {
      return (
        <p>
          {rowData['firstName']} {rowData['lastName']}
        </p>
      );
    },
  },
  {
    title: function title() {
      return <p>Email</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['email']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Phone</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['phone']}</p>;
    },
  },
  {
    title: function title() {
      return <p>BVN</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['bvn']}</p>;
    },
  },
  {
    title: function title() {
      return <p>MMO</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['platform']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function DateTaken(rowData) {
      return <VirtualWalletRequestStatus status={rowData['status']} />;
    },
  },
  {
    title: function title() {
      return <p>Vendor</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{rowData['vendor']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Date</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{formatDate(rowData['timeCreated'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Time</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{formatTime(rowData['timeCreated'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Action</p>;
    },
    render: function SerialNumber(rowData) {
      return (
        <>
          <ViewVirtualWalletRequest virtualWalletRequest={rowData} />
          {rowData['status'] === 'pending' && (
            <div style={{ display: 'flex' }}>
              <ApproveRequest requestId={rowData['_id']} />
              <DeclineRequest requestId={rowData['_id']} />
            </div>
          )}
        </>
      );
    },
  },
];
