import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import ModalTwo from 'src/components/common/ModalTwo';
import { TextInput, SelectInput } from 'src/components/common/inputs';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { updateStaff } from 'src/store/actions/staffActions';

const Wrapper = styled.div`
  h2 {
    font-size: x-large;
  }

  .form-section-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    align-items: end;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .long {
      grid-column-end: span 1;
    }

    .longer {
      grid-column-end: span 2;

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .longest {
      grid-column-end: span 3;

      @media (max-width: 768px) {
        grid-column-end: span 2;
      }

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .align-start {
      align-self: start;
    }

    .align-center {
      align-self: center;
    }

    .align-end {
      align-self: end;
    }

    .justify-end {
      justify-self: end;
    }
  }

  .input-staff {
    height: 2.2rem;
    width: 100%;
    padding: 0rem 0.5rem;
    outline: 0px;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.color.grey};
    :focus {
      border: 1px solid ${(props) => props.theme.color.grey};
    }
  }

  .label {
    color: ${(props) => props.theme.color.black};
    min-width: max-content;
  }
`;

const EditStaff = ({ roles, staff, staffDepartments, updateStaff }) => {
  if (!staff) return null;
  if (!roles) return null;
  if (!staffDepartments) return null;

  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [firstName, setFirstName] = useState(staff.firstName || '');
  const [lastName, setLastName] = useState(staff.lastName || '');
  const [email, setEmail] = useState(staff.email?.address || '');
  const [department, setDepartment] = useState(staff.department || '');
  const [role, setRole] = useState(staff.role || '');
  const [phone, setPhone] = useState(staff.phone?.number || '');

  const setFormState = (staff) => {
    setFirstName(staff.firstName || '');
    setLastName(staff.lastName || '');
    setEmail(staff.email?.address || '');
    setDepartment(staff.department || '');
    setRole(staff.role || '');
    setPhone(staff.phone?.number || '');
  };

  useEffect(() => {
    setFormState(staff);
  }, [staff]);

  const submitForm = (e) => {
    e.preventDefault();

    const data = {
      firstName,
      lastName,
      email,
      department,
      role,
      phone,
    };

    setIsSubmitting(true);
    updateStaff(staff.staffId, data, {
      always: () => {
        setIsSubmitting(false);
      },
      success: () => {
        setShow(false);
      },
    });
  };

  const { data: roleData = [] } = roles || {};
  const roleOptions = Array.isArray(roleData)
    ? roleData.map((role) => ({ ...role, text: role.name, value: role.roleId }))
    : [];
  const departmentOptions = Array.isArray(staffDepartments)
    ? staffDepartments.map((dept) => ({ text: dept, value: dept }))
    : [];

  return (
    <Wrapper>
      <Button text="Edit Staff" onClick={toggleShow} />

      <ModalTwo size="large" active={show} onModalCloseCallback={toggleShow}>
        <div>
          <center>
            <h2>Edit Staff</h2>
          </center>

          <br />

          <form id="updateStaffForm" onSubmit={submitForm} className="form-section-grid">
            <div>
              <TextInput label="First Name" value={firstName} onChange={setFirstName} required />
            </div>

            <div>
              <TextInput label="Last Name" value={lastName} onChange={setLastName} required />
            </div>

            <div>
              <TextInput label="Email" value={email} onChange={setEmail} required />
            </div>

            <div>
              <SelectInput
                label="Select Department"
                options={departmentOptions}
                value={department}
                onChange={setDepartment}
                required
              />
            </div>

            <div>
              <SelectInput label="Select Role" options={roleOptions} value={role} onChange={setRole} required />
            </div>

            <div>
              <TextInput label="Phone" value={phone} onChange={setPhone} />
            </div>

            <div className="longest justify-end">
              <Button
                type="submit"
                text={isSubmitting ? 'Updating Staff ...' : 'Update Staff'}
                disabled={isSubmitting}
              />
            </div>
          </form>
        </div>
      </ModalTwo>
    </Wrapper>
  );
};

EditStaff.propTypes = {
  roles: PropTypes.object.isRequired,
  staff: PropTypes.object,
  staffDepartments: PropTypes.array.isRequired,
  updateStaff: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { roles } = state.role;
  const { staff, staffDepartments } = state.staff;
  return { roles, staff, staffDepartments };
};

export default connect(mapStateToProps, { updateStaff })(EditStaff);
