import React from 'react';
import PropTypes from 'prop-types';

const SelectRolePermissions = ({ rolePermissions, role = {} }) => {
  if (Object.entries(rolePermissions || {}).length === 0) return null;

  return (
    <React.Fragment>
      {Object.entries(rolePermissions).map((category) => {
        return category[1].map((permission, index) => {
          return (
            <React.Fragment key={index}>
              {index === 0 && <div className="longest">{category[0]}</div>}
              <div>
                <label htmlFor={permission.code}>
                  <input
                    id={permission.code}
                    type="checkbox"
                    value={permission.code}
                    name="permissions[]"
                    defaultChecked={role.permissions && role.permissions.some((x) => x === permission.code)}
                  />
                  <span>{permission.name}</span>
                </label>
              </div>
            </React.Fragment>
          );
        });
      })}
    </React.Fragment>
  );
};

SelectRolePermissions.propTypes = {
  rolePermissions: PropTypes.object.isRequired,
  role: PropTypes.object,
};

export default SelectRolePermissions;
