import React from 'react';
import { formatDate, formatTime, formatAmount } from 'src/utils/helpers';
import RefreshNotification from './RefreshNotification';

export const notificationsTableHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Terminal ID</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['tid']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Vendor Ref</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['ref']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Amount</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['amount'] ? formatAmount(rowData['amount']) : ''}</p>;
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function DateTaken(rowData) {
      return rowData['status'] === 'PENDING' ? (
        <p style={{ color: 'red' }}>{rowData['status']}</p>
      ) : (
        <p>{rowData['status']}</p>
      );
    },
  },
  {
    title: function title() {
      return <p>Time Created</p>;
    },
    render: function SerialNumber(rowData) {
      return (
        <p>
          {rowData['timeCreated'] && rowData['timeCreated'] !== 'None'
            ? `${formatDate(rowData['timeCreated'])} ${formatTime(rowData['timeCreated'])}`
            : ''}
        </p>
      );
    },
  },
  {
    title: function title() {
      return <p>Refresh</p>;
    },
    render: function SerialNumber(rowData) {
      return <RefreshNotification notificationId={rowData['_id']} />;
    },
  },
];
