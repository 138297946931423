import client from 'src/client';
import {
  ISSUES_IS_LOADING,
  ISSUES_NOT_LOADING,
  ADD_ISSUES,
  SET_ISSUES_PAGE,
  SET_ISSUES_LIMIT,
  ADD_ISSUE,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from './alertActions';

const { adminService } = client;

const issuesIsLoading =
  (msg = 'Loading') =>
  (dispatch) => {
    dispatch({ type: ISSUES_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const issuesNotLoading = () => (dispatch) => {
  dispatch({ type: ISSUES_NOT_LOADING });
  dispatch(clearAlert());
};

const addIssues = (issues) => {
  return { type: ADD_ISSUES, payload: issues };
};

const generatePath = (endPoint, platform) => {
  if (platform !== 'ikonik') {
    return `${endPoint}&mmo=${platform}`;
  }
  return endPoint;
};

export const getIssues = (status, platform) => (dispatch, getState) => {
  dispatch(issuesIsLoading('Loading Issues'));
  const { limit, page } = getState().issue.allIssues || {};
  const endPoint = `/issues?status=${status.toUpperCase()}&page=${page}&limit=${limit}`;
  adminService
    .get(generatePath(endPoint, platform))
    .then((res) => {
      dispatch(addIssues(res.data));
      dispatch(issuesNotLoading());
    })
    .catch(() => {
      dispatch(errorAlert({ msg: 'Error loading issues' }));
      dispatch(issuesNotLoading());
    });
};

export const setIssuesPage = (page) => {
  return {
    type: SET_ISSUES_PAGE,
    payload: page,
  };
};

export const setIssuesLimit = (limit) => {
  return {
    type: SET_ISSUES_LIMIT,
    payload: limit,
  };
};

const addIssue = (issue) => {
  return { type: ADD_ISSUE, payload: issue };
};

export const getIssueById = (issueId) => (dispatch) => {
  dispatch(issuesIsLoading('Loading Issue'));
  adminService
    .get(`/issues/${issueId}`)
    .then((res) => {
      dispatch(addIssue(res.data));
      dispatch(issuesNotLoading());
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err.response.data.message }));
      dispatch(issuesNotLoading());
    });
};

// {issueId, message}
export const addFeedback = (issueId, message) => (dispatch) => {
  dispatch(issuesIsLoading('Loading Issue'));
  adminService
    .post(`/issues/${issueId}/feedback`, { message })
    .then((res) => {
      dispatch(successAlert(res.data.message));
      dispatch(getIssueById(issueId));
      dispatch(issuesNotLoading());
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err.response.data.message }));
      dispatch(issuesNotLoading());
    });
};

export const resolveIssue = (issueId) => (dispatch) => {
  dispatch(issuesIsLoading('Resolving Issue'));
  adminService
    .get(`/issues/${issueId}/resolve`)
    .then((res) => {
      dispatch(successAlert(res.data.message));
      dispatch(getIssueById(issueId));
      dispatch(issuesNotLoading());
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err.response.data.message }));
      dispatch(issuesNotLoading());
    });
};

export const closeIssue = (issueId) => (dispatch) => {
  dispatch(issuesIsLoading('Closing Issue'));
  adminService
    .get(`/issues/${issueId}/close`)
    .then((res) => {
      dispatch(successAlert(res.data.message));
      dispatch(getIssueById(issueId));
      dispatch(issuesNotLoading());
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err.response.data.message }));
      dispatch(issuesNotLoading());
    });
};
