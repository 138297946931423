import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import DevicesLayout from 'src/components/devices/DevicesLayout';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from 'styled-components';
import Icon from 'src/components/common/Icon';
import { withRouter } from 'react-router-dom';
import { getSinglePos, fetchPosTypes, fetchPosOsTypes } from 'src/store/actions/posActions';
import { connect } from 'react-redux';
import { formatDate } from 'src/utils/helpers';
import EditPos from './EditPos';
import UnmapPosFromTerminalId from './UnmapPosFromTerminalId';
import UnmapPosFromMmo from './UnmapPosFromMmo';
import UnmapPosFromAgent from './UnmapPosFromAgent';
import MapPosToTerminalId from './MapPosToTerminalId';
import MapPosToMmo from './MapPosToMmo';
import MapPosToAgent from './MapPosToAgent';

const Wrapper = styled.div`
  .back {
    display: flex;
    width: max-content;
    grid-gap: 1rem;
    align-items: center;
  }
  .section {
    border-top: 1px solid grey;
    min-height: 10rem;
    margin-top: 1rem;
    padding: 1rem 0rem;
  }
  .avatar {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
  }
  .img {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    margin: 0;
    padding: 0;
  }
  .profile {
    display: flex;
    grid-gap: 1rem;
    align-items: center;
  }
  .details_body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }

  .main_body {
    padding: 1rem;
    background-color: ${(props) => props.theme.color.white};
    border-radius: 0.5rem;
    min-height: calc(100vh - 6rem);

    & > :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .header_text {
    text-align: center;
  }
  .pos_body {
    padding: 0.5rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
  .left {
    border-right: 1px solid ${(props) => props.theme.color.grey};
  }
  .section_title {
    color: ${(props) => props.theme.color.deepGrey};
    margin-top: 1rem;
  }
  .item {
    display: flex;
    padding-top: 0.5rem;
  }
  .item_title {
    width: 10rem;
    font-weight: bold;

    svg {
      padding: 0;
      margin-top: -0.25rem;
    }
  }
  .item_value {
    color: ${(props) => props.theme.color.deepGrey};
    font-weight: bold;
  }
`;

const ViewPos = ({ match, getSinglePos, pos, isLoading, fetchPosTypes, fetchPosOsTypes }) => {
  const { posId } = match.params;

  useEffect(() => {
    fetchPosTypes();
    fetchPosOsTypes();
    getSinglePos(posId);
  }, [posId]);

  const displayInfo = (title, value, titleStyle = null) => (
    <div className="item">
      <h4 className="item_title" style={titleStyle}>
        {title || ''}
      </h4>
      <h4 className="item_value">
        {value || ''}
        {value && value[0] === '#' && (
          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              background: `${value}`,
              margin: 'auto 5px',
            }}
          />
        )}
      </h4>
    </div>
  );

  return (
    <DevicesLayout>
      <Wrapper>
        <div className="main_body">
          <h2 className="header_text">POS Details</h2>
          <div className="back">
            <Icon Icon={ArrowBackIcon} type="link" to="/pos/pos-new" alt="Back to POS" text="Back" />
            <span>Back</span>
          </div>
          <div className="pos_body">
            {isLoading ? (
              <React.Fragment />
            ) : pos === null ? (
              <div>POS Not Found</div>
            ) : (
              <React.Fragment>
                <div className="left">
                  <div>
                    <EditPos pos={pos} getSinglePos={getSinglePos} useButton={true} />
                    {/* <br /> */}
                    <h5 className="section_title">GENERAL INFO</h5>
                    {displayInfo('Serial Number:', pos.serialNumber || '')}
                    {displayInfo('Device Name:', pos.deviceName || '')}
                    {displayInfo('Device Type:', pos.type || '')}
                    {displayInfo('Device OS:', pos.os || '')}
                    {displayInfo(
                      'Created At:',
                      pos.createdAt && pos.createdAt !== 'None' ? formatDate(pos.createdAt) : ''
                    )}
                    {displayInfo(
                      'Updated At:',
                      pos.updatedAt && pos.updatedAt !== 'None' ? formatDate(pos.updatedAt) : ''
                    )}
                  </div>
                </div>
                <div>
                  <div>
                    <MapPosToTerminalId pos={pos} getSinglePos={getSinglePos} useButton={true} />
                    <h5 className="section_title">TERMINALS ({pos?.terminals?.terminals?.length || 0})</h5>
                    <table style={{ width: '75%', overflowX: 'auto' }}>
                      <tbody>
                        {pos?.terminals?.terminals?.map((terminal, index) => {
                          const mappedTerminal = terminal?.terminal || {};
                          return (
                            <tr key={index}>
                              <td className="item_title">{mappedTerminal?.terminalId}</td>
                              <td className="item_value">{mappedTerminal?.ptsp}</td>
                              <td>
                                {mappedTerminal?.isMapped && (
                                  <div style={{ height: '1.75rem' }}>
                                    <UnmapPosFromTerminalId pos={pos} terminalId={mappedTerminal} />
                                  </div>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <br />

                  <div>
                    <h5 className="section_title">MAPPING INFORMATION</h5>
                    <table style={{ width: '75%', overflowX: 'auto' }}>
                      <tbody>
                        <tr>
                          <td className="item_title">MMO</td>
                          <td className="item_value">
                            {pos?.mapping?.mmoMapping?.mmoShortCode ? (
                              <Link to={`/users/mmo/${pos?.mapping?.mmoMapping?.mmoShortCode}`} className="color-blue">
                                {pos?.mapping?.mmoMapping?.mmoShortCode}
                              </Link>
                            ) : (
                              '---'
                            )}
                          </td>
                          <td>
                            <div style={{ height: '1.75rem' }}>
                              {pos?.mapping?.mmoMapping?.isActive ? (
                                <UnmapPosFromMmo pos={pos} mmo={pos?.mapping?.mmoMapping || {}} />
                              ) : (
                                <MapPosToMmo pos={pos} getSinglePos={getSinglePos} />
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="item_title">AGENT</td>
                          <td className="item_value">
                            {pos?.mapping?.agentMapping?.agentId ? (
                              <Link to={`/users/${pos?.mapping?.agentMapping?.agentId}`} className="color-blue">
                                {pos?.mapping?.agentMapping?.agentId}
                              </Link>
                            ) : (
                              '---'
                            )}
                          </td>
                          <td>
                            <div style={{ height: '1.75rem' }}>
                              {pos?.mapping?.agentMapping?.isActive ? (
                                <UnmapPosFromAgent pos={pos} agent={pos?.mapping?.agentMapping || {}} />
                              ) : (
                                <MapPosToAgent pos={pos} getSinglePos={getSinglePos} />
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="item_title">INVESTOR</td>
                          <td className="item_value">---</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <br />

                  <div>
                    <h5 className="section_title">MAPPING HISTORY</h5>
                    <div style={{ margin: '0.5rem 0' }}>
                      <Link to="#" className="color-blue">
                        See all
                      </Link>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </Wrapper>
    </DevicesLayout>
  );
};

ViewPos.propTypes = {
  match: PropTypes.object.isRequired,
  pos: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSinglePos: PropTypes.func.isRequired,
  posTypes: PropTypes.array.isRequired,
  posOsTypes: PropTypes.array.isRequired,
  fetchPosTypes: PropTypes.func.isRequired,
  fetchPosOsTypes: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { pos, isLoading, posTypes } = state.pos;
  return { pos, isLoading, posTypes };
};

export default connect(mapStateToProps, { getSinglePos, fetchPosTypes, fetchPosOsTypes })(withRouter(ViewPos));
