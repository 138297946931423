/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import reactSelectUtil from 'src/utils/reactSelectUtil';
import { loadVendorOptions } from 'src/store/actions/yawo/vendorActions';
import YawoLayout from 'src/components/yawo/YawoLayout';
import TransactionsTable from './TransactionsTable';
import {
  getAllYawoTransactions,
  setYawoTransactionLimit,
  setYawoTransactionPage,
  setYawoTransactionDate,
  resetYawoTransactionDate,
} from 'src/store/actions/yawo/transactionActions';
import { DateRangeInput } from 'src/components/common/inputs';
import { formatDate } from 'src/utils/helpers';
import { Card } from '@material-ui/core';
import Icon from 'src/components/common/Icon';

import DisplayCard from 'src/components/common/DisplayCard';
import TransactionIcon from '@material-ui/icons/CreditCard';

// Table Icons
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import DateRangeIcon from '@material-ui/icons/DateRange';

const Wrapper = styled.div`
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .Transaction--body {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .date-selector {
    display: flex;
    justify-content: flex-end;

    .date_range {
      padding: 0rem 0.5rem;
      display: grid;
      border-radius: 5px;
      grid-template-columns: max-content max-content max-content;
      grid-gap: 0.5rem;
      align-items: center;
      justify-content: center;
    }
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const Transactions = ({
  yawoTransactions,
  getAllYawoTransactions,
  setYawoTransactionLimit,
  setYawoTransactionPage,
  resetYawoTransactionDate,
  setYawoTransactionDate,
}) => {
  const [type, setType] = useState('all');
  const [status, setStatus] = useState('all');
  const [vendor, setVendor] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const getYawoTransactions = () => {
    const query = {};
    if (vendor?.value) {
      query.shortCode = vendor?.value;
    }
    if (status && status !== 'all') {
      query.status = status;
    }
    if (type && type !== 'all') {
      query.transactionType = type;
    }
    getAllYawoTransactions(query);
  };

  useEffect(() => {
    setYawoTransactionPage(1);
    setYawoTransactionLimit(20);
    getYawoTransactions();
  }, [status, type, vendor]);

  const onPageChange = (page) => {
    setYawoTransactionPage(page);
    getYawoTransactions();
  };

  const onLimitChange = (limit) => {
    setYawoTransactionLimit(limit);
    getYawoTransactions();
  };

  const onDateChange = async (date) => {
    await setYawoTransactionDate(date.startDate, date.endDate);
    getYawoTransactions();
  };

  const onResetDateClick = () => {
    resetYawoTransactionDate();
    getYawoTransactions();
  };

  return (
    <YawoLayout>
      <Wrapper>
        <div className="Transaction--body">
          <div className="cards">
            <DisplayCard icon={TransactionIcon} title="Total Transactions" value={yawoTransactions?.count} />
            <DisplayCard icon={TransactionIcon} title="Total Revenue" value={yawoTransactions?.totalRevenue} />
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <h4>Vendor:</h4>
              <div style={{ width: '20rem' }}>
                <AsyncSelect
                  name="vendor"
                  placeholder="Select Vendor"
                  cacheOptions
                  loadOptions={(val) => loadVendorOptions(val)}
                  theme={reactSelectUtil.theme}
                  styles={reactSelectUtil.styles}
                  isClearable
                  onChange={(newValue) => setVendor(newValue)}
                  value={vendor}
                />
              </div>
            </div>
            <Card
              className="date_range"
              style={{ display: 'flex', alignItems: 'center', gap: '1rem', padding: '0 10px' }}
            >
              <h6>{`${yawoTransactions === null ? '' : formatDate(yawoTransactions.startDate)} - ${
                yawoTransactions === null ? '' : formatDate(yawoTransactions.endDate)
              }`}</h6>
              <Icon Icon={DateRangeIcon} alt="Set Date" onClick={toggle} />
              <Icon Icon={RotateLeftIcon} alt="Reset Date" onClick={onResetDateClick} />
            </Card>
          </div>

          <TransactionsTable
            tableData={yawoTransactions}
            onPageChange={onPageChange}
            onLimitChange={onLimitChange}
            type={type}
            setType={setType}
            status={status}
            setStatus={setStatus}
          />
          <div className="range_input">
            <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
          </div>
        </div>
      </Wrapper>
    </YawoLayout>
  );
};

Transactions.propTypes = {
  yawoTransactions: PropTypes.object,
  getAllYawoTransactions: PropTypes.func.isRequired,
  setYawoTransactionLimit: PropTypes.func.isRequired,
  setYawoTransactionPage: PropTypes.func.isRequired,
  setYawoTransactionDate: PropTypes.func.isRequired,
  resetYawoTransactionDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { yawoTransactions } = state.yawoTransaction;
  return { yawoTransactions };
};

export default connect(mapStateToProps, {
  getAllYawoTransactions,
  setYawoTransactionLimit,
  setYawoTransactionPage,
  setYawoTransactionDate,
  resetYawoTransactionDate,
})(Transactions);
