import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  getAllCardPayments,
  setCardPaymentsLimit,
  setCardPaymentsPage,
  setCardPaymentsDate,
  resetCardPaymentsDate,
} from 'src/store/actions/cardPaymentActions';
import DownloadTransactions from './DownloadTransactions';
import CardPaymentsTable from './CardPaymentsTable';
import NotificationsLayout from 'src/components/notifications/NotificationsLayout';
import { DateRangeInput, SelectInput } from 'src/components/common/inputs';
import CardPaymentsSearch from './CardPaymentsSearch';
import { formatDate } from 'src/utils/helpers';
import { Card } from '@material-ui/core';
import Icon from 'src/components/common/Icon';

// Table Icons
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import DateRangeIcon from '@material-ui/icons/DateRange';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  height: max-content;
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .notification-filters__wrapper {
    display: flex;
    justify-content: space-between;
  }
  .notification-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .notification-filter {
    display: grid;
    width: max-content;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 0.5rem;
  }
  .date_range {
    padding: 0rem 0.5rem;
    display: grid;
    border-radius: 5px;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const CardPayments = ({
  cardPayments,
  getAllCardPayments,
  setCardPaymentsLimit,
  setCardPaymentsPage,
  setCardPaymentsDate,
  resetCardPaymentsDate,
}) => {
  const [searchString, setSearchString] = useState('');
  const [cardPaymentsStatus, setCardPaymentsStatus] = useState('all');
  const [cardPaymentsIsResolved, setCardPaymentsIsResolved] = useState('all');
  const [cardPaymentsPtsp, setCardPaymentsPtsp] = useState('all');

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const getQuery = () => ({
    search: searchString !== '' ? searchString : undefined,
    status: cardPaymentsStatus !== 'all' ? cardPaymentsStatus : undefined,
    isResolved: cardPaymentsIsResolved !== 'all' ? cardPaymentsIsResolved : undefined,
    ptsp: cardPaymentsPtsp !== 'all' ? cardPaymentsPtsp : undefined,
  });

  const getCardPayments = (params) => {
    const { resetSearchString = false } = params || {};
    const query = getQuery();
    if (resetSearchString) {
      query.search = undefined;
    }
    getAllCardPayments(query);
  };

  useEffect(() => {
    setCardPaymentsPage(1);
    setCardPaymentsLimit(20);
    getCardPayments();
  }, [cardPaymentsStatus, cardPaymentsIsResolved, cardPaymentsPtsp]);

  const onSearchChange = (e) => {
    setSearchString(e.target.value);
    if (e.target.value === '') {
      setCardPaymentsPage(1);
      setCardPaymentsLimit(20);
      getCardPayments({ resetSearchString: true });
    }
  };

  const onSearchEnterPress = (e) => {
    const validEvent = e.type === 'click' || e.key === 'Enter';
    if (validEvent && searchString !== '') {
      e.preventDefault();
      e.stopPropagation();
      getCardPayments();
    }
  };

  const onPageChange = (page) => {
    setCardPaymentsPage(page);
    getCardPayments();
  };

  const onLimitChange = (limit) => {
    setCardPaymentsLimit(limit);
    getCardPayments();
  };

  const onDateChange = (date) => {
    setCardPaymentsDate(date.startDate, date.endDate);
    getCardPayments();
  };

  const onResetDateClick = () => {
    resetCardPaymentsDate();
    getCardPayments();
  };

  return (
    <NotificationsLayout>
      <Wrapper>
        <div className="notification-filters__wrapper">
          <div className="notification-filters">
            <div className="notification-filter">
              <CardPaymentsSearch
                searchString={searchString}
                onChange={onSearchChange}
                onEnterPress={onSearchEnterPress}
              />
            </div>
            <div className="notification-filter">
              <h4>Status:</h4>
              <SelectInput
                options={cardPaymentsStatusOptions}
                value={cardPaymentsStatus}
                onChange={setCardPaymentsStatus}
              />
            </div>
            <div className="notification-filter">
              <h4>Resolved:</h4>
              <SelectInput
                options={cardPaymentsIsResolvedOptions}
                value={cardPaymentsIsResolved}
                onChange={setCardPaymentsIsResolved}
              />
            </div>
            <div className="notification-filter">
              <h4>PTSP:</h4>
              <SelectInput options={cardPaymentsPtspOptions} value={cardPaymentsPtsp} onChange={setCardPaymentsPtsp} />
            </div>
            <div className="notification-filter">
              <Card className="date_range">
                <h6>{`${cardPayments === null ? '' : formatDate(cardPayments.startDate)} - ${
                  cardPayments === null ? '' : formatDate(cardPayments.endDate)
                }`}</h6>
                <Icon Icon={DateRangeIcon} alt="Set Date" onClick={toggle} />
                <Icon Icon={RotateLeftIcon} alt="Reset Date" onClick={onResetDateClick} />
              </Card>
            </div>
            <div className="notification-filter">
              <DownloadTransactions query={getQuery()} />
            </div>
          </div>
        </div>
        <CardPaymentsTable tableData={cardPayments} onPageChange={onPageChange} onLimitChange={onLimitChange} />
        <div className="range_input">
          <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
        </div>
      </Wrapper>
    </NotificationsLayout>
  );
};

const cardPaymentsPtspOptions = [
  {
    text: 'All',
    value: 'all',
  },
  {
    text: 'Elkanah',
    value: 'elkanah',
  },
  {
    text: 'Interswitch',
    value: 'interswitch',
  },
  {
    text: '3Line',
    value: 'threeLine',
  },
];

const cardPaymentsStatusOptions = [
  {
    text: 'All',
    value: 'all',
  },
  {
    text: 'SUCCESS',
    value: 'success',
  },
  {
    text: 'FAILED',
    value: 'failed',
  },
];

const cardPaymentsIsResolvedOptions = [
  {
    text: 'All',
    value: 'all',
  },
  {
    text: 'UNRESOLVED',
    value: 'unResolved',
  },
  {
    text: 'RESOLVED',
    value: 'resolved',
  },
];

CardPayments.propTypes = {
  platforms: PropTypes.array.isRequired,
  cardPayments: PropTypes.object.isRequired,
  getAllCardPayments: PropTypes.func.isRequired,
  setCardPaymentsLimit: PropTypes.func.isRequired,
  setCardPaymentsPage: PropTypes.func.isRequired,
  setCardPaymentsDate: PropTypes.func.isRequired,
  resetCardPaymentsDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { cardPayments } = state.cardPayment;
  return { cardPayments };
};

export default connect(mapStateToProps, {
  getAllCardPayments,
  setCardPaymentsPage,
  setCardPaymentsLimit,
  setCardPaymentsDate,
  resetCardPaymentsDate,
})(CardPayments);
