import {
  YAWO_AGENT_IS_LOADING,
  YAWO_AGENT_NOT_LOADING,
  ADD_YAWO_AGENTS,
  ADD_YAWO_AGENT,
  SET_YAWO_AGENT_DATE,
  RESET_YAWO_AGENT_DATE,
  SET_YAWO_AGENT_LIMIT,
  SET_YAWO_AGENT_PAGE,
  SET_YAWO_AGENT_STATUS,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  yawoAgents: null,
  yawoAgent: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case YAWO_AGENT_IS_LOADING:
      return { ...state, isLoading: true };
    case YAWO_AGENT_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_YAWO_AGENTS:
      return { ...state, yawoAgents: action.payload };
    case ADD_YAWO_AGENT:
      return { ...state, yawoAgent: action.payload };
    case SET_YAWO_AGENT_DATE:
      return {
        ...state,
        yawoAgents: {
          ...state,
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
        },
      };
    case RESET_YAWO_AGENT_DATE:
      return {
        ...state,
        terminals: {
          ...state,
          start_date: null,
          end_date: null,
        },
      };
    case SET_YAWO_AGENT_LIMIT:
      return {
        ...state,
        yawoAgents: { ...state, limit: action.payload },
      };
    case SET_YAWO_AGENT_PAGE:
      return {
        ...state,
        yawoAgents: { ...state, page: action.payload },
      };
    case SET_YAWO_AGENT_STATUS:
      return {
        ...state,
        yawoAgents: { ...state, status: action.payload },
      };
    default:
      return state;
  }
}
