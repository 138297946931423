import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import Modal from 'src/components/common/Modal';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { resolveFwCardPayments } from 'src/store/actions/fwCardPaymentActions';
import Icon from 'src/components/common/Icon';
import ResolveIcon from '@material-ui/icons/Refresh';

const Wrapper = styled.div`
  width: max-content;
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    display: flex;
    justify-content: space-between;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .confirm {
    display: grid;
    grid-gap: 1rem;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0rem;
  }
  .buttons {
    padding-top: 0.5rem;
    display: flex;
    justify-content: space-between;
  }
  .buttons > * {
    width: 7rem;
  }
`;

const ResolveFwCardPayment = ({ paymentRef, resolveFwCardPayments }) => {
  if (!paymentRef) return null;

  const [show, setShow] = useState(false);

  const toggleShow = () => setShow(!show);

  return (
    <div>
      <Icon Icon={ResolveIcon} onClick={toggleShow} alt="Resolve Payment" />
      <Modal isOpen={show} handleClose={toggleShow}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h1>Resolve Card Payment</h1>
            </div>

            <h6 onClick={toggleShow} className="cancel">
              X
            </h6>
          </div>

          <div className="confirm">
            <p>Are you sure you want to resolve this Flutterwave card payment transaction?</p>
            <div className="buttons">
              <div>
                <Button
                  text="Yes"
                  onClick={() => {
                    resolveFwCardPayments(paymentRef);
                    setShow(false);
                  }}
                  theme="purple"
                />
              </div>
              <div>
                <Button text="Cancel" onClick={toggleShow} theme="purple" />
              </div>
            </div>
          </div>
        </Wrapper>
      </Modal>
    </div>
  );
};

ResolveFwCardPayment.propTypes = {
  paymentRef: PropTypes.string.isRequired,
  resolveFwCardPayments: PropTypes.func.isRequired,
};

export default connect(null, { resolveFwCardPayments })(ResolveFwCardPayment);
