import React from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/common/table/Table';
import { VirtualWalletRequestsTableHeader } from './virtualWalletRequestsTableHeader';
import { SelectInput } from 'src/components/common/inputs';
import { sortTableData } from 'src/utils/helpers';

const statusOptions = [
  { text: 'All', value: 'all' },
  { text: 'Approved', value: 'approved' },
  { text: 'Declined', value: 'declined' },
];
const VirtualWalletRequestsTable = ({ tableData, onPageChange, onLimitChange, status, setStatus }) => {
  const rightHeader = (
    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
      <h4>Status:</h4>
      <div>
        <SelectInput options={statusOptions} value={status} onChange={setStatus} />
      </div>
    </div>
  );

  return (
    <div>
      <Table
        showTopNav={true}
        rightHeader={rightHeader}
        headers={VirtualWalletRequestsTableHeader}
        data={tableData && tableData.data ? sortTableData(tableData.data, {}) : []}
        limit={tableData && tableData.limit}
        page={tableData && tableData.page}
        count={tableData && tableData.count}
        showNav={true}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </div>
  );
};

VirtualWalletRequestsTable.propTypes = {
  tableData: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
  status: PropTypes.string,
  setStatus: PropTypes.func,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default VirtualWalletRequestsTable;
