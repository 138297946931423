import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100vh;
  display: grid;
  justify-items: center;
  align-content: center;
  .title {
    color: ${(props) => props.theme.color.blue};
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-decoration: none;
  }
`;

const AuthLayout = ({ children }) => {
  return (
    <Wrapper>
      <Link to="/" className="title">
        <h1>Ikonik</h1>
      </Link>
      {children}
    </Wrapper>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AuthLayout;
