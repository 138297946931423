import {
  VIRTUAL_WALLET_REQUESTS_IS_LOADING,
  VIRTUAL_WALLET_REQUESTS_NOT_LOADING,
  ADD_VIRTUAL_WALLET_REQUESTS,
  SET_VIRTUAL_WALLET_REQUESTS_PAGE,
  SET_VIRTUAL_WALLET_REQUESTS_LIMIT,
  SET_VIRTUAL_WALLET_REQUESTS_STATUS,
  ADD_VIRTUAL_WALLET_REQUEST,
  SET_VIRTUAL_WALLET_REQUEST_DATE,
  RESET_VIRTUAL_WALLET_REQUEST_DATE,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  virtualWalletRequests: null,
  virtualWalletRequest: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case VIRTUAL_WALLET_REQUESTS_IS_LOADING:
      return { ...state, isLoading: true };
    case VIRTUAL_WALLET_REQUESTS_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_VIRTUAL_WALLET_REQUESTS:
      return { ...state, virtualWalletRequests: action.payload };
    case ADD_VIRTUAL_WALLET_REQUEST:
      return { ...state, virtualWalletRequest: action.payload };
    case SET_VIRTUAL_WALLET_REQUEST_DATE:
      return {
        ...state,
        virtualWalletRequests: {
          ...state,
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
        },
      };
    case RESET_VIRTUAL_WALLET_REQUEST_DATE:
      return {
        ...state,
        terminals: {
          ...state,
          start_date: null,
          end_date: null,
        },
      };
    case SET_VIRTUAL_WALLET_REQUESTS_LIMIT:
      return {
        ...state,
        virtualWalletRequests: { ...state, limit: action.payload },
      };
    case SET_VIRTUAL_WALLET_REQUESTS_PAGE:
      return {
        ...state,
        virtualWalletRequests: { ...state, page: action.payload },
      };
    case SET_VIRTUAL_WALLET_REQUESTS_STATUS:
      return {
        ...state,
        virtualWalletRequests: { ...state, status: action.payload },
      };
    default:
      return state;
  }
}
