import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatToDigits } from 'src/utils/helpers';

const Wrapper = styled.div`
  .inline {
    display: flex;
    align-items: center;
  }
  .input {
    height: 2.2rem;
    width: 100%;
    padding: 0rem 0.5rem;
    outline: 0px;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.color.grey};
    :focus {
      border: 1px solid ${(props) => props.theme.color.grey};
    }
  }
  .label {
    color: ${(props) => props.theme.color.black};
    min-width: max-content;
  }
`;

function NumberInput({ type, onChange, value, label, formType, max }) {
  const onInputChange = (e) => {
    return onChange(formatToDigits(e.target.value, max));
  };
  return (
    <Wrapper>
      <div className={formType === 'inline' ? 'inline' : formType}>
        <p className="label">{label}</p>
        <input type={type} className="input" value={value} onChange={onInputChange} />
      </div>
    </Wrapper>
  );
}

Text.defaultProps = {
  type: 'text',
  formType: 'block',
  max: 10000,
};

NumberInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  max: PropTypes.number,
  formType: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { NumberInput };
