import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import ModalTwo from 'src/components/common/ModalTwo';
import { TextInput, SelectInput } from 'src/components/common/inputs';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { createStaff, getAllStaffs } from 'src/store/actions/staffActions';

const Wrapper = styled.div`
  h2 {
    font-size: x-large;
  }

  .form-section-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    align-items: end;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .long {
      grid-column-end: span 1;
    }

    .longer {
      grid-column-end: span 2;

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .longest {
      grid-column-end: span 3;

      @media (max-width: 768px) {
        grid-column-end: span 2;
      }

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .align-start {
      align-self: start;
    }

    .align-center {
      align-self: center;
    }

    .align-end {
      align-self: end;
    }

    .justify-end {
      justify-self: end;
    }
  }

  .label {
    color: ${(props) => props.theme.color.black};
    min-width: max-content;
  }
`;

const CreateStaff = ({ roles, staffDepartments, createStaff, getAllStaffs }) => {
  if (!roles) return null;
  if (!staffDepartments) return null;

  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [department, setDepartment] = useState('');
  const [role, setRole] = useState('');
  const [phone, setPhone] = useState('');

  const resetFormState = () => {
    document.getElementById('createStaffForm').reset();
    setFirstName('');
    setLastName('');
    setEmail('');
    setDepartment('');
    setRole('');
    setPhone('');
  };

  const submitForm = (e) => {
    e.preventDefault();

    const data = {
      firstName,
      lastName,
      email,
      department,
      role,
      phone,
    };

    setIsSubmitting(true);
    createStaff(data, {
      always: () => {
        setIsSubmitting(false);
      },
      success: () => {
        setShow(false);
        resetFormState();
        if (getAllStaffs) getAllStaffs();
      },
    });
  };

  const { data: roleData = [] } = roles || {};
  const roleOptions = Array.isArray(roleData)
    ? roleData.map((role) => ({ ...role, text: role.name, value: role.roleId }))
    : [];
  const departmentOptions = Array.isArray(staffDepartments)
    ? staffDepartments.map((dept) => ({ text: dept, value: dept }))
    : [];

  return (
    <Wrapper>
      <div className="form-section-grid">
        <div className="longest justify-end">
          <Button text="Create Staff" onClick={toggleShow} />
        </div>
      </div>

      <ModalTwo size="large" active={show} onModalCloseCallback={toggleShow}>
        <div>
          <center>
            <h2>Create Staff</h2>
          </center>

          <br />

          <form id="createStaffForm" onSubmit={submitForm} className="form-section-grid">
            <div>
              <TextInput label="First Name" value={firstName} onChange={setFirstName} required />
            </div>

            <div>
              <TextInput label="Last Name" value={lastName} onChange={setLastName} required />
            </div>

            <div>
              <TextInput label="Email" value={email} onChange={setEmail} required />
            </div>

            <div>
              <SelectInput
                label="Select Department"
                options={departmentOptions}
                value={department}
                onChange={setDepartment}
                required
              />
            </div>

            <div>
              <SelectInput label="Select Role" options={roleOptions} value={role} onChange={setRole} required />
            </div>

            <div>
              <TextInput label="Phone" value={phone} onChange={setPhone} />
            </div>

            <div className="longest justify-end">
              <Button
                type="submit"
                text={isSubmitting ? 'Creating Staff ...' : '+ Create Staff'}
                disabled={isSubmitting}
              />
            </div>
          </form>
        </div>
      </ModalTwo>
    </Wrapper>
  );
};

CreateStaff.propTypes = {
  roles: PropTypes.object.isRequired,
  staffDepartments: PropTypes.array.isRequired,
  createStaff: PropTypes.func.isRequired,
  getAllStaffs: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { roles } = state.role;
  const { staffDepartments } = state.staff;
  return { roles, staffDepartments };
};

export default connect(mapStateToProps, { createStaff, getAllStaffs })(CreateStaff);
