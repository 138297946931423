import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import ModalTwo from 'src/components/common/ModalTwo';
import { TextInput } from 'src/components/common/inputs';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { updateMmoIsUploaded, updateMmoIsLive, resetMmoPassword } from 'src/store/actions/mmoActions';

const Wrapper = styled.div`
  .actions {
    display: flex;
    gap: 0.25rem;
  }

  h2 {
    font-size: x-large;
  }

  .form-section-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    align-items: end;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .long {
      grid-column-end: span 1;
    }

    .longer {
      grid-column-end: span 2;

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .longest {
      grid-column-end: span 3;

      @media (max-width: 768px) {
        grid-column-end: span 2;
      }

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .align-start {
      align-self: start;
    }

    .align-center {
      align-self: center;
    }

    .align-end {
      align-self: end;
    }

    .justify-end {
      justify-self: end;
    }
  }

  .label {
    color: ${(props) => props.theme.color.black};
    min-width: max-content;
  }
`;

const UploadMmoApp = ({ mmo, updateMmoIsUploaded, updateMmoIsLive, getSingleMmo, resetMmoPassword }) => {
  if (!mmo) return null;

  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [appLink, setAppLink] = useState(mmo.appLink || '');

  const submitForm = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    updateMmoIsLive(
      mmo.shortCode,
      { appLink },
      {
        always: () => {
          setIsSubmitting(false);
        },
        success: () => {
          setShow(false);
          if (getSingleMmo) getSingleMmo(mmo.shortCode, { silently: true });
        },
      }
    );
  };

  return (
    <Wrapper>
      <div className="actions">
        {mmo.isUploaded ? (
          <Button text="Update App Link" onClick={toggleShow} />
        ) : (
          <Button text="Upload App" onClick={() => updateMmoIsUploaded(mmo.shortCode)} />
        )}
        <Button text="Reset Password" onClick={() => resetMmoPassword(mmo.shortCode)} />
      </div>

      <ModalTwo active={show} onModalCloseCallback={toggleShow}>
        <div>
          <center>
            <h2>Edit App Link</h2>
          </center>

          <br />

          <form id="updateMmoIsUploadedForm" onSubmit={submitForm} className="form-section-grid">
            <div className="longest">
              <TextInput type="url" label="Google Play Store App Link" value={appLink} onChange={setAppLink} required />
            </div>

            <div className="longest justify-end">
              <Button
                type="submit"
                text={isSubmitting ? 'Updating App Link ...' : 'Update App Link'}
                disabled={isSubmitting}
              />{' '}
            </div>
          </form>
        </div>
      </ModalTwo>
    </Wrapper>
  );
};

UploadMmoApp.propTypes = {
  mmo: PropTypes.object,
  updateMmoIsUploaded: PropTypes.func.isRequired,
  updateMmoIsLive: PropTypes.func.isRequired,
  resetMmoPassword: PropTypes.func.isRequired,
  getSingleMmo: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { mmo } = state.mmo;
  return { mmo };
};

export default connect(mapStateToProps, { updateMmoIsUploaded, updateMmoIsLive, resetMmoPassword })(UploadMmoApp);
