import React from 'react';
import AuthLayout from 'src/components/layout/AuthLayout';
//import PropTypes from 'prop-types'
import ForgotPasswordForm from 'src/components/auth/ForgotPasswordForm';

function ForgotPassword() {
  return (
    <AuthLayout>
      <ForgotPasswordForm />
    </AuthLayout>
  );
}

ForgotPassword.propTypes = {};

export default ForgotPassword;
