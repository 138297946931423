import QS from 'qs';
import client from 'src/client';
import {
  ADD_CARD_PAYMENTS,
  ADD_CARD_PAYMENT,
  CLEAR_CARD_PAYMENT,
  CARD_PAYMENT_IS_LOADING,
  CARD_PAYMENT_NOT_LOADING,
  SET_CARD_PAYMENTS_PAGE,
  SET_CARD_PAYMENTS_LIMIT,
  SET_CARD_PAYMENTS_DATE,
  RESET_CARD_PAYMENTS_DATE,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from './alertActions';
import { dateFormatYMD } from 'src/utils/helpers';
import convertArrayAsCsv from 'src/utils/convertArrayAsCsv';
import downloadBlob from 'src/utils/downloadBlob';

const { adminService } = client;

const cardPaymentIsLoading = (msg = 'Card Payment Loading') => {
  return (dispatch) => {
    dispatch({ type: CARD_PAYMENT_IS_LOADING });
    dispatch(infoAlert(msg));
  };
};

const cardPaymentNotLoading = () => (dispatch) => {
  dispatch({ type: CARD_PAYMENT_NOT_LOADING });
};

const addCardPayments = (cardPayments) => {
  return { type: ADD_CARD_PAYMENTS, payload: cardPayments };
};

const addCardPayment = (cardPayment) => {
  return { type: ADD_CARD_PAYMENT, payload: cardPayment };
};

const clearCardPayment = () => {
  return { type: CLEAR_CARD_PAYMENT };
};

export const setCardPaymentsDate = (startDate, endDate) => {
  return { type: SET_CARD_PAYMENTS_DATE, payload: { startDate, endDate } };
};

export const resetCardPaymentsDate = () => {
  return { type: RESET_CARD_PAYMENTS_DATE };
};

export const setCardPaymentsPage = (page) => {
  return { type: SET_CARD_PAYMENTS_PAGE, payload: page };
};

export const setCardPaymentsLimit = (limit) => {
  return { type: SET_CARD_PAYMENTS_LIMIT, payload: limit };
};

const generatePath = (endPoint, cardPayments, query) => {
  if (cardPayments === null) {
    return endPoint;
  }

  const { startDate, endDate, limit = 20, page = 1 } = cardPayments;
  const queryString = QS.stringify({
    limit,
    page,
    startDate: dateFormatYMD(startDate),
    endDate: dateFormatYMD(endDate),
    ...query,
  });

  return `${endPoint}?${queryString}`;
};

export const getAllCardPayments = (query = {}) => {
  return (dispatch, getState) => {
    dispatch(cardPaymentIsLoading('Loading Card Payments'));
    const { cardPayments } = getState().cardPayment;
    const path = generatePath('/card-payments', cardPayments, query);
    adminService
      .get(path)
      .then((res) => {
        dispatch(addCardPayments(res.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading Card Payments' })))
      .finally(() => dispatch(cardPaymentNotLoading()));
  };
};

export const getSingleCardPayment = (paymentRef, extraParams) => (dispatch) => {
  const { silently = false } = extraParams || {};
  if (!silently) {
    dispatch(cardPaymentNotLoading('Loading Card Payment'));
  }
  adminService
    .get(`/card-payments/${paymentRef}`)
    .then((res) => {
      dispatch(addCardPayment(res.data));
      dispatch(clearAlert());
    })
    .catch((err) => {
      dispatch(clearCardPayment());
      dispatch(errorAlert({ msg: err.response?.data?.message || err.message }));
    })
    .finally(() => dispatch(cardPaymentNotLoading()));
};

// Download Card Payments

export const downloadCardPayments = (query = {}, callback) => {
  return (dispatch, getState) => {
    dispatch(cardPaymentIsLoading('Downloading Card Payments'));
    const { cardPayments } = getState().cardPayment;
    // eslint-disable-next-line no-console
    console.log(cardPayments);
    const path = generatePath(
      '/card-payments/download',
      { ...cardPayments, limit: cardPayments.count, page: 1 },
      query
    );

    adminService
      .get(path)
      .then((res) => {
        const data = res.data?.data?.data?.data || [];
        const csvData = convertArrayAsCsv(data, (row) => ({
          'Payment Reference': row?.ref || '',
          Amount: row?.amount || '',
          'Serial Number': row?.serialNumber || '',
          'Terminal ID': row?.terminalId || '',
          Message: row?.message || '',
          Status: row?.status || '',
          'Masked PAN': row?.maskedPan || '',
          'Vendor Ref': row?.vendorRef || '',
          PTSP: row?.ptsp || '',
          'Is Resolved': row?.isResolved ? 'true' : 'false',
          'Is Settled': row?.isSettled || '',
          'Created At': row?.createdAt || '',
        }));
        downloadBlob(csvData, 'Card_Payments_Transactions.csv');
        dispatch(clearAlert());
        if (callback && callback.success) callback.success();
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Downloading Card Payments' })))
      .finally(() => dispatch(cardPaymentNotLoading()));
  };
};

// Resolve Card Payment

const resolveCardPaymentIsLoading = (msg = 'Resolving Card Payment') => {
  return (dispatch) => {
    return dispatch(infoAlert(msg));
  };
};

const resolveCardPaymentsNotLoading = () => (dispatch) => dispatch(clearAlert());

export const resolveCardPayments = (paymentRef, callback) => {
  return (dispatch, getState) => {
    dispatch(resolveCardPaymentIsLoading());
    adminService
      .post('/card-payments/resolve', { paymentRef })
      .then(() => {
        // Manually update current card payment isResolved value in the cardPayment store
        let { cardPayments } = getState().cardPayment || {};
        const { data = [] } = cardPayments || {};
        const currentCardPaymentIndex = data.findIndex((x) => x.ref === paymentRef);
        if (currentCardPaymentIndex) {
          let currentCardPayment = data.slice().splice(currentCardPaymentIndex, 1)[0];
          currentCardPayment = { ...currentCardPayment, isResolved: true };
          data[currentCardPaymentIndex] = currentCardPayment;
          cardPayments = { ...cardPayments, data };
          const finalPayload = { data: { data: cardPayments } };
          dispatch(addCardPayments(finalPayload));
        }
        // ---
        dispatch(successAlert('Card Payment Resolved'));
        setTimeout(() => {
          if (callback && callback.success) callback.success();
        }, 1000);
      })
      .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
      .finally(() => {
        setTimeout(() => {
          dispatch(resolveCardPaymentsNotLoading());
          if (callback && callback.always) callback.always();
        }, 1000);
      });
  };
};
