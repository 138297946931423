import React from 'react';
import { formatDate } from 'src/utils/helpers';
import ViewPlatform from 'src/components/platform/ViewPlatform';
import Icon from 'src/components/common/Icon';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export const userTableHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Name</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{`${rowData['firstName']} ${rowData['lastName']}`}</p>;
    },
  },
  {
    title: function title() {
      return <p>MMO</p>;
    },
    render: function DateTaken(rowData) {
      return <ViewPlatform platform={rowData['platform']} />;
    },
  },
  {
    title: function title() {
      return <p>Email</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['email']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['status']}</p>;
    },
  },
  {
    title: function title() {
      return <p>SignUp Date</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{formatDate(rowData['timeCreated'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>View</p>;
    },
    render: function SerialNumber(rowData) {
      return <Icon Icon={OpenInNewIcon} type="link" to={`users/${rowData['_id']}`} />;
    },
  },
];
