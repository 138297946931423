import {
  RECONCILIATION_IS_LOADING,
  RECONCILIATION_NOT_LOADING,
  ADD_RECONCILIATIONS,
  SET_RECONCILIATION_DATE,
  RESET_RECONCILIATION_DATE,
  SET_RECONCILIATION_LIMIT,
  SET_RECONCILIATION_PAGE,
  SET_RECONCILIATION_STATUS,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  reconciliations: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RECONCILIATION_IS_LOADING:
      return { ...state, isLoading: true };
    case RECONCILIATION_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_RECONCILIATIONS:
      return { ...state, reconciliations: action.payload };
    case SET_RECONCILIATION_DATE:
      return {
        ...state,
        reconciliations: {
          ...state.reconciliations,
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
        },
      };
    case RESET_RECONCILIATION_DATE:
      return {
        ...state,
        reconciliations: {
          ...state.reconciliations,
          start_date: null,
          end_date: null,
        },
      };
    case SET_RECONCILIATION_LIMIT:
      return {
        ...state,
        reconciliations: { ...state.reconciliations, limit: action.payload },
      };
    case SET_RECONCILIATION_PAGE:
      return {
        ...state,
        reconciliations: { ...state.reconciliations, page: action.payload },
      };
    case SET_RECONCILIATION_STATUS:
      return {
        ...state,
        reconciliations: { ...state.reconciliations, status: action.payload },
      };
    default:
      return state;
  }
}
