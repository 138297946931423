import React from 'react';
import { formatDate, formatTime, formatAmount } from 'src/utils/helpers';
import Icon from 'src/components/common/Icon';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import TransactionStatus from './TransactionStatus';

export const transactionsTableHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Amount</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['amount'] ? formatAmount(rowData['amount']) : ''}</p>;
    },
  },
  {
    title: function title() {
      return <p>Sender Short Code</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['senderShortCode']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Reciever Short Code</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['receiverShortCode']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Type</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['transactionType']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function DateTaken(rowData) {
      return <TransactionStatus status={rowData['status']} />;
    },
  },

  {
    title: function title() {
      return <p>Date</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{formatDate(rowData['createdAt'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Time</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{formatTime(rowData['createdAt'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>View</p>;
    },
    render: function SerialNumber(rowData) {
      return <Icon Icon={OpenInNewIcon} type="link" to={`transactions/${rowData['yawoRef']}`} />;
    },
  },
];
