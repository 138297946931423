import React from 'react';
import Container from 'src/components/common/Container';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 1rem;
`;

const Overview = () => {
  return (
    <Wrapper>
      <Container>Dashboard Overview</Container>
    </Wrapper>
  );
};

Overview.propTypes = {};

export default Overview;
