import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from './SideBar';
import Button from 'src/components/common/Button';
import { TextInput } from 'src/components/common/inputs';
import { getIssueById, closeIssue, resolveIssue, addFeedback } from 'src/store/actions/issuesActions';
import ImageViewer from 'src/components/common/ImageViewer';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  .main_body {
    margin: 1.5rem;
    padding: 0.5rem;
    display: grid;
    grid-gap: 1rem;
    height: max-content;
    background-color: ${(props) => props.theme.color.white};
    border-radius: 0.5rem;
  }
  .header_text {
    text-align: center;
  }
  .issue_body {
    padding: 0.5rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
  .left {
    border-right: 1px solid ${(props) => props.theme.color.grey};
  }
  .section_title {
    color: ${(props) => props.theme.color.deepGrey};
    margin-top: 1rem;
  }
  .item {
    display: flex;
    padding-top: 0.5rem;
  }
  .item_title {
    width: 10rem;
  }
  .item_value {
    color: ${(props) => props.theme.color.deepGrey};
  }
  .receipt {
    width: 20rem;
    height: 10rem;
    margin-top: 0.5rem;
    cursor: pointer;
  }
  .btn {
    max-width: 6rem;
    min-width: max-content;
    padding: 1rem;
  }
  .actions {
    display: flex;
    grid-gap: 1rem;
    margin-top: 1rem;
  }
  .small {
    font-size: 0.6rem;
  }
  .helper {
    color: ${(props) => props.theme.color.deepGrey};
  }
  .feedback {
    margin-top: 0.5rem;
  }
  .feedback_btn {
    display: flex;
    justify-content: flex-end;
  }
`;

const Issue = () => {
  const dispatch = useDispatch();
  const { issueId } = useParams();
  const { issue } = useSelector((state) => state.issue);
  const [feedback, setFeedback] = useState('');
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const toggleViewer = () => setViewerIsOpen(!viewerIsOpen);

  useEffect(() => {
    dispatch(getIssueById(issueId));
  }, [issueId]);

  const displayInfo = (title, value) => (
    <div className="item">
      <h4 className="item_title">{title || ''}</h4>
      <h4 className="item_value">{value || ''}</h4>
    </div>
  );

  const feedbacks = issue?.issue?.feedbacks || [];

  return (
    <Wrapper>
      <SideBar />
      <div className="main_body">
        <h2 className="header_text">Issue Details</h2>
        <div className="issue_body">
          <div className="left">
            <div>
              <h5 className="section_title">Issue</h5>
              {displayInfo('Status:', issue?.issue?.status || '')}
              {displayInfo('Type:', issue?.issue?.issueType || '')}
              {displayInfo('RRN:', issue?.issue?.rrn || '')}
              {displayInfo('MMO:', issue?.issue?.mmo || '')}
              {displayInfo('PAN:', issue?.issue?.pan || '')}
              {displayInfo('AMOUNT:', issue?.issue?.amount || '')}
              {displayInfo('CATEGORY:', issue?.issue?.category || '')}
              {displayInfo('TRANSACTION TYPE:', issue?.issue?.transactionType || '')}
              {displayInfo('COMMENT:', issue?.issue?.comment || '')}
              {displayInfo('TIME:', issue?.issue?.createdAt || '')}
            </div>
            <div>
              <h5 className="section_title">Transaction</h5>
              {displayInfo('Amount:', issue?.transaction?.amount)}
              {displayInfo('Transaction REF:', issue?.transaction?.transactionRef)}
              {displayInfo('Type:', issue?.transaction?.type)}
              {displayInfo('Status:', issue?.transaction?.status)}
              {displayInfo('Narration:', issue?.transaction?.narration)}
              {displayInfo('Wallet Balance:', issue?.transaction?.walletBalance)}
              {displayInfo('Platform:', issue?.transaction?.platform)}
              {displayInfo('Platform Fee:', issue?.transaction?.platformFee)}
              {displayInfo('Ikonik Fee:', issue?.transaction?.ikonikFee)}
              {displayInfo('Time:', issue?.transaction?.timeCreated)}
              {displayInfo('User:', issue?.transaction?.user)}
              {displayInfo('Email:', issue?.transaction?.email)}
              {displayInfo('Receiving Platform:', issue?.transaction?.receivingPlatform)}
              {displayInfo('Receiving Wallet Balance:', issue?.transaction?.receivingWalletBalance)}
              {displayInfo('Receiver: ', issue?.transaction?.receiver)}
            </div>
            <div>
              <h5 className="section_title">User</h5>
              {displayInfo('First Name:', issue?.user?.firstName)}
              {displayInfo('Last Name:', issue?.user?.lastName)}
              {displayInfo('Email:', issue?.user?.email)}
              {displayInfo('Phone Number:', issue?.user?.phoneNumber)}
              {displayInfo('Date of Birth:', issue?.user?.dob)}
              {displayInfo('Transfer ID:', issue?.user?.iuwi)}
              {displayInfo('Platform:', issue?.user?.platform)}
              {displayInfo('User Type:', issue?.user?.accountType)}
            </div>
          </div>
          <div>
            <div>
              <h5 className="section_title">ATTACHED RECEIPT SCREENSHOT</h5>
              <img src={issue?.issue?.image || ''} className="receipt" onClick={toggleViewer} />

              {issue?.issue?.image && (
                <ImageViewer isOpen={viewerIsOpen} onClose={toggleViewer} images={[issue?.issue?.image]} />
              )}
            </div>

            {issue?.issue?.status === 'OPEN' && (
              <div className="actions">
                <Button text="Close Issue" className="btn" onClick={() => dispatch(closeIssue(issueId))} />
                <Button text="Resolved Issue" className="btn" onClick={() => dispatch(resolveIssue(issueId))} />
              </div>
            )}
            <div>
              <h5 className="section_title">FEEDBACKS</h5>
              {feedbacks.map((feedback) => (
                <div className="feedback" key={feedback._id}>
                  <p>{feedback.message}</p>
                  <p className="small">
                    {feedback.date} <span className="helper">by {feedback.author}</span>
                  </p>
                </div>
              ))}
            </div>
            <div>
              <h5 className="section_title">Leave a feedback message</h5>
              <div>
                <TextInput value={feedback} onChange={setFeedback} />
                <p className="small">MAX = 160 characters</p>
              </div>
              <div className="feedback_btn">
                <Button
                  text="Leave Feedback"
                  className="btn"
                  onClick={() => {
                    dispatch(addFeedback(issueId, feedback));
                    return setFeedback('');
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

Issue.propTypes = {};

export default Issue;
