import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import ReconciliationsComponent from 'src/components/finance/reconciliation/Reconciliations';

const Reconciliation = () => {
  return (
    <DashboardLayout>
      <ReconciliationsComponent />
    </DashboardLayout>
  );
};

export default Reconciliation;
