import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import Modal from 'src/components/common/Modal';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { updateLoanLimit } from 'src/store/actions/yawo/vendorActions';
import { NumberInput } from 'src/components/common/inputs';

const Wrapper = styled.div`
  width: max-content;
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    display: flex;
    justify-content: space-between;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .confirm {
    display: grid;
    grid-gap: 1rem;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0rem;
  }
`;

const UpdateLoanLimit = ({ updateLoanLimit, shortCode, callback }) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  const [loanLimit, setLoanLimit] = useState('');

  return (
    <div>
      <Button text="Edit Loan Limit" theme="blue" onClick={toggleShow} />
      <Modal isOpen={show} handleClose={toggleShow}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h1>Update Loan Limit</h1>
            </div>

            <h6 onClick={toggleShow} className="cancel">
              X
            </h6>
          </div>

          <div className="confirm">
            <div style={{ minWidth: '20rem' }}>
              <NumberInput label="Set New Loan Limit" value={loanLimit} onChange={setLoanLimit} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                text="Update Limit"
                onClick={() => {
                  const requestData = {
                    shortCode,
                    loanLimit,
                  };
                  updateLoanLimit(requestData, () => {
                    setShow(false);
                    if (callback) callback();
                  });
                }}
              />
            </div>
          </div>
        </Wrapper>
      </Modal>
    </div>
  );
};

UpdateLoanLimit.propTypes = {
  updateLoanLimit: PropTypes.func.isRequired,
  shortCode: PropTypes.string.isRequired,
  callback: PropTypes.func,
};

export default connect(null, { updateLoanLimit })(UpdateLoanLimit);
