import QS from 'qs';
import client from 'src/client';
import {
  ADD_STAFFS,
  ADD_STAFF,
  CLEAR_STAFF,
  STAFF_IS_LOADING,
  STAFF_NOT_LOADING,
  SET_STAFF_DATE,
  RESET_STAFF_DATE,
  SET_STAFF_LIMIT,
  SET_STAFF_STATUS,
  SET_STAFF_PAGE,
  SET_STAFF_DEPARTMENTS,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from './alertActions';

const { adminService } = client;

const staffIsLoading =
  (msg = 'Staff Loading') =>
  (dispatch) => {
    dispatch({ type: STAFF_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const staffNotLoading = () => (dispatch) => {
  dispatch({ type: STAFF_NOT_LOADING });
};

const addStaffs = (staffs) => {
  return { type: ADD_STAFFS, payload: staffs };
};

const addStaff = (staff) => {
  return { type: ADD_STAFF, payload: staff };
};

const clearStaff = () => {
  return { type: CLEAR_STAFF };
};

export const setStaffDate = (start_date, end_date) => {
  return { type: SET_STAFF_DATE, payload: { start_date, end_date } };
};

export const resetStaffDate = () => {
  return { type: RESET_STAFF_DATE };
};

export const setStaffPage = (page) => {
  return { type: SET_STAFF_PAGE, payload: page };
};

export const setStaffLimit = (limit) => {
  return { type: SET_STAFF_LIMIT, payload: limit };
};

export const setStaffStatus = (status) => {
  return { type: SET_STAFF_STATUS, payload: status };
};

const generatePath = (endPoint, staff, query) => {
  const { limit, page } = staff || {};
  let url = `${endPoint}?limit=${limit}&page=${page}`;
  if (query && Object.keys(query).length > 0) {
    url += `&${QS.stringify(query)}`;
  }
  return url;
};

export const getAllStaffs =
  (query = {}) =>
  (dispatch, getState) => {
    dispatch(staffIsLoading('Loading Staffs'));
    const { staffs } = getState().staff;
    const path = generatePath('/staffs', staffs, query);
    adminService
      .get(path)
      .then((res) => {
        dispatch(addStaffs(res.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading Staffs' })))
      .finally(() => dispatch(staffNotLoading()));
  };

export const getSingleStaff = (staffId, extraParams) => (dispatch) => {
  const { silently = false } = extraParams || {};
  if (!silently) {
    dispatch(staffIsLoading('Loading Staff'));
  }
  adminService
    .get(`/staffs/${staffId}`)
    .then((res) => {
      dispatch(addStaff(res.data));
      dispatch(clearAlert());
    })
    .catch((err) => {
      dispatch(clearStaff());
      dispatch(errorAlert({ msg: err.response?.data?.message || err.message }));
    })
    .finally(() => dispatch(staffNotLoading()));
};

export const searchStaff = (searchString) => (dispatch) => {
  dispatch(staffIsLoading('Searching...'));
  adminService
    .get(`/staffs?search=${searchString}`)
    .then((res) => {
      dispatch(addStaffs(res.data));
      dispatch(clearAlert());
    })
    .catch(() => {
      dispatch(errorAlert({ msg: 'Error Loading Staffs' }));
    })
    .finally(() => dispatch(staffNotLoading()));
};

// Create & Update STAFF

const createStaffIsLoading =
  (msg = 'Creating Staff') =>
  (dispatch) =>
    dispatch(infoAlert(msg));
const createStaffNotLoading = () => (dispatch) => dispatch(clearAlert());

export const createStaff = (formData, callback) => (dispatch) => {
  dispatch(createStaffIsLoading());
  adminService
    .post('/staffs', formData)
    .then(() => {
      dispatch(successAlert('Staff Created'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => {
      setTimeout(() => {
        dispatch(createStaffNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

const updateStaffIsLoading =
  (msg = 'Updating Staff') =>
  (dispatch) =>
    dispatch(infoAlert(msg));
const updateStaffNotLoading = () => (dispatch) => dispatch(clearAlert());

export const updateStaff = (staffShortCode, formData, callback) => (dispatch) => {
  dispatch(updateStaffIsLoading());
  adminService
    .put(`/staffs/${staffShortCode}`, formData)
    .then(({ data }) => {
      if (data && data.data) dispatch(addStaff(data.data));
      dispatch(successAlert('Staff Updated'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => {
      setTimeout(() => {
        dispatch(updateStaffNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

export const activateStaffAccount = (staffId) => (dispatch) => {
  dispatch(updateStaffIsLoading('Activating Staff Account'));
  adminService
    .get(`/staffs/${staffId}/activate`)
    .then(({ data }) => {
      if (data && data.data) dispatch(addStaff(data.data));
      dispatch(successAlert('Staff Activated'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err.response?.data?.message || err.message }));
    })
    .finally(() => dispatch(staffNotLoading()));
};

export const suspendStaffAccount = (staffId) => (dispatch) => {
  dispatch(updateStaffIsLoading('Suspending Staff Account'));
  adminService
    .get(`/staffs/${staffId}/suspend`)
    .then(({ data }) => {
      if (data && data.data) dispatch(addStaff(data.data));
      dispatch(successAlert('Staff Suspended'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err.response?.data?.message || err.message }));
    })
    .finally(() => dispatch(staffNotLoading()));
};

// Staff Departments

const setStaffDepartments = (staffDepartments) => {
  return { type: SET_STAFF_DEPARTMENTS, payload: staffDepartments };
};

export const fetchStaffDepartments = () => (dispatch) => {
  adminService
    .get('/staffs/departments')
    .then((res) => dispatch(setStaffDepartments(res.data)))
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading Staff Departments' })))
    .finally(() => dispatch(staffNotLoading()));
};
