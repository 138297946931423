import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import { getAllYawoVendors } from 'src/store/actions/yawo/vendorActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 250,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 0,
  },
}));

const VendorsSearch = ({ getAllYawoVendors }) => {
  const classes = useStyles();
  const [searchString, setSearchString] = useState('');

  const searchVendors = () => {
    getAllYawoVendors({ page: 1, search: searchString });
  };

  const onChange = (e) => {
    setSearchString(e.target.value);
    if (e.target.value === '') {
      getAllYawoVendors({ page: 1 });
    }
  };

  const onEnterPress = (e) => {
    if (e.key === 'Enter' && searchString !== '') {
      e.preventDefault();
      e.stopPropagation();
      searchVendors();
    }
  };

  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Search Vendors......"
        value={searchString}
        onChange={onChange}
        onKeyPress={onEnterPress}
      />
      <IconButton className={classes.iconButton} onClick={searchVendors}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

VendorsSearch.propTypes = {
  getAllYawoVendors: PropTypes.func.isRequired,
};

export default connect(null, { getAllYawoVendors })(VendorsSearch);
