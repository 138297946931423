import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import ViewCardPaymentComponent from 'src/components/notifications/cards/ViewCardPayment';

const ViewCardPayment = () => {
  return (
    <DashboardLayout>
      <ViewCardPaymentComponent />
    </DashboardLayout>
  );
};

export default ViewCardPayment;
