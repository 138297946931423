import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import ViewRoleComponent from 'src/components/staffs/roles/ViewRole';

const ViewRole = () => {
  return (
    <DashboardLayout>
      <ViewRoleComponent />
    </DashboardLayout>
  );
};

export default ViewRole;
