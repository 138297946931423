import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';

const Wrapper = styled(Container)`
  height: 100%;
  min-width: max-content;
  max-width: 1325px;
`;

const AppContainer = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

AppContainer.propTypes = {
  children: PropTypes.any,
};

export default AppContainer;
