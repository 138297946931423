import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import SingleWalletComponent from 'src/components/yawo/wallets/SingleWallet';

const SingleWallet = () => {
  return (
    <DashboardLayout>
      <SingleWalletComponent />
    </DashboardLayout>
  );
};

export default SingleWallet;
