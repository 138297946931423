import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import CustomersComponent from 'src/components/yawo/customers/Customers';

const Customers = () => {
  return (
    <DashboardLayout>
      <CustomersComponent />
    </DashboardLayout>
  );
};

export default Customers;
