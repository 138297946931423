import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import PtspsComponent from 'src/components/notifications/ptsps/Ptsps';

const Ptsps = () => {
  return (
    <DashboardLayout>
      <PtspsComponent />
    </DashboardLayout>
  );
};

export default Ptsps;
