import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card } from '@material-ui/core';
import DevicesLayout from 'src/components/devices/DevicesLayout';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import DateRangeIcon from '@material-ui/icons/DateRange';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import Icon from 'src/components/common/Icon';
import DisplayCard from 'src/components/common/DisplayCard';
import PosTransactionsTable from './PosTransactionTable';
import {
  getTransactionsByWalletId,
  setTransactionPage,
  setTransactionLimit,
  setTransactionDate,
  setTransactionStatus,
  resetTransactionDate,
} from 'src/store/actions/transactionActions';
import { formatAmount, formatDate } from 'src/utils/helpers';
import { DateRangeInput } from 'src/components/common/inputs';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  .back {
    display: flex;
    width: max-content;
    grid-gap: 1rem;
    align-items: center;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .platform_select {
    display: flex;
    justify-content: space-between;
  }
  .date_range {
    padding: 0rem 0.5rem;
    display: grid;
    border-radius: 5px;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const UserTransactions = ({
  match,
  getTransactionsByWalletId,
  transactions,
  setTransactionPage,
  setTransactionLimit,
  setTransactionDate,
  setTransactionStatus,
  resetTransactionDate,
}) => {
  const { walletId } = match.params;
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const getTransactions = useCallback(() => {
    getTransactionsByWalletId(walletId, 'pos');
  }, [getTransactionsByWalletId, walletId]);

  useEffect(() => {
    getTransactions();
  }, []);

  const onResetDateClick = () => {
    resetTransactionDate();
    getTransactions();
  };

  const onDateChange = (date) => {
    setTransactionDate(date.startDate, date.endDate);
    getTransactions();
  };

  const onPageChange = (page) => {
    setTransactionPage(page);
    getTransactions();
  };

  const onLimitChange = (limit) => {
    setTransactionLimit(limit);
    getTransactions();
  };

  const onStatusChange = (status) => {
    setTransactionStatus(status);
    getTransactions();
  };

  return (
    <DevicesLayout>
      <Wrapper>
        <div className="cards">
          <DisplayCard
            icon={WorkOutlineIcon}
            title="Total Volume"
            value={transactions !== null && transactions.count}
          />
          <DisplayCard
            icon={AccountBalanceIcon}
            title="Total Value"
            value={transactions !== null && formatAmount(transactions.value)}
          />
          <DisplayCard
            icon={AccountBalanceWalletIcon}
            title="Total Revenue"
            value={transactions !== null && formatAmount(transactions.revenue)}
          />
          <DisplayCard
            icon={TrendingUpIcon}
            title="Total Income"
            value={transactions !== null && formatAmount(transactions.income)}
          />
          <DisplayCard
            icon={TrendingUpIcon}
            title="Ikonik Income"
            value={transactions !== null && formatAmount(transactions.ikonik_income)}
          />
        </div>
        <div className="platform_select">
          <div className="back">
            <Icon Icon={ArrowBackIcon} type="link" to="/pos" alt="Back" />
            <h6>Pos Transactions</h6>
          </div>
          <Card className="date_range">
            <h6>{`${transactions === null ? '' : formatDate(transactions.start_date)} - ${
              transactions === null ? '' : formatDate(transactions.end_date)
            }`}</h6>
            <Icon Icon={DateRangeIcon} alt="Set Date" onClick={toggle} />
            <Icon Icon={RotateLeftIcon} alt="Reset Date" onClick={onResetDateClick} />
          </Card>
        </div>
        <PosTransactionsTable
          tableData={transactions}
          onPageChange={onPageChange}
          onLimitChange={onLimitChange}
          onStatusChange={onStatusChange}
        />
        <div className="range_input">
          <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
        </div>
      </Wrapper>
    </DevicesLayout>
  );
};

UserTransactions.propTypes = {
  getTransactionsByWalletId: PropTypes.func.isRequired,
  setTransactionStatus: PropTypes.func.isRequired,
  resetTransactionDate: PropTypes.func.isRequired,
  setTransactionLimit: PropTypes.func.isRequired,
  setTransactionPage: PropTypes.func.isRequired,
  setTransactionDate: PropTypes.func.isRequired,
  transactions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { transactions } = state.transaction;
  return { transactions };
};

export default connect(mapStateToProps, {
  getTransactionsByWalletId,
  setTransactionPage,
  setTransactionLimit,
  setTransactionDate,
  setTransactionStatus,
  resetTransactionDate,
})(withRouter(UserTransactions));
