import {
  YAWO_LOAN_IS_LOADING,
  YAWO_LOAN_NOT_LOADING,
  ADD_YAWO_LOANS,
  ADD_YAWO_LOAN,
  SET_YAWO_LOAN_DATE,
  RESET_YAWO_LOAN_DATE,
  SET_YAWO_LOAN_LIMIT,
  SET_YAWO_LOAN_PAGE,
  SET_YAWO_LOAN_STATUS,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  yawoLoans: null,
  yawoLoan: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case YAWO_LOAN_IS_LOADING:
      return { ...state, isLoading: true };
    case YAWO_LOAN_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_YAWO_LOANS:
      return { ...state, yawoLoans: action.payload };
    case ADD_YAWO_LOAN:
      return { ...state, yawoLoan: action.payload };
    case SET_YAWO_LOAN_DATE:
      return {
        ...state,
        yawoLoans: {
          ...state.yawoLoans,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          page: 1
        },
      };
    case RESET_YAWO_LOAN_DATE:
      return {
        ...state,
        yawoLoans: {
          ...state.yawoLoans,
          startDate: null,
          endDate: null,
          page: 1
        },
      };
    case SET_YAWO_LOAN_LIMIT:
      return {
        ...state,
        yawoLoans: { ...state.yawoLoans, limit: action.payload },
      };
    case SET_YAWO_LOAN_PAGE:
      return {
        ...state,
        yawoLoans: { ...state.yawoLoans, page: action.payload },
      };
    case SET_YAWO_LOAN_STATUS:
      return {
        ...state,
        yawoLoans: { ...state.yawoLoans, status: action.payload },
      };
    default:
      return state;
  }
}
