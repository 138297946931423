import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import ModalTwo from 'src/components/common/ModalTwo';
import { TextInput, SelectInput } from 'src/components/common/inputs';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { lodgeChargeback, getAllChargebacks } from 'src/store/actions/chargebackActions';

const Wrapper = styled.div`
  h2 {
    font-size: x-large;
  }

  .form-section-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    align-items: end;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .long {
      grid-column-end: span 1;
    }

    .longer {
      grid-column-end: span 2;

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .longest {
      grid-column-end: span 3;

      @media (max-width: 768px) {
        grid-column-end: span 2;
      }

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .align-start {
      align-self: start;
    }

    .align-center {
      align-self: center;
    }

    .align-end {
      align-self: end;
    }

    .justify-end {
      justify-self: end;
    }
  }

  .label {
    color: ${(props) => props.theme.color.black};
    min-width: max-content;
  }
`;

const LodgeChargeback = ({ lodgeChargeback, getAllChargebacks }) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [vendor, setVendor] = useState('');
  const [rrn, setRrn] = useState('');
  const [terminalId, setTerminalId] = useState('');
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [transactionRef, setTransactionRef] = useState('');
  const [maskedPan, setMaskedPan] = useState('');

  const resetFormState = () => {
    document.getElementById('lodgeChargebackForm').reset();
    setVendor('');
    setRrn('');
    setTerminalId('');
    setAmount('');
    setDate('');
    setTime('');
    setTransactionRef('');
    setMaskedPan('');
  };

  const submitForm = (e) => {
    e.preventDefault();

    const data = {
      vendor,
      rrn,
      terminalId,
      amount,
      date,
      time,
      transactionRef,
      maskedPan,
    };

    setIsSubmitting(true);
    lodgeChargeback(data, {
      always: () => {
        setIsSubmitting(false);
      },
      success: () => {
        setShow(false);
        resetFormState();
        if (getAllChargebacks) getAllChargebacks();
      },
    });
  };

  return (
    <Wrapper>
      <div className="form-section-grid">
        <div className="longest justify-end">
          <Button text="Lodge Chargeback" onClick={toggleShow} />
        </div>
      </div>

      <ModalTwo size="medium" active={show} onModalCloseCallback={toggleShow}>
        <div>
          <center>
            <h2>Lodge Chargeback</h2>
          </center>

          <br />

          <form id="lodgeChargebackForm" onSubmit={submitForm} className="form-section-grid">
            <SelectInput label="Select Vendor" options={vendorOptions} value={vendor} onChange={setVendor} required />
            <TextInput label="RRN" value={rrn} onChange={setRrn} required />
            <TextInput label="Terminal ID" value={terminalId} onChange={setTerminalId} required />
            <TextInput label="Amount" value={amount} onChange={setAmount} required />
            <TextInput type="date" label="Date" value={date} onChange={setDate} required />
            <TextInput type="time" label="Time" value={time} onChange={setTime} required />
            <TextInput label="Transaction Ref" value={transactionRef} onChange={setTransactionRef} required />
            <TextInput label="Masked PAN" value={maskedPan} onChange={setMaskedPan} required />

            <div className="longest justify-end">
              <Button
                type="submit"
                text={isSubmitting ? 'Processing ...' : 'Lodge Chargeback'}
                disabled={isSubmitting}
              />
            </div>
          </form>
        </div>
      </ModalTwo>
    </Wrapper>
  );
};

const vendorOptions = [
  {
    text: 'Ikonik',
    value: 'ikonik',
  },
  {
    text: 'Flutterwave',
    value: 'flutterwave',
  },
];

LodgeChargeback.propTypes = {
  lodgeChargeback: PropTypes.func.isRequired,
  getAllChargebacks: PropTypes.func,
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { lodgeChargeback, getAllChargebacks })(LodgeChargeback);
