import QS from 'qs';
import client from 'src/client';
import {
  ADD_CHARGEBACKS,
  CHARGEBACK_IS_LOADING,
  CHARGEBACK_NOT_LOADING,
  SET_CHARGEBACK_LIMIT,
  SET_CHARGEBACK_PAGE,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from './alertActions';

const { adminService } = client;

const chargebackIsLoading =
  (msg = 'Chargeback Loading') =>
  (dispatch) => {
    dispatch({ type: CHARGEBACK_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const chargebackNotLoading = () => (dispatch) => {
  dispatch({ type: CHARGEBACK_NOT_LOADING });
};

const addChargebacks = (chargebacks) => {
  return { type: ADD_CHARGEBACKS, payload: chargebacks };
};

export const setChargebackPage = (page) => {
  return { type: SET_CHARGEBACK_PAGE, payload: page };
};

export const setChargebackLimit = (limit) => {
  return { type: SET_CHARGEBACK_LIMIT, payload: limit };
};

const generatePath = (endPoint, chargebacks, query) => {
  const { limit, page } = chargebacks || {};
  let url = `${endPoint}?limit=${limit}&page=${page}`;
  if (query && Object.keys(query).length > 0) {
    url += `&${QS.stringify(query)}`;
  }
  return url;
};

export const getAllChargebacks =
  (query = {}, loadSilently = false) =>
  (dispatch, getState) => {
    if (!loadSilently) dispatch(chargebackIsLoading('Loading Chargebacks'));
    const { chargebacks } = getState().chargeback;
    const path = generatePath('/reconciliation/chargeback/fetch', chargebacks, query);
    adminService
      .get(path)
      .then((res) => {
        dispatch(addChargebacks(res.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading Chargebacks' })))
      .finally(() => dispatch(chargebackNotLoading()));
  };

export const searchChargeback = (searchString) => (dispatch) => {
  dispatch(chargebackIsLoading('Searching...'));
  adminService
    .get(`/reconciliation/chargeback/fetch?search=${searchString}`)
    .then((res) => {
      dispatch(addChargebacks(res.data));
      dispatch(clearAlert());
    })
    .catch(() => {
      dispatch(errorAlert({ msg: 'Error Loading Chargebacks' }));
    })
    .finally(() => dispatch(chargebackNotLoading()));
};

// Create & Update Chargeback

const lodgeChargebackIsLoading =
  (msg = 'Lodging Chargeback') =>
  (dispatch) =>
    dispatch(infoAlert(msg));
const lodgeChargebackNotLoading = () => (dispatch) => dispatch(clearAlert());

export const lodgeChargeback = (formData, callback) => (dispatch) => {
  dispatch(lodgeChargebackIsLoading());
  adminService
    .post('/reconciliation/chargeback/lodge', formData)
    .then(() => {
      dispatch(successAlert('Chargeback Lodged'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => {
      setTimeout(() => {
        dispatch(lodgeChargebackNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};
