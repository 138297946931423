import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styled from 'styled-components';
import { dateFormatYMD, formatTime } from 'src/utils/helpers';
import Icon from 'src/components/common/Icon';
import { connect } from 'react-redux';
import { getSingleTerminal } from 'src/store/actions/terminalActions';
import Loader from 'src/components/notification/Loader';

const Wrapper = styled.div`
  min-width: 25vw;

  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    display: flex;
    justify-content: center;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .body {
    background-color: ${(props) => props.theme.color.lightGrey};
    padding: 1rem;
    margin: 0.5rem;
  }
  .item {
    display: flex;
    justify-content: space-between;
    color: black;
    margin-bottom: 0.5rem;
    grid-gap: 1rem;
  }
  .value {
    color: black;
    font-weight: bold;
    font-size: 0.9rem;
  }
`;

const ViewTerminal = ({ terminalId, terminal, getSingleTerminal, isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      getSingleTerminal(terminalId);
    }
  }, [isOpen, getSingleTerminal, terminalId]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Icon Icon={OpenInNewIcon} alt="View Terminal" onClick={toggleModal} />
      <Modal isOpen={isOpen} handleClose={toggleModal}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h6>Terminal Details</h6>
            </div>

            <CloseIcon onClick={toggleModal} className="cancel" />
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="body">
              <div className="item">
                <p>PTSP</p>
                <h6 className="value">{terminal && terminal.ptsp}</h6>
              </div>
              <div className="item">
                <p>Terminal ID</p>
                <h6 className="value">{terminal && terminal.terminalId}</h6>
              </div>
              <div className="item">
                <p>Is Mapped</p>
                <h6 className="value">{terminal && terminal.isMapped ? 'TRUE' : 'FALSE'}</h6>
              </div>
              <div className="item">
                <p>Created</p>
                <h6 className="value">
                  {terminal && terminal.createdAt
                    ? `${dateFormatYMD(terminal.createdAt)} ${formatTime(terminal.createdAt)}`
                    : ''}
                </h6>
              </div>
            </div>
          )}
        </Wrapper>
      </Modal>
    </div>
  );
};

ViewTerminal.propTypes = {
  terminal: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSingleTerminal: PropTypes.func.isRequired,
  terminalId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading, terminal } = state.terminal;
  return { isLoading, terminal };
};

export default connect(mapStateToProps, { getSingleTerminal })(ViewTerminal);
