import QS from 'qs';
import client from 'src/client';
import {
  ADD_PTSPS,
  PTSP_IS_LOADING,
  PTSP_NOT_LOADING,
  SET_PTSPS_PAGE,
  SET_PTSPS_LIMIT,
  SET_CARD_TYPES,
  SET_PTSP_OPTIONS,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from './alertActions';

const { adminService } = client;

const ptspIsLoading = (msg = 'PTSP Loading') => {
  return (dispatch) => {
    dispatch({ type: PTSP_IS_LOADING });
    dispatch(infoAlert(msg));
  };
};

const ptspNotLoading = () => (dispatch) => {
  dispatch({ type: PTSP_NOT_LOADING });
};

const addPtsps = (ptsps) => {
  return { type: ADD_PTSPS, payload: ptsps };
};

export const setPtspsPage = (page) => {
  return { type: SET_PTSPS_PAGE, payload: page };
};

export const setPtspsLimit = (limit) => {
  return { type: SET_PTSPS_LIMIT, payload: limit };
};

const generatePath = (endPoint, ptsps, query) => {
  if (ptsps === null) {
    return endPoint;
  }

  const { limit = 20, page = 1 } = ptsps || {};
  const queryString = QS.stringify({
    limit,
    page,
    ...query,
  });

  return `${endPoint}?${queryString}`;
};

export const getAllPtsps = (query = {}) => {
  return (dispatch, getState) => {
    dispatch(ptspIsLoading('Loading PTSPs'));
    const { ptsps } = getState().ptsp;
    const path = generatePath('/ptsp/price-settings', ptsps, query);
    adminService
      .get(path)
      .then((res) => {
        const sortFunction1 = (a, b) => {
          if (a.min < b.min) return -1;
          if (a.min > b.min) return 1;
          return 0;
        };
        const sortFunction2 = (a, b) => {
          return a.cardType.localeCompare(b.cardType);
        };
        const priceSettings = res.data.data;
        let sortedPriceSettings = priceSettings.sort(sortFunction1);
        sortedPriceSettings = sortedPriceSettings.sort(sortFunction2);
        dispatch(addPtsps({ ...res.data, data: sortedPriceSettings }));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading PTSPs' })))
      .finally(() => dispatch(ptspNotLoading()));
  };
};

// Create & Update Ptsp

const createPtspIsLoading =
  (msg = 'Creating Ptsp') =>
  (dispatch) =>
    dispatch(infoAlert(msg));
const createPtspNotLoading = () => (dispatch) => dispatch(clearAlert());

export const createPtsp = (formData, callback) => (dispatch) => {
  dispatch(createPtspIsLoading());
  adminService
    .post('/ptsp/price-settings', formData)
    .then(() => {
      dispatch(successAlert('PTSP Created'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data?.message?.message || err.message }));
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(createPtspNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

const updatePtspIsLoading =
  (msg = 'Updating Ptsp') =>
  (dispatch) =>
    dispatch(infoAlert(msg));
const updatePtspNotLoading = () => (dispatch) => dispatch(clearAlert());

export const updatePtsp = (formData, callback) => (dispatch) => {
  dispatch(updatePtspIsLoading());
  adminService
    .put('/ptsp/price-settings', formData)
    .then(() => {
      dispatch(successAlert('POS Updated'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data?.message?.message || err.message }));
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(updatePtspNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

const resetPtspIsLoading =
  (msg = 'Resetting Price Settings') =>
  (dispatch) =>
    dispatch(infoAlert(msg));
const resetPtspNotLoading = () => (dispatch) => dispatch(clearAlert());

export const resetPriceSettings = (callback) => (dispatch) => {
  dispatch(resetPtspIsLoading());
  adminService
    .get('/ptsp/price-settings/reset')
    .then(() => {
      dispatch(successAlert('Reset Successful'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data?.message?.message || err.message }));
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(resetPtspNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

// Card Types

const setCardTypes = (cardTypes) => {
  return { type: SET_CARD_TYPES, payload: cardTypes };
};

export const fetchCardTypes = () => (dispatch) => {
  adminService
    .get('/ptsp/card-types')
    .then((res) => dispatch(setCardTypes(res?.data?.data)))
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading Card Types' })))
    .finally(() => dispatch(ptspNotLoading()));
};

// PTSP Options

const setPtspOptions = (ptspOptions) => {
  return { type: SET_PTSP_OPTIONS, payload: ptspOptions };
};

export const fetchPtspOptions = () => (dispatch) => {
  adminService
    .get('/ptsp/ptsp-options')
    .then((res) => dispatch(setPtspOptions(res?.data?.data)))
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading PTSP Options' })))
    .finally(() => dispatch(ptspNotLoading()));
};

// Delete Ptsp

const deletePtspIsLoading =
  (msg = 'Deleting Ptsp') =>
  (dispatch) =>
    dispatch(infoAlert(msg));
const deletePtspNotLoading = () => (dispatch) => dispatch(clearAlert());

export const deletePtsp = (formData, callback) => (dispatch) => {
  dispatch(deletePtspIsLoading());
  adminService
    .delete(`/ptsp/price-settings?settingId=${formData.settingId}`)
    .then(() => {
      dispatch(successAlert('PTSP Deleted'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data?.message || err.message }));
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(deletePtspNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};
