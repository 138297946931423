import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import styled from 'styled-components';

const Wrapper = styled.div`
  .button {
    text-transform: none;
    height: 2.3rem;
    padding: 0 1.5rem;
  }
  .fullWidth {
    width: 100%;
    height: 2.3rem;
  }
  .blue {
    background-color: ${(props) => props.theme.color.blue};
    color: ${(props) => props.theme.color.grey};
    :hover {
      background-color: ${(props) => props.theme.color.grey};
      color: ${(props) => props.theme.color.blue};
    }
    :disabled {
      color: white;
      opacity: 0.55;
    }
  }
  .grey {
    color: ${(props) => props.theme.color.blue};
    background-color: ${(props) => props.theme.color.grey};
    :hover {
      background-color: ${(props) => props.theme.color.blue};
      color: ${(props) => props.theme.color.grey};
    }
  }
  .purple {
    color: ${(props) => props.theme.color.grey};
    background-color: ${(props) => props.theme.color.purple};
    :hover {
      background-color: ${(props) => props.theme.color.white};
      color: ${(props) => props.theme.color.purple};
    }
  }
  .red {
    color: ${(props) => props.theme.color.white};
    background-color: ${(props) => props.theme.color.red};
    :hover {
      background-color: ${(props) => props.theme.color.grey};
      color: ${(props) => props.theme.color.red};
    }
  }
`;

function AppButton({ type, text, theme, onClick, fullWidth, className, endIcon, startIcon, disabled }) {
  return (
    <Wrapper>
      <Button
        type={type}
        onClick={onClick}
        endIcon={endIcon}
        startIcon={startIcon}
        className={`button ${theme} ${fullWidth && 'fullWidth'} ${className}`}
        disabled={disabled}
      >
        <p>{text}</p>
      </Button>
    </Wrapper>
  );
}

AppButton.defaultProps = {
  type: 'button',
  theme: 'blue',
  fullWidth: false,
  disabled: false,
};

AppButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  endIcon: PropTypes.any,
  startIcon: PropTypes.any,
  disabled: PropTypes.bool,
};

export default AppButton;
