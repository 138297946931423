import React from 'react';
import { formatAmount, formatDate, formatTime } from 'src/utils/helpers';
import ResolveCardPayment from './ResolveCardPayment';
import Icon from 'src/components/common/Icon';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export const cardPaymentsTableHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function getTableNo(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>PTSP</p>;
    },
    render: function getPtsp(rowData) {
      return <p>{rowData['ptsp']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Amount</p>;
    },
    render: function getAmount(rowData) {
      return <p>{formatAmount(rowData['amount'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Ref</p>;
    },
    render: function getAmount(rowData) {
      return <p>{rowData['ref']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function getStatus(rowData) {
      return <p style={{ color: statusColorMap[rowData['status']] }}>{rowData['status']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Resolved</p>;
    },
    render: function getIsResolved(rowData) {
      return (
        <p style={{ color: isResolvedColorMap[rowData['isResolved']] }}>
          {rowData['isResolved'] ? 'resolved' : 'unresolved'}
        </p>
      );
    },
  },
  {
    title: function title() {
      return <p>Txn Time</p>;
    },
    render: function getPaymentTime(rowData) {
      return (
        <p>
          <span>{formatDate(rowData['createdAt'])} </span>
          <span>{formatTime(rowData['createdAt'])}</span>
        </p>
      );
    },
  },
  {
    title: function title() {
      return <p>Actions</p>;
    },
    render: function actions(rowData) {
      return (
        <p style={{ display: 'flex', gap: '1rem', maxWidth: '5rem' }}>
          <div style={{ width: '2rem' }}>
            <Icon Icon={OpenInNewIcon} type="link" to={`/notifications/cards/${rowData['ref']}`} />
          </div>
          <div style={{ width: '2rem' }}>
            <ResolveCardPayment paymentRef={rowData['ref']} />
          </div>
        </p>
      );
    },
  },
];

const statusColorMap = {
  success: 'green',
  failed: 'crimson',
};

const isResolvedColorMap = {
  true: 'green',
  false: 'crimson',
};
