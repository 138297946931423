import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import NotificationsComponent from 'src/components/notifications/Notifications';

const TransactionNotifications = () => {
  return (
    <DashboardLayout>
      <NotificationsComponent />
    </DashboardLayout>
  );
};

export default TransactionNotifications;
