import dayjs from 'dayjs';
import isValidDate from './isValidDate';

/**
 * formats a date string
 *
 * @param {date} date a valid date string
 * @param {string} [format='DD MMM YYYY'] e.g. "DD MMM YYYY"
 * @return {*} formatted date string
 */
export const formatDate = (date, format = 'DD MMM YYYY') => {
  if (date && isValidDate(date)) return dayjs(date).format(format);
  return date;
};

export default formatDate;
