import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import Modal from 'src/components/common/Modal';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { updateWalletLimit } from 'src/store/actions/accountActions';
import { NumberInput } from 'src/components/common/inputs';

const Wrapper = styled.div`
  width: max-content;
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    display: flex;
    justify-content: space-between;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .confirm {
    display: grid;
    grid-gap: 1rem;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0rem;
  }
`;

const UpdateWalletLimit = ({ updateWalletLimit, userId, callback }) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  const [limit, setLimit] = useState('');

  return (
    <div>
      <Button text="Update Wallet Limit" theme="grey" onClick={toggleShow} />
      <Modal isOpen={show} handleClose={toggleShow}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h1>Update Wallet Limit</h1>
            </div>

            <h6 onClick={toggleShow} className="cancel">
              X
            </h6>
          </div>

          <div className="confirm">
            <div style={{ minWidth: '20rem' }}>
              <NumberInput label="Set New Wallet Limit" value={limit} onChange={setLimit} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                text="Update Limit"
                onClick={() => {
                  const requestData = {
                    userId,
                    limit,
                  };
                  updateWalletLimit(requestData, () => {
                    setShow(false);
                    if (callback) callback();
                  });
                }}
              />
            </div>
          </div>
        </Wrapper>
      </Modal>
    </div>
  );
};

UpdateWalletLimit.propTypes = {
  updateWalletLimit: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  callback: PropTypes.func,
};

export default connect(null, { updateWalletLimit })(UpdateWalletLimit);
