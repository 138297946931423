import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
// import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import styled from 'styled-components';
import Icon from 'src/components/common/Icon';
import { connect } from 'react-redux';
import {
  getAllVirtualWalletRequests,
  approveVirtualWalletRequest,
} from 'src/store/actions/virtualWalletRequestsActions';
import Button from 'src/components/common/Button';

const Wrapper = styled.div`
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    display: flex;
    justify-content: space-between;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .confirm {
    display: grid;
    grid-gap: 1rem;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0rem;
  }
  .buttons {
    padding-top: 0.5rem;
    display: flex;
    justify-content: space-between;
  }
  .buttons > * {
    width: 7rem;
  }
`;

const ApproveRequest = ({ requestId, approveVirtualWalletRequest, getAllVirtualWalletRequests }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const approveRequest = () => {
    approveVirtualWalletRequest(requestId);
    toggleModal();
    setTimeout(() => {
      //to refresh table data
      getAllVirtualWalletRequests();
    }, 1500);
  };

  return (
    <div>
      <Icon Icon={CheckCircleOutlineIcon} alt="Approve request" onClick={toggleModal} />
      <Modal isOpen={isOpen} handleClose={toggleModal}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h1>Approve Request</h1>
            </div>

            <h6 onClick={toggleModal} className="cancel">
              X
            </h6>
          </div>

          <div className="confirm">
            <p>Are you sure you want to approve this virtual wallet request?</p>
            <div className="buttons">
              <div>
                <Button text="Yes" onClick={approveRequest} theme="purple" />
              </div>
              <div>
                <Button text="Cancel" onClick={toggleModal} theme="purple" />
              </div>
            </div>
          </div>
        </Wrapper>
      </Modal>
    </div>
  );
};

ApproveRequest.propTypes = {
  getAllVirtualWalletRequests: PropTypes.func.isRequired,
  approveVirtualWalletRequest: PropTypes.func.isRequired,
  requestId: PropTypes.string.isRequired,
};

export default connect(null, { getAllVirtualWalletRequests, approveVirtualWalletRequest })(ApproveRequest);
