import React from 'react';
import SideBar from './SideBar';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  min-height: 100vh;
  height: max-content;
  display: grid;
  grid-template-columns: max-content 1fr;
  .main_div {
    display: grid;
    grid-template-rows: max-content 1fr;
  }
`;

const DashboardLayout = ({ children }) => {
  return (
    <Wrapper>
      <SideBar />
      <div className="main_div">{children}</div>
    </Wrapper>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.any,
};

export default DashboardLayout;
