import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import ModalTwo from 'src/components/common/ModalTwo';
import AsyncSelect from 'react-select/async';
import { FixRequiredSelect } from 'src/components/common/inputs';
import reactSelectUtil from 'src/utils/reactSelectUtil';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { mapPosToMmo } from 'src/store/actions/posActions';
import { loadMmoOptions } from 'src/store/actions/mmoActions';
import Icon from 'src/components/common/Icon';
import LinkIcon from '@material-ui/icons/Link';

const Wrapper = styled.div`
  h2 {
    font-size: x-large;
  }

  .form-section-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    margin-bottom: 1.5rem;
    align-items: end;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .long {
      grid-column-end: span 1;
    }

    .longer {
      grid-column-end: span 2;

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .longest {
      grid-column-end: span 3;

      @media (max-width: 768px) {
        grid-column-end: span 2;
      }

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .align-start {
      align-self: start;
    }

    .align-center {
      align-self: center;
    }

    .align-end {
      align-self: end;
    }

    .justify-end {
      justify-self: end;
    }
  }

  .label {
    color: ${(props) => props.theme.color.black};
    min-width: max-content;
  }

  .modal-body {
    overflow-y: initial !important;
  }
`;

const MapPosToMmo = ({
  pos,
  mapPosToMmo,
  getSinglePos,
  useButton,
  externalControlForVisibility,
  setShowComponent,
  showComponent,
}) => {
  const [stateShow, setStateShow] = useState(false);
  const show = showComponent || stateShow;
  const setShow = setShowComponent || setStateShow;
  const toggleShow = () => setShow(!show);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mmo, setMmo] = useState(null);

  const setFormState = (pos) => {
    setMmo(pos.mmo || null);
  };

  useEffect(() => {
    setFormState(pos);
  }, [pos]);

  const submitForm = (e) => {
    e.preventDefault();

    const data = {
      posId: pos._id,
      mmoShortCode: mmo?.value,
    };

    setIsSubmitting(true);
    mapPosToMmo(data, {
      always: () => setIsSubmitting(false),
      success: () => {
        if (getSinglePos) getSinglePos(pos._id);
        setShow(false);
      },
    });
  };

  return (
    <Wrapper>
      {!externalControlForVisibility && (
        <>
          {useButton ? (
            <Button text="Map To MMO" onClick={toggleShow} />
          ) : (
            <Icon Icon={LinkIcon} type="icon" onClick={toggleShow} />
          )}
        </>
      )}

      <ModalTwo active={show} onModalCloseCallback={toggleShow}>
        <div>
          <center>
            <h2>Map POS To MMO</h2>
          </center>

          <br />

          <form id="mapPosToMmoForm" onSubmit={submitForm} className="form-section-grid">
            <div className="longer">
              <div>
                <b>Device Name</b>
              </div>
              <div>{pos.deviceName}</div>
            </div>

            <div>
              <div>
                <b>Serial Number</b>
              </div>
              <div>{pos.serialNumber}</div>
            </div>

            <div className="longest">
              <div>
                <b>Select MMO</b>
              </div>
              <FixRequiredSelect
                name="mmo"
                placeholder="Select Mmo"
                cacheOptions
                defaultOptions
                loadOptions={loadMmoOptions}
                theme={reactSelectUtil.theme}
                styles={reactSelectUtil.styles}
                isClearable
                SelectComponent={AsyncSelect}
                required
                onChange={(newValue) => setMmo(newValue)}
                value={mmo}
              />
            </div>

            <div className="longest justify-end">
              <Button type="submit" text={isSubmitting ? 'Mapping ...' : 'Map To MMO'} disabled={isSubmitting} />
            </div>
          </form>
        </div>
      </ModalTwo>
    </Wrapper>
  );
};

MapPosToMmo.propTypes = {
  pos: PropTypes.object,
  mapPosToMmo: PropTypes.func.isRequired,
  getSinglePos: PropTypes.func,
  useButton: PropTypes.bool,
  externalControlForVisibility: PropTypes.bool,
  setShowComponent: PropTypes.func,
  showComponent: PropTypes.bool,
};

export default connect(null, { mapPosToMmo })(MapPosToMmo);
