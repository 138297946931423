import {
  OLD_POS_IS_LOADING,
  OLD_POS_NOT_LOADING,
  OLD_ADD_ALL_POS,
  CLEAR_POS,
  OLD_SET_POS_PAGE,
  OLD_SET_POS_LIMIT,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  allPos: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OLD_POS_IS_LOADING:
      return { ...state, isLoading: true };
    case OLD_POS_NOT_LOADING:
      return { ...state, isLoading: false };
    case OLD_ADD_ALL_POS:
      return { ...state, allPos: action.payload };
    case CLEAR_POS:
      return { ...state, allPos: initialState.allPos };
    case OLD_SET_POS_LIMIT:
      return {
        ...state,
        allPos: {
          ...state.allPos,
          limit: action.payload,
        },
      };
    case OLD_SET_POS_PAGE:
      return {
        ...state,
        allPos: {
          ...state.allPos,
          page: action.payload,
        },
      };
    default:
      return state;
  }
}
