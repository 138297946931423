import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import Modal from 'src/components/common/Modal';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { verifyAgent } from 'src/store/actions/yawo/agentActions';

const Wrapper = styled.div`
  width: max-content;
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    display: flex;
    justify-content: space-between;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .confirm {
    display: grid;
    grid-gap: 1rem;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0rem;
  }
  .buttons {
    padding-top: 0.5rem;
    display: flex;
    justify-content: space-between;
  }
  .buttons > * {
    width: 7rem;
  }
`;

const VerifyAgent = ({ verifyAgent, agentId }) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => setShow(!show);

  return (
    <div>
      <Button text="Verify Agent" onClick={toggleShow} />
      <Modal isOpen={show} handleClose={toggleShow}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h1>Verify Agent</h1>
            </div>

            <h6 onClick={toggleShow} className="cancel">
              X
            </h6>
          </div>

          <div className="confirm">
            <p>Are you sure you want to verify this agent?</p>
            <div className="buttons">
              <div>
                <Button text="Yes" onClick={() => verifyAgent(agentId)} theme="purple" />
              </div>
              <div>
                <Button text="Cancel" onClick={toggleShow} theme="purple" />
              </div>
            </div>
          </div>
        </Wrapper>
      </Modal>
    </div>
  );
};

VerifyAgent.propTypes = {
  verifyAgent: PropTypes.func.isRequired,
  agentId: PropTypes.string.isRequired,
};

export default connect(null, { verifyAgent })(VerifyAgent);
