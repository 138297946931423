import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import SideNav from 'src/components/layout/SideNav';
import { useHistory, useLocation } from 'react-router-dom';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  .body {
    padding: 1.5rem;
    display: grid;
    grid-gap: 1rem;
    height: max-content;
  }
`;

const sideNavOptions = [
  { title: 'Pos', value: '/pos', icon: ChromeReaderModeIcon },
  { title: 'Terminal IDs', value: '/pos/terminals', icon: DesktopWindowsIcon },
  { title: 'Pos NEW', value: '/pos/pos-new', icon: ChromeReaderModeIcon },
];

const DevicesLayout = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const activeSidenavOption = sideNavOptions.slice(1).find((x) => {
    return pathname.toLowerCase().includes(x.value.toLowerCase());
  });
  const handleSetType = (value) => history.push(value);

  return (
    <Wrapper>
      <SideNav
        title={activeSidenavOption?.title || 'Pos'}
        type={activeSidenavOption?.value || '/pos'}
        setType={handleSetType}
        options={sideNavOptions}
      />
      <div className="body">{children}</div>
    </Wrapper>
  );
};

DevicesLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default DevicesLayout;
