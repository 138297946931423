import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import PosComponent from 'src/components/devices/oldpos/Pos';

const Devices = () => {
  return (
    <DashboardLayout>
      <PosComponent />
    </DashboardLayout>
  );
};

export default Devices;
