import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import IssuesComponent from 'src/components/support/Issues';

const Issues = () => {
  return (
    <DashboardLayout>
      <IssuesComponent />
    </DashboardLayout>
  );
};

export default Issues;
