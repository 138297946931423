import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import ViewCustomerComponent from 'src/components/yawo/customers/ViewCustomer';

const ViewCustomer = () => {
  return (
    <DashboardLayout>
      <ViewCustomerComponent />
    </DashboardLayout>
  );
};

export default ViewCustomer;
