import React from 'react';
import { formatDate } from 'src/utils/helpers';
import Icon from 'src/components/common/Icon';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export const roleTableHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Role Name</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['name']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Role Id</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['roleId']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Is Active</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['isActive'] ? '✅' : '❌'}</p>;
    },
  },
  {
    title: function title() {
      return <p>No. Of Permissions</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['permissions'] ? rowData['permissions'].length : 0}</p>;
    },
  },
  {
    title: function title() {
      return <p>Created</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{rowData['createdAt'] && rowData['createdAt'] !== 'None' ? formatDate(rowData['createdAt']) : ''}</p>;
    },
  },
  {
    title: function title() {
      return <p>View</p>;
    },
    render: function SerialNumber(rowData) {
      return <Icon Icon={OpenInNewIcon} type="link" to={`roles/${rowData['roleId']}`} />;
    },
  },
];
