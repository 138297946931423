import {
  MMO_IS_LOADING,
  MMO_NOT_LOADING,
  ADD_MMOS,
  ADD_MMO,
  CLEAR_MMO,
  SET_MMO_DATE,
  RESET_MMO_DATE,
  SET_MMO_LIMIT,
  SET_MMO_PAGE,
  SET_MMO_STATUS,
  SET_MMO_SERVICES,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  mmos: null,
  mmo: null,
  mmoServices: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MMO_IS_LOADING:
      return { ...state, isLoading: true };
    case MMO_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_MMOS:
      return { ...state, mmos: action.payload };
    case ADD_MMO:
      return { ...state, mmo: action.payload };
    case CLEAR_MMO:
      return { ...state, mmo: initialState.mmo };
    case SET_MMO_DATE:
      return {
        ...state,
        mmos: {
          ...state.mmos,
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
        },
      };
    case RESET_MMO_DATE:
      return {
        ...state,
        mmos: {
          ...state.mmos,
          start_date: null,
          end_date: null,
        },
      };
    case SET_MMO_LIMIT:
      return {
        ...state,
        mmos: { ...state.mmos, limit: action.payload },
      };
    case SET_MMO_PAGE:
      return {
        ...state,
        mmos: { ...state.mmos, page: action.payload },
      };
    case SET_MMO_STATUS:
      return {
        ...state,
        mmos: { ...state.mmos, status: action.payload },
      };
    case SET_MMO_SERVICES:
      return { ...state, mmoServices: action.payload };
    default:
      return state;
  }
}
