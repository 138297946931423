import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import reactSelectUtil from 'src/utils/reactSelectUtil';
import { loadVendorOptions } from 'src/store/actions/yawo/vendorActions';
import YawoLayout from 'src/components/yawo/YawoLayout';
import WalletsTable from './WalletsTable';
import {
  getWalletStatsByIuwi,
  getAllYawoWallets,
  setYawoWalletLimit,
  setYawoWalletPage,
  setYawoWalletDate,
  resetYawoWalletDate,
} from 'src/store/actions/yawo/walletActions';

import DisplayCard from 'src/components/common/DisplayCard';
import WalletIcon from '@material-ui/icons/CreditCard';
import { formatAmount } from 'src/utils/helpers';
import { DateRangeInput } from 'src/components/common/inputs';

import { formatDate } from 'src/utils/helpers';
import { Card } from '@material-ui/core';
import Icon from 'src/components/common/Icon';

// Table Icons
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import DateRangeIcon from '@material-ui/icons/DateRange';

const Wrapper = styled.div`
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .wallet--body {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .date-selector {
    display: flex;
    justify-content: flex-end;

    .date_range {
      padding: 0rem 0.5rem;
      display: grid;
      border-radius: 5px;
      grid-template-columns: max-content max-content max-content;
      grid-gap: 0.5rem;
      align-items: center;
      justify-content: center;
    }
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const Wallets = ({
  yawoWallets,
  yawoWalletIuwi,
  getWalletStatsByIuwi,
  getAllYawoWallets,
  setYawoWalletLimit,
  setYawoWalletPage,
  setYawoWalletDate,
  resetYawoWalletDate,
}) => {
  const [type, setType] = useState('all');
  const [status, setStatus] = useState('all');
  const [vendor, setVendor] = useState(null);
  const [yawoWalletStats, setYawoWalletStats] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (yawoWalletIuwi) {
      getWalletStatsByIuwi(yawoWalletIuwi, (yawoWalletStatsData) => {
        setYawoWalletStats(yawoWalletStatsData);
      });
    }
  }, [yawoWalletIuwi]);

  const getYawoWallets = () => {
    const query = {};
    if (vendor?.value) {
      query.shortCode = vendor?.value;
    }
    if (status && status !== 'all') {
      query.status = status;
    }
    if (type && type !== 'all') {
      query.walletHistoryType = type;
    }
    getAllYawoWallets(query);
  };

  useEffect(() => {
    setYawoWalletPage(1);
    setYawoWalletLimit(20);
    getYawoWallets();
  }, [status, type, vendor]);

  const onPageChange = (page) => {
    setYawoWalletPage(page);
    getYawoWallets();
  };

  const onLimitChange = (limit) => {
    setYawoWalletLimit(limit);
    getYawoWallets();
  };

  const onDateChange = async (date) => {
    await setYawoWalletDate(date.startDate, date.endDate);
    getYawoWallets();
  };

  const onResetDateClick = () => {
    resetYawoWalletDate();
    getYawoWallets();
  };

  return (
    <YawoLayout>
      <Wrapper>
        <div className="wallet--body">
          <div className="cards">
            <DisplayCard
              icon={WalletIcon}
              title="Wallet Balance"
              value={formatAmount(yawoWalletStats?.walletBalance)}
            />
            <DisplayCard icon={WalletIcon} title="Total Income" value={yawoWallets?.totalIncome} />
            <DisplayCard icon={WalletIcon} title="Total Debit" value={yawoWallets?.totalDebit} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', flexWrap: 'wrap' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <h4>Vendor:</h4>
            <div style={{ width: '20rem' }}>
              <AsyncSelect
                name="vendor"
                placeholder="Select Vendor"
                cacheOptions
                loadOptions={(val) => loadVendorOptions(val)}
                theme={reactSelectUtil.theme}
                styles={reactSelectUtil.styles}
                isClearable
                onChange={(newValue) => setVendor(newValue)}
                value={vendor}
              />
            </div>
            <Card
              className="date_range"
              style={{ display: 'flex', alignItems: 'center', gap: '1rem', padding: '0 10px' }}
            >
              <h6>{`${yawoWallets === null ? '' : formatDate(yawoWallets.startDate)} - ${
                yawoWallets === null ? '' : formatDate(yawoWallets.endDate)
              }`}</h6>
              <Icon Icon={DateRangeIcon} alt="Set Date" onClick={toggle} />
              <Icon Icon={RotateLeftIcon} alt="Reset Date" onClick={onResetDateClick} />
            </Card>
            </div>
          </div>
          <WalletsTable
            tableData={yawoWallets}
            onPageChange={onPageChange}
            onLimitChange={onLimitChange}
            type={type}
            setType={setType}
            status={status}
            setStatus={setStatus}
          />
          <div className="range_input">
            <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
          </div>
        </div>
      </Wrapper>
    </YawoLayout>
  );
};

Wallets.propTypes = {
  yawoWallets: PropTypes.object,
  yawoWalletIuwi: PropTypes.string,
  getWalletStatsByIuwi: PropTypes.func.isRequired,
  getAllYawoWallets: PropTypes.func.isRequired,
  setYawoWalletLimit: PropTypes.func.isRequired,
  setYawoWalletPage: PropTypes.func.isRequired,
  setYawoWalletDate: PropTypes.func.isRequired,
  resetYawoWalletDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { yawoWallets, yawoWalletIuwi } = state.yawoWallet;
  return { yawoWallets, yawoWalletIuwi };
};

export default connect(mapStateToProps, {
  getWalletStatsByIuwi,
  getAllYawoWallets,
  setYawoWalletLimit,
  setYawoWalletPage,
  setYawoWalletDate,
  resetYawoWalletDate,
})(Wallets);
