import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  getAllPoses,
  getAllPosesByMmoShortCode,
  getAllPosesByAgentId,
  setPosLimit,
  setPosPage,
  setPosDate,
  resetPosDate,
  fetchPosTypes,
  fetchPosOsTypes,
} from 'src/store/actions/posActions';
import AsyncSelect from 'react-select/async';
import reactSelectUtil from 'src/utils/reactSelectUtil';
import { loadAgentOptions } from 'src/store/actions/accountActions';
import { sortPlatforms } from 'src/utils/helpers';
import { SelectInput } from 'src/components/common/inputs';
import Button from 'src/components/common/Button';
// import { formatDate } from 'src/utils/helpers';
// import { DateRangeInput, SelectInput } from 'src/components/common/inputs';
import CreatePos from './CreatePos';
import PosTable from './PosTable';
import DevicesLayout from 'src/components/devices/DevicesLayout';
import DisplayCard from 'src/components/common/DisplayCard';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import DownloadPosMappings from './DownloadPosMappings';

// import { Card } from '@material-ui/core';
// import Icon from 'src/components/common/Icon';

// // Table Icons
// import RotateLeftIcon from '@material-ui/icons/RotateLeft';
// import DateRangeIcon from '@material-ui/icons/DateRange';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  height: max-content;
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .pos-filters__wrapper {
    display: flex;
    justify-content: space-between;
  }
  .pos-filters {
    display: flex;

    & > :not(:last-child) {
      margin-right: 1rem;
    }
  }
  .pos-filter {
    display: grid;
    width: max-content;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 0.5rem;
  }
  .date_range {
    padding: 0rem 0.5rem;
    display: grid;
    border-radius: 5px;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .linked {
    color: ${(props) => props.theme.color.blue};
  }
`;

const Poses = ({
  poses,
  getAllPoses,
  getAllPosesByMmoShortCode,
  getAllPosesByAgentId,
  setPosLimit,
  setPosPage,
  fetchPosTypes,
  fetchPosOsTypes,
  platforms,
}) => {
  useEffect(() => {
    fetchPosTypes();
    fetchPosOsTypes();
  }, []);

  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);
  const [platform, setPlatform] = useState(null);
  const [agent, setAgent] = useState(null);

  const getPoses = () => {
    const query = {};
    if (platform && platform !== null) {
      getAllPosesByMmoShortCode(platform, query);
      return;
    }
    if (agent && agent !== null) {
      getAllPosesByAgentId(agent._id, query);
      return;
    }
    getAllPoses(query);
  };

  useEffect(() => {
    setPosPage(1);
    setPosLimit(20);
    getPoses();
  }, [platform, agent]);

  const onPageChange = (page) => {
    setPosPage(page);
    getPoses();
  };

  const onLimitChange = (limit) => {
    setPosLimit(limit);
    getPoses();
  };

  // const onDateChange = (date) => {
  // 	setPosDate(date.startDate, date.endDate);
  // 	getPoses();
  // };

  // const onResetDateClick = () => {
  // 	resetPosDate();
  // 	getAllPoses();
  // };

  return (
    <DevicesLayout>
      <Wrapper>
        <CreatePos getAllPoses={getAllPoses} />
        <div className="cards">
          <DisplayCard icon={DesktopWindowsIcon} title="Total POS" value={poses !== null && poses.count} />
        </div>
        <div className="pos-filters__wrapper">
          <div className="pos-filters" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <div className="pos-filter" style={{ display: agent ? 'none' : 'flex' }}>
              <h4>MMO:</h4>
              <SelectInput
                options={[{ text: 'Select MMO', value: '' }, ...sortPlatforms(platforms)]}
                value={platform}
                onChange={setPlatform}
                disabled={Boolean(agent)}
              />
            </div>
            <div className="pos-filter" style={{ display: platform ? 'none' : 'flex' }}>
              <h4>Agent:</h4>
              <div style={{ width: '20rem' }}>
                <AsyncSelect
                  name="agent"
                  placeholder="Select Agent"
                  cacheOptions
                  loadOptions={(val) => loadAgentOptions(val)}
                  theme={reactSelectUtil.theme}
                  styles={reactSelectUtil.styles}
                  isClearable
                  onChange={(newValue) => setAgent(newValue)}
                  value={agent}
                />
              </div>
            </div>
            <div className="pos-filter" style={{ display: platform || agent ? 'flex' : 'none' }}>
              <Button
                text="Clear"
                onClick={() => {
                  setPlatform(null);
                  setAgent(null);
                }}
              />
            </div>
            <div className="notification-filter">
              <DownloadPosMappings mmoShortCode={platform} />
            </div>
          </div>
          {/* <Card className="date_range">
						<h6>{`${poses === null ? '' : formatDate(poses.start_date)} - ${poses === null ? '' : formatDate(poses.end_date)}`}</h6>
						<Icon Icon={DateRangeIcon} alt='Set Date' onClick={toggle} />
						<Icon Icon={RotateLeftIcon } alt='Reset Date' onClick={onResetDateClick}/>
					</Card> */}
        </div>
        <PosTable tableData={poses} onPageChange={onPageChange} onLimitChange={onLimitChange} />
        {/* <div className="range_input">
					<DateRangeInput
						isOpen={isOpen}
						onChange={onDateChange}
						toggleOpen={toggle}
					/>
				</div> */}
      </Wrapper>
    </DevicesLayout>
  );
};

// const appIsUploadedOrLiveOptions = [
// 	{
// 		text: 'All',
// 		value: 'all',
// 	},
// 	{
// 		text: 'Yes',
// 		value: 'true',
// 	},
// 	{
// 		text: 'No',
// 		value: 'false',
// 	},
// ];

Poses.propTypes = {
  poses: PropTypes.object,
  getAllPoses: PropTypes.func.isRequired,
  getAllPosesByMmoShortCode: PropTypes.func.isRequired,
  getAllPosesByAgentId: PropTypes.func.isRequired,
  setPosLimit: PropTypes.func.isRequired,
  setPosPage: PropTypes.func.isRequired,
  setPosDate: PropTypes.func.isRequired,
  resetPosDate: PropTypes.func.isRequired,
  fetchPosTypes: PropTypes.func.isRequired,
  fetchPosOsTypes: PropTypes.func.isRequired,
  platforms: PropTypes.array,
};

const mapStateToProps = (state) => {
  const { poses } = state.pos;
  const { platforms } = state.platform;
  return { poses, platforms };
};

export default connect(mapStateToProps, {
  getAllPoses,
  getAllPosesByMmoShortCode,
  getAllPosesByAgentId,
  setPosPage,
  setPosLimit,
  setPosDate,
  resetPosDate,
  fetchPosTypes,
  fetchPosOsTypes,
})(Poses);
