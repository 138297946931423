module.exports = Object.freeze({
  // APP ACTIONS TYPES
  CLEAR_SESSION: 'CLEAR_SESSION',
  APP_IS_LOADING: 'APP_IS_LOADING',
  APP_NOT_LOADING: 'APP_NOT_LOADING',
  SET_APP_IS_ACTIVE: 'SET_APP_IS_ACTIVE',

  //NOTIFICATION ACTION TYPES
  SUCCESS_ALERT: 'SUCCESS_ALERT',
  ERROR_ALERT: 'ERROR_ALERT',
  INFO_ALERT: 'INFO_ALERT',
  CLEAR_ALERT: 'CLEAR_ALERT',

  // USER ACTION TYPES
  SET_USER: 'SET_USER',
  SET_AUTH: 'SET_AUTH',
  REMOVE_USER: 'REMOVE_USER',
  SET_USER_SERVICES_LIST: 'SET_USER_SERVICES_LIST',
  SET_USER_SERVICES: 'SET_USER_SERVICES',

  // PLATFORM ACTIONS TYPES
  ADD_PLATFORMS: 'ADD_PLATFORMS',
  PLATFORM_IS_LOADING: 'PLATFORM_IS_LOADING',
  PLATFORM_NOT_LOADING: 'PLATFORM_NOT_LOADING',

  // APP ACTIONS TYPES
  ADD_ACCOUNTS: 'ADD_ACCOUNTS',
  ADD_ACCOUNT: 'ADD_ACCOUNT',
  CLEAR_ACCOUNT: 'CLEAR_ACCOUNT',
  ACCOUNT_IS_LOADING: 'ACCOUNT_IS_LOADING',
  ACCOUNT_NOT_LOADING: 'ACCOUNT_NOT_LOADING',
  SET_ACCOUNT_PAGE: 'SET_ACCOUNT_PAGE',
  SET_ACCOUNT_LIMIT: 'SET_ACCOUNT_LIMIT',
  SET_ACCOUNT_STATUS: 'SET_ACCOUNT_STATUS',

  // TRANSACTION HISTORY
  TRANSACTION_IS_LOADING: 'TRANSACTION_IS_LOADING',
  TRANSACTION_NOT_LOADING: 'TRANSACTION_NOT_LOADING',
  SET_IS_DOWNLOADING: 'SET_IS_DOWNLOADING',
  ADD_TRANSACTIONS: 'ADD_TRANSACTIONS',
  ADD_TRANSACTION: 'ADD_TRANSACTION',
  CLEAR_TRANSACTIONS: 'CLEAR_TRANSACTIONS',
  SET_TRANSACTION_PAGE: 'SET_TRANSACTION_PAGE',
  SET_TRANSACTION_LIMIT: 'SET_TRANSACTION_LIMIT',
  SET_TRANSACTION_STATUS: 'SET_TRANSACTION_STATUS',
  SET_TRANSACTION_DATE: 'SET_TRANSACTION_DATE',
  RESET_TRANSACTION_DATE: 'RESET_TRANSACTION_DATE',
  SET_TRANSACTION_SEARCH_STRING: 'SET_TRANSACTION_SEARCH_STRING',

  //OLD POS ACTION TYPES
  OLD_POS_IS_LOADING: 'OLD_POS_IS_LOADING',
  OLD_POS_NOT_LOADING: 'OLD_POS_NOT_LOADING',
  OLD_ADD_ALL_POS: 'OLD_ADD_ALL_POS',
  OLD_CLEAR_POS: 'OLD_CLEAR_POS',
  OLD_SET_POS_PAGE: 'OLD_SET_POS_PAGE',
  OLD_SET_POS_LIMIT: 'OLD_SET_POS_LIMIT',

  //POS ACTION TYPES
  ADD_POSES: 'ADD_POSES',
  ADD_POS: 'ADD_POS',
  CLEAR_POS: 'CLEAR_POS',
  POS_IS_LOADING: 'POS_IS_LOADING',
  POS_NOT_LOADING: 'POS_NOT_LOADING',
  SET_POS_PAGE: 'SET_POS_PAGE',
  SET_POS_LIMIT: 'SET_POS_LIMIT',
  SET_POS_STATUS: 'SET_POS_STATUS',
  SET_POS_DATE: 'SET_POS_DATE',
  RESET_POS_DATE: 'RESET_POS_DATE',
  SET_POS_TYPES: 'SET_POS_TYPES',
  SET_POS_OS_TYPES: 'SET_POS_OS_TYPES',

  //TERMINAL ACTION TYPES
  ADD_TERMINALS: 'ADD_TERMINALS',
  ADD_TERMINAL: 'ADD_TERMINAL',
  CLEAR_TERMINAL: 'CLEAR_TERMINAL',
  TERMINAL_IS_LOADING: 'TERMINAL_IS_LOADING',
  TERMINAL_NOT_LOADING: 'TERMINAL_NOT_LOADING',
  SET_TERMINAL_PAGE: 'SET_TERMINAL_PAGE',
  SET_TERMINAL_LIMIT: 'SET_TERMINAL_LIMIT',
  SET_TERMINAL_STATUS: 'SET_TERMINAL_STATUS',
  SET_TERMINAL_DATE: 'SET_TERMINAL_DATE',
  RESET_TERMINAL_DATE: 'RESET_TERMINAL_DATE',
  SET_TERMINAL_TYPES: 'SET_TERMINAL_TYPES',

  //ISSUES ACTION TYPES
  ISSUES_IS_LOADING: 'ISSUES_IS_LOADING',
  ISSUES_NOT_LOADING: 'ISSUES_NOT_LOADING',
  ADD_ISSUES: 'ADD_ISSUES',
  ADD_ISSUE: 'ADD_ISSUE',
  SET_ISSUES_PAGE: 'SET_ISSUES_PAGE',
  SET_ISSUES_LIMIT: 'SET_ISSUES_LIMIT',

  //VIRTUAL_WALLET_REQUESTS ACTION TYPES
  VIRTUAL_WALLET_REQUESTS_IS_LOADING: 'VIRTUAL_WALLET_REQUESTS_IS_LOADING',
  VIRTUAL_WALLET_REQUESTS_NOT_LOADING: 'VIRTUAL_WALLET_REQUESTS_NOT_LOADING',
  ADD_VIRTUAL_WALLET_REQUESTS: 'ADD_VIRTUAL_WALLET_REQUESTS',
  ADD_VIRTUAL_WALLET_REQUEST: 'ADD_VIRTUAL_WALLET_REQUEST',
  SET_VIRTUAL_WALLET_REQUESTS_PAGE: 'SET_VIRTUAL_WALLET_REQUESTS_PAGE',
  SET_VIRTUAL_WALLET_REQUESTS_LIMIT: 'SET_VIRTUAL_WALLET_REQUESTS_LIMIT',
  SET_VIRTUAL_WALLET_REQUESTS_STATUS: 'SET_VIRTUAL_WALLET_REQUESTS_STATUS',
  SET_VIRTUAL_WALLET_REQUEST_DATE: 'SET_VIRTUAL_WALLET_REQUEST_DATE',
  RESET_VIRTUAL_WALLET_REQUEST_DATE: 'RESET_VIRTUAL_WALLET_REQUEST_DATE',

  //MMO ACTION TYPES
  ADD_MMOS: 'ADD_MMOS',
  ADD_MMO: 'ADD_MMO',
  CLEAR_MMO: 'CLEAR_MMO',
  MMO_IS_LOADING: 'MMO_IS_LOADING',
  MMO_NOT_LOADING: 'MMO_NOT_LOADING',
  SET_MMO_PAGE: 'SET_MMO_PAGE',
  SET_MMO_LIMIT: 'SET_MMO_LIMIT',
  SET_MMO_STATUS: 'SET_MMO_STATUS',
  SET_MMO_DATE: 'SET_MMO_DATE',
  RESET_MMO_DATE: 'RESET_MMO_DATE',
  SET_MMO_SERVICES: 'SET_MMO_SERVICES',

  // STAFF ACTION TYPES
  ADD_STAFFS: 'ADD_STAFFS',
  ADD_STAFF: 'ADD_STAFF',
  CLEAR_STAFF: 'CLEAR_STAFF',
  STAFF_IS_LOADING: 'STAFF_IS_LOADING',
  STAFF_NOT_LOADING: 'STAFF_NOT_LOADING',
  SET_STAFF_PAGE: 'SET_STAFF_PAGE',
  SET_STAFF_LIMIT: 'SET_STAFF_LIMIT',
  SET_STAFF_STATUS: 'SET_STAFF_STATUS',
  SET_STAFF_DATE: 'SET_STAFF_DATE',
  RESET_STAFF_DATE: 'RESET_STAFF_DATE',
  SET_STAFF_DEPARTMENTS: 'SET_STAFF_DEPARTMENTS',

  // ROLE ACTION TYPES
  ADD_ROLES: 'ADD_ROLES',
  ADD_ROLE: 'ADD_ROLE',
  CLEAR_ROLE: 'CLEAR_ROLE',
  ROLE_IS_LOADING: 'ROLE_IS_LOADING',
  ROLE_NOT_LOADING: 'ROLE_NOT_LOADING',
  SET_ROLE_PAGE: 'SET_ROLE_PAGE',
  SET_ROLE_LIMIT: 'SET_ROLE_LIMIT',
  SET_ROLE_STATUS: 'SET_ROLE_STATUS',
  SET_ROLE_DATE: 'SET_ROLE_DATE',
  RESET_ROLE_DATE: 'RESET_ROLE_DATE',
  SET_ROLE_PERMISSIONS: 'SET_ROLE_PERMISSIONS',

  // NOTIFICATION ACTION TYPES
  ADD_NOTIFICATIONS: 'ADD_NOTIFICATIONS',
  NOTIFICATION_IS_LOADING: 'NOTIFICATION_IS_LOADING',
  NOTIFICATION_NOT_LOADING: 'NOTIFICATION_NOT_LOADING',
  SET_NOTIFICATION_DATE: 'SET_NOTIFICATION_DATE',
  RESET_NOTIFICATION_DATE: 'RESET_NOTIFICATION_DATE',
  SET_NOTIFICATION_LIMIT: 'SET_NOTIFICATION_LIMIT',
  SET_NOTIFICATION_PAGE: 'SET_NOTIFICATION_PAGE',
  SET_NOTIFICATION_STATUS: 'SET_NOTIFICATION_STATUS',

  // RECONCILIATION ACTION TYPES
  ADD_RECONCILIATIONS: 'ADD_RECONCILIATIONS',
  RECONCILIATION_IS_LOADING: 'RECONCILIATION_IS_LOADING',
  RECONCILIATION_NOT_LOADING: 'RECONCILIATION_NOT_LOADING',
  SET_RECONCILIATION_PAGE: 'SET_RECONCILIATION_PAGE',
  SET_RECONCILIATION_LIMIT: 'SET_RECONCILIATION_LIMIT',
  SET_RECONCILIATION_STATUS: 'SET_RECONCILIATION_STATUS',
  SET_RECONCILIATION_DATE: 'SET_RECONCILIATION_DATE',
  RESET_RECONCILIATION_DATE: 'RESET_RECONCILIATION_DATE',

  // CHARGEBACK ACTION TYPES
  ADD_CHARGEBACKS: 'ADD_CHARGEBACKS',
  CHARGEBACK_IS_LOADING: 'CHARGEBACK_IS_LOADING',
  CHARGEBACK_NOT_LOADING: 'CHARGEBACK_NOT_LOADING',
  SET_CHARGEBACK_PAGE: 'SET_CHARGEBACK_PAGE',
  SET_CHARGEBACK_LIMIT: 'SET_CHARGEBACK_LIMIT',

  // CARD PAYMENTS ACTION TYPES
  ADD_CARD_PAYMENTS: 'ADD_CARD_PAYMENTS',
  ADD_CARD_PAYMENT: 'ADD_CARD_PAYMENT',
  CARD_PAYMENT_IS_LOADING: 'CARD_PAYMENT_IS_LOADING',
  CARD_PAYMENT_NOT_LOADING: 'CARD_PAYMENT_NOT_LOADING',
  SET_CARD_PAYMENTS_PAGE: 'SET_CARD_PAYMENTS_PAGE',
  SET_CARD_PAYMENTS_LIMIT: 'SET_CARD_PAYMENTS_LIMIT',
  SET_CARD_PAYMENTS_DATE: 'SET_CARD_PAYMENTS_DATE',
  RESET_CARD_PAYMENTS_DATE: 'RESET_CARD_PAYMENTS_DATE',

  // PTSP ACTION TYPES
  ADD_PTSPS: 'ADD_PTSPS',
  ADD_PTSP: 'ADD_PTSP',
  PTSP_IS_LOADING: 'PTSP_IS_LOADING',
  PTSP_NOT_LOADING: 'PTSP_NOT_LOADING',
  SET_PTSPS_PAGE: 'SET_PTSPS_PAGE',
  SET_PTSPS_LIMIT: 'SET_PTSPS_LIMIT',
  SET_CARD_TYPES: 'SET_CARD_TYPES',
  SET_PTSP_OPTIONS: 'SET_PTSP_OPTIONS',

  //YAWO VENDORS ACTION TYPES
  ADD_YAWO_VENDORS: 'ADD_YAWO_VENDORS',
  ADD_YAWO_VENDOR: 'ADD_YAWO_VENDOR',
  YAWO_VENDOR_IS_LOADING: 'YAWO_VENDOR_IS_LOADING',
  YAWO_VENDOR_NOT_LOADING: 'YAWO_VENDOR_NOT_LOADING',
  SET_YAWO_VENDOR_PAGE: 'SET_YAWO_VENDOR_PAGE',
  SET_YAWO_VENDOR_LIMIT: 'SET_YAWO_VENDOR_LIMIT',
  SET_YAWO_VENDOR_STATUS: 'SET_YAWO_VENDOR_STATUS',
  SET_YAWO_VENDOR_DATE: 'SET_YAWO_VENDOR_DATE',
  RESET_YAWO_VENDOR_DATE: 'RESET_YAWO_VENDOR_DATE',

  // FW TRANSACTIONS ACTION TYPES
  ADD_FW_CARD_PAYMENTS: 'ADD_FW_CARD_PAYMENTS',
  FW_CARD_PAYMENT_IS_LOADING: 'FW_CARD_PAYMENT_IS_LOADING',
  FW_CARD_PAYMENT_NOT_LOADING: 'FW_CARD_PAYMENT_NOT_LOADING',
  SET_FW_CARD_PAYMENTS_PAGE: 'SET_FW_CARD_PAYMENTS_PAGE',
  SET_FW_CARD_PAYMENTS_LIMIT: 'SET_FW_CARD_PAYMENTS_LIMIT',
  SET_FW_CARD_PAYMENTS_DATE: 'SET_FW_CARD_PAYMENTS_DATE',
  RESET_FW_CARD_PAYMENTS_DATE: 'RESET_FW_CARD_PAYMENTS_DATE',

  //YAWO AGENTS ACTION TYPES
  ADD_YAWO_AGENTS: 'ADD_YAWO_AGENTS',
  ADD_YAWO_AGENT: 'ADD_YAWO_AGENT',
  YAWO_AGENT_IS_LOADING: 'YAWO_AGENT_IS_LOADING',
  YAWO_AGENT_NOT_LOADING: 'YAWO_AGENT_NOT_LOADING',
  SET_YAWO_AGENT_PAGE: 'SET_YAWO_AGENT_PAGE',
  SET_YAWO_AGENT_LIMIT: 'SET_YAWO_AGENT_LIMIT',
  SET_YAWO_AGENT_STATUS: 'SET_YAWO_AGENT_STATUS',
  SET_YAWO_AGENT_DATE: 'SET_YAWO_AGENT_DATE',
  RESET_YAWO_AGENT_DATE: 'RESET_YAWO_AGENT_DATE',

  //YAWO CUSTOMERS ACTION TYPES
  ADD_YAWO_CUSTOMERS: 'ADD_YAWO_CUSTOMERS',
  ADD_YAWO_CUSTOMER: 'ADD_YAWO_CUSTOMER',
  YAWO_CUSTOMER_IS_LOADING: 'YAWO_CUSTOMER_IS_LOADING',
  YAWO_CUSTOMER_NOT_LOADING: 'YAWO_CUSTOMER_NOT_LOADING',
  SET_YAWO_CUSTOMER_PAGE: 'SET_YAWO_CUSTOMER_PAGE',
  SET_YAWO_CUSTOMER_LIMIT: 'SET_YAWO_CUSTOMER_LIMIT',
  SET_YAWO_CUSTOMER_STATUS: 'SET_YAWO_CUSTOMER_STATUS',
  SET_YAWO_CUSTOMER_DATE: 'SET_YAWO_CUSTOMER_DATE',
  RESET_YAWO_CUSTOMER_DATE: 'RESET_YAWO_CUSTOMER_DATE',

  //YAWO GUARANTORS ACTION TYPES
  ADD_YAWO_GUARANTORS: 'ADD_YAWO_GUARANTORS',
  ADD_YAWO_GUARANTOR: 'ADD_YAWO_GUARANTOR',
  YAWO_GUARANTOR_IS_LOADING: 'YAWO_GUARANTOR_IS_LOADING',
  YAWO_GUARANTOR_NOT_LOADING: 'YAWO_GUARANTOR_NOT_LOADING',
  SET_YAWO_GUARANTOR_PAGE: 'SET_YAWO_GUARANTOR_PAGE',
  SET_YAWO_GUARANTOR_LIMIT: 'SET_YAWO_GUARANTOR_LIMIT',
  SET_YAWO_GUARANTOR_STATUS: 'SET_YAWO_GUARANTOR_STATUS',
  SET_YAWO_GUARANTOR_DATE: 'SET_YAWO_GUARANTOR_DATE',
  RESET_YAWO_GUARANTOR_DATE: 'RESET_YAWO_GUARANTOR_DATE',

  //YAWO LOANS ACTION TYPES
  ADD_YAWO_LOANS: 'ADD_YAWO_LOANS',
  ADD_YAWO_LOAN: 'ADD_YAWO_LOAN',
  YAWO_LOAN_IS_LOADING: 'YAWO_LOAN_IS_LOADING',
  YAWO_LOAN_NOT_LOADING: 'YAWO_LOAN_NOT_LOADING',
  SET_YAWO_LOAN_PAGE: 'SET_YAWO_LOAN_PAGE',
  SET_YAWO_LOAN_LIMIT: 'SET_YAWO_LOAN_LIMIT',
  SET_YAWO_LOAN_STATUS: 'SET_YAWO_LOAN_STATUS',
  SET_YAWO_LOAN_DATE: 'SET_YAWO_LOAN_DATE',
  RESET_YAWO_LOAN_DATE: 'RESET_YAWO_LOAN_DATE',

  //YAWO WALLETS ACTION TYPES
  ADD_YAWO_WALLETS: 'ADD_YAWO_WALLETS',
  ADD_YAWO_WALLET: 'ADD_YAWO_WALLET',
  YAWO_WALLET_IS_LOADING: 'YAWO_WALLET_IS_LOADING',
  YAWO_WALLET_NOT_LOADING: 'YAWO_WALLET_NOT_LOADING',
  SET_YAWO_WALLET_PAGE: 'SET_YAWO_WALLET_PAGE',
  SET_YAWO_WALLET_LIMIT: 'SET_YAWO_WALLET_LIMIT',
  SET_YAWO_WALLET_STATUS: 'SET_YAWO_WALLET_STATUS',
  SET_YAWO_WALLET_DATE: 'SET_YAWO_WALLET_DATE',
  RESET_YAWO_WALLET_DATE: 'RESET_YAWO_WALLET_DATE',
  SET_YAWO_WALLET_IUWI: 'SET_YAWO_WALLET_IUWI',

  //YAWO TRANSACTIONS ACTION TYPES
  ADD_YAWO_TRANSACTIONS: 'ADD_YAWO_TRANSACTIONS',
  ADD_YAWO_TRANSACTION: 'ADD_YAWO_TRANSACTION',
  YAWO_TRANSACTION_IS_LOADING: 'YAWO_TRANSACTION_IS_LOADING',
  YAWO_TRANSACTION_NOT_LOADING: 'YAWO_TRANSACTION_NOT_LOADING',
  SET_YAWO_TRANSACTION_PAGE: 'SET_YAWO_TRANSACTION_PAGE',
  SET_YAWO_TRANSACTION_LIMIT: 'SET_YAWO_TRANSACTION_LIMIT',
  SET_YAWO_TRANSACTION_STATUS: 'SET_YAWO_TRANSACTION_STATUS',
  SET_YAWO_TRANSACTION_DATE: 'SET_YAWO_TRANSACTION_DATE',
  RESET_YAWO_TRANSACTION_DATE: 'RESET_YAWO_TRANSACTION_DATE',

  // YAWO COLLECTIONS ACTION TYPES
  ADD_YAWO_COLLECTIONS: 'ADD_YAWO_COLLECTIONS',
  ADD_YAWO_COLLECTION: 'ADD_YAWO_COLLECTION',
  YAWO_COLLECTION_IS_LOADING: 'YAWO_COLLECTION_IS_LOADING',
  YAWO_COLLECTION_NOT_LOADING: 'YAWO_COLLECTION_NOT_LOADING',
  SET_YAWO_COLLECTION_PAGE: 'SET_YAWO_COLLECTION_PAGE',
  SET_YAWO_COLLECTION_LIMIT: 'SET_YAWO_COLLECTION_LIMIT',
  SET_YAWO_COLLECTION_STATUS: 'SET_YAWO_COLLECTION_STATUS',
  SET_YAWO_COLLECTION_DATE: 'SET_YAWO_COLLECTION_DATE',
  RESET_YAWO_COLLECTION_DATE: 'RESET_YAWO_COLLECTION_DATE',
});
