import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import Modal from 'src/components/common/Modal';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { downloadWalletHistory } from 'src/store/actions/accountActions';
import dayjs from 'dayjs';
import formatDate from 'src/utils/formatDate';
import { useJsonToCsv } from 'src/utils/hooks';
import { DateRangeInput } from 'src/components/common/inputs';

const Wrapper = styled.div`
  width: max-content;
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    display: flex;
    justify-content: space-between;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .confirm {
    display: grid;
    grid-gap: 1rem;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0rem;
  }
  .range_input.datepicker-without-backdrop {
    & > div:first-child > div:first-child {
      display: none !important;
    }
  }
`;

const DownloadWalletHistory = ({ downloadWalletHistory, walletId }) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  const today = new Date();
  const initialStartDate = dayjs(today).subtract(6, 'months').format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(today);
  const onDateChange = (date) => {
    setStartDate(date.startDate);
    setEndDate(date.endDate);
  };

  const { saveAsCsv } = useJsonToCsv();

  return (
    <div>
      <Button text="Download Wallet History" theme="grey" onClick={toggleShow} />
      <Modal isOpen={show} handleClose={toggleShow}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h1>Dowload Wallet History</h1>
            </div>

            <h6 onClick={toggleShow} className="cancel">
              X
            </h6>
          </div>

          <div className="confirm">
            <p style={{ minWidth: '20rem' }}>Select Date Range</p>
            <div className="range_input datepicker-without-backdrop">
              <DateRangeInput
                initialDateRange={{ startDate, endDate }}
                isOpen
                onChange={onDateChange}
                toggleOpen={() => null}
              />
            </div>
            <p style={{ minWidth: '20rem', textAlign: 'right' }}>
              <span>max: 6 months, </span>
              <span>default: past 6 months</span>
            </p>

            <Button
              text="Download"
              onClick={() => {
                const dateRange = {
                  startDate: formatDate(startDate, 'YYYY-MM-DD'),
                  endDate: formatDate(endDate, 'YYYY-MM-DD'),
                };
                downloadWalletHistory(walletId, dateRange, (data) => {
                  if (data !== null) {
                    const filename = `wallet history ${dateRange.startDate} to ${dateRange.endDate}`;
                    const fields = {
                      amount: 'Amount',
                      status: 'Status',
                      timeCreated: 'Time Created',
                      transactionRef: 'Transaction Reference',
                      type: 'Transaction Type',
                      walletBalance: 'Wallet Balance',
                    };
                    saveAsCsv({ data: data.data, fields, filename });
                  }
                  setShow(false);
                });
              }}
            />
          </div>
        </Wrapper>
      </Modal>
    </div>
  );
};

DownloadWalletHistory.propTypes = {
  downloadWalletHistory: PropTypes.func.isRequired,
  walletId: PropTypes.string.isRequired,
};

export default connect(null, { downloadWalletHistory })(DownloadWalletHistory);
