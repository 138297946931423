import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import MmoComponent from 'src/components/users/mmo/Mmo';

const Mmo = () => {
  return (
    <DashboardLayout>
      <MmoComponent />
    </DashboardLayout>
  );
};

export default Mmo;
