import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  getAllTerminals,
  setTerminalLimit,
  setTerminalPage,
  setTerminalDate,
  resetTerminalDate,
  fetchTerminalTypes,
} from 'src/store/actions/terminalActions';
// import { formatDate } from 'src/utils/helpers';
// import { DateRangeInput, SelectInput } from 'src/components/common/inputs';
import CreateTerminal from './CreateTerminal';
import UploadTerminals from './UploadTerminals';
import TerminalsTable from './TerminalsTable';
import DevicesLayout from 'src/components/devices/DevicesLayout';
import DisplayCard from 'src/components/common/DisplayCard';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
// import { Card } from '@material-ui/core';
// import Icon from 'src/components/common/Icon';

// // Table Icons
// import RotateLeftIcon from '@material-ui/icons/RotateLeft';
// import DateRangeIcon from '@material-ui/icons/DateRange';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  height: max-content;
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .terminal-filters__wrapper {
    display: flex;
    justify-content: space-between;
  }
  .terminal-filters {
    display: flex;

    & > :not(:last-child) {
      margin-right: 1rem;
    }
  }
  .terminal-filter {
    display: grid;
    width: max-content;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 0.5rem;
  }
  .date_range {
    padding: 0rem 0.5rem;
    display: grid;
    border-radius: 5px;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const Terminals = ({ terminals, getAllTerminals, setTerminalLimit, setTerminalPage, fetchTerminalTypes }) => {
  useEffect(() => {
    fetchTerminalTypes();
  }, []);

  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);
  const [appIsUploaded] = useState('all');
  const [appIsLive] = useState('all');

  useEffect(() => {
    setTerminalPage(1);
    setTerminalLimit(20);
    const query = {};
    if (appIsUploaded && appIsUploaded !== 'all') {
      query.isUploaded = appIsUploaded;
    }
    if (appIsUploaded && appIsLive !== 'all') {
      query.appIsLive = appIsLive;
    }
    getAllTerminals(query);
  }, [appIsUploaded, appIsLive]);

  // const onAppIsUploadedChange = (value) => setAppIsUploaded(value);
  // const onAppIsLiveChange = (value) => setAppIsLive(value);

  const getTerminals = () => {
    const query = {};
    if (appIsUploaded && appIsUploaded !== 'all') {
      query.isUploaded = appIsUploaded;
    }
    if (appIsUploaded && appIsLive !== 'all') {
      query.appIsLive = appIsLive;
    }
    getAllTerminals(query);
  };

  const onPageChange = (page) => {
    setTerminalPage(page);
    getTerminals();
  };

  const onLimitChange = (limit) => {
    setTerminalLimit(limit);
    getTerminals();
  };

  // const onDateChange = (date) => {
  // 	setTerminalDate(date.startDate, date.endDate);
  // 	getTerminals();
  // };

  // const onResetDateClick = () => {
  // 	resetTerminalDate();
  // 	getAllTerminals();
  // };

  return (
    <DevicesLayout>
      <Wrapper>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, max-content)',
            gap: '0.5rem',
            justifyContent: 'end',
          }}
        >
          <CreateTerminal getAllTerminals={getAllTerminals} />
          <UploadTerminals getAllTerminals={getAllTerminals} />
        </div>
        <div className="cards">
          <DisplayCard
            icon={DesktopWindowsIcon}
            title="Total Terminal IDs"
            value={terminals !== null && terminals.count}
          />
        </div>
        {/* <div className="terminal-filters__wrapper">
					<div className="terminal-filters">
						<div className="terminal-filter">
							<h4>App Uploaded:</h4>
							<SelectInput options={appIsUploadedOrLiveOptions} value={appIsUploaded} onChange={onAppIsUploadedChange} />
						</div>
						<div className="terminal-filter">
							<h4>App Live:</h4>
							<SelectInput options={appIsUploadedOrLiveOptions} value={appIsLive} onChange={onAppIsLiveChange} />
						</div>
					</div>
					<Card className="date_range">
						<h6>{`${terminals === null ? '' : formatDate(terminals.start_date)} - ${terminals === null ? '' : formatDate(terminals.end_date)}`}</h6>
						<Icon Icon={DateRangeIcon} alt='Set Date' onClick={toggle} />
						<Icon Icon={RotateLeftIcon } alt='Reset Date' onClick={onResetDateClick}/>
					</Card>
				</div> */}
        <TerminalsTable tableData={terminals} onPageChange={onPageChange} onLimitChange={onLimitChange} />
        {/* <div className="range_input">
					<DateRangeInput
						isOpen={isOpen}
						onChange={onDateChange}
						toggleOpen={toggle}
					/>
				</div> */}
      </Wrapper>
    </DevicesLayout>
  );
};

// const appIsUploadedOrLiveOptions = [
// 	{
// 		text: 'All',
// 		value: 'all',
// 	},
// 	{
// 		text: 'Yes',
// 		value: 'true',
// 	},
// 	{
// 		text: 'No',
// 		value: 'false',
// 	},
// ];

Terminals.propTypes = {
  terminals: PropTypes.object.isRequired,
  getAllTerminals: PropTypes.func.isRequired,
  setTerminalLimit: PropTypes.func.isRequired,
  setTerminalPage: PropTypes.func.isRequired,
  setTerminalDate: PropTypes.func.isRequired,
  resetTerminalDate: PropTypes.func.isRequired,
  fetchTerminalTypes: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { terminals } = state.terminal;
  return { terminals };
};

export default connect(mapStateToProps, {
  getAllTerminals,
  setTerminalPage,
  setTerminalLimit,
  setTerminalDate,
  resetTerminalDate,
  fetchTerminalTypes,
})(Terminals);
