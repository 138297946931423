import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HomeIcon from '@material-ui/icons/Home';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PeopleIcon from '@material-ui/icons/People';
import NotificationsIcon from '@material-ui/icons/NotificationImportant';
// import FinanceIcon from '@material-ui/icons/MonetizationOn';
import SettingsCellIcon from '@material-ui/icons/SettingsCell';
import HelpIcon from '@material-ui/icons/Help';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LoanIcon from 'src/assets/img/yawo.svg';
import Icon from 'src/components/common/Icon';
import { connect } from 'react-redux';
import { logoutUser } from 'src/store/actions/userActions';

const Wrapper = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-gap: 0.5rem;
`;

const Nav = ({ logoutUser }) => {
  return (
    <Wrapper>
      <Icon Icon={HomeIcon} alt="Home" theme="grey" type="navLink" to="/dashboard" />
      <Icon Icon={ReceiptIcon} alt="Transactions" theme="grey" type="navLink" to="/transactions" />
      <Icon Icon={LoanIcon} localImage={true} alt="Yawo" theme="grey" type="navLink" to="/yawo" />
      <Icon Icon={PeopleIcon} alt="Users" theme="grey" type="navLink" to="/users" />
      <Icon Icon={SettingsCellIcon} alt="Devices" theme="grey" type="navLink" to="/pos" />
      <Icon Icon={HelpIcon} alt="Support" theme="grey" type="navLink" to="/support" />
      <Icon Icon={SupervisorAccountIcon} alt="Staffs" theme="grey" type="navLink" to="/staffs" />
      <Icon Icon={NotificationsIcon} alt="Notifications" theme="grey" type="navLink" to="/notifications" />
      {/* <Icon Icon={FinanceIcon} alt="Finance" theme='grey' type='navLink' to='/finance' /> */}
      <Icon Icon={ExitToAppIcon} alt="Logout" theme="grey" type="icon" onClick={logoutUser} />
    </Wrapper>
  );
};

Nav.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};

export default connect(null, { logoutUser })(Nav);
