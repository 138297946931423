import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StaffLayout from './StaffLayout';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from 'styled-components';
import Icon from 'src/components/common/Icon';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { withRouter } from 'react-router-dom';
import { getAllRoles } from 'src/store/actions/roleActions';
import { getSingleStaff, fetchStaffDepartments } from 'src/store/actions/staffActions';
import { connect } from 'react-redux';
import SuspendStaff from './SuspendStaff';
import ActivateStaff from './ActivateStaff';
import EditStaff from './EditStaff';

const Wrapper = styled.div`
  .back {
    display: flex;
    width: max-content;
    grid-gap: 1rem;
    align-items: center;
  }
  .section {
    border-top: 1px solid grey;
    min-height: 10rem;
    margin-top: 1rem;
    padding: 1rem 0rem;
  }
  .avatar {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
  }
  .img {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    margin: 0;
    padding: 0;
  }
  .profile {
    display: flex;
    grid-gap: 1rem;
    align-items: center;
  }
  .details_body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .details_item {
    display: grid;
    grid-gap: 0.5rem;
    min-width: 10rem;
  }
  .title_headers {
    padding-bottom: 1rem;
  }
  .item_header {
    color: ${(props) => props.theme.color.blue};
  }
`;

const ViewStaff = ({ getAllRoles, fetchStaffDepartments, match, getSingleStaff, staff, isLoading }) => {
  const { staffId } = match.params;

  useEffect(() => {
    getAllRoles({}, true);
    fetchStaffDepartments();
    getSingleStaff(staffId);
  }, [staffId, getSingleStaff]);

  const displayDetails = (title, value) => (
    <div className="details_item">
      <p className="item_header">{title}</p>
      <h4 className="item_text">{value}</h4>
    </div>
  );

  return (
    <StaffLayout>
      <Wrapper>
        {isLoading ? (
          <React.Fragment />
        ) : staff === null ? (
          <div>Staff Account Not Found</div>
        ) : (
          <React.Fragment>
            <div className="back">
              <Icon Icon={ArrowBackIcon} type="link" to="/staffs" alt="Back to Staffs" />
              <h3>{`${staff.firstName || ''} ${staff.lastName || ''}`}</h3>
            </div>
            <div className="section profile">
              <div className="avatar">
                <AccountCircleIcon className="img" />
              </div>
              <EditStaff />
              {staff.isActive ? <SuspendStaff staffId={staff.staffId} /> : <ActivateStaff staffId={staff.staffId} />}
            </div>
            <div className="section details">
              <div className="title_headers">
                <h3>Staff Details</h3>
              </div>
              <div className="details_body">
                {displayDetails('Full name', `${staff.firstName || ''} ${staff.lastName || ''}`)}
                {displayDetails('Department', staff.department)}
                {displayDetails('Role', staff.role)}
                {displayDetails('Staff Id', staff.staffId)}
                {displayDetails('Is Active', staff.isActive ? '✅' : '❌')}
                {displayDetails('Email address', staff.email?.address)}
                {displayDetails('Is Verified', staff.email?.isVerified ? '✅' : '❌')}
                {displayDetails('Phone Number', staff.phone?.number)}
                {displayDetails('Default Password', staff.defaultPassword ? '✅' : '❌')}
              </div>
            </div>
          </React.Fragment>
        )}
      </Wrapper>
    </StaffLayout>
  );
};

ViewStaff.propTypes = {
  match: PropTypes.object.isRequired,
  staff: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSingleStaff: PropTypes.func.isRequired,
  getAllRoles: PropTypes.func.isRequired,
  fetchStaffDepartments: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { staff, isLoading } = state.staff;
  return { staff, isLoading };
};

export default connect(mapStateToProps, { getSingleStaff, getAllRoles, fetchStaffDepartments })(withRouter(ViewStaff));
