import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import VendorsComponent from 'src/components/yawo/vendors/Vendors';

const Vendors = () => {
  return (
    <DashboardLayout>
      <VendorsComponent />
    </DashboardLayout>
  );
};

export default Vendors;
