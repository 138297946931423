import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import UserComponent from 'src/components/users/User';

const User = () => {
  return (
    <DashboardLayout>
      <UserComponent />
    </DashboardLayout>
  );
};

export default User;
