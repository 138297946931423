import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getAllChargebacks, setChargebackLimit, setChargebackPage } from 'src/store/actions/chargebackActions';
import LodgeChargeback from './LodgeChargeback';
import ChargebackTable from './ChargebackTable';
import FinanceLayout from 'src/components/finance/FinanceLayout';
import DisplayCard from 'src/components/common/DisplayCard';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  height: max-content;
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .mmo-filters__wrapper {
    display: flex;
    justify-content: space-between;
  }
  .mmo-filters {
    display: flex;

    & > :not(:last-child) {
      margin-right: 1rem;
    }
  }
  .mmo-filter {
    display: grid;
    width: max-content;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 0.5rem;
  }
  .date_range {
    padding: 0rem 0.5rem;
    display: grid;
    border-radius: 5px;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const Chargebacks = ({ chargebacks, getAllChargebacks, setChargebackLimit, setChargebackPage }) => {
  useEffect(() => {
    setChargebackPage(1);
    setChargebackLimit(20);
    const query = {};
    getAllChargebacks(query);
  }, []);

  const getChargebacks = () => {
    const query = {};
    getAllChargebacks(query);
  };

  const onPageChange = (page) => {
    setChargebackPage(page);
    getChargebacks();
  };

  const onLimitChange = (limit) => {
    setChargebackLimit(limit);
    getChargebacks();
  };

  return (
    <FinanceLayout>
      <Wrapper>
        <div className="cards">
          <DisplayCard
            icon={ChangeHistoryIcon}
            title="Total Chargebacks"
            value={chargebacks !== null && chargebacks.count}
          />
          <DisplayCard icon={ChangeHistoryIcon} title="Resolved" value={chargebacks !== null && chargebacks.resolved} />
          <DisplayCard
            icon={ChangeHistoryIcon}
            title="In Progress"
            value={chargebacks !== null && chargebacks.inProgress}
          />
        </div>
        <LodgeChargeback />
        <ChargebackTable tableData={chargebacks} onPageChange={onPageChange} onLimitChange={onLimitChange} />
      </Wrapper>
    </FinanceLayout>
  );
};

Chargebacks.propTypes = {
  chargebacks: PropTypes.object.isRequired,
  getAllChargebacks: PropTypes.func.isRequired,
  setChargebackLimit: PropTypes.func.isRequired,
  setChargebackPage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { chargebacks } = state.chargeback;
  return { chargebacks };
};

export default connect(mapStateToProps, {
  getAllChargebacks,
  setChargebackPage,
  setChargebackLimit,
})(Chargebacks);
