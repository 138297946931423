import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 250,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 0,
  },
}));

const CardPaymentsSearch = ({ searchString, onChange, onEnterPress }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Search Card Payments......"
        value={searchString}
        onChange={onChange}
        onKeyPress={onEnterPress}
      />
      <IconButton className={classes.iconButton} onClick={onEnterPress}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

CardPaymentsSearch.propTypes = {
  searchString: PropTypes.func.searchString,
  onChange: PropTypes.func.isRequired,
  onEnterPress: PropTypes.func.isRequired,
};

export default CardPaymentsSearch;
