import React from 'react';
//import PropTypes from 'prop-types';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import Overview from 'src/components/dashboard/Overview';

const Dashboard = () => {
  return (
    <DashboardLayout>
      <Overview />
    </DashboardLayout>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
