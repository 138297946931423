import QS from 'qs';
import client from 'src/client';
import { clearAlert, errorAlert, infoAlert, successAlert } from './alertActions';
import { dateFormatYMD } from 'src/utils/helpers';
import {
  ADD_TRANSACTIONS,
  ADD_TRANSACTION,
  CLEAR_TRANSACTIONS,
  SET_TRANSACTION_DATE,
  SET_TRANSACTION_PAGE,
  SET_TRANSACTION_LIMIT,
  SET_TRANSACTION_STATUS,
  RESET_TRANSACTION_DATE,
  TRANSACTION_IS_LOADING,
  TRANSACTION_NOT_LOADING,
  SET_IS_DOWNLOADING,
  SET_TRANSACTION_SEARCH_STRING,
} from 'src/store/types';

const { walletService, adminService } = client;

const transactionIsLoading =
  (msg = 'Loading Transactions') =>
  (dispatch) => {
    dispatch({ type: TRANSACTION_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const transactionNotLoading = (clearTheAlert) => (dispatch) => {
  if (clearTheAlert) dispatch(clearAlert());
  dispatch({ type: TRANSACTION_NOT_LOADING });
};

const addTransactions = (transactions) => {
  return { type: ADD_TRANSACTIONS, payload: transactions };
};

const addTransaction = (transaction) => {
  return { type: ADD_TRANSACTION, payload: transaction };
};

const clearTransactions = () => {
  return { type: CLEAR_TRANSACTIONS };
};

const generatePath = (endPoint, transaction, setLimit) => {
  if (transaction === null) {
    return endPoint;
  }

  const { start_date, end_date, limit = 20, page = 1, status } = transaction;

  const queryString = QS.stringify({
    limit: setLimit || limit,
    page,
    status: status === 'all' ? undefined : status,
    start_date: dateFormatYMD(start_date),
    end_date: dateFormatYMD(end_date),
  });

  return `${endPoint}?${queryString}`;
};

export const setTransactionDate = (start_date, end_date) => {
  return { type: SET_TRANSACTION_DATE, payload: { start_date, end_date } };
};

export const resetTransactionDate = () => {
  return { type: RESET_TRANSACTION_DATE };
};

export const setTransactionPage = (page) => {
  return { type: SET_TRANSACTION_PAGE, payload: page };
};

export const setTransactionLimit = (limit) => {
  return { type: SET_TRANSACTION_LIMIT, payload: limit };
};

export const setTransactionStatus = (status) => {
  return { type: SET_TRANSACTION_STATUS, payload: status };
};

export const setTransactionSearchString = (searchparam) => {
  return { type: SET_TRANSACTION_SEARCH_STRING, payload: searchparam };
};

export const getAllTransactions =
  (platform, refresh = false) =>
  (dispatch, getState) => {
    const { transaction } = getState();
    const { searchString } = transaction || {};
    if (searchString) return dispatch(searchTransactions(searchString));
    !refresh && dispatch(transactionIsLoading());
    const path = generatePath(`history/${platform}`, transaction.transactions);
    !refresh && dispatch(clearTransactions());
    walletService
      .get(path)
      .then((res) => {
        dispatch(addTransactions(res.data));
        dispatch(transactionNotLoading(true));
      })
      .catch((err) => {
        const error = err.response.data.message;
        dispatch(errorAlert({ msg: error }));
        dispatch(transactionNotLoading());
      });
  };

export const searchTransactions =
  (param, refresh = false) =>
  (dispatch, getState) => {
    !refresh && dispatch(transactionIsLoading());
    const { transaction } = getState();
    const path = generatePath(`transactions/search/${param}`, transaction.transactions);
    !refresh && dispatch(clearTransactions());
    walletService
      .get(path)
      .then((res) => {
        dispatch(addTransactions(res.data));
        dispatch(transactionNotLoading(true));
      })
      .catch((err) => {
        const error = err.response.data.message;
        dispatch(errorAlert({ msg: error }));
        dispatch(transactionNotLoading());
      });
  };

export const getTransactionsByType =
  (platform, type, refresh = false) =>
  (dispatch, getState) => {
    const { transaction } = getState();
    const { searchString } = transaction || {};
    if (searchString) return dispatch(searchTransactions(searchString));
    !refresh && dispatch(transactionIsLoading());
    const path = generatePath(`history/transactions/${platform}/${type}`, transaction.transactions);
    !refresh && dispatch(clearTransactions());
    walletService
      .get(path)
      .then((res) => {
        dispatch(addTransactions(res.data));
        dispatch(transactionNotLoading(true));
      })
      .catch((err) => {
        const error = err.response.data.message;
        dispatch(errorAlert({ msg: error }));
        dispatch(transactionNotLoading());
      });
  };

export const getPosActivationTransactions =
  (platform, refresh = false) =>
  (dispatch, getState) => {
    const { transaction } = getState();
    // const { searchString } = transaction || {};
    // if (searchString) return dispatch(searchTransactions(searchString));
    !refresh && dispatch(transactionIsLoading());
    const path = generatePath('transactions/pos-activation', transaction.transactions);
    !refresh && dispatch(clearTransactions());
    adminService
      .get(path + (platform !== 'ikonik' ? `&platform=${platform}` : ''))
      .then((res) => {
        dispatch(addTransactions(res.data.data));
        dispatch(transactionNotLoading(true));
      })
      .catch((err) => {
        const error = err.response.data.message;
        dispatch(errorAlert({ msg: error }));
        dispatch(transactionNotLoading());
      });
  };

const setIsDownloading = (state) => {
  return { type: SET_IS_DOWNLOADING, payload: state };
};

export const getTransactionsForDownload = (data, callback) => (dispatch, getState) => {
  const { type, platform, limit } = data;
  dispatch(setIsDownloading(true));
  const { transaction } = getState();
  const rootPath = type ? `history/transactions/${platform}/${type}` : `history/${platform}`;
  const path = generatePath(rootPath, transaction.transactions, limit);
  walletService
    .get(path)
    .then((res) => {
      callback(res.data);
      dispatch(setIsDownloading(false));
    })
    .catch((err) => {
      const error = err.response.data.message;
      dispatch(errorAlert({ msg: error }));
      dispatch(setIsDownloading(false));
    });
};

export const getTransaction = (transactionRef) => (dispatch) => {
  dispatch(transactionIsLoading());
  walletService
    .get(`history/transaction/${transactionRef}`)
    .then((res) => {
      dispatch(addTransaction(res.data));
      dispatch(transactionNotLoading(true));
    })
    .catch(() => {
      dispatch(errorAlert({ msg: 'Something went wrong. Try Again' }));
      dispatch(transactionNotLoading());
    });
};

const generateWalletIdPath = (endPoint, type, transactions) => {
  if (transactions === null) {
    return `${endPoint}?type=${type}`;
  }
  const { start_date, end_date, limit, page, status } = transactions;

  if (start_date === null || end_date === null) {
    if (status === 'all') {
      return `${endPoint}?type=${type}&limit=${limit}&page=${page}`;
    }

    return `${endPoint}?type=${type}&limit=${limit}&status=${status}&page=${page}`;
  }

  if (status === 'all') {
    return `${endPoint}?type=${type}&limit=${limit}&page=${page}&start_date=${dateFormatYMD(
      start_date
    )}&end_date=${dateFormatYMD(end_date)}`;
  }

  return `${endPoint}?type=${type}&limit=${limit}&status=${status}&page=${page}&start_date=${dateFormatYMD(
    start_date
  )}&end_date=${dateFormatYMD(end_date)}`;
};

export const getTransactionsByWalletId = (walletId, type) => (dispatch, getState) => {
  dispatch(transactionIsLoading());
  const { transaction } = getState();
  const path = generateWalletIdPath(`history/transactions/${walletId}`, type, transaction.transactions);
  dispatch(clearTransactions());
  walletService
    .get(path)
    .then((res) => {
      dispatch(addTransactions(res.data));
      dispatch(transactionNotLoading(true));
    })
    .catch((err) => {
      const error = err.response.data.message;
      dispatch(errorAlert({ msg: error }));
      dispatch(transactionNotLoading());
    });
};

export const refreshTransaction = (transactionRef, callback) => (dispatch) => {
  dispatch(transactionIsLoading('Refreshing...'));
  walletService
    .get(`/transactions/refresh/${transactionRef}`)
    .then(() => dispatch(successAlert('Transaction Successfully Refreshed')))
    .catch((err) => {
      const error = err?.response?.data?.message || 'Error Refreshing Transaction';
      dispatch(errorAlert({ msg: error }));
    })
    .finally(() => {
      dispatch(transactionNotLoading());
      if (callback) callback();
    });
};

export const updateTransactionStatus = (transactionRef, status, callback) => (dispatch) => {
  // eslint-disable-next-line no-console
  console.log(transactionRef);
  dispatch(transactionIsLoading('Updating...'));
  adminService
    .post(`/transactions/${status}`, { transactionRef })
    .then(() => dispatch(successAlert('Transaction Successfully Updated')))
    .catch((err) => {
      const error = err?.response?.data?.msg || 'Error Updating Transaction';
      dispatch(errorAlert({ msg: error }));
    })
    .finally(() => {
      dispatch(transactionNotLoading());
      if (callback) callback();
    });
};
