import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import PosTransactionsComponent from 'src/components/devices/oldpos/PosTransactions';

const PosTransactions = () => {
  return (
    <DashboardLayout>
      <PosTransactionsComponent />
    </DashboardLayout>
  );
};

export default PosTransactions;
