import jwtDecode from 'jwt-decode';
import client, { setAuthToHeader, removeAuthFromHeader } from 'src/client';
import { appIsLoading, appNotLoading } from 'src/store/actions/appActions';
import { errorAlert, clearAlert, infoAlert, successAlert } from './alertActions';
import { SET_USER, SET_AUTH, CLEAR_SESSION } from 'src/store/types';

const { adminService } = client;

const setIsAuthenticated = (state) => {
  return { type: SET_AUTH, payload: state };
};

const setUser = (user) => {
  return { type: SET_USER, payload: user };
};

export const isLoggedIn = () => {
  const token = localStorage.getItem('kq_dfe');
  if (token) {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000000000;
    if (decoded.exp < currentTime) {
      return false;
    } else {
      setAuthToHeader(token);
      return decoded;
    }
  } else {
    return false;
  }
};

export const setCurrentUser = () => (dispatch) => {
  const decoded = isLoggedIn();
  if (decoded) {
    adminService
      .get('auth/me')
      .then((res) => {
        dispatch(setUser(res.data));
        dispatch(setIsAuthenticated(true));
        dispatch(appNotLoading());
      })
      .catch(() => {
        dispatch(logoutUser());
      });
  } else {
    dispatch(logoutUser());
  }
};

export const loginUser = (userData) => (dispatch) => {
  dispatch(clearAlert());
  dispatch(infoAlert('Logging In'));
  adminService
    .post('/auth/login', userData)
    .then((res) => {
      dispatch(appIsLoading());
      const { token } = res.data;
      localStorage.setItem('kq_dfe', token);
      dispatch(setCurrentUser());
      dispatch(clearAlert());
    })
    .catch((err) => {
      const error = err.response.data.message;
      dispatch(errorAlert({ msg: error }));
      dispatch(appNotLoading());
    });
};

// // Old Login
// export const loginUser = (userData) => dispatch => {
// 	dispatch(clearAlert());
// 	dispatch(infoAlert('Logging In'));
// 	authService.post('/auth/login/admin', userData)
// 		.then(res => {
// 			dispatch(appIsLoading());
// 			const token = res.data.accessToken;
// 			localStorage.setItem('kq_dfe', token);
// 			dispatch(setCurrentUser());
// 			dispatch(clearAlert());
// 		})
// 		.catch(err => {
// 			const error = err.response.data.message;
// 			dispatch(errorAlert({msg: error}));
// 			dispatch(appNotLoading());
// 		});
// };

export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem('kq_dfe');
  removeAuthFromHeader();
  dispatch({ type: CLEAR_SESSION });
  dispatch(appNotLoading());
};

export const forgotPassword =
  (email, callback = null) =>
  (dispatch) => {
    dispatch(clearAlert());
    dispatch(infoAlert('Submitting Email'));
    adminService
      .get(`/auth/forgot/${email}`)
      .then(() => {
        dispatch(successAlert('Check your email to continue this process'));
        if (callback && callback.success) callback.success();
      })
      .catch((err) => {
        const error = err.response.data.message;
        dispatch(errorAlert({ msg: error }));
      })
      .finally(() => dispatch(appNotLoading()));
  };

export const verifyResetToken =
  (token, callback = null) =>
  (dispatch) => {
    dispatch(clearAlert());
    adminService
      .post('/auth/verify', { token })
      .then(() => {
        if (callback && callback.success) callback.success();
      })
      .catch((err) => {
        const error = err.response.data.token || err.response.data.message;
        dispatch(errorAlert({ msg: error }));
      })
      .finally(() => dispatch(appNotLoading()));
  };

export const resetPassword =
  (data, callback = null) =>
  (dispatch) => {
    dispatch(clearAlert());
    dispatch(infoAlert('Reseting Password'));
    adminService
      .post('/auth/reset', data)
      .then(() => {
        dispatch(successAlert('Your Password Reset Is Successful'));
        if (callback && callback.success) callback.success();
      })
      .catch((err) => {
        const error = err.response.data.confirmPassword || err.response.data.token || err.response.data.message;
        dispatch(errorAlert({ msg: error }));
      })
      .finally(() => dispatch(appNotLoading()));
  };
