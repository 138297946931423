import {
  YAWO_WALLET_IS_LOADING,
  YAWO_WALLET_NOT_LOADING,
  ADD_YAWO_WALLETS,
  ADD_YAWO_WALLET,
  SET_YAWO_WALLET_DATE,
  RESET_YAWO_WALLET_DATE,
  SET_YAWO_WALLET_LIMIT,
  SET_YAWO_WALLET_PAGE,
  SET_YAWO_WALLET_STATUS,
  SET_YAWO_WALLET_IUWI,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  yawoWallets: null,
  yawoWallet: null,
  yawoWalletIuwi: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case YAWO_WALLET_IS_LOADING:
      return { ...state, isLoading: true };
    case YAWO_WALLET_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_YAWO_WALLETS:
      return { ...state, yawoWallets: action.payload };
    case ADD_YAWO_WALLET:
      return { ...state, yawoWallet: action.payload };
    case SET_YAWO_WALLET_DATE:
      return {
        ...state,
        yawoWallets: {
          ...state,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };
    case RESET_YAWO_WALLET_DATE:
      return {
        ...state,
        yawoWallets: {
          ...state,
          startDate: null,
          endDate: null,
        },
      };
    case SET_YAWO_WALLET_LIMIT:
      return {
        ...state,
        yawoWallets: { ...state, limit: action.payload },
      };
    case SET_YAWO_WALLET_PAGE:
      return {
        ...state,
        yawoWallets: { ...state, page: action.payload },
      };
    case SET_YAWO_WALLET_STATUS:
      return {
        ...state,
        yawoWallets: { ...state, status: action.payload },
      };
    case SET_YAWO_WALLET_IUWI:
      return { ...state, yawoWalletIuwi: action.payload };
    default:
      return state;
  }
}
