import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  getAllYawoCustomers,
  setYawoCustomerLimit,
  setYawoCustomerPage,
  setYawoCustomerDate,
  resetYawoCustomerDate,
} from 'src/store/actions/yawo/customerActions';
import AsyncSelect from 'react-select/async';
import reactSelectUtil from 'src/utils/reactSelectUtil';
import { loadAgentOptions } from 'src/store/actions/yawo/agentActions';
// import { formatDate } from 'src/utils/helpers';
// import { DateRangeInput } from 'src/components/common/inputs';
import YawoLayout from 'src/components/yawo/YawoLayout';
import CustomersTable from './CustomersTable';
// import { Card } from '@material-ui/core';
import DisplayCard from 'src/components/common/DisplayCard';
// import Icon from 'src/components/common/Icon';

// Table Icons
import BusinessIcon from '@material-ui/icons/Business';
// import RotateLeftIcon from '@material-ui/icons/RotateLeft';
// import DateRangeIcon from '@material-ui/icons/DateRange';

const Wrapper = styled.div`
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .wallet--body {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .date-selector {
    display: flex;
    justify-content: flex-end;

    .date_range {
      padding: 0rem 0.5rem;
      display: grid;
      border-radius: 5px;
      grid-template-columns: max-content max-content max-content;
      grid-gap: 0.5rem;
      align-items: center;
      justify-content: center;
    }
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const Customers = ({ yawoCustomers, getAllYawoCustomers, setYawoCustomerLimit, setYawoCustomerPage }) => {
  const [agent, setAgent] = useState(null);
  const [status, setStatus] = useState('all');
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);

  const getYawoCustomers = () => {
    const query = {};
    if (agent?.value) {
      query.agentId = agent?.value;
    }
    if (status && status !== 'all') {
      query.status = status;
    }
    getAllYawoCustomers(query);
  };

  useEffect(() => {
    setYawoCustomerPage(1);
    setYawoCustomerLimit(20);
    getYawoCustomers();
  }, [status, agent]);

  const onPageChange = (page) => {
    setYawoCustomerPage(page);
    getYawoCustomers();
  };

  const onLimitChange = (limit) => {
    setYawoCustomerLimit(limit);
    getYawoCustomers();
  };

  return (
    <YawoLayout>
      <Wrapper>
        <div className="wallet--body">
          <div className="cards">
            <DisplayCard
              icon={BusinessIcon}
              title="Total Customers"
              value={yawoCustomers !== null && yawoCustomers.count}
            />
          </div>
          {/* <div className="date-selector">
            <Card className="date_range">
              <h6>{`${formatDate('2019-01-01 00:00:00')} - ${formatDate('2022-02-10 00:00:00')}`}</h6>
              <Icon Icon={DateRangeIcon} alt="Set Date" onClick={toggle} />
              <Icon Icon={RotateLeftIcon} alt="Reset Date" onClick={null} />
            </Card>
          </div> */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <h4>Agent:</h4>
            <div style={{ width: '20rem' }}>
              <AsyncSelect
                name="agent"
                placeholder="Select Agent"
                cacheOptions
                loadOptions={(val) => loadAgentOptions(val)}
                theme={reactSelectUtil.theme}
                styles={reactSelectUtil.styles}
                isClearable
                onChange={(newValue) => setAgent(newValue)}
                value={agent}
              />
            </div>
          </div>
          <CustomersTable
            tableData={yawoCustomers}
            status={status}
            setStatus={(val) => setStatus(val)}
            onPageChange={onPageChange}
            onLimitChange={onLimitChange}
          />
          {/* <div className="range_input">
            <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
          </div> */}
        </div>
      </Wrapper>
    </YawoLayout>
  );
};

Customers.propTypes = {
  yawoCustomers: PropTypes.object.isRequired,
  getAllYawoCustomers: PropTypes.func.isRequired,
  setYawoCustomerLimit: PropTypes.func.isRequired,
  setYawoCustomerPage: PropTypes.func.isRequired,
  setYawoCustomerDate: PropTypes.func.isRequired,
  resetYawoCustomerDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { yawoCustomers } = state.yawoCustomer;
  return { yawoCustomers };
};

export default connect(mapStateToProps, {
  getAllYawoCustomers,
  setYawoCustomerLimit,
  setYawoCustomerPage,
  setYawoCustomerDate,
  resetYawoCustomerDate,
})(Customers);
