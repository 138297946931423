import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  getAllYawoVendors,
  setYawoVendorLimit,
  setYawoVendorPage,
  setYawoVendorDate,
  resetYawoVendorDate,
} from 'src/store/actions/yawo/vendorActions';
// import { formatDate } from 'src/utils/helpers';
// import { DateRangeInput } from 'src/components/common/inputs';
import YawoLayout from 'src/components/yawo/YawoLayout';
import VendorsTable from './VendorsTable';
// import { Card } from '@material-ui/core';
import DisplayCard from 'src/components/common/DisplayCard';
// import Icon from 'src/components/common/Icon';

// Table Icons
import BusinessIcon from '@material-ui/icons/Business';
// import RotateLeftIcon from '@material-ui/icons/RotateLeft';
// import DateRangeIcon from '@material-ui/icons/DateRange';

const Wrapper = styled.div`
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .wallet--body {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .date-selector {
    display: flex;
    justify-content: flex-end;

    .date_range {
      padding: 0rem 0.5rem;
      display: grid;
      border-radius: 5px;
      grid-template-columns: max-content max-content max-content;
      grid-gap: 0.5rem;
      align-items: center;
      justify-content: center;
    }
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const Vendors = ({ yawoVendors, getAllYawoVendors, setYawoVendorLimit, setYawoVendorPage }) => {
  const [status, setStatus] = useState('all');
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    setYawoVendorPage(1);
    setYawoVendorLimit(20);
    const query = {};
    if (status && status !== 'all') {
      query.status = status;
    }
    getAllYawoVendors(query);
  }, [status]);

  const getYawoVendors = () => {
    const query = {};
    if (status && status !== 'all') {
      query.status = status;
    }
    getAllYawoVendors(query);
  };

  const onPageChange = (page) => {
    setYawoVendorPage(page);
    getYawoVendors();
  };

  const onLimitChange = (limit) => {
    setYawoVendorLimit(limit);
    getYawoVendors();
  };

  return (
    <YawoLayout>
      <Wrapper>
        <div className="wallet--body">
          <div className="cards">
            <DisplayCard icon={BusinessIcon} title="Total Vendors" value={yawoVendors !== null && yawoVendors.count} />
          </div>
          {/* <div className="date-selector">
            <Card className="date_range">
              <h6>{`${formatDate('2019-01-01 00:00:00')} - ${formatDate('2022-02-10 00:00:00')}`}</h6>
              <Icon Icon={DateRangeIcon} alt="Set Date" onClick={toggle} />
              <Icon Icon={RotateLeftIcon} alt="Reset Date" onClick={null} />
            </Card>
          </div> */}
          <VendorsTable
            tableData={yawoVendors}
            status={status}
            setStatus={(val) => setStatus(val)}
            onPageChange={onPageChange}
            onLimitChange={onLimitChange}
          />
          {/* <div className="range_input">
            <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
          </div> */}
        </div>
      </Wrapper>
    </YawoLayout>
  );
};

Vendors.propTypes = {
  yawoVendors: PropTypes.object.isRequired,
  getAllYawoVendors: PropTypes.func.isRequired,
  setYawoVendorLimit: PropTypes.func.isRequired,
  setYawoVendorPage: PropTypes.func.isRequired,
  setYawoVendorDate: PropTypes.func.isRequired,
  resetYawoVendorDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { yawoVendors } = state.yawoVendor;
  return { yawoVendors };
};

export default connect(mapStateToProps, {
  getAllYawoVendors,
  setYawoVendorLimit,
  setYawoVendorPage,
  setYawoVendorDate,
  resetYawoVendorDate,
})(Vendors);
