import {
  NOTIFICATION_IS_LOADING,
  NOTIFICATION_NOT_LOADING,
  ADD_NOTIFICATIONS,
  SET_NOTIFICATION_DATE,
  RESET_NOTIFICATION_DATE,
  SET_NOTIFICATION_LIMIT,
  SET_NOTIFICATION_PAGE,
  SET_NOTIFICATION_STATUS,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  notifications: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_IS_LOADING:
      return { ...state, isLoading: true };
    case NOTIFICATION_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case SET_NOTIFICATION_DATE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
        },
      };
    case RESET_NOTIFICATION_DATE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          start_date: null,
          end_date: null,
        },
      };
    case SET_NOTIFICATION_LIMIT:
      return {
        ...state,
        notifications: { ...state.notifications, limit: action.payload },
      };
    case SET_NOTIFICATION_PAGE:
      return {
        ...state,
        notifications: { ...state.notifications, page: action.payload },
      };
    case SET_NOTIFICATION_STATUS:
      return {
        ...state,
        notifications: { ...state.notifications, status: action.payload },
      };
    default:
      return state;
  }
}
