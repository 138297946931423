import React from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/common/table/Table';
import { fwCardPaymentsTableHeader } from './fwCardPaymentsTableHeader';
import { sortTableData } from 'src/utils/helpers';

const FwCardPaymentsTable = ({ tableData, onPageChange, onLimitChange }) => {
  const leftHeader = null;

  return (
    <div>
      <Table
        headers={fwCardPaymentsTableHeader}
        rightHeader={null}
        leftHeader={leftHeader}
        data={tableData && tableData.data ? sortTableData(tableData.data, {}) : []}
        limit={tableData && tableData.limit}
        page={tableData && tableData.page}
        count={tableData && tableData.count}
        showNav={true}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </div>
  );
};

FwCardPaymentsTable.defaultProps = {
  tableData: { data: [], limit: 0, page: 1, count: 0 },
};

FwCardPaymentsTable.propTypes = {
  tableData: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
};

export default FwCardPaymentsTable;
