import React from 'react';
import { formatDate, formatAmount, formatTime } from 'src/utils/helpers';
import Icon from 'src/components/common/Icon';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export const vendorsTableHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Short Code</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['shortCode']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Is Verified</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['isVerified'] ? '✅' : '❌'}</p>;
    },
  },
  {
    title: function title() {
      return <p>Is Active</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['isActive'] ? '✅' : '❌'}</p>;
    },
  },
  {
    title: function title() {
      return <p>loan Limit</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{formatAmount(rowData['loanLimit'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Yawo Wallet</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{rowData['yawoWallet'] || ''}</p>;
    },
  },
  {
    title: function title() {
      return <p>Date</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{formatDate(rowData['createdAt'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Time</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{formatTime(rowData['createdAt'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>View</p>;
    },
    render: function SerialNumber(rowData) {
      return <Icon Icon={OpenInNewIcon} type="link" to={`vendors/${rowData['shortCode']}`} />;
    },
  },
];
