import React from 'react';
import { formatDate, formatAmount, formatTime } from 'src/utils/helpers';
import Icon from 'src/components/common/Icon';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LoanStatus from './LoanStatus';
import { Link } from 'react-router-dom';

export const loansTableHeader = [
  {
    title: function title() {
      return <p>Amount</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{formatAmount(rowData['amount'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Loan ref</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['loanRef']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Vendor</p>;
    },
    render: function DateTaken(rowData) {
      return (
        <Link className="linked-shortcode" to={`/users/mmo/${rowData['shortCode']}`}>
          {rowData['shortCode']}
        </Link>
      );
    },
  },
  {
    title: function title() {
      return <p>Customer</p>;
    },
    render: function DateTaken(rowData) {
      return (
        <p>
          {rowData['loanCustomer']?.firstName} {rowData['loanCustomer']?.lastName}
        </p>
      );
    },
  },
  {
    title: function title() {
      return <p>Guarantor</p>;
    },
    render: function SerialNumber(rowData) {
      return (
        <p>
          {rowData['loanGuarantor']?.firstName} {rowData['loanGuarantor']?.lastName}
        </p>
      );
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function SerialNumber(rowData) {
      return <LoanStatus status={rowData['status']} />;
    },
  },
  {
    title: function title() {
      return <p>Date</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{formatDate(rowData['createdAt'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Time</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{formatTime(rowData['createdAt'])}</p>;
    },
  },

  {
    title: function title() {
      return <p>View</p>;
    },
    render: function SerialNumber(rowData) {
      return <Icon Icon={OpenInNewIcon} type="link" to={`loans/${rowData['_id']}`} />;
    },
  },
];
