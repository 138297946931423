import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import ModalTwo from 'src/components/common/ModalTwo';
import { TextInput, SelectInput } from 'src/components/common/inputs';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { uploadReconciliationReport, getAllReconciliations } from 'src/store/actions/reconciliationActions';
// import { csvToArray } from 'src/utils/helpers';

const Wrapper = styled.div`
  h2 {
    font-size: x-large;
  }

  .form-section-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    align-items: end;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .long {
      grid-column-end: span 1;
    }

    .longer {
      grid-column-end: span 2;

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .longest {
      grid-column-end: span 3;

      @media (max-width: 768px) {
        grid-column-end: span 2;
      }

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .align-start {
      align-self: start;
    }

    .align-center {
      align-self: center;
    }

    .align-end {
      align-self: end;
    }

    .justify-end {
      justify-self: end;
    }
  }

  .label {
    color: ${(props) => props.theme.color.black};
    min-width: max-content;
  }
`;

const ExtractTransactions = ({ uploadReconciliationReport, getAllReconciliations }) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [vendor, setVendor] = useState('');
  const [file, setFile] = useState(null);
  // const [date, setDate] = useState('');

  const onFileChange = (files) => setFile(files[0] || null);
  const resetFile = () => {
    setFile(null);
    document.getElementById('file').files = new DataTransfer().files;
  };

  const resetFormState = () => {
    document.getElementById('extractTransactionsForm').reset();
    setVendor('');
    resetFile();
    // setDate('');
  };

  const submitForm = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('vendor', vendor);
    formData.append('file', file, file.name);
    // formData.append('date', date);

    setIsSubmitting(true);
    uploadReconciliationReport(formData, {
      success: () => {
        setShow(false);
        resetFormState();
        if (getAllReconciliations) getAllReconciliations();
      },
      always: () => {
        setIsSubmitting(false);
      },
    });
  };

  return (
    <Wrapper>
      <div className="form-section-grid">
        <div className="longest justify-end">
          <Button text="Extract Transactions" onClick={toggleShow} />
        </div>
      </div>

      <ModalTwo size="large" active={show} onModalCloseCallback={toggleShow}>
        <div>
          <center>
            <h2>Extract Transactions</h2>
          </center>

          <br />

          <form
            id="extractTransactionsForm"
            onSubmit={submitForm}
            className="form-section-grid"
            encType="multipart/form-data"
          >
            <div className="align-start">
              <SelectInput label="Select Vendor" options={vendorOptions} value={vendor} onChange={setVendor} required />
            </div>

            <div className="align-start">
              <TextInput type="file" label="Select Transactions CSV File" onChange={onFileChange} required />
            </div>

            {/* <div className="align-start">
							<TextInput type="date" label="Date" value={date} onChange={setDate} required />
						</div> */}

            <div className="longest justify-end">
              <Button type="submit" text={isSubmitting ? 'Extracting ...' : 'Extract'} disabled={isSubmitting} />
            </div>
          </form>
        </div>
      </ModalTwo>
    </Wrapper>
  );
};

const vendorOptions = [
  {
    text: 'Ikonik',
    value: 'ikonik',
  },
  {
    text: 'Flutterwave',
    value: 'flutterwave',
  },
];

ExtractTransactions.propTypes = {
  uploadReconciliationReport: PropTypes.func.isRequired,
  getAllReconciliations: PropTypes.func,
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { uploadReconciliationReport, getAllReconciliations })(ExtractTransactions);
