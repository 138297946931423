import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import Modal from 'src/components/common/Modal';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { rejectLoan } from 'src/store/actions/yawo/loanActions';
import { TextInput } from 'src/components/common/inputs';

const Wrapper = styled.div`
  width: max-content;
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    display: flex;
    justify-content: space-between;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .confirm {
    display: grid;
    grid-gap: 1rem;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0rem;
  }
  .buttons {
    padding-top: 0.5rem;
    display: flex;
    justify-content: space-between;
  }
  .buttons > * {
    width: 7rem;
  }
`;

const RejectLoan = ({ rejectLoan, loanId }) => {
  const [show, setShow] = useState(false);
  const [loanRejectReason, setLoanRejectReason] = useState('');

  const toggleShow = () => setShow(!show);

  const onSubmit = (event) => {
    event.preventDefault();
    rejectLoan({ loanId, message: loanRejectReason });
  };

  return (
    <div>
      <Button text="Reject Loan" theme="red" onClick={toggleShow} />
      <Modal isOpen={show} handleClose={toggleShow}>
        <Wrapper>
          <form onSubmit={onSubmit}>
            <div className="header">
              <div className="header_title">
                <h1>Reject Loan</h1>
              </div>

              <h6 onClick={toggleShow} className="cancel">
                X
              </h6>
            </div>

            <div className="confirm">
              <TextInput
                label="Enter reason for Loan rejection"
                value={loanRejectReason}
                onChange={setLoanRejectReason}
                required
              />

              <div className="buttons">
                <div style={{ minWidth: '10rem' }}>
                  <Button text="Reject" type="submit" theme="purple" />
                </div>
                <div>
                  <Button text="Cancel" onClick={toggleShow} theme="purple" />
                </div>
              </div>
            </div>
          </form>
        </Wrapper>
      </Modal>
    </div>
  );
};

RejectLoan.propTypes = {
  rejectLoan: PropTypes.func.isRequired,
  loanId: PropTypes.string.isRequired,
};

export default connect(null, { rejectLoan })(RejectLoan);
