import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StaffLayout from 'src/components/staffs/StaffLayout';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from 'styled-components';
import Icon from 'src/components/common/Icon';
import Button from 'src/components/common/Button';
import { withRouter } from 'react-router-dom';
import { getSingleRole, fetchRolePermissions, activateRole, archiveRole } from 'src/store/actions/roleActions';
import { connect } from 'react-redux';
import { formatDate } from 'src/utils/helpers';
import EditRole from './EditRole';

const Wrapper = styled.div`
  .actions {
    display: flex;
    gap: 0.25rem;
  }

  .back {
    display: flex;
    width: max-content;
    grid-gap: 1rem;
    align-items: center;
  }
  .section {
    border-top: 1px solid grey;
    min-height: 10rem;
    margin-top: 1rem;
    padding: 1rem 0rem;
  }
  .avatar {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
  }
  .img {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    margin: 0;
    padding: 0;
  }
  .profile {
    display: flex;
    grid-gap: 1rem;
    align-items: center;
  }
  .details_body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }

  .main_body {
    padding: 1rem;
    background-color: ${(props) => props.theme.color.white};
    border-radius: 0.5rem;
    min-height: calc(100vh - 6rem);

    & > :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .header_text {
    text-align: center;
  }
  .role_body {
    padding: 0.5rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
  .left {
    border-right: 1px solid ${(props) => props.theme.color.grey};
  }
  .section_title {
    color: ${(props) => props.theme.color.deepGrey};
    margin-top: 1rem;
  }
  .item {
    display: flex;
    padding-top: 0.5rem;
  }
  .item_title {
    width: 10rem;
  }
  .item_value {
    color: ${(props) => props.theme.color.deepGrey};
  }
`;

const ViewRole = ({ match, getSingleRole, role, isLoading, fetchRolePermissions, activateRole, archiveRole }) => {
  const { roleId } = match.params;

  useEffect(() => {
    fetchRolePermissions();
    getSingleRole(roleId);
  }, [roleId]);

  const displayInfo = (title, value) => (
    <div className="item">
      <h4 className="item_title">{title || ''}</h4>
      <h4 className="item_value">{value || ''}</h4>
    </div>
  );

  return (
    <StaffLayout>
      <Wrapper>
        <div className="main_body">
          <h2 className="header_text">Role Details</h2>
          <div className="back">
            <Icon Icon={ArrowBackIcon} type="link" to="/staffs/roles" alt="Back to Roles" text="Back" />
            <span>Back</span>
          </div>
          <div className="role_body">
            {isLoading ? (
              <React.Fragment />
            ) : role === null ? (
              <div>Role Not Found</div>
            ) : (
              <React.Fragment>
                <div className="left">
                  <div>
                    <h5 className="section_title">GENERAL INFO</h5>
                    {displayInfo('Name:', role.name || '')}
                    {displayInfo('Role ID:', role.roleId || '')}
                    {displayInfo('Is Active:', role.isActive ? 'True' : 'False')}
                    {displayInfo(
                      'Created At:',
                      role.createdAt && role.createdAt !== 'None' ? formatDate(role.createdAt) : ''
                    )}
                    {displayInfo(
                      'Updated At:',
                      role.updatedAt && role.updatedAt !== 'None' ? formatDate(role.updatedAt) : ''
                    )}
                  </div>
                </div>
                <div>
                  <div>
                    <div className="actions">
                      <EditRole />
                      {role.isActive ? (
                        <Button text="Archive Role" onClick={() => archiveRole(role.roleId)} theme="red" />
                      ) : (
                        <Button text="Activate Role" onClick={() => activateRole(role.roleId)} />
                      )}
                    </div>
                    <h5 className="section_title">{(role && role.permissions?.length) || 0} PERMISSIONS</h5>
                    {role &&
                      role.permissions &&
                      role.permissions.map((permissionCode, index) => (
                        <React.Fragment key={index}>{displayInfo(permissionCode, '')}</React.Fragment>
                      ))}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </Wrapper>
    </StaffLayout>
  );
};

ViewRole.propTypes = {
  match: PropTypes.object.isRequired,
  role: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSingleRole: PropTypes.func.isRequired,
  fetchRolePermissions: PropTypes.func.isRequired,
  activateRole: PropTypes.func.isRequired,
  archiveRole: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { role, isLoading } = state.role;
  return { role, isLoading };
};

export default connect(mapStateToProps, {
  getSingleRole,
  fetchRolePermissions,
  activateRole,
  archiveRole,
})(withRouter(ViewRole));
