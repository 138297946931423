import React from 'react';
import { useHistory } from 'react-router-dom';
import DashboardLayout from 'src/components/layout/DashboardLayout';

const Notifications = () => {
  const history = useHistory();
  history.push('/notifications/transactions');
  return <DashboardLayout />;
};

export default Notifications;
