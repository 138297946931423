import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  .inline {
    display: flex;
    align-items: center;
  }
  .input {
    height: 2.2rem;
    width: 100%;
    padding: 0rem 0.5rem;
    outline: 0px;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.color.grey};
    :focus {
      border: 1px solid ${(props) => props.theme.color.grey};
    }
    :disabled {
      opacity: 0.55;
    }
  }
  .label {
    color: ${(props) => props.theme.color.black};
    min-width: max-content;
  }
`;

function ColorInput({ onChange, value, label, formType, required, disabled, onBlur }) {
  return (
    <Wrapper>
      <div className={formType === 'inline' ? 'inline' : formType}>
        <p className="label">{label}</p>
        <div style={{ position: 'relative' }}>
          <input
            type="text"
            className="input"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onBlur={onBlur}
            required={required}
            disabled={disabled}
            style={{ paddingRight: 'calc(0.5rem + 36px)' }}
            pattern="#[A-Fa-f0-9]{6}"
            title="hexadecimal color"
            placeholder="#000000"
          />
          <input
            type="color"
            className="input"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            style={{ position: 'absolute', top: '0', right: '0', zIndex: '-1' }}
          />
          <div
            style={{
              position: 'absolute',
              top: '1px',
              right: '1px',
              height: 'calc(100% - 2px)',
              width: '36px',
              backgroundColor: `${value.length === 7 ? value : '#000000'}`,
              borderRadius: '0 4px 4px 0',
              cursor: 'pointer',
            }}
            onClick={(e) => e.target.previousElementSibling.click()}
          />
        </div>
      </div>
    </Wrapper>
  );
}

Text.defaultProps = {
  formType: 'block',
  required: false,
  disabled: false,
};

ColorInput.propTypes = {
  label: PropTypes.string,
  formType: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
};

export { ColorInput };
