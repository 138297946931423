import QS from 'qs';
import client from 'src/client';
import { errorAlert, clearAlert, infoAlert } from './alertActions';
import {
  OLD_POS_IS_LOADING,
  OLD_POS_NOT_LOADING,
  OLD_ADD_ALL_POS,
  OLD_SET_POS_LIMIT,
  OLD_SET_POS_PAGE,
} from 'src/store/types';
import { dateFormatYMD } from 'src/utils/helpers';

const { authService } = client;

const posIsLoading =
  (msg = 'Loading POS') =>
  (dispatch) => {
    dispatch({ type: OLD_POS_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const posNotLoading = () => (dispatch) => {
  dispatch({ type: OLD_POS_NOT_LOADING });
  dispatch(clearAlert());
};

const addAllPos = (allPos) => {
  return { type: OLD_ADD_ALL_POS, payload: allPos };
};

const generatePath = (endPoint, allPos) => {
  if (allPos === null) {
    return endPoint;
  }

  const { start_date, end_date, limit = 20, page = 1 } = allPos;

  const queryString = QS.stringify({
    limit,
    page,
    start_date: dateFormatYMD(start_date),
    end_date: dateFormatYMD(end_date),
  });

  return `${endPoint}?${queryString}`;
};

export const setPosPage = (page) => {
  return { type: OLD_SET_POS_PAGE, payload: page };
};

export const setPosLimit = (limit) => {
  return { type: OLD_SET_POS_LIMIT, payload: limit };
};

export const getAllPos = (platform) => (dispatch, getState) => {
  dispatch(posIsLoading());
  const { allPos } = getState().oldpos;
  const path = generatePath(`/mappings/pos/${platform}`, allPos);
  authService
    .get(path)
    .then((res) => {
      dispatch(addAllPos(res.data));
      dispatch(posNotLoading());
    })
    .catch(() => {
      dispatch(errorAlert({ msg: 'Internal Server Error' }));
      dispatch(posNotLoading());
    });
};
