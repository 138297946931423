import React from 'react';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import WalletIcon from '@material-ui/icons/CreditCard';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SideNav from 'src/components/layout/SideNav';
import { useHistory, useLocation } from 'react-router-dom';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
`;

const sideNavOptions = [
  { title: 'Issues', value: '/support', icon: LowPriorityIcon },
  { title: 'Virtual Wallet Requests', value: '/support/virtual-wallet-requests', icon: WalletIcon },
];

const SideBar = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const activeSidenavOption = sideNavOptions.slice(1).find((x) => {
    return pathname.toLowerCase().includes(x.value.toLowerCase());
  });
  const handleSetType = (value) => history.push(value);

  return (
    <Wrapper>
      <SideNav
        title={activeSidenavOption?.title || 'Support'}
        type={activeSidenavOption?.value || '/support'}
        setType={handleSetType}
        options={sideNavOptions}
      />
      <div className="body">{children}</div>
    </Wrapper>
  );
};

SideBar.propTypes = {
  children: PropTypes.any.isRequired,
};

export default SideBar;
