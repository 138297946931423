import React from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/common/table/Table';
import { vendorsTableHeader } from './vendorsTableHeader';
import { sortTableData } from 'src/utils/helpers';
import VendorsSearch from './VendorsSearch';
import { SelectInput } from 'src/components/common/inputs';

const statusOptions = [
  { text: 'All', value: 'all' },
  { text: 'Active', value: 'active' },
  { text: 'Suspended', value: 'suspended' },
];

const VendorsTable = ({ tableData, onPageChange, onLimitChange, status, setStatus }) => {
  const leftHeader = (
    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
      <div>
        <VendorsSearch />
      </div>
    </div>
  );
  return (
    <div>
      <Table
        showTopNav={true}
        rightHeader={<SelectInput options={statusOptions} value={status} onChange={setStatus} />}
        leftHeader={leftHeader}
        headers={vendorsTableHeader}
        data={tableData && tableData.data ? sortTableData(tableData.data, {}) : []}
        limit={tableData && tableData.limit}
        page={tableData && tableData.page}
        count={tableData && tableData.count}
        showNav={true}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </div>
  );
};

VendorsTable.propTypes = {
  tableData: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
};

export default VendorsTable;
