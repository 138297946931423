import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import StaffsComponent from 'src/components/staffs/Staffs';

const Staffs = () => {
  return (
    <DashboardLayout>
      <StaffsComponent />
    </DashboardLayout>
  );
};

export default Staffs;
