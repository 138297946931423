import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import ViewCollectionComponent from 'src/components/yawo/collections/ViewCollection';

const ViewCollection = () => {
  return (
    <DashboardLayout>
      <ViewCollectionComponent />
    </DashboardLayout>
  );
};

export default ViewCollection;
