import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  .item {
    display: flex;
    width: max-content;
    padding: 0;
    align-items: center;
  }

  .text {
    font-size: 0.8rem;
  }

  .success_text {
    color: ${(props) => props.theme.color.green};
  }
  .failed_text {
    color: ${(props) => props.theme.color.red};
  }
`;

function YawoUserVerification({ user }) {
  let verificationCount = 0;
  if (user?.vendor?.isVerified) verificationCount++;
  if (user?.adminVerification?.isVerified) verificationCount++;
  const text = `${verificationCount} of 2`;

  const displayIncompleteVerification = () => (
    <div className="item">
      <p className="text failed_text">{text} ❌</p>
    </div>
  );

  const displayCompleteVerification = () => (
    <div className="item">
      <p className="text success_text">{text} ✅</p>
    </div>
  );

  return <Wrapper>{verificationCount === 2 ? displayCompleteVerification() : displayIncompleteVerification()}</Wrapper>;
}

YawoUserVerification.propTypes = {
  user: PropTypes.object.isRequired,
};

export default YawoUserVerification;
