import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/common/Button';
import ModalTwo from 'src/components/common/ModalTwo';
import { TextInput, SelectInput } from 'src/components/common/inputs';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { updatePos, getAllPoses } from 'src/store/actions/posActions';
import Icon from 'src/components/common/Icon';
import EditIcon from '@material-ui/icons/Edit';

const Wrapper = styled.div`
  h2 {
    font-size: x-large;
  }

  .form-section-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    margin-bottom: 1.5rem;
    align-items: end;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .long {
      grid-column-end: span 1;
    }

    .longer {
      grid-column-end: span 2;

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .longest {
      grid-column-end: span 3;

      @media (max-width: 768px) {
        grid-column-end: span 2;
      }

      @media (max-width: 320px) {
        grid-column-end: span 1;
      }
    }

    .align-start {
      align-self: start;
    }

    .align-center {
      align-self: center;
    }

    .align-end {
      align-self: end;
    }

    .justify-end {
      justify-self: end;
    }
  }

  .label {
    color: ${(props) => props.theme.color.black};
    min-width: max-content;
  }
`;

const EditPos = ({
  posTypes,
  posOsTypes,
  pos,
  updatePos,
  getAllPoses,
  useButton,
  externalControlForVisibility,
  setShowComponent,
  showComponent,
}) => {
  if (!posTypes) return null;
  if (!posOsTypes) return null;

  const [stateShow, setStateShow] = useState(false);
  const show = showComponent || stateShow;
  const setShow = setShowComponent || setStateShow;
  const toggleShow = () => setShow(!show);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [type, setType] = useState('');
  const [os, setOs] = useState('');
  const [serialNumber, setSerialNumber] = useState('');

  const setFormState = (pos) => {
    setType(pos.type || '');
    setOs(pos.os || '');
    setSerialNumber(pos.serialNumber || '');
  };

  useEffect(() => {
    setFormState(pos);
  }, [pos]);

  const submitForm = (e) => {
    e.preventDefault();

    const data = {
      type,
      os,
      serialNumber,
    };

    setIsSubmitting(true);
    updatePos(pos._id, data, {
      always: () => {
        setIsSubmitting(false);
      },
      success: () => {
        setShow(false);
        if (getAllPoses) getAllPoses();
      },
    });
  };

  const posTypeOptions = Array.isArray(posTypes) ? posTypes.map((type) => ({ text: type, value: type })) : [];

  const posOsOptions = Array.isArray(posOsTypes) ? posOsTypes.map((type) => ({ text: type, value: type })) : [];

  return (
    <Wrapper>
      {!externalControlForVisibility && (
        <>
          {useButton ? (
            <Button text="Edit POS" onClick={toggleShow} />
          ) : (
            <Icon Icon={EditIcon} type="icon" onClick={toggleShow} />
          )}
        </>
      )}

      <ModalTwo size="large" active={show} onModalCloseCallback={toggleShow}>
        <div>
          <center>
            <h2>Edit POS</h2>
          </center>

          <br />

          <form id="updatePosForm" onSubmit={submitForm} className="form-section-grid">
            <div>
              <SelectInput label="Select POS Type" options={posTypeOptions} value={type} onChange={setType} required />
            </div>

            <div>
              <SelectInput label="Select OS" options={posOsOptions} value={os} onChange={setOs} required />
            </div>

            <div>
              <TextInput label="Serial Number" value={serialNumber} onChange={setSerialNumber} required />
            </div>

            <div className="longest justify-end">
              <Button type="submit" text={isSubmitting ? 'Updating Pos ...' : 'Update POS'} disabled={isSubmitting} />
            </div>
          </form>
        </div>
      </ModalTwo>
    </Wrapper>
  );
};

EditPos.propTypes = {
  pos: PropTypes.object,
  posTypes: PropTypes.array.isRequired,
  posOsTypes: PropTypes.array,
  updatePos: PropTypes.func.isRequired,
  getAllPoses: PropTypes.func,
  useButton: PropTypes.bool,
  externalControlForVisibility: PropTypes.bool,
  setShowComponent: PropTypes.func,
  showComponent: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { posTypes, posOsTypes } = state.pos;
  return { posTypes, posOsTypes };
};

export default connect(mapStateToProps, { updatePos, getAllPoses })(EditPos);
