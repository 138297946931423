import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const ViewPlatform = ({ platforms, platform }) => {
  return (
    <div>
      <p>{platforms[platform] || platform}</p>
    </div>
  );
};

ViewPlatform.propTypes = {
  platforms: PropTypes.object.isRequired,
  platform: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { platforms } = state.platform;
  return { platforms };
};

export default connect(mapStateToProps)(ViewPlatform);
