import React from 'react';
//import PropTypes from 'prop-types';
import styled from 'styled-components';
import logo from 'src/assets/img/logo.png';
import Nav from './Nav';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.color.purple};
  min-height: 100%;
  .logo {
    width: 2.5rem;
    height: 2.5rem;
    background-color: ${(props) => props.theme.color.blue};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img_logo {
    height: 2rem;
    width: 2rem;
  }
`;

const SideBar = () => {
  return (
    <Wrapper>
      <div className="logo">
        <img src={logo} alt="logo" className="img_logo" />
      </div>
      <Nav />
    </Wrapper>
  );
};

SideBar.propTypes = {};

export default SideBar;
