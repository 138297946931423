import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import WalletsComponent from 'src/components/yawo/wallets/Wallets';

const Wallets = () => {
  return (
    <DashboardLayout>
      <WalletsComponent />
    </DashboardLayout>
  );
};

export default Wallets;
