import { SET_USER, SET_AUTH, REMOVE_USER } from 'src/store/types';

const initialState = {
  isAuthenticated: false,
  profile: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        profile: action.payload,
      };
    case SET_AUTH:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case REMOVE_USER:
      return initialState;
    default:
      return state;
  }
}
