import QS from 'qs';
import client from 'src/client';
import {
  ADD_POSES,
  ADD_POS,
  CLEAR_POS,
  POS_IS_LOADING,
  POS_NOT_LOADING,
  SET_POS_DATE,
  RESET_POS_DATE,
  SET_POS_LIMIT,
  SET_POS_STATUS,
  SET_POS_PAGE,
  SET_POS_TYPES,
  SET_POS_OS_TYPES,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from './alertActions';
import convertArrayAsCsv from 'src/utils/convertArrayAsCsv';
import downloadBlob from 'src/utils/downloadBlob';

const { adminService } = client;

const posIsLoading =
  (msg = 'POS Loading') =>
  (dispatch) => {
    dispatch({ type: POS_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const posNotLoading = () => (dispatch) => {
  dispatch({ type: POS_NOT_LOADING });
};

const addPoses = (poses) => {
  return { type: ADD_POSES, payload: poses };
};

const addPos = (pos) => {
  return { type: ADD_POS, payload: pos };
};

const clearPos = () => {
  return { type: CLEAR_POS };
};

export const setPosDate = (start_date, end_date) => {
  return { type: SET_POS_DATE, payload: { start_date, end_date } };
};

export const resetPosDate = () => {
  return { type: RESET_POS_DATE };
};

export const setPosPage = (page) => {
  return { type: SET_POS_PAGE, payload: page };
};

export const setPosLimit = (limit) => {
  return { type: SET_POS_LIMIT, payload: limit };
};

export const setPosStatus = (status) => {
  return { type: SET_POS_STATUS, payload: status };
};

const generatePath = (endPoint, pos, query) => {
  const { limit, page } = pos || {};
  let url = `${endPoint}?limit=${limit}&page=${page}`;
  if (query && Object.keys(query).length > 0) {
    url += `&${QS.stringify(query)}`;
  }
  return url;
};

export const getAllPoses =
  (query = {}) =>
  (dispatch, getState) => {
    dispatch(posIsLoading('Loading POS'));
    const { poses } = getState().pos;
    const path = generatePath('/pos', poses, query);
    adminService
      .get(path)
      .then((res) => {
        dispatch(addPoses(res.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading POS' })))
      .finally(() => dispatch(posNotLoading()));
  };

export const getAllPosesByMmoShortCode =
  (mmoShortCode, query = {}) =>
  (dispatch, getState) => {
    dispatch(posIsLoading('Loading POS'));
    const { poses } = getState().pos;
    const path = generatePath(`/pos/mmo/${mmoShortCode}`, poses, query);
    adminService
      .get(path)
      .then((res) => {
        dispatch(addPoses(res.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading POS' })))
      .finally(() => dispatch(posNotLoading()));
  };

export const getAllPosesByAgentId =
  (agentId, query = {}) =>
  (dispatch, getState) => {
    dispatch(posIsLoading('Loading POS'));
    const { poses } = getState().pos;
    const path = generatePath(`/pos/agent/${agentId}`, poses, query);
    adminService
      .get(path)
      .then((res) => {
        dispatch(addPoses(res.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading POS' })))
      .finally(() => dispatch(posNotLoading()));
  };

export const getSinglePos = (posId, extraParams) => (dispatch) => {
  const { silently = false } = extraParams || {};
  if (!silently) {
    dispatch(posIsLoading('Loading POS'));
  }
  adminService
    .get(`/pos/${posId}`)
    .then((res) => {
      dispatch(addPos(res.data));
      dispatch(clearAlert());
    })
    .catch((err) => {
      dispatch(clearPos());
      dispatch(errorAlert({ msg: err.response?.data?.message || err.message }));
    })
    .finally(() => dispatch(posNotLoading()));
};

export const searchPos = (searchString) => (dispatch) => {
  dispatch(posIsLoading('Searching...'));
  adminService
    .get(`/pos?search=${searchString}`)
    .then((res) => {
      dispatch(addPoses(res.data));
      dispatch(clearAlert());
    })
    .catch(() => {
      dispatch(errorAlert({ msg: 'Error Loading POS' }));
    })
    .finally(() => dispatch(posNotLoading()));
};

// Create & Update POS

const createPosIsLoading =
  (msg = 'Creating Pos') =>
  (dispatch) =>
    dispatch(infoAlert(msg));
const createPosNotLoading = () => (dispatch) => dispatch(clearAlert());

export const createPos = (formData, callback) => (dispatch) => {
  dispatch(createPosIsLoading());
  adminService
    .post('/pos', formData)
    .then(() => {
      dispatch(successAlert('POS Created'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => {
      const error = err?.response?.data?.serialNumber || err?.response?.data?.message;
      dispatch(errorAlert({ msg: error || 'Technical Error' }));
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(createPosNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

const updatePosIsLoading =
  (msg = 'Updating Pos') =>
  (dispatch) =>
    dispatch(infoAlert(msg));
const updatePosNotLoading = () => (dispatch) => dispatch(clearAlert());

export const updatePos = (id, formData, callback) => (dispatch) => {
  dispatch(updatePosIsLoading());
  adminService
    .put(`/pos/${id}`, formData)
    .then(() => {
      dispatch(successAlert('POS Updated'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => {
      const error = err?.response?.data?.serialNumber || err?.response?.data?.message;
      dispatch(errorAlert({ msg: error || 'Technical Error' }));
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(updatePosNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

// POS Types

const setPosTypes = (rolePermissions) => {
  return { type: SET_POS_TYPES, payload: rolePermissions };
};

export const fetchPosTypes = () => (dispatch) => {
  adminService
    .get('/pos/pos-types')
    .then((res) => dispatch(setPosTypes(res.data)))
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading POS Types' })))
    .finally(() => dispatch(posNotLoading()));
};

// POS OS Types

const setPosOsTypes = (rolePermissions) => {
  return { type: SET_POS_OS_TYPES, payload: rolePermissions };
};

export const fetchPosOsTypes = () => (dispatch) => {
  adminService
    .get('/pos/os-types')
    .then((res) => dispatch(setPosOsTypes(res.data)))
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading POS OS Types' })))
    .finally(() => dispatch(posNotLoading()));
};

// POS Mapping

const mapPosIsLoading =
  (msg = 'Mapping ...') =>
  (dispatch) =>
    dispatch(infoAlert(msg));
const mapPosNotLoading = () => (dispatch) => dispatch(clearAlert());

export const mapPosToTerminalId = (formData, callback) => (dispatch) => {
  dispatch(mapPosIsLoading());
  adminService
    .post('/pos/mappings/terminals/map', formData)
    .then((res) => {
      dispatch(successAlert(res?.data?.message || 'Mapping is Successful'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => {
      const error = err?.response?.data?.message || err?.response?.data?.posId || err?.response?.data?.terminalId;
      dispatch(errorAlert({ msg: error || 'Technical Error' }));
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => {
      setTimeout(() => {
        dispatch(mapPosNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

export const mapPosToMmo = (formData, callback) => (dispatch) => {
  dispatch(mapPosIsLoading());
  adminService
    .post('/pos/mappings/mmo/map', formData)
    .then((res) => {
      dispatch(successAlert(res?.data?.message || 'Mapping is Successful'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => {
      const error = err?.response?.data?.message || err?.response?.data?.posId || err?.response?.data?.mmoShortCode;
      dispatch(errorAlert({ msg: error || 'Technical Error' }));
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(mapPosNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

export const mapPosToAgent = (formData, callback) => (dispatch) => {
  dispatch(mapPosIsLoading());
  adminService
    .post('/pos/mappings/agent/map', formData)
    .then((res) => {
      dispatch(successAlert(res?.data?.message || 'Mapping is Successful'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => {
      const error = err?.response?.data?.message || err?.response?.data?.posId || err?.response?.data?.agentId;
      dispatch(errorAlert({ msg: error || 'Technical Error' }));
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(mapPosNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

// POS Unmapping

const unmapPosIsLoading =
  (msg = 'Unmapping ...') =>
  (dispatch) =>
    dispatch(infoAlert(msg));
const unmapPosNotLoading = () => (dispatch) => dispatch(clearAlert());

export const unmapPosFromTerminalId = (formData, callback) => (dispatch) => {
  dispatch(unmapPosIsLoading());
  adminService
    .post('/pos/mappings/terminals/un-map', formData)
    .then((res) => {
      dispatch(successAlert(res?.data?.message || 'Unmapping is Successful'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => {
      const error = err?.response?.data?.message || err?.response?.data?.posId || err?.response?.data?.terminalId;
      dispatch(errorAlert({ msg: error || 'Technical Error' }));
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(unmapPosNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

export const unmapPosFromMmo = (formData, callback) => (dispatch) => {
  dispatch(unmapPosIsLoading());
  adminService
    .post('/pos/mappings/mmo/un-map', formData)
    .then((res) => {
      dispatch(successAlert(res?.data?.message || 'Unmapping is Successful'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => {
      const error = err?.response?.data?.message || err?.response?.data?.posId || err?.response?.data?.mmoShortCode;
      dispatch(errorAlert({ msg: error || 'Technical Error' }));
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(unmapPosNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

export const unmapPosFromAgent = (formData, callback) => (dispatch) => {
  dispatch(unmapPosIsLoading());
  adminService
    .post('/pos/mappings/agent/un-map', formData)
    .then((res) => {
      dispatch(successAlert(res?.data?.message || 'Unmapping is Successful'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => {
      const error = err?.response?.data?.message || err?.response?.data?.posId || err?.response?.data?.agentId;
      dispatch(errorAlert({ msg: error || 'Technical Error' }));
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(unmapPosNotLoading());
        if (callback && callback.always) callback.always();
      }, 1000);
    });
};

// Download POS Mappings

export const downloadPosMappings = (mmoShortCode, callback) => {
  return (dispatch, getState) => {
    dispatch(posIsLoading('Downloading POS Mappings'));
    const { poses } = getState().pos;
    const urlPath = mmoShortCode ? `/pos/mmo/${mmoShortCode}` : '/pos';
    const path = generatePath(urlPath, { ...poses, limit: poses.count, page: 1 });

    adminService
      .get(path)
      .then((res) => {
        const data = res.data?.data || [];
        const csvData = convertArrayAsCsv(data, (row) => ({
          'Serial Number': row?.serialNumber || '',
          Status: row?.mapping?.agentMapping?.agentId ? 'mapped' : 'not mapped',
          MMO: row?.mapping?.mmoMapping?.mmoShortCode || '',
          Agent: row?.mapping?.agentMapping?.agentId || '',
          'Device Activation': row?.mapping?.isActivated?.status ? 'activated' : 'not activated',
        }));
        const fileName = mmoShortCode ? `POS_Mappings_${mmoShortCode}` : 'POS_Mappings';
        downloadBlob(csvData, `${fileName}.csv`);
        dispatch(clearAlert());
        if (callback && callback.success) callback.success();
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Downloading POS Mappings' })))
      .finally(() => dispatch(posNotLoading()));
  };
};
