import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { SelectInput } from 'src/components/common/inputs';
import { useSelector } from 'react-redux';
import { sortPlatforms } from 'src/utils/helpers';
import IssuesTable from './IssuesTable';
import { getIssues, setIssuesPage, setIssuesLimit } from 'src/store/actions/issuesActions';
import SideBar from './SideBar';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;

  .issues_body {
    padding: 1.5rem;
    display: grid;
    grid-gap: 1rem;
    height: max-content;
  }
  .platform {
    display: grid;
    width: max-content;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 0.5rem;
  }
`;

const Issues = () => {
  const dispatch = useDispatch();
  const { allIssues } = useSelector((state) => state.issue);

  const platforms = useSelector((state) => state.platform.platforms);
  const [platform, setPlatform] = useState('ikonik');
  const [status, setStatus] = useState('open');

  const onLimitChange = (limit) => {
    dispatch(setIssuesLimit(limit));
    dispatch(getIssues(status, platform));
  };

  const onPageChange = (page) => {
    dispatch(setIssuesPage(page));
    dispatch(getIssues(status, platform));
  };

  useEffect(() => {
    dispatch(getIssues(status, platform));
  }, [platform, status]);

  return (
    <SideBar>
      <Wrapper>
        <div className="issues_body">
          <div className="platform">
            <h4>Select platform:</h4>
            <SelectInput options={sortPlatforms(platforms)} value={platform} onChange={setPlatform} />
          </div>
          <IssuesTable
            tableData={allIssues}
            status={status}
            onStatusChange={setStatus}
            onPageChange={onPageChange}
            onLimitChange={onLimitChange}
          />
        </div>
      </Wrapper>
    </SideBar>
  );
};

export default Issues;
