import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getAllRoles } from 'src/store/actions/roleActions';
import {
  getAllStaffs,
  setStaffLimit,
  setStaffPage,
  setStaffStatus,
  fetchStaffDepartments,
} from 'src/store/actions/staffActions';
import CreateStaff from './CreateStaff';
import StaffsTable from './StaffsTable';
import StaffLayout from './StaffLayout';
import DisplayCard from 'src/components/common/DisplayCard';
import PeopleIcon from '@material-ui/icons/People';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  height: max-content;
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1rem;
    height: max-content;
  }
  .platform {
    display: grid;
    width: max-content;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 0.5rem;
  }
`;

const Staffs = ({
  getAllRoles,
  staffs,
  getAllStaffs,
  setStaffLimit,
  setStaffPage,
  setStaffStatus,
  fetchStaffDepartments,
}) => {
  useEffect(() => {
    getAllRoles({}, true);
    fetchStaffDepartments();
    setStaffPage(1);
    setStaffLimit(20);
    const query = {};
    getAllStaffs(query);
  }, []);

  const onPageChange = (page) => {
    setStaffPage(page);
    getAllStaffs();
  };

  const onLimitChange = (limit) => {
    setStaffLimit(limit);
    getAllStaffs();
  };

  const onStatusChange = (status) => {
    setStaffStatus(status);
    getAllStaffs();
  };

  return (
    <StaffLayout>
      <Wrapper>
        <CreateStaff />
        <div className="cards">
          <DisplayCard icon={PeopleIcon} title="Total Staffs" value={staffs !== null && staffs.count} />
          <DisplayCard
            icon={SupervisorAccountIcon}
            title="Active Staffs"
            value={staffs !== null && staffs.active_users}
          />
          <DisplayCard
            icon={SupervisedUserCircleIcon}
            title="Suspended Staffs"
            value={staffs !== null && staffs.suspended_users}
          />
        </div>
        <StaffsTable
          tableData={staffs}
          onPageChange={onPageChange}
          onLimitChange={onLimitChange}
          onStatusChange={onStatusChange}
        />
      </Wrapper>
    </StaffLayout>
  );
};

Staffs.propTypes = {
  getAllRoles: PropTypes.func.isRequired,
  staffs: PropTypes.object.isRequired,
  getAllStaffs: PropTypes.func.isRequired,
  setStaffLimit: PropTypes.func.isRequired,
  setStaffPage: PropTypes.func.isRequired,
  setStaffStatus: PropTypes.func.isRequired,
  fetchStaffDepartments: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { staffs } = state.staff;
  return { staffs };
};

export default connect(mapStateToProps, {
  getAllRoles,
  getAllStaffs,
  setStaffPage,
  setStaffLimit,
  setStaffStatus,
  fetchStaffDepartments,
})(Staffs);
