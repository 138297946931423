import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
import styled from 'styled-components';
import Button from 'src/components/common/Button';
import Icon from 'src/components/common/Icon';
import UpdateIcon from '@material-ui/icons/ArrowUpward';
import { SelectInput } from 'src/components/common/inputs';
import { connect } from 'react-redux';
import { updateTransactionStatus } from 'src/store/actions/transactionActions';

const Wrapper = styled.div`
  width: max-content;
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    display: flex;
    justify-content: space-between;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .confirm {
    display: grid;
    grid-gap: 1rem;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0rem;
  }
  .buttons {
    padding-top: 0.5rem;
    display: flex;
    justify-content: space-between;
  }
  .buttons > * {
    width: 7rem;
  }
`;

const updateOptions = [
  { text: 'SUCCESS', value: 'success' },
  { text: 'REFUNDED', value: 'refund' },
];

const UpdateTransaction = ({ updateTransactionStatus, transaction }) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => setShow(!show);

  const statusIsSuccess = transaction?.status?.includes('SUCCESS');
  const [status, setStatus] = useState(statusIsSuccess ? 'refund' : 'success');

  return (
    <div>
      <Icon Icon={UpdateIcon} alt="Update Transaction" onClick={toggleShow} />
      <Modal isOpen={show} handleClose={toggleShow}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h1>Update Transaction</h1>
            </div>

            <h6 onClick={toggleShow} className="cancel">
              X
            </h6>
          </div>

          <div className="confirm">
            <SelectInput
              value={status}
              onChange={setStatus}
              options={statusIsSuccess ? updateOptions.slice(1) : updateOptions}
            />
            <p>Are you sure you want to update this transaction?</p>
            <div className="buttons">
              <div>
                <Button
                  text="Yes"
                  onClick={() => {
                    updateTransactionStatus(transaction.transactionRef, status);
                  }}
                  theme="purple"
                />
              </div>
              <div>
                <Button text="Cancel" onClick={toggleShow} theme="purple" />
              </div>
            </div>
          </div>
        </Wrapper>
      </Modal>
    </div>
  );
};

UpdateTransaction.propTypes = {
  updateTransactionStatus: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
};

export default connect(null, { updateTransactionStatus })(UpdateTransaction);
