import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  .success_text {
    color: ${(props) => props.theme.color.green};
  }
  .failed_text {
    color: ${(props) => props.theme.color.red};
  }
  .submitted_text {
    color: ${(props) => props.theme.color.blue};
  }
`;

function LoanStatus({ status }) {
  const displayRequest = () => <p className="submitted_text">submitted</p>;

  const displayRejected = () => <p className="failed_text">rejected</p>;

  const displayApproved = () => <p className="success_text">approved</p>;

  const displayOngoing = () => <p className="success_text">ongoing</p>;

  const displayCompleted = () => <p className="success_text">completed</p>;

  return (
    <Wrapper>
      {status === 'submitted' && displayRequest()}
      {status === 'rejected' && displayRejected()}
      {status === 'approved' && displayApproved()}
      {status === 'ongoing' && displayOngoing()}
      {status === 'completed' && displayCompleted()}
    </Wrapper>
  );
}

LoanStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default LoanStatus;
