import React from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/common/table/Table';
import { transactionsHeader } from 'src/components/transactions/transactionHeaders';
import { sortTableData } from 'src/utils/helpers';
import { SelectInput } from 'src/components/common/inputs';

const statusOptions = [
  { text: 'All', value: 'all' },
  { text: 'Success', value: 'success' },
  { text: 'Failed', value: 'failed' },
  { text: 'Pending', value: 'pending' },
  { text: 'Refunded', value: 'refunded' },
];

const PosTransactionsTable = ({ tableData, onPageChange, onLimitChange, onStatusChange }) => {
  const tableKey = {
    Amount: 'amount',
    Type: 'type',
    Status: 'status',
    Date: 'timeCreated',
    Time: 'timeCreated',
  };

  const rightHeader = (
    <SelectInput
      value={tableData === null ? 'all' : tableData.status}
      onChange={onStatusChange}
      options={statusOptions}
    />
  );

  return (
    <div>
      <Table
        headers={transactionsHeader}
        rightHeader={rightHeader}
        showNav={true}
        data={tableData === null ? [] : sortTableData(tableData.data, tableKey)}
        limit={tableData === null ? 20 : tableData.limit}
        page={tableData === null ? 1 : tableData.page}
        count={tableData === null ? 1 : tableData.count}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </div>
  );
};

PosTransactionsTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
};

export default PosTransactionsTable;
