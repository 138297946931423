import QS from 'qs';
import client from 'src/client';
import {
  ADD_ACCOUNTS,
  ADD_ACCOUNT,
  CLEAR_ACCOUNT,
  ACCOUNT_IS_LOADING,
  ACCOUNT_NOT_LOADING,
  SET_ACCOUNT_LIMIT,
  SET_ACCOUNT_STATUS,
  SET_ACCOUNT_PAGE,
  SET_USER_SERVICES_LIST,
  SET_USER_SERVICES,
} from 'src/store/types';
import { errorAlert, successAlert, infoAlert, clearAlert } from './alertActions';

const { userService, adminService } = client;

const accountIsLoading =
  (msg = 'Account Loading') =>
  (dispatch) => {
    dispatch({ type: ACCOUNT_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const accountNotLoading = () => (dispatch) => {
  dispatch({ type: ACCOUNT_NOT_LOADING });
};

const addAccounts = (accounts) => {
  return { type: ADD_ACCOUNTS, payload: accounts };
};

const addAccount = (account) => {
  return { type: ADD_ACCOUNT, payload: account };
};

const clearAccount = () => {
  return { type: CLEAR_ACCOUNT };
};

export const setAccountPage = (page) => {
  return { type: SET_ACCOUNT_PAGE, payload: page };
};

export const setAccountLimit = (limit) => {
  return { type: SET_ACCOUNT_LIMIT, payload: limit };
};

export const setAccountStatus = (status) => {
  return { type: SET_ACCOUNT_STATUS, payload: status };
};

const generatePath = (endPoint, account, platform) => {
  if (account === null) {
    return `${endPoint}?platform=${platform}`;
  }

  const { limit = 20, page = 1, status } = account;
  const queryString = QS.stringify({
    limit,
    page,
    status,
    platform,
  });

  return `${endPoint}?${queryString}`;
};

export const getAllUserAccounts =
  (platform = 'all') =>
  (dispatch, getState) => {
    dispatch(accountIsLoading('Loading Accounts'));
    const { accounts } = getState().account;
    const path = generatePath('/users', accounts, platform);
    userService
      .get(path)
      .then((res) => {
        dispatch(addAccounts(res.data));
        dispatch(clearAlert());
      })
      .catch(() => {
        dispatch(errorAlert({ msg: 'Error Loading Accounts' }));
      })
      .finally(() => dispatch(accountNotLoading()));
  };

export const getUserAccount = (account) => (dispatch) => {
  dispatch(accountIsLoading('Loading Account'));
  userService
    .post('/users/lookup', account)
    .then((res) => {
      dispatch(addAccount(res.data));
      dispatch(clearAlert());
    })
    .catch((err) => {
      dispatch(clearAccount());
      dispatch(errorAlert({ msg: err.response.data }));
    })
    .finally(() => dispatch(accountNotLoading()));
};

export const activateAccount = (userId) => (dispatch) => {
  dispatch(accountIsLoading('Activating Account'));
  userService
    .get(`/users/${userId}/activate`)
    .then((res) => {
      dispatch(addAccount(res.data));
      dispatch(successAlert('User Activated'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err.response.data }));
    })
    .finally(() => dispatch(accountNotLoading()));
};

export const suspendAccount = (userId) => (dispatch) => {
  dispatch(accountIsLoading('Suspending Account'));
  userService
    .get(`/users/${userId}/suspend`)
    .then((res) => {
      dispatch(addAccount(res.data));
      dispatch(successAlert('User Suspended'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err.response.data }));
    })
    .finally(() => dispatch(accountNotLoading()));
};

export const accountResetPin = (userId) => (dispatch) => {
  dispatch(accountIsLoading('Resetting User Pin'));
  adminService
    .get(`/users/${userId}/reset-pin`)
    .then(() => {
      dispatch(successAlert('Pin Reset Successful'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err.response.data }));
    })
    .finally(() => dispatch(accountNotLoading()));
};

export const downloadWalletHistory = (walletId, dateRange, callback) => (dispatch) => {
  const { startDate, endDate } = dateRange;
  dispatch(accountIsLoading('Downloading ...'));
  adminService
    .get(`/transactions/download/${walletId}?startDate=${startDate}&endDate=${endDate}`)
    .then((res) => {
      callback(res.data);
      dispatch(successAlert('Wallet History Download'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data || 'Error downloading wallet history' }));
    })
    .finally(() => dispatch(accountNotLoading()));
};

export const updateWalletLimit = (requestData, callback) => (dispatch) => {
  dispatch(accountIsLoading('Updating Wallet Limit ...'));
  adminService
    .post('/users/wallet-limit', requestData)
    .then((res) => {
      callback(res.data);
      dispatch(successAlert('Wallet Limit Updated'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data || 'Error updating wallet limit' }));
    })
    .finally(() => dispatch(accountNotLoading()));
};

export const searchAccount = (searchString, platform) => (dispatch) => {
  dispatch(accountIsLoading('Searching...'));
  userService
    .get(`/users/search/${platform}/${searchString}`)
    .then((res) => {
      dispatch(addAccounts(res.data));
      dispatch(clearAlert());
    })
    .catch(() => {
      dispatch(errorAlert({ msg: 'Error Loading Accounts' }));
    })
    .finally(() => dispatch(accountNotLoading()));
};

export const loadAgentOptions = async (searchString, platform = 'all') => {
  let options = [];
  await userService
    .get(`/users/search/${platform}/${searchString}`)
    .then((res) => {
      options = res?.data?.data || [];
    })
    .catch(() => {});

  return options
    .map((x) => ({
      ...x,
      label: `${x.firstName} ${x.lastName} // ${x.platform} // ${x.email}`,
      value: x._id,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
};

// User Services

const setUserServicesList = (userServicesList) => {
  return { type: SET_USER_SERVICES_LIST, payload: userServicesList };
};

export const fetchUserServicesList = () => (dispatch) => {
  adminService
    .get('/users/services')
    .then((res) => dispatch(setUserServicesList(res?.data?.data)))
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading Users Services List' })));
};

const setUserServices = (userServices) => {
  return { type: SET_USER_SERVICES, payload: userServices };
};

export const fetchUserServices =
  ({ userId }) =>
  (dispatch) => {
    adminService
      .get(`/users/get-services/${userId}`)
      .then((res) => dispatch(setUserServices(res?.data?.data)))
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading User Services' })));
  };

export const updateUserServices = (userId, services, callback) => (dispatch) => {
  adminService
    .post('/users/add-service', { userId, services })
    .then(() => {
      dispatch(successAlert('Successfully Updated User Services'));
      setTimeout(() => {
        if (callback && callback.success) callback.success();
      }, 1000);
    })
    .catch((err) => dispatch(errorAlert({ msg: err.response?.data?.message || err.message })))
    .finally(() => {
      if (callback && callback.always) callback.always();
    });
};
