import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useJsonToCsv } from 'src/utils/hooks';
import Icon from 'src/components/common/Icon';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import Modal from 'src/components/common/Modal';
import styled from 'styled-components';
import { NumberInput } from 'src/components/common/inputs';
import Button from 'src/components/common/Button';
import { useSelector, useDispatch } from 'react-redux';
import { getTransactionsForDownload } from 'src/store/actions/transactionActions';

const Wrapper = styled.div`
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    display: flex;
    justify-content: center;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .body {
    padding: 1rem;
    margin: 0.5rem;
  }
`;

const DownloadTransactions = ({ type, platform, limit }) => {
  const dispatch = useDispatch();
  const { saveAsCsv } = useJsonToCsv();
  const [showModal, setShowModal] = useState(false);
  const [number, setLimit] = useState(limit);
  const { isDownloading } = useSelector((state) => state.transaction);

  const toggleModal = () => setShowModal(!showModal);

  const onDownloadClick = () => {
    const data = { type, limit: number, platform };
    dispatch(
      getTransactionsForDownload(data, (data) => {
        if (data !== null) {
          const filename = 'transactions';
          const fields = {
            amount: 'GROSS AMOUNT',
            meta: 'Terminal Information',
            platform: 'PLATFORM',
            PaymentLogId: 'REFERENCE ID',
            ref: 'TRANSACTION REFERENCE',
            timeCreated: 'DATE AND TIME',
            commission: 'COMMISSION',
            type: 'SERVICE/PRODUCT NAME',
            billingType: 'BILLING TYPE',
            status: 'STATUS',
            transactionRef: 'TRANSACTION REF',
            vendor: 'VENDOR',
            vendorRef: 'VENDOR REF',
            terminalId: 'TERMINAL ID',
            tid: 'TID',
            mPan: 'MASKED PAN',
            ptsp: 'PTSP',
            card: 'CARD',
            cardPaymentRef: 'CARD PAYMENT REF',
          };
          saveAsCsv({ data: data.data, fields, filename });
        }
      })
    );
  };

  return (
    <div>
      <Icon Icon={GetAppIcon} alt="Download Transactions" onClick={toggleModal} />
      <Modal isOpen={showModal} handleClose={toggleModal}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h6>Transaction Details</h6>
            </div>

            <CloseIcon onClick={toggleModal} className="cancel" />
          </div>
          <div className="body">
            <NumberInput label="Enter Document limit" value={number} onChange={setLimit} />
            {isDownloading && <p> Fetching transactions ...</p>}
            <br />

            {!isDownloading && <Button onClick={onDownloadClick} text="Generate report" />}
          </div>
        </Wrapper>
      </Modal>
    </div>
  );
};

DownloadTransactions.propTypes = {
  limit: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
};

export default DownloadTransactions;
