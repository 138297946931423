import React from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/common/table/Table';
import { chargebackTableHeader } from './chargebackTableHeader';
import { sortTableData } from 'src/utils/helpers';
// import ChargebackSearch from './ChargebackSearch';

const ChargebackTable = ({ tableData }) => {
  // const leftHeader = <ChargebackSearch />;
  const leftHeader = null;
  const rightHeader = null;

  return (
    <div>
      <Table
        headers={chargebackTableHeader}
        rightHeader={rightHeader}
        leftHeader={leftHeader}
        data={tableData && tableData.data ? sortTableData(tableData.data, {}) : []}
        showNav={false}
      />
    </div>
  );
};

ChargebackTable.defaultProps = {
  tableData: { data: [], limit: 0, page: 1, count: 0 },
};

ChargebackTable.propTypes = {
  tableData: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
};

export default ChargebackTable;
