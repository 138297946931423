import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import ViewPosComponent from 'src/components/devices/pos/ViewPos';

const ViewPos = () => {
  return (
    <DashboardLayout>
      <ViewPosComponent />
    </DashboardLayout>
  );
};

export default ViewPos;
