import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { connect, useDispatch } from 'react-redux';
import { getAllYawoWallets, setYawoWalletIuwi } from 'src/store/actions/yawo/walletActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 250,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 0,
  },
}));

const WalletHistoryIuwiSearch = ({ getAllYawoWallets }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const useQuery = () => new URLSearchParams(history.location.search);
  const yawoWalletIuwi = useQuery().get('yawoWalletIuwi') || '';

  const classes = useStyles();
  const [searchString, setSearchString] = useState('');

  const searchWallets = (yawoWalletIuwi) => {
    getAllYawoWallets({ page: 1, iuwi: yawoWalletIuwi || searchString });
    dispatch(setYawoWalletIuwi(yawoWalletIuwi || searchString));
  };

  const onChange = (e) => {
    setSearchString(e.target.value);
    if (e.target.value === '') {
      getAllYawoWallets({ page: 1 });
      dispatch(setYawoWalletIuwi(''));
    }
  };

  const onEnterPress = (e) => {
    if (e.key === 'Enter' && searchString !== '') {
      e.preventDefault();
      e.stopPropagation();
      searchWallets();
    }
  };

  useEffect(() => {
    if (yawoWalletIuwi) {
      setSearchString(yawoWalletIuwi);
      setTimeout(() => searchWallets(yawoWalletIuwi), 1000);
    }
  }, [yawoWalletIuwi]);

  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Search by IUWI..."
        value={searchString}
        onChange={onChange}
        onKeyPress={onEnterPress}
      />
      <IconButton className={classes.iconButton} onClick={searchWallets}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

WalletHistoryIuwiSearch.propTypes = {
  getAllYawoWallets: PropTypes.func.isRequired,
};

export default connect(null, { getAllYawoWallets })(WalletHistoryIuwiSearch);
