import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Login from 'src/views/auth/Login';
import ForgotPassword from 'src/views/auth/ForgotPassword';
import ResetPassword from 'src/views/auth/ResetPassword';
import Dashboard from 'src/views/dashboard/Dashboard';
import Transactions from 'src/views/transactions/Transactions';
import Users from 'src/views/users/Users';
import User from 'src/views/users/User';
import UserTransactions from 'src/views/users/UserTransactions';
import Staffs from 'src/views/staffs/Staffs';
import ViewStaff from 'src/views/staffs/ViewStaff';
import Roles from 'src/views/staffs/roles/Roles';
import ViewRole from 'src/views/staffs/roles/ViewRole';
import Mmo from 'src/views/users/mmo/Mmo';
import ViewMmo from 'src/views/users/mmo/ViewMmo';
import TransactionsNotifications from 'src/views/notifications/transactions/TransactionNotifications';
import ViewCardPayment from 'src/views/notifications/cards/ViewCardPayment';
import CardPayments from 'src/views/notifications/cards/CardPayments';
import Ptsps from 'src/views/notifications/ptsps/Ptsps';
import FwCardPayments from 'src/views/notifications/fw/FwCardPayments';
import Notifications from 'src/views/notifications/Notifications';
import Pos from 'src/views/devices/Pos';
import PosTransactions from 'src/views/devices/PosTransactions';
import NewPos from 'src/views/devices/pos/Pos';
import ViewPos from 'src/views/devices/pos/ViewPos';
import Terminals from 'src/views/devices/terminals/Terminals';
import VirtualWalletRequests from 'src/views/support/VirtualWalletRequests';
import Issues from 'src/views/support/Issues';
import Issue from 'src/views/support/Issue';
import Yawo from 'src/views/yawo/Yawo';
import YawoLoans from 'src/views/yawo/loans/Loans';
import ViewYawoLoan from 'src/views/yawo/loans/ViewLoan';
import YawoWallets from 'src/views/yawo/wallets/Wallets';
import SingleWallet from 'src/views/yawo/wallets/SingleWallet';
import YawoTransactions from 'src/views/yawo/transactions/Transactions';
import ViewYawoTransaction from 'src/views/yawo/transactions/ViewYawoTransaction';
import YawoCollections from 'src/views/yawo/collections/Collections';
import ViewYawoCollection from 'src/views/yawo/collections/ViewCollection';
import YawoVendors from 'src/views/yawo/vendors/Vendors';
import ViewYawoVendor from 'src/views/yawo/vendors/ViewVendor';
import YawoAgents from 'src/views/yawo/agents/Agents';
import ViewYawoAgent from 'src/views/yawo/agents/ViewAgent';
import YawoCustomers from 'src/views/yawo/customers/Customers';
import ViewYawoCustomer from 'src/views/yawo/customers/ViewCustomer';
import YawoGuarantors from 'src/views/yawo/guarantors/Guarantors';
import ViewYawoGuarantor from 'src/views/yawo/guarantors/ViewGuarantor';
import Reconciliation from 'src/views/finance/reconciliation/Reconciliation';
import Chargeback from 'src/views/finance/chargeback/Chargeback';
import Finance from 'src/views/finance/Finance';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <PrivateRoute exact path="/" isAuth={false} component={Login} />
        <PrivateRoute exact path="/forgot-password" isAuth={false} component={ForgotPassword} />
        <PrivateRoute exact path="/reset-password" isAuth={false} component={ResetPassword} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/transactions" component={Transactions} />
        <PrivateRoute exact path="/users/mmo/:mmoId" component={ViewMmo} />
        <PrivateRoute exact path="/users/mmo" component={Mmo} />
        <PrivateRoute exact path="/users" component={Users} />
        <PrivateRoute exact path="/users/:userId" component={User} />
        <PrivateRoute exact path="/users/:walletId/transactions" component={UserTransactions} />
        <PrivateRoute exact path="/users/:userId" component={User} />
        <PrivateRoute exact path="/users" component={Users} />
        <PrivateRoute exact path="/staffs/roles/:roleId" component={ViewRole} />
        <PrivateRoute exact path="/staffs/roles" component={Roles} />
        <PrivateRoute exact path="/staffs" component={Staffs} />
        <PrivateRoute exact path="/staffs/:staffId" component={ViewStaff} />
        <PrivateRoute exact path="/pos/pos-new/:posId" component={ViewPos} />
        <PrivateRoute exact path="/pos/pos-new" component={NewPos} />
        <PrivateRoute exact path="/pos/terminals" component={Terminals} />
        <PrivateRoute exact path="/pos" component={Pos} />
        <PrivateRoute exact path="/pos/:walletId/transactions" component={PosTransactions} />
        <PrivateRoute exact path="/support/virtual-wallet-requests" component={VirtualWalletRequests} />
        <PrivateRoute exact path="/support" component={Issues} />
        <PrivateRoute exact path="/support/:issueId" component={Issue} />
        <PrivateRoute exact path="/yawo/vendors/:vendorId" component={ViewYawoVendor} />
        <PrivateRoute exact path="/yawo/vendors" component={YawoVendors} />
        <PrivateRoute exact path="/yawo/agents/:agentId" component={ViewYawoAgent} />
        <PrivateRoute exact path="/yawo/agents" component={YawoAgents} />
        <PrivateRoute exact path="/yawo/customers/:customerId" component={ViewYawoCustomer} />
        <PrivateRoute exact path="/yawo/customers" component={YawoCustomers} />
        <PrivateRoute exact path="/yawo/guarantors/:guarantorId" component={ViewYawoGuarantor} />
        <PrivateRoute exact path="/yawo/guarantors" component={YawoGuarantors} />
        <PrivateRoute exact path="/yawo/collections/:collectionId" component={ViewYawoCollection} />
        <PrivateRoute exact path="/yawo/collections" component={YawoCollections} />
        <PrivateRoute exact path="/yawo/transactions/:transactionRef" component={ViewYawoTransaction} />
        <PrivateRoute exact path="/yawo/transactions" component={YawoTransactions} />
        <PrivateRoute exact path="/yawo/wallets/:historyId" component={SingleWallet} />
        <PrivateRoute exact path="/yawo/wallets" component={YawoWallets} />
        <PrivateRoute exact path="/yawo/loans/:loanId" component={ViewYawoLoan} />
        <PrivateRoute exact path="/yawo/loans" component={YawoLoans} />
        <PrivateRoute exact path="/yawo" component={Yawo} />
        <PrivateRoute exact path="/notifications/transactions" component={TransactionsNotifications} />
        <PrivateRoute exact path="/notifications/cards/:paymentRef" component={ViewCardPayment} />
        <PrivateRoute exact path="/notifications/cards" component={CardPayments} />
        <PrivateRoute exact path="/notifications/ptsps" component={Ptsps} />
        <PrivateRoute exact path="/notifications/flutterwave" component={FwCardPayments} />
        <PrivateRoute exact path="/notifications" component={Notifications} />
        <PrivateRoute exact path="/finance/reconciliation" component={Reconciliation} />
        <PrivateRoute exact path="/finance/chargeback" component={Chargeback} />
        <PrivateRoute exact path="/finance" component={Finance} />
      </Switch>
    </Router>
  );
};

export default Routes;
