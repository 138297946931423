import React from 'react';
import { formatAmount } from 'src/utils/helpers';
import Icon from 'src/components/common/Icon';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CollectionStatus from './CollectionStatus';

export const collectionsTableHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Short code</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['shortCode']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Amount</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{formatAmount(rowData['amount'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Day</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['day']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Date</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['collectionDate']}</p>;
    },
  },
  // {
  //   title: function title() {
  //     return <p>Loan Ref</p>;
  //   },
  //   render: function SerialNumber(rowData) {
  //     return <p>{rowData['loanRef'] || ''}</p>;
  //   },
  // },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function SerialNumber(rowData) {
      return <CollectionStatus status={rowData['collectionStatus']} />;
    },
  },
  {
    title: function title() {
      return <p>Collection Ref</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{rowData['collectionRef'] || ''}</p>;
    },
  },
  {
    title: function title() {
      return <p>View</p>;
    },
    render: function SerialNumber(rowData) {
      return <Icon Icon={OpenInNewIcon} type="link" to={`collections/${rowData['_id']}`} />;
    },
  },
];
