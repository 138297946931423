import React from 'react';
import { formatAmount } from 'src/utils/helpers';
import EditPtsp from './EditPtsp';
import DeletePtsp from './DeletePtsp';

export const ptspsTableHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function getTableNo(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Card Type</p>;
    },
    render: function getPtsp(rowData) {
      return <p>{rowData['cardType']}</p>;
    },
  },
  {
    title: function title() {
      return <p>PTSP</p>;
    },
    render: function getPtsp(rowData) {
      return <p>{rowData['ptsp']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Min</p>;
    },
    render: function getStatus(rowData) {
      return <p>{formatAmount(rowData['min'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Max</p>;
    },
    render: function getStatus(rowData) {
      return <p>{formatAmount(rowData['max'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Actions</p>;
    },
    render: function actions(rowData) {
      return (
        <p style={{ display: 'flex', gap: '1rem', maxWidth: '5rem' }}>
          <div style={{ width: '2rem' }}>
            <EditPtsp ptsp={rowData} />
          </div>
          <div style={{ width: '2rem' }}>
            <DeletePtsp ptsp={rowData} />
          </div>
        </p>
      );
    },
  },
];
