import querystring from 'querystring';
import client from 'src/client';
import {
  ADD_YAWO_GUARANTORS,
  ADD_YAWO_GUARANTOR,
  YAWO_GUARANTOR_IS_LOADING,
  YAWO_GUARANTOR_NOT_LOADING,
  SET_YAWO_GUARANTOR_DATE,
  RESET_YAWO_GUARANTOR_DATE,
  SET_YAWO_GUARANTOR_LIMIT,
  SET_YAWO_GUARANTOR_STATUS,
  SET_YAWO_GUARANTOR_PAGE,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from 'src/store/actions/alertActions';

const { adminService } = client;

const yawoGuarantorIsLoading =
  (msg = 'Loading Yawo Guarantors') =>
  (dispatch) => {
    dispatch({ type: YAWO_GUARANTOR_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const yawoGuarantorNotLoading = () => (dispatch) => {
  dispatch({ type: YAWO_GUARANTOR_NOT_LOADING });
};

const addYawoGuarantors = (yawoGuarantors) => {
  return { type: ADD_YAWO_GUARANTORS, payload: yawoGuarantors };
};

const addYawoGuarantor = (yawoGuarantor) => {
  return { type: ADD_YAWO_GUARANTOR, payload: yawoGuarantor };
};

export const setYawoGuarantorDate = (start_date, end_date) => {
  return { type: SET_YAWO_GUARANTOR_DATE, payload: { start_date, end_date } };
};

export const resetYawoGuarantorDate = () => {
  return { type: RESET_YAWO_GUARANTOR_DATE };
};

export const setYawoGuarantorPage = (page) => {
  return { type: SET_YAWO_GUARANTOR_PAGE, payload: page };
};

export const setYawoGuarantorLimit = (limit) => {
  return { type: SET_YAWO_GUARANTOR_LIMIT, payload: limit };
};

export const setYawoGuarantorStatus = (status) => {
  return { type: SET_YAWO_GUARANTOR_STATUS, payload: status };
};

const generatePath = (endPoint, yawoGuarantor, query) => {
  const { limit, page } = yawoGuarantor || {};
  query = {
    page,
    limit,
    ...query,
  };
  if (query.page === undefined) delete query.page;
  if (query.limit === undefined) delete query.limit;
  let url = endPoint;
  if (query && Object.keys(query).length > 0) {
    url += `?${querystring.encode(query)}`;
  }
  return url;
};

export const getAllYawoGuarantors =
  (query = {}) =>
  (dispatch, getState) => {
    dispatch(yawoGuarantorIsLoading(query?.search ? 'Searching...' : 'Loading Yawo Guarantors'));
    const { yawoGuarantors } = getState().yawoGuarantor;
    const path = generatePath('/yawo/users/guarantors', yawoGuarantors, query);
    adminService
      .get(path)
      .then((res) => {
        dispatch(addYawoGuarantors(res?.data?.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Guarantors' })))
      .finally(() => dispatch(yawoGuarantorNotLoading()));
  };

export const getSingleYawoGuarantor = (userId, extraParams) => (dispatch) => {
  const { silently = false } = extraParams || {};
  if (!silently) {
    dispatch(yawoGuarantorIsLoading('Loading Yawo Guarantor'));
  }
  adminService
    .get(`/yawo/users/${userId}`)
    .then((res) => {
      dispatch(addYawoGuarantor(res?.data?.data));
      dispatch(clearAlert());
    })
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Guarantor' })))
    .finally(() => dispatch(yawoGuarantorNotLoading()));
};

export const activateGuarantor = (userId) => (dispatch) => {
  dispatch(yawoGuarantorIsLoading('Activating Guarantor ...'));
  adminService
    .get(`/yawo/users/${userId}/activate`)
    .then((res) => {
      dispatch(addYawoGuarantor(res?.data?.data));
      dispatch(successAlert('Guarantor Activated'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data?.message || 'Error activating guarantor' }));
    })
    .finally(() => dispatch(yawoGuarantorNotLoading()));
};

export const suspendGuarantor = (userId) => (dispatch) => {
  dispatch(yawoGuarantorIsLoading('Suspending Guarantor ...'));
  adminService
    .get(`/yawo/users/${userId}/suspend`)
    .then((res) => {
      dispatch(addYawoGuarantor(res?.data?.data));
      dispatch(successAlert('Guarantor Suspended'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data?.message || 'Error suspending guarantor' }));
    })
    .finally(() => dispatch(yawoGuarantorNotLoading()));
};

export const verifyGuarantor = (guarantorId) => (dispatch) => {
  dispatch(yawoGuarantorIsLoading('Verifying Guarantor ...'));
  adminService
    .post('/yawo/users/guarantors/verify', { guarantorId })
    .then((res) => {
      dispatch(addYawoGuarantor(res?.data?.data));
      dispatch(successAlert('Guarantor Verified'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data?.message || 'Error verifying guarantor' }));
    })
    .finally(() => dispatch(yawoGuarantorNotLoading()));
};
