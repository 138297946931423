import React from 'react';
import DashboardLayout from 'src/components/layout/DashboardLayout';
import ViewMmoComponent from 'src/components/users/mmo/ViewMmo';

const ViewMmo = () => {
  return (
    <DashboardLayout>
      <ViewMmoComponent />
    </DashboardLayout>
  );
};

export default ViewMmo;
